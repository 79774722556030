import { useTranslation } from 'react-i18next'

import { defT, TErrorKeys } from '@common/validation'

export function useTFunc() {
  const { t } = useTranslation('shared')

  const tFunc: typeof defT = (key: TErrorKeys, options) => {
    return t(`validation.${key}`, options)
  }

  return tFunc
}
