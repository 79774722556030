import { useTranslation } from 'react-i18next'

import { TFormattedPersonalApplication } from '@common/types'

import { AccountApplicationCardTitle } from '@features/account'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

import { getCountryNameByAbbreviation } from '@shared/utils'

interface IProps {
  personalApplication: TFormattedPersonalApplication
}

export function IdentityDocument({ personalApplication }: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <GroupFieldsCard>
      <AccountApplicationCardTitle
        title={t('account-application.identityDocument')}
        sx={{ mb: 2 }}
      />

      <LabelValue
        label={t('account-application.documentType')}
        value={personalApplication.documentType}
      />

      <LabelValue
        label={t('account-application.documentNumber')}
        value={personalApplication.documentNumber}
      />

      <LabelValue
        label={t('account-application.issuingCountry')}
        value={getCountryNameByAbbreviation(personalApplication.issuingCountry)}
      />

      <LabelValue
        label={t('account-application.documentIssue')}
        value={personalApplication.documentIssue}
      />

      <LabelValue
        label={t('account-application.documentExpire')}
        value={personalApplication.documentExpire}
      />

      <LabelValue
        label={t('account-application.citizenship')}
        value={getCountryNameByAbbreviation(personalApplication.citizenship)}
      />
    </GroupFieldsCard>
  )
}
