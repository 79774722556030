import { useMediaQuery, useTheme } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { IUserWithStatuses } from '@common/types'

import { routePaths } from '@shared/constants'
import { IMobileListItem, ComposeTable, MobileList } from '@shared/ui'

import { useUserListTableTemplate } from '../../model'

import { getBodyTableRowSx, StyledEllipsisTypography } from './styled'
import { UserIdvStatus } from './user-idv-status'
import { ValueWithCopyButton } from './value-with-copy-button'

interface IProps {
  users: IUserWithStatuses[]
}

export function UserList({ users }: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const { t } = useTranslation('pages')

  const template = useUserListTableTemplate()

  const handleClick = (userId: string) => {
    navigate(`${routePaths.users.list}/${userId}`)
  }

  const mobileVisibleUsers: IMobileListItem[] | undefined = users?.map(
    (user) => {
      const { active, blocked, createdAt, email, fullName, id, phoneNumber } =
        user

      return {
        id: id,
        warningTrigger: !active || blocked,
        firstRow: [
          fullName || t('user-list.unverified'),
          <UserIdvStatus key={id} data={user} />,
        ],
        middleRows: [
          format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
          <ValueWithCopyButton value={phoneNumber} key={1} />,
          <ValueWithCopyButton value={email} key={2} />,
          !active ? t('user-list.account-deactivated') : undefined,
          blocked ? t('user-list.account-blocked') : undefined,
        ],
        lastRow: [
          <StyledEllipsisTypography
            variant="body1"
            color="text.secondary"
            key={1}
          >
            {`id: ${id}`}
          </StyledEllipsisTypography>,
        ],
      } satisfies IMobileListItem
    }
  )

  return (
    <>
      {mdAndUp && (
        <ComposeTable
          data={users}
          template={template}
          onClickItem={handleClick}
          itemIdProperty="id"
          bodyTableRowSxWithTrigger={getBodyTableRowSx}
          bodyTableRowSxTrigger={['active', 'blocked']}
        />
      )}

      {!mdAndUp && mobileVisibleUsers && (
        <MobileList mobileList={mobileVisibleUsers} onClick={handleClick} />
      )}
    </>
  )
}
