import {
  IVerifyWithdrawalOptBody,
  IVerifyWithdrawalOtpCodeResponse,
  IWithdrawalRequestPathParams,
} from '@common/types'

import { instance } from '@shared/requester'

export function verifyWithdrawalOtpCode({
  otpCode,
  withdrawalId,
}: IVerifyWithdrawalOptBody & Partial<IWithdrawalRequestPathParams>) {
  if (!withdrawalId) {
    throw new Error()
  }

  return instance
    .put<IVerifyWithdrawalOtpCodeResponse>(
      `/withdrawals/${withdrawalId}/verify-otp`,
      {
        otpCode,
      }
    )
    .then((res) => res.data)
}
