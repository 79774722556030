import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { IEmailTemplate, TUpdateEmailTemplateBody } from '@common/types'
import { getUpdateEmailTemplateSchema } from '@common/validation'

import { useTFunc } from '@shared/hooks'

export function useUpdateEmailTemplateForm(template: IEmailTemplate) {
  const tFunc = useTFunc()

  const { minSubjectLength, schema } = getUpdateEmailTemplateSchema(tFunc)

  const form = useForm<TUpdateEmailTemplateBody>({
    resolver: yupResolver(schema),
    defaultValues: {
      subject: template.subject,
      html: template.html,
    },
  })

  return { form, minSubjectLength }
}
