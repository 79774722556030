import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar as AppBarMui,
  Box,
  Drawer,
  IconButton,
  SxProps,
  Toolbar,
  Typography,
} from '@mui/material'
import { useState } from 'react'

import Logo from '@shared/assets/logo.svg'

import { SidebarMenuList } from './components'
import { LogoWrapper } from './styled'

interface IProps {
  sx?: SxProps
}

export function SidebarMobile({ sx }: IProps) {
  const [open, setOpen] = useState(false)

  return (
    <AppBarMui
      position="sticky"
      sx={{
        bgcolor: 'background.paper',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: 'none',
        ...sx,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <LogoWrapper href={'/'}>
          <Box component={'img'} src={Logo} width={'100%'} />

          <Typography color={'info.main'} textAlign={'end'} variant={'body1'}>
            Backoffice
          </Typography>
        </LogoWrapper>

        <IconButton
          edge="start"
          color="primary"
          aria-label="open drawer"
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            width: 300,
            p: 5,
          }}
        >
          <SidebarMenuList
            open={true}
            hover={true}
            sx={{ p: 4, flex: 1, display: 'flex', flexDirection: 'column' }}
            onClickItem={() => setOpen(false)}
          />
        </Drawer>
      </Toolbar>
    </AppBarMui>
  )
}
