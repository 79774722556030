import { styled, Tabs } from '@mui/material'

import { grey } from '@shared/constants'

export const StyledTabs = styled(Tabs)({
  position: 'relative',
  minHeight: 'initial',

  '& .MuiTabs-flexContainer': {
    gap: '20px',
  },

  '& .MuiTabs-indicator': {
    zIndex: 1,
  },

  '&:after': {
    content: "' '",
    display: 'block',
    width: '100%',
    height: '1px',
    backgroundColor: grey[100],
    position: 'absolute',
    bottom: 0,
  },
})
