import { IPersonalApplication } from '@common/types'

import { instance } from '@shared/requester'

interface IPayload extends Partial<IPersonalApplication> {
  id: string
}

export function updateAccountPersonalApplication({ id, ...payload }: IPayload) {
  return instance.put(`/accounts/${id}/personal`, payload)
}
