export const primarySourceIncomeList = [
  'Salary/Pension/Social benefits',
  'Savings',
  'Allowance (dole)',
  'Inheritance',
  'Cryptocurrency',
  'Real estate proceeds',
  'Investment / dividends',
  'Family support',
  'Winnings from Betting or lotteries',
  'Scholarship / student loan',
  'Other',
] as const

export type PrimarySourceIncomeTranslationKeysType =
  | 'salary-pension-social-benefits'
  | 'savings'
  | 'allowance'
  | 'inheritance'
  | 'cryptocurrency'
  | 'real-estate-proceeds'
  | 'investment-dividends'
  | 'family-support'
  | 'winnings-from-betting-or-lotteries'
  | 'scholarship-student-loan'
  | 'other'

export const primarySourceIncomeTranslationKeys: Record<
  primarySourceIncomeListType,
  PrimarySourceIncomeTranslationKeysType
> = {
  'Salary/Pension/Social benefits': 'salary-pension-social-benefits',
  Savings: 'savings',
  'Allowance (dole)': 'allowance',
  Inheritance: 'inheritance',
  Cryptocurrency: 'cryptocurrency',
  'Real estate proceeds': 'real-estate-proceeds',
  'Investment / dividends': 'investment-dividends',
  'Family support': 'family-support',
  'Winnings from Betting or lotteries': 'winnings-from-betting-or-lotteries',
  'Scholarship / student loan': 'scholarship-student-loan',
  Other: 'other',
}

export type primarySourceIncomeListType =
  (typeof primarySourceIncomeList)[number]
