import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { TFormattedBusinessApplication } from '@common/types'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

const mainTranslationPath = 'account-application.business-application'
const mainEntitiesTranslationPath = 'entities:account.business-application'

export function CompanyData({
  personalData,
  privacy,
  terms,
  createdAt,
  updatedAt,
}: TFormattedBusinessApplication) {
  const { t } = useTranslation(['widgets', 'entities'])

  return (
    <GroupFieldsCard>
      <Typography variant="h3" mb={1}>
        {t(`${mainTranslationPath}.company-data`)}
      </Typography>

      <LabelValue
        label={t(`${mainTranslationPath}.personal-data`)}
        value={JSON.stringify(personalData)}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.privacy`)}
        value={JSON.stringify(privacy)}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.terms`)}
        value={JSON.stringify(terms)}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.created`)}
        value={format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.updated`)}
        value={format(new Date(updatedAt), 'dd.MM.yyyy HH:mm')}
      />
    </GroupFieldsCard>
  )
}
