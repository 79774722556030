// import { useQuery } from '@tanstack/react-query'
// import { useEffect } from 'react'
// import { useParams } from 'react-router-dom'

// import { TopUpsTable } from '@widgets/top-up'

// import { useTopUpsFilters } from '@features/top-up'

// import { getTopUpsList } from '@entities/top-up'

// import { TParamsKeys } from '@shared/constants'
// import { useCustomPagination } from '@shared/ui'

export function AccountTopUpsTable() {
  // const { accountId } = useParams<TParamsKeys>()

  // const { currentStatus, filtersOptions } = useTopUpsFilters()

  // const { page, take, paginationNode, setTotalPages, setListLength, setPage } =
  //   useCustomPagination()

  // const { data: topUpsList, isLoading } = useQuery({
  //   queryKey: [
  //     'get-account-top-ups-list',
  //     page,
  //     take,
  //     accountId,
  //     currentStatus,
  //   ],
  //   queryFn: () =>
  //     getTopUpsList({
  //       page: page || 1,
  //       perPage: take || '10',
  //       accountId,
  //       status: currentStatus !== 'all' ? currentStatus : undefined,
  //     }),
  //   refetchInterval: 30 * 1000,
  // })

  // useEffect(() => {
  //   if (topUpsList) {
  //     setTotalPages(topUpsList.total)

  //     setListLength(topUpsList.list.length)
  //   }
  // }, [topUpsList?.total])

  // useEffect(() => {
  //   setPage(1)
  // }, [currentStatus])

  return (
    <></>
    // <TopUpsTable
    //   isLoading={isLoading}
    //   currentStatus={currentStatus}
    //   filterMenuOptions={filtersOptions}
    //   rowsPerPage={+(take || 10)}
    //   paginationNode={paginationNode}
    //   topUpsList={topUpsList?.list}
    //   isOwnPage={false}
    // />
  )
}
