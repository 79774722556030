import { styled, SwipeableDrawer } from '@mui/material'

export const StyledMobileDrawer = styled(SwipeableDrawer)({
  '& > .MuiPaper-root': {
    maxHight: '100vh',
    paddingBottom: '40px',

    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',

    display: 'flex',
    flexDirection: 'column',
  },
})
