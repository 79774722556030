import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ResizeObserverProvider } from '@shared/providers'

import { KeycloakProvider } from './keycloak-provider'
import { QueryProvider } from './query'
import { RouterProvider } from './router'
import { ThemeProvider } from './theme'
import { TimeLocaleProvider } from './time-locale-provider'

export function ComposeProviders() {
  return (
    <ThemeProvider>
      <KeycloakProvider>
        <QueryProvider>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            pauseOnFocusLoss
          />

          <TimeLocaleProvider>
            <ResizeObserverProvider>
              <RouterProvider />
            </ResizeObserverProvider>
          </TimeLocaleProvider>
        </QueryProvider>
      </KeycloakProvider>
    </ThemeProvider>
  )
}
