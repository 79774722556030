import { useQuery } from '@tanstack/react-query'

import { IDepositDetails } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchDepositDetails } from '../../api'

export const useDepositDetails = () => {
  return useQuery<IDepositDetails>({
    queryKey: [queryKeys.depositDetails],
    queryFn: fetchDepositDetails,
  })
}
