import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { TDepositLimits } from '@common/types'
import {
  getCreateDepositSchema,
  getValidationSchemas,
} from '@common/validation'

import { TAccountDepositForm } from '@entities/deposit'

import { useTFunc } from '@shared/hooks'

export function useAccountDepositForm(depositLimits: TDepositLimits) {
  const tFunc = useTFunc()

  const mainDepositSchema = getCreateDepositSchema(tFunc)
  const { getUiAmountSchema } = getValidationSchemas(tFunc)

  const formSchema: yup.ObjectSchema<TAccountDepositForm> =
    mainDepositSchema.concat(
      yup.object().shape({
        amount: getUiAmountSchema(
          depositLimits.EUR.min,
          depositLimits.EUR.max,
          'EUR'
        ),
      })
    )

  const form = useForm<TAccountDepositForm>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      amount: '',
      currency: 'EUR',
      reference: '',
    },
    mode: 'onSubmit',
  })

  return form
}
