import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

export function LabelValue({
  label,
  value,
}: {
  label: string
  value: string | ReactNode
}) {
  return (
    <Stack
      direction="row"
      gap={2.5}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body1">{label}</Typography>

      {typeof value === 'string' ? (
        <Typography variant="body1" textAlign="end">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  )
}
