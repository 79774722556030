import {
  Collapse,
  Divider,
  List,
  ListItem,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSidebarItems } from '../content'
import {
  SidebarMenuListItemButton,
  StyledNotifyCountChip,
  StyledListItemIcon,
} from '../styled'

import { FadeIcon } from './fade-icon'

interface ISidebarMenuListProps {
  sx?: SxProps
  open: boolean
  hover: boolean
  onClickItem?: () => void
  visibleContent?: boolean
}

export function SidebarMenuList({
  sx,
  open,
  hover,
  onClickItem,
  visibleContent,
}: ISidebarMenuListProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const sidebarItems = useSidebarItems()

  const getMainPath = () => pathname.split('/').filter((item) => item)[0]

  const isActivePath = (path?: string) => {
    if (!path) {
      return false
    }

    return getMainPath() === path.replaceAll('/', '')
  }

  return (
    <List sx={{ gap: 1, ...sx }}>
      {sidebarItems.map((item) => {
        const isActive = isActivePath(item.navigationLink)

        return (
          <Fragment key={item.id}>
            <ListItem disablePadding sx={{ position: 'relative', ...item.sx }}>
              <Tooltip
                title={item.title}
                disableHoverListener={open || hover}
                placement={'right'}
                enterDelay={500}
                leaveDelay={200}
              >
                <SidebarMenuListItemButton
                  selected={isActive}
                  disabled={item.disabled}
                  onClick={() => {
                    onClickItem && onClickItem()

                    item.navigationLink && navigate(item.navigationLink)

                    item.action && item.action()
                  }}
                >
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <StyledListItemIcon open={open || hover}>
                      <FadeIcon show={isActive}>
                        <item.iconFill sx={{ color: 'background.paper' }} />
                      </FadeIcon>

                      <FadeIcon show={!isActive}>
                        <item.icon sx={{ color: 'primary.main' }} />
                      </FadeIcon>
                    </StyledListItemIcon>

                    <Collapse in={open || hover} orientation={'horizontal'}>
                      <Typography
                        mb={-0.25}
                        variant={'body1'}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                      >
                        {item.title}
                      </Typography>
                    </Collapse>
                  </Stack>

                  {item.actionsQuantity !== undefined &&
                    item.actionsQuantity > 0 && (
                      <StyledNotifyCountChip
                        active={isActive}
                        visibleContent={!!visibleContent}
                        label={item.actionsQuantity}
                      />
                    )}
                </SidebarMenuListItemButton>
              </Tooltip>
            </ListItem>

            {item.divider && <Divider />}
          </Fragment>
        )
      })}
    </List>
  )
}
