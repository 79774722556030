import { useNavigate } from 'react-router'

import { IWithdrawalListItem } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'
import { ComposeTable, MobileList } from '@shared/ui'

import { withdrawalsPageId, useWithdrawalListTemplate } from '../../model'

interface IProps {
  withdrawals: IWithdrawalListItem[]
}

export function WithdrawalList({ withdrawals }: IProps) {
  const boxMdAndUp = useBoxMediaQuery(withdrawalsPageId).up('md')

  const navigate = useNavigate()

  const { desktopTemplate, mobileTemplate } =
    useWithdrawalListTemplate(withdrawals)

  const handleClick = (id: string) => {
    navigate(id)
  }

  return (
    <>
      {boxMdAndUp ? (
        <ComposeTable
          template={desktopTemplate}
          data={withdrawals}
          itemIdProperty="id"
          onClickItem={handleClick}
        />
      ) : (
        <MobileList mobileList={mobileTemplate} onClick={handleClick} />
      )}
    </>
  )
}
