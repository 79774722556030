import { styled } from '@mui/material'

export const StyledLine = styled('div')({
  width: 48,
  height: 4,

  borderRadius: 100,
  boxShadow: '0px 1px 4px 0px #00000040 inset',

  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})
