import { Stack, SxProps, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IDepositResponse } from '@common/types'

import { TypographyWithCopyButton } from '@shared/ui'

interface IProps
  extends Pick<
    IDepositResponse,
    'id' | 'amount' | 'currency' | 'description' | 'createdAt'
  > {
  sx?: SxProps
}

export function DepositInfo({
  amount,
  createdAt,
  currency,
  description,
  id,
  sx,
}: IProps) {
  const { t } = useTranslation('pages')

  return (
    <Stack direction="row" spacing={1.5} sx={sx}>
      <Stack spacing={0.5} color="text.secondary">
        <Typography variant="body2">{t('deposit.id')}</Typography>
        <Typography variant="body2">{t('deposit.amount')}</Typography>

        {description && (
          <Typography variant="body2">{t('deposit.description')}</Typography>
        )}

        <Typography variant="body2">{t('deposit.created-at')}</Typography>
      </Stack>

      <Stack spacing={0.5}>
        <TypographyWithCopyButton
          variant="body2"
          value={id}
          sx={{ height: { xs: 18, md: 20 } }}
        />

        <Typography variant="body2">{`${amount} ${currency}`}</Typography>

        {description && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}

        <Typography variant="body2">
          {format(new Date(createdAt || 0), 'dd-MM-yyyy HH:mm:ss')}
        </Typography>
      </Stack>
    </Stack>
  )
}
