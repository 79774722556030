import { useRef, useState } from 'react'

import { useDepositLimits } from '@entities/deposit'

import { TConfirmationOptions } from '@shared/types'

import { IResetFormRef } from '../model'

import { AccountDepositConfirmation, CreateAccountDeposit } from './components'

interface IProps {
  reference: string
  onConfirm: () => void
}

export function AccountDeposit({ reference, onConfirm }: IProps) {
  const [isShowOtp, setIsShowOtp] = useState<boolean>(false)
  const resetFormRef = useRef<IResetFormRef>(null)

  const { data: depositLimits } = useDepositLimits()

  const [depositConfirmationOptions, setDepositConfirmationOptions] =
    useState<TConfirmationOptions>(null)

  const handleConfirm = () => {
    resetFormRef.current?.resetForm()

    onConfirm()
  }

  return (
    <>
      {depositLimits && (
        <CreateAccountDeposit
          reference={reference}
          openOtpModal={() => setIsShowOtp(true)}
          setDepositConfirmationOptions={setDepositConfirmationOptions}
          depositLimits={depositLimits}
          ref={resetFormRef}
        />
      )}

      <AccountDepositConfirmation
        depositConfirmationOptions={depositConfirmationOptions}
        setDepositConfirmationOptions={setDepositConfirmationOptions}
        onClose={() => setIsShowOtp(false)}
        show={isShowOtp}
        onConfirm={handleConfirm}
      />
    </>
  )
}
