import { DepositDetails, DepositLimits } from './components'

export function DepositSettings() {
  return (
    <>
      <DepositDetails />
      <DepositLimits />
    </>
  )
}
