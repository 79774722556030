import { useTranslation } from 'react-i18next'

import { WithdrawalSettingsForm } from '@widgets/settings'

import {
  useWithdrawalSettings,
  withdrawalSettingsPageId,
} from '@entities/withdrawal'

import { SettingLayout } from './components'

export function WithdrawalSettings() {
  const { t } = useTranslation('pages')
  const { data, isLoading } = useWithdrawalSettings()

  return (
    <SettingLayout
      data={!!data}
      loading={isLoading}
      title={t('settings.withdrawal-settings')}
      id={withdrawalSettingsPageId}
    >
      {data && <WithdrawalSettingsForm data={data} />}
    </SettingLayout>
  )
}
