import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { Grid, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IBusinessApplicationForm } from '@entities/account-application'

import { CountrySelectInput, DateField } from '@shared/ui'

import { GroupSectionTitle } from '../../../group-section-title'

interface IProps {
  index: number
  disabled?: boolean
}

export function BeneficiaryPersonalDetails({ index, disabled }: IProps) {
  const { control } = useFormContext<IBusinessApplicationForm>()
  const { t } = useTranslation('widgets')

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GroupSectionTitle
          icon={<PersonOutlineOutlinedIcon sx={{ fontSize: 20 }} />}
          title={t('account-application.personalDetails')}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.firstName`}
          render={({ field, fieldState }) => (
            <TextField
              variant="outlined"
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.firstName')}
              placeholder={t('account-application.firstName')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.lastName`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.lastName')}
              placeholder={t('account-application.lastName')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.birthDate`}
          render={({ field, fieldState }) => (
            <DateField
              {...field}
              format="dd.MM.yyyy"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.birthDate')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.nationality`}
          render={({ field, fieldState }) => (
            <CountrySelectInput
              {...field}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.nationality')}
              placeholder={t('account-application.nationality')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.personalNumber`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.personalNumber')}
              placeholder={t('account-application.personalNumber')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
