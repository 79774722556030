import { IAddTransactionDocuments } from '@common/types'

import { instance } from '@shared/requester'

export function uploadTransactionDocuments({
  fileList,
  transactionId,
}: IAddTransactionDocuments) {
  return instance
    .patch(`/transactions/${transactionId}/documents`, { fileList })
    .then((res) => res.data)
}
