import { Grid, SxProps, TextField } from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CountrySelectInput } from '@shared/ui'

interface IProps<T extends FieldValues> {
  addressName: FieldPath<T>
  zipCodeName: FieldPath<T>
  countryName: FieldPath<T>
  cityName: FieldPath<T>
  sx?: SxProps
}

export function HomeAddressFields<T extends FieldValues>({
  addressName,
  cityName,
  countryName,
  zipCodeName,
  sx,
}: IProps<T>) {
  const { t } = useTranslation('features')
  const { control } = useFormContext<T>()

  return (
    <Grid
      sx={sx}
      container
      columnSpacing={{ xs: 0, md: 4.5 }}
      rowSpacing={{ xs: 1.5, md: 2 }}
    >
      <Grid item xs={12} md={8}>
        <Controller
          control={control}
          name={addressName}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              value={field.value || ''}
              inputRef={field.ref}
              ref={undefined}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('accounts-application.home-address.address')}
              placeholder={t('accounts-application.home-address.address')}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={zipCodeName}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              value={field.value || ''}
              inputRef={field.ref}
              ref={undefined}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('accounts-application.home-address.post-code')}
              placeholder={t('accounts-application.home-address.post-code')}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={countryName}
          render={({ field, fieldState }) => (
            <CountrySelectInput
              {...field}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('accounts-application.home-address.country')}
              placeholder={t('accounts-application.home-address.country')}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={cityName}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              value={field.value || ''}
              inputRef={field.ref}
              ref={undefined}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('accounts-application.home-address.city')}
              placeholder={t('accounts-application.home-address.city')}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
