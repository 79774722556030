import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import to from 'await-to-js'
import { Fragment, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TOnCloseMethod, TOtpResendCodeMethod } from '../../model'
import { ErrorText, ResendButton } from '../styled'

import { Timer } from './timer'

interface IProps {
  onTimerFinish?: () => void
  setShowResend: (value: boolean) => void
  reset: () => void
  setLoading: (value: boolean) => void
  resendCodeMethod?: TOtpResendCodeMethod
  handleClose: TOnCloseMethod
  loading: boolean
  showResend: boolean
  timer: number
}

export function Actions({
  onTimerFinish,
  setShowResend,
  reset,
  resendCodeMethod,
  loading,
  setLoading,
  handleClose,
  showResend,
  timer,
}: IProps) {
  const [otpTimer, setOtpTimer] = useState(timer)
  const { t } = useTranslation('shared')

  const handleTimerFinish = () => {
    setShowResend(true)

    reset()

    onTimerFinish && onTimerFinish()
  }

  const handleResendOtp = async () => {
    try {
      if (!resendCodeMethod) {
        // eslint-disable-next-line no-console
        return console.error('OTPDialog: resendCodeMethod is required')
      }

      setLoading(true)

      const [error, result] = await to(resendCodeMethod())

      setLoading(false)

      if (error || !result.result) {
        toast.error(t('otp dialog.errors.resendOtpCodeFail'))

        handleClose('resendOtpFail')

        return
      }

      setOtpTimer(result.ttl)

      toast.success(t('otp dialog.resendSuccess'))

      reset()

      setShowResend(false)
    } catch {
      handleClose('resendOtpFail')
    }
  }

  if (showResend) {
    return (
      <Fragment>
        <ErrorText sx={{ mt: 4 }}>
          <Trans ns="shared" i18nKey="otp dialog.errors.otpCodeTimeout" />
        </ErrorText>

        {resendCodeMethod && (
          <ResendButton
            loading={loading}
            onClick={handleResendOtp}
            sx={{ mt: 2 }}
          >
            <Trans ns="shared" i18nKey="otp dialog.resendOtpCode" />
          </ResendButton>
        )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Timer timer={otpTimer} onFinish={handleTimerFinish} />

      <LoadingButton
        variant="contained"
        loading={loading}
        type="submit"
        sx={{
          mt: { xs: '24px', md: '36px' },
          p: { xs: '16px 64px', md: '12px 64px' },
          borderRadius: '8px',
        }}
      >
        <Typography fontSize={20} fontWeight={500} lineHeight={1.2}>
          <Trans ns="common" i18nKey="confirm" />
        </Typography>
      </LoadingButton>
    </Fragment>
  )
}
