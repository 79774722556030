import { IWithdrawalListItem } from '@common/types'

import { useWithdrawalStatusOptions } from '@entities/withdrawal'

import { ITemplateProps, StatusChip } from '@shared/ui'

export function WithdrawalStatus({
  data: { status },
}: ITemplateProps<IWithdrawalListItem>) {
  const { getStatusColor, getStatusText } = useWithdrawalStatusOptions(status)

  return (
    <StatusChip
      circleColor={getStatusColor()}
      text={getStatusText()}
      variant="text"
    />
  )
}
