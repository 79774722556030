import * as yup from 'yup'

import { IUploadFileSettings } from '../../types'
import { defT } from '../validation-rules'

export const getUploadFileSettingsSchema = (tFunc?: typeof defT) => {
  const t = tFunc || defT

  const schema: yup.ObjectSchema<IUploadFileSettings> = yup.object().shape({
    allowedTypes: yup
      .array()
      .of(yup.string().required(t('required')))
      .required(t('required')),
    maxFileSize: yup
      .number()
      .required(t('required'))
      .positive(t('positive-number')),
    fileMaxNumber: yup
      .number()
      .required(t('required'))
      .positive(t('positive-number'))
      .integer(t('integer-number')),
  })

  return schema
}
