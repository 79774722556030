import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { IGetExchangeById, ISingleErrorResponse } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchExchangeDetails } from '../../api'

export function useExchangeDetails(id?: string) {
  return useQuery<
    IGetExchangeById | undefined,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.exchangeDetails, id],
    queryFn: () => (id ? fetchExchangeDetails(id) : undefined),
    enabled: !!id,
    refetchInterval: 5 * 1000,
  })
}
