import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import { Grid, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IBusinessApplicationForm } from '@entities/account-application'

import { GroupSectionTitle } from '../../../group-section-title'

interface IProps {
  index: number
  disabled: boolean
}

export function BeneficiaryContacts({ index, disabled }: IProps) {
  const { control } = useFormContext<IBusinessApplicationForm>()
  const { t } = useTranslation('widgets')

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GroupSectionTitle
          icon={<LocalPhoneOutlinedIcon sx={{ fontSize: 20 }} />}
          title={t('account-application.contacts')}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.phoneNumber`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.phoneNumber')}
              placeholder={t('account-application.phoneNumber')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.email`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.email')}
              placeholder={t('account-application.email')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
