import { Stack, SxProps, Typography, useTheme } from '@mui/material'

import { TypographyWithCopyButton } from '@shared/ui'

interface IProps {
  label: string
  value?: string | number
  copyable?: boolean
  sx?: SxProps
}

export function SecondaryInfoRow({ label, value, copyable, sx }: IProps) {
  const theme = useTheme()

  return (
    <Stack spacing={0.25} direction="column" alignItems="initial" sx={sx}>
      <Typography variant="subtitle2" color="text.secondary">
        {label.toUpperCase()}
      </Typography>

      {copyable ? (
        <TypographyWithCopyButton
          variant="h5"
          value={value}
          sx={{
            maxWidth: 200,
            height: theme.typography.h5.lineHeight,
            '& .MuiButtonBase-root': { color: theme.palette.common.black },
          }}
        />
      ) : (
        <Typography variant="h5" className="ellipsis" color="text.primary">
          {value}
        </Typography>
      )}
    </Stack>
  )
}
