import { SxProps } from '@mui/material'
import { useState, useMemo, memo } from 'react'

import { IFeePlan } from '@common/types'

import { FeePlanAccordionDetails } from './fee-plan-accordion-details'
import { FeePlanAccordionSummary } from './fee-plan-accordion-summary'
import { StyledAccordion } from './styled'

interface IMainProps {
  feePlan: IFeePlan
  currentFeePlanId: string
  sx?: SxProps
}

interface ICurrentProps extends IMainProps {
  expandedFeePlanId?: never
  onExpand?: never
  loading?: never
  selectedFeePlanId?: string | null
  onSelect?: never
}

interface INotCurrentProps extends IMainProps {
  expandedFeePlanId: string | null
  onExpand: () => void
  loading: boolean
  selectedFeePlanId: string | null
  onSelect: () => void
}

type TProps = ICurrentProps | INotCurrentProps

export const FeePlanAccordion = memo(function FeePlanAccordion({
  expandedFeePlanId,
  currentFeePlanId,
  feePlan,
  onExpand,
  loading,
  selectedFeePlanId,
  onSelect,
  sx,
}: TProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const handleExpand = () => {
    onExpand ? onExpand() : setIsExpanded(!isExpanded)
  }

  const expanded = useMemo(
    () => (!onExpand ? isExpanded : expandedFeePlanId === feePlan.id),
    [expandedFeePlanId, feePlan, isExpanded]
  )

  return (
    <StyledAccordion
      expanded={expanded}
      current={!onExpand}
      key={feePlan.id}
      sx={sx}
    >
      <FeePlanAccordionSummary
        name={feePlan.name}
        isDefault={!!feePlan.isDefault}
        loading={loading}
        current={currentFeePlanId === feePlan.id}
        onExpand={handleExpand}
        expanded={expanded}
        selected={selectedFeePlanId === feePlan.id}
        onSelect={onSelect}
      />

      <FeePlanAccordionDetails feePlan={feePlan} />
    </StyledAccordion>
  )
})
