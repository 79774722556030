import { Stack, SxProps, Typography, TypographyOwnProps } from '@mui/material'

import { CopyButton } from '@shared/ui'

interface IProps {
  variant: TypographyOwnProps['variant']
  value?: string | number
  sx?: SxProps
}

export function TypographyWithCopyButton({ variant, value, sx }: IProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5} sx={sx}>
      <Typography variant={variant} className="ellipsis">
        {value}
      </Typography>

      <CopyButton value={value} size="small" />
    </Stack>
  )
}
