import { Box, Button } from '@mui/material'
import { Outlet, useParams } from 'react-router-dom'

import { useAccountDetails } from '@entities/account'
import { updateAccountPersonalApplication } from '@entities/personal-account-application'

import { TParamsKeys } from '@shared/constants'

import { IContext, usePersonalApplicationForms } from '../model'

import { ApplicationTabs } from './components'

export function PersonalAccountApplicationLayout() {
  const { accountId } = useParams<TParamsKeys>()

  const { data: account } = useAccountDetails({ accountId })

  const {
    expectedTurnoverForm,
    personalDetailsForm,
    identityDocumentForm,
    homeAddressForm,
    termsAndConditionsForm,
    checkIfAllFormsAreValid,
    getChangedFields,
  } = usePersonalApplicationForms()

  const context: IContext = {
    personalDetailsForm,
    identityDocumentForm,
    expectedTurnoverForm,
    termsAndConditionsForm,
    homeAddressForm,
  }

  return (
    <Box p={2} position="relative">
      {accountId && <ApplicationTabs accountId={accountId} />}
      <Outlet context={context} />

      <Button
        variant="contained"
        onClick={async () => {
          const isValid = await checkIfAllFormsAreValid()

          if (isValid && accountId && account?.personalApplication?.id) {
            const fields = getChangedFields()

            await updateAccountPersonalApplication({
              id: account.personalApplication.id,
              ...fields,
            })
          }
        }}
      >
        Save changes
      </Button>
    </Box>
  )
}
