import { Grid } from '@mui/material'

import { TFormattedParticipant } from '@common/types'

import { PersonalParticipantCard, BusinessParticipantCard } from './components'

interface IProps {
  participants?: TFormattedParticipant[]
}

export function ParticipantCard({ participants }: IProps) {
  return (
    <Grid container spacing={3}>
      {participants?.map((participant) =>
        'firstName' in participant ? (
          <Grid item xs={12} sm={6} md={4} xl={3} key={participant.id}>
            <PersonalParticipantCard {...participant} />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={4} xl={3} key={participant.id}>
            <BusinessParticipantCard {...participant} />
          </Grid>
        )
      )}
    </Grid>
  )
}
