import to from 'await-to-js'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ISingleErrorResponse, TOtpError } from '@common/types'

import {
  approveWithdrawal,
  verifyWithdrawalOtpCode,
} from '@entities/withdrawal'

import { TConfirmationOptions } from '@shared/types'
import {
  TOtpConfirmationStatuses,
  TOtpConfirmationMethod,
  TOtpResendCodeMethod,
} from '@shared/ui'
import { getTimeToLeftFromTimestamp } from '@shared/utils'

import { useUpdateWithdrawalQueryData } from './use-update-withdrawal-data'

export function useConfirmWithdrawal(withdrawalId?: string) {
  if (!withdrawalId) {
    throw new Error('withdrawalId is required')
  }

  const [isShowOtp, setIsShowOtp] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [withdrawalConfirmationOptions, setWithdrawalConfirmationOptions] =
    useState<TConfirmationOptions | null>(null)

  const { t } = useTranslation(['features', 'shared'])

  const updateWithdrawalQueryData = useUpdateWithdrawalQueryData(withdrawalId)

  const otpErrorResolver = (error?: TOtpError): TOtpConfirmationStatuses => {
    switch (error) {
      case 'OTP_INVALID':
        return 'NOT_VALID'
      // TODO: backend returns only "OTP_INVALID". Need to add "OTP_EXPIRED" and "MAX_ATTEMPTS"
      case 'OTP_EXPIRED':
        return 'EXPIRE'
      default:
        throw new Error(t('shared:toasts.try-again'))
    }
  }

  const confirmationMethod: TOtpConfirmationMethod = async ({ otp }) => {
    const [error, response] = await to(
      verifyWithdrawalOtpCode({ withdrawalId, otpCode: otp })
    )

    if (!error && response) {
      updateWithdrawalQueryData(
        response.newStatus,
        response.newAvailableBalance
      )

      return 'SUCCESS'
    }

    return otpErrorResolver(
      (error as AxiosError<ISingleErrorResponse<TOtpError>>).response?.data
        .error
    )
  }

  const onConfirm = () => {
    toast.success(t('shared:toasts.success'))

    setIsShowOtp(false)
  }

  const resendOtp: TOtpResendCodeMethod = async () => {
    const [error, response] = await to(approveWithdrawal(withdrawalId))

    if (error || !response) {
      toast.error(t('shared:toasts.try-again'))

      throw { result: false, ttl: 0 }
    }

    setWithdrawalConfirmationOptions({
      id: response.withdrawalId,
      end: getTimeToLeftFromTimestamp(
        new Date(response.confirmation.end).getTime()
      ),
      attempts: response.confirmation.tries,
    })

    return {
      result: true,
      ttl: getTimeToLeftFromTimestamp(
        new Date(response.confirmation.end).getTime()
      ),
    }
  }

  const handleClickConfirm = async () => {
    try {
      setIsLoading(true)

      const response = await approveWithdrawal(withdrawalId)

      setWithdrawalConfirmationOptions({
        id: response.withdrawalId,
        end: getTimeToLeftFromTimestamp(
          new Date(response.confirmation.end).getTime()
        ),
        attempts: response.confirmation.tries,
      })

      setIsShowOtp(true)
    } catch {
      toast.error(t('shared:toasts.try-again'))
    } finally {
      setIsLoading(false)
    }
  }

  return {
    handleClickConfirm,
    resendOtp,
    onConfirm,
    confirmationMethod,
    isLoading,
    isShowOtp,
    withdrawalConfirmationOptions,
    closeOtpDialog: () => !isLoading && setIsShowOtp(false),
  }
}
