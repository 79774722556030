import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Box, Button, Link, Modal } from '@mui/material'
import { useState } from 'react'

interface IProps {
  options:
    | {
        s3Link: string
        contentType: string
      }
    | null
    | undefined
}

export function ShowOrDownloadFile({ options }: IProps) {
  const link = options?.s3Link
  const type = options?.contentType

  const [showImageOnFullScreen, setShowImageOnFullScreen] =
    useState<boolean>(false)

  const isPdf = type === 'application/pdf'

  const isImage =
    type === 'image/jpeg' || type === 'image/jpg' || type === 'image/png'

  return (
    <>
      {isPdf && (
        <Link href={link} target="_blank">
          <PictureAsPdfIcon fontSize="large" />
        </Link>
      )}

      {isImage && (
        <>
          <Button
            sx={{ p: 0, minHeight: 20 }}
            onClick={() => setShowImageOnFullScreen(true)}
          >
            <Box component="img" src={link} sx={{ width: 100 }} />
          </Button>

          <Modal
            open={showImageOnFullScreen}
            onClose={() => setShowImageOnFullScreen(false)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box component="img" src={link} sx={{ width: 800 }} />
          </Modal>
        </>
      )}
    </>
  )
}
