import { Button, styled } from '@mui/material'

export const StyledCancelBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.warning.light,

  color: theme.palette.warning.dark,

  width: '50%',

  '&:hover': {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.error.light,
  },
}))
