import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  IGetTransactionListQueryParams,
  IPaginationResponse,
  ISingleErrorResponse,
  ITransactionListItem,
} from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchTransactionList } from '../../api'

export function useTransactionList(
  params: IGetTransactionListQueryParams = {}
) {
  return useQuery<
    IPaginationResponse<ITransactionListItem> | null,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.transactionList, params],
    queryFn: () =>
      params.skip !== undefined && params.take
        ? fetchTransactionList(params)
        : null,
    refetchInterval: 90 * 1000,
  })
}
