import { Box } from '@mui/material'

import { ITemplateProps } from './index'

export function HiddenMiddleTemplate({ data }: ITemplateProps<string>) {
  const truncatedData = data.slice(0, 4) + '...' + data.slice(-4)

  return (
    <Box component="span" display="block" width={100} className="ellipsis">
      {truncatedData}
    </Box>
  )
}
