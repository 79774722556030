import { Paper, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const GroupFieldsCard = styled(Paper)(({ theme }) => ({
  padding: '12px 20px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  borderRadius: '12px',
  border: `1px solid ${grey[100]}`,

  [theme.breakpoints.up('sm')]: {
    padding: '20px 28px',
  },
}))
