import { LoadingButton } from '@mui/lab'
import { Box, Grid, Stack, TextField, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TCurrency, TDepositLimits } from '@common/types'

import { updateDepositLimits, useDepositLimitsForm } from '@entities/deposit'

import { queryKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'

import { settingPageId } from '../model'

interface IProps {
  data: TDepositLimits
}

export function DepositLimitsSettings({ data }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const mdAndUp = useBoxMediaQuery(settingPageId).up(800)

  const queryClient = useQueryClient()
  const { t } = useTranslation(['widgets', 'shared'])

  const form = useDepositLimitsForm(data)

  const { control, handleSubmit, reset } = form

  const onSubmit = async (updateData: TDepositLimits) => {
    try {
      if (isEqual(data, updateData)) {
        toast.info(t('shared:toasts.no-changes'))

        return
      }

      setIsLoading(true)

      const response = await updateDepositLimits(updateData)

      toast.success(t('shared:toasts.success'))

      queryClient.setQueryData([queryKeys.depositLimits], response)

      reset(response)
    } catch {
      toast.error(t('shared:toasts.try-again'))
    } finally {
      setIsLoading(false)
    }
  }

  const dataEntries = Object.keys(data) as TCurrency[]

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={2}>
      {dataEntries.map((currency, index) => (
        <Box key={index}>
          <Stack
            direction={mdAndUp ? 'row' : 'column'}
            gap={mdAndUp ? 3 : 1}
            alignItems={mdAndUp ? 'center' : 'initial'}
          >
            <Typography variant="h4" flexBasis={mdAndUp ? 80 : 'initial'}>
              {currency}:
            </Typography>

            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name={`${currency}.min`}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      ref={null}
                      inputRef={field.ref}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      label={t('settings.deposit-limits.min-label')}
                      autoFocus
                      fullWidth
                      sx={{ minHeight: 93 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name={`${currency}.max`}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      ref={null}
                      inputRef={field.ref}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      label={t('settings.deposit-limits.max-label')}
                      fullWidth
                      sx={{ minHeight: 93 }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              loading={isLoading}
              variant="contained"
              fullWidth={!mdAndUp}
              sx={{ display: 'flex', mx: 'auto', minWidth: 200 }}
            >
              {t('settings.deposit-limits.button')}
            </LoadingButton>
          </Stack>
        </Box>
      ))}
    </Stack>
  )
}
