import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  IDepositListItem,
  IGetDepositListQueryParams,
  IPaginationResponse,
  ISingleErrorResponse,
} from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchDepositList } from '../../api'

export function useDepositList(params: IGetDepositListQueryParams = {}) {
  return useQuery<
    IPaginationResponse<IDepositListItem> | null,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.depositList, params],
    queryFn: () =>
      params.skip !== undefined && params.take
        ? fetchDepositList(params)
        : null,
    refetchInterval: 5 * 60 * 1000,
  })
}
