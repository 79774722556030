import * as yup from 'yup'

import { IDepositDetails } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

export function getDepositDetailsSchema(tFunc?: typeof defT) {
  const { ibanSchema, bicSchema, nameSchema } = getValidationSchemas(tFunc)

  const depositDetailsSchema: yup.ObjectSchema<IDepositDetails> = yup
    .object()
    .shape({
      IBAN: ibanSchema,
      BIC: bicSchema,
      bankName: nameSchema,
      name: nameSchema,
      companyAddress: nameSchema,
    })

  return { depositDetailsSchema }
}
