import { Paper, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useExchangeList } from '@entities/exchange'

import { ListLoader, useCustomPagination } from '@shared/ui'
import { getPaginationSkip } from '@shared/utils'

import { exchangeListPageId } from '../model'

import { ExchangeList } from './components'

export function ExchangeListPage() {
  const { t } = useTranslation(['pages', 'shared'])

  // TODO: use this hook when the filters are ready
  // const { currentMethod, currentStatus, currentType } = useExchangesFilters()

  const { take, paginationNode, setTotalPages, setListLength, page } =
    useCustomPagination()

  const {
    data: exchanges,
    isLoading,
    isPlaceholderData,
  } = useExchangeList({
    take: take,
    skip: getPaginationSkip(take, page),
  })

  useEffect(() => {
    if (exchanges) {
      const totalPages = Math.ceil(exchanges.total / exchanges.take)

      setTotalPages(totalPages)

      setListLength(exchanges.list.length)
    }
  }, [exchanges])

  // useEffect(() => {
  //   setPage(1)
  // }, [currentMethod, currentStatus, currentType])

  return (
    <Paper sx={{ padding: '16px 24px 28px' }} id={exchangeListPageId}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p="12px"
      >
        <Typography variant="h4">{t('exchange-list.title')}</Typography>
      </Stack>

      {isLoading && <ListLoader skeletonsNumber={take} />}

      {!isLoading && exchanges && exchanges?.list.length > 0 && (
        <ExchangeList
          exchangesList={exchanges.list}
          take={take}
          loading={isPlaceholderData}
        />
      )}

      {!isLoading && !exchanges?.list.length && (
        <Typography variant="h6">{t('shared:no-data')}</Typography>
      )}

      {paginationNode}
    </Paper>
  )
}
