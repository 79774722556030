import { SxProps, Stack } from '@mui/material'

import { InfoButton } from '@shared/ui/info-button'

import { ShortTemplate } from './short-template'

interface IProps {
  data: string
  infoMessage: string
  error?: boolean
  showInfo?: boolean
  short?: boolean
  sx?: SxProps
}

export function InfoCellTemplate({
  data,
  infoMessage,
  error,
  showInfo = true,
  short,
  sx,
}: IProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1} sx={sx}>
      {short ? <ShortTemplate data={data} /> : data}

      {showInfo && (
        <InfoButton
          popperSx={{ maxWidth: 228 }}
          label={infoMessage}
          error={error}
        />
      )}
    </Stack>
  )
}
