import { number, object, ObjectSchema, string } from 'yup'

import {
  assets,
  currencies,
  ICreateExchange,
  TAsset,
  TCurrency,
} from '../../types'

export const createExchangeValidationSchema: ObjectSchema<ICreateExchange> =
  object({
    accountId: string().required(),
    asset: string().required().oneOf<TAsset>(assets),
    currency: string().required().oneOf<TCurrency>(currencies),
    amount: number().required(),
  })
