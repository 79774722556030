import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { FeePlanList } from '@widgets/settings'

import { useFeePlans } from '@entities/fee-plans'

import { routePaths } from '@shared/constants'

import { SettingLayout } from './components'

export function FeePlanListPage() {
  const { t } = useTranslation('pages')
  const { data, isLoading } = useFeePlans()
  const navigate = useNavigate()

  const handleCreateNewFeePlan = () => {
    navigate(`${routePaths.settings.feePlan.list}/NEW`)
  }

  return (
    <SettingLayout
      data={!!data}
      loading={isLoading}
      title={
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4">{t('settings.fee-plans')}</Typography>

          <Box
            display="flex"
            gap={1}
            justifyContent={'space-between'}
            alignItems="center"
            onClick={handleCreateNewFeePlan}
          >
            <Typography variant="h4" color="text.secondary">
              {t('fee-plan-list.add-fee-plan')}
            </Typography>

            <IconButton>
              <AddCircleIcon color="primary" fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      }
    >
      {data && <FeePlanList data={data} />}
    </SettingLayout>
  )
}
