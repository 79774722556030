import {
  IDepositListItem,
  IGetDepositListQueryParams,
  IPaginationResponse,
} from '@common/types'

import { instance } from '@shared/requester'

export async function fetchDepositList(params: IGetDepositListQueryParams) {
  return instance
    .get<IPaginationResponse<IDepositListItem>>('/deposits', { params })
    .then((res) => res.data)
}
