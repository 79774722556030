import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { getUploadFileSettingsSchema } from '@common/validation'

import { useTFunc } from '@shared/hooks'

import { TUploadFileSettingsForm } from '../types'

export function useUploadFileSettingsForm(
  defaultValues: TUploadFileSettingsForm
) {
  const tFunc = useTFunc()

  const schema = getUploadFileSettingsSchema(tFunc).shape({
    allowedTypes: yup
      .array()
      .of(
        yup
          .object({
            value: yup.string().required(tFunc('required')),
          })
          .required(tFunc('required'))
      )
      .required(tFunc('required')),
  })

  const form = useForm<TUploadFileSettingsForm>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  return form
}
