import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TFormattedBusinessApplication } from '@common/types'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

const mainTranslationPath = 'account-application.business-application'

export function CompanyPlannedMonthlyTurnover({
  planedMonthlyTurnoverIncoming,
  planedMonthlyTurnoverOutgoing,
}: TFormattedBusinessApplication) {
  const { t } = useTranslation('widgets')

  return (
    <GroupFieldsCard>
      <Typography variant="h3" mb={1}>
        {t(`${mainTranslationPath}.planned-monthly-turnover`)}
      </Typography>

      <LabelValue
        label={t(`${mainTranslationPath}.incoming`)}
        value={planedMonthlyTurnoverIncoming}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.outgoing`)}
        value={planedMonthlyTurnoverOutgoing}
      />
    </GroupFieldsCard>
  )
}
