// import { useEffect } from 'react'

// import { ExchangesList } from '@widgets/exchanges-table'

// import { useExchangesFilters } from '@features/exchange'

// import { useGetExchangesList } from '@entities/exchange'

// import { useCustomPagination } from '@shared/ui'

export function AccountExchangesTable() {
  // const { currentMethod, currentStatus, currentType, filtersOptions } =
  //   useExchangesFilters()

  // const { take, paginationNode, setTotalPages, setListLength, setPage } =
  //   useCustomPagination()

  // const { data: exchanges } = useGetExchangesList({
  //   // page: page || 1,
  //   // perPage: take || '10',
  //   // method: currentMethod,
  //   // type: currentType,
  //   // status: currentStatus,
  //   // wallet: currentWallet.split(' ')[0], // currentWallet = `${wallet.address} (${wallet.blockchain})`,
  //   // userId: account?.userId,
  //   // enabled: !!wallets && !!currentWallet,
  // })

  // useEffect(() => {
  //   if (exchanges) {
  //     setTotalPages(exchanges.total)

  //     setListLength(exchanges.list.length)
  //   }
  // }, [exchanges?.total])

  // useEffect(() => {
  //   setPage(1)
  // }, [currentType, currentStatus, currentMethod])

  return (
    //   <ExchangesList
    //     filtersOptions={filtersOptions}
    //     // isLoading={isWalletsLoading || isLoadingExchanges || isLoadingAccount}
    //     rowsPerPage={+(take || 10)}
    //     currentType={currentType}
    //     currentStatus={currentStatus}
    //     currentMethod={currentMethod}
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     //@ts-ignore
    //     exchangesList={exchanges?.list}
    //     paginationNode={paginationNode}
    //     // walletAddressFilterOptions={walletAddressFilterOptions}
    <></>
  )
}
