import { useState } from 'react'

import { CustomPagination } from '../../ui'

export function useCustomPagination() {
  const [page, setPage] = useState<number>()
  const [take, setTake] = useState<string>()
  const [totalPages, setTotalPages] = useState<number>(1)
  const [listLength, setListLength] = useState<number>(0)

  const paginationNode = (
    <CustomPagination
      onChangeTake={setTake}
      onChangePage={setPage}
      totalPages={totalPages}
      take={take}
      currentPage={page}
      listLength={listLength}
    />
  )

  return {
    paginationNode,
    page,
    take,
    setTotalPages,
    setListLength,
    setPage,
  }
}
