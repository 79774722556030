import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { deleteFeePlan } from '@entities/fee-plans'

import { routePaths } from '@shared/constants'

interface IProps {
  show?: boolean
  onClose?: () => void
  id: string
}

export function DeleteFeePlanModal({ show, onClose, id }: IProps) {
  const { t } = useTranslation(['features', 'common'])

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteFeePlan = async (id: string) => {
    setIsLoading(true)

    try {
      await deleteFeePlan(id)

      toast.success(t('fee-plan.modal.success'))

      onClose?.()

      navigate(routePaths.settings.feePlan.list)
    } catch {
      toast.error(t('fee-plan.modal.error'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      open={!!show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      sx={{ '& .MuiPaper-root': { pb: 2 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" color="error" textAlign="center">
          {t('fee-plan.modal.warning-message')}
        </Typography>
      </DialogTitle>

      <DialogActions sx={{ px: 3, justifyContent: 'center' }}>
        <LoadingButton
          loading={isLoading}
          onClick={() => handleDeleteFeePlan(id)}
          variant="outlined"
          fullWidth
          disabled={isLoading}
          color={'error'}
        >
          {t('fee-plan.modal.delete')}
        </LoadingButton>

        <Button
          variant="contained"
          onClick={onClose}
          fullWidth
          disabled={isLoading}
        >
          {t('common:cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
