import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  IGetNumberOfOperationsNeededToBeApprovedManuallyResponse,
  ISingleErrorResponse,
} from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchOperationsNumberNeededToBeApprovedManually } from '../../api'

export function useOperationsNumberNeededToBeApprovedManually() {
  return useQuery<
    IGetNumberOfOperationsNeededToBeApprovedManuallyResponse,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.operationsNumberNeededToBeApprovedManually],
    queryFn: fetchOperationsNumberNeededToBeApprovedManually,
    refetchInterval: 30 * 1000,
  })
}
