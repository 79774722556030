import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  IAccountCryptoBalance,
  IGetCryptoBalanceListQueryParams,
  IPaginationResponse,
  ISingleErrorResponse,
} from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchCryptoBalanceList } from '../../api'

export function useCryptoBalanceList(params: IGetCryptoBalanceListQueryParams) {
  return useQuery<
    IPaginationResponse<IAccountCryptoBalance> | null,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.cryptoBalanceList, params],
    queryFn: () => fetchCryptoBalanceList(params),
    refetchInterval: 5 * 60 * 1000,
  })
}
