import to from 'await-to-js'
import { AxiosError, AxiosResponse } from 'axios'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { ISingleErrorResponse } from '@common/types'

import {
  useAccountDetails,
  useGetAccountStatusOptions,
} from '@entities/account'
import {
  IBusinessApplicationForm,
  useAccountApplicationModalState,
} from '@entities/account-application'
import { updateAccountStatus } from '@entities/account-application'

import { TParamsKeys } from '@shared/constants'

type TModalType = 'approve' | 'reject' | 'approve-closed' | 'reject-closed'

interface IModalTexts {
  title: string
  text: string
  button: string
}

export function useUpdateAccountStatus() {
  const { t } = useTranslation('features')

  const { accountId } = useParams<TParamsKeys>()

  const { data: account, refetch: refetchAccountDetails } = useAccountDetails({
    accountId,
  })

  const { getStatusText, getStatusColor } = useGetAccountStatusOptions(
    account?.status
  )

  const [disabledButtons, setDisabledButtons] = useState<boolean>(false)

  const isBusinessAccount = account?.variant === 'BUSINESS'

  const form = useFormContext<IBusinessApplicationForm>()

  const { openModal } = useAccountApplicationModalState()

  const [openConfirmation, setOpenConfirmation] = useState<null | TModalType>(
    null
  )

  const handleOpen =
    (type: Extract<TModalType, 'approve' | 'reject'>) => () => {
      setOpenConfirmation(type)
    }

  const handleClose = () => {
    if (disabledButtons) {
      return
    }

    const closedType: Extract<TModalType, 'approve-closed' | 'reject-closed'> =
      openConfirmation === 'approve' ? 'approve-closed' : 'reject-closed'

    setOpenConfirmation(closedType)
  }

  const approveApplication = async () => {
    try {
      setDisabledButtons(true)

      if (!account) {
        return
      }

      if (
        isBusinessAccount &&
        (form?.formState?.isDirty ||
          form?.getValues('beneficiaries').length === 0)
      ) {
        openModal('beneficiaries')

        handleClose

        return
      }

      // TODO: this checking only for dev mode. This mistake should not happen in production
      if (!account.user.fullName) {
        toast.error('User does not have full name')

        return
      }

      const [error] = await to<
        AxiosResponse<object>,
        AxiosError<ISingleErrorResponse>
      >(updateAccountStatus({ accountId: account.id, status: 'APPROVED' }))

      if (error) {
        toast.error(error.response?.data.error)

        return
      }

      await refetchAccountDetails()
    } catch {
      return
    } finally {
      handleClose()

      setTimeout(() => {
        setDisabledButtons(false)
      }, 300)
    }
  }

  const rejectApplication = async () => {
    try {
      setDisabledButtons(true)

      if (!account) {
        return
      }

      const [error] = await to<
        AxiosResponse<object>,
        AxiosError<ISingleErrorResponse>
      >(updateAccountStatus({ accountId: account.id, status: 'REJECTED' }))

      if (error) {
        toast.error(error.response?.data.error)

        return
      }

      await refetchAccountDetails()
    } catch {
      return
    } finally {
      handleClose()

      setTimeout(() => {
        setDisabledButtons(false)
      }, 300)
    }
  }

  const isApprove = useMemo(
    () => openConfirmation === 'approve',
    [openConfirmation]
  )

  const showDialog = useMemo(
    () => openConfirmation === 'approve' || openConfirmation === 'reject',
    [openConfirmation]
  )

  const modalTexts: IModalTexts = useMemo(() => {
    switch (openConfirmation) {
      case 'approve':
      case 'approve-closed':
        return {
          title: t('account.approveTitle'),
          text: t('account.approveText'),
          button: t('account.approveAccount'),
        }
      case 'reject':
      case 'reject-closed':
        return {
          title: t('account.rejectTitle'),
          text: t('account.rejectText'),
          button: t('account.rejectAccount'),
        }
      default:
        return { title: '', text: '', button: '' }
    }
  }, [openConfirmation, t])

  const hiddenButtons = useMemo(() => {
    return (
      !account || account.status === 'APPROVED' || account.status === 'REJECTED'
    )
  }, [account?.status])

  return {
    handleClose,
    handleOpen,
    isApprove,
    showDialog,
    modalTexts,
    approveApplication,
    rejectApplication,
    disabledButtons,
    getStatusText,
    getStatusColor,
    hiddenButtons,
  }
}
