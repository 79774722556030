import { useTranslation } from 'react-i18next'

import { DepositLimitsSettings } from '@widgets/settings'

import { useDepositLimits } from '@entities/deposit'

import { SettingLayout } from './setting-layout'

export function DepositLimits() {
  const { t } = useTranslation('pages')
  const { data, isLoading } = useDepositLimits()

  return (
    <SettingLayout
      data={!!data}
      loading={isLoading}
      title={t('settings.deposit-limits')}
    >
      {data && <DepositLimitsSettings data={data} />}
    </SettingLayout>
  )
}
