// TODO: All commented code here is for canceling the transaction if it was stuck with the status 'Created'
// import { useQueryClient } from '@tanstack/react-query'
// import { isAfter, subMinutes } from 'date-fns'
// import { useState } from 'react'
// import { useTranslation } from 'react-i18next'
// import { useParams } from 'react-router'
// import { toast } from 'react-toastify'

import { ITransactionDetails } from '@common/types'

import { transactionPageId } from '@pages/transaction/model'

// import {
// useTransactionStatusOptions,
// cancelTransaction,
// } from '@entities/transaction'

// import { TParamsKeys, queryKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import {
  IInfoSectionRow,
  SecondaryInfoSection,
  // StatusApproveReject,
} from '@shared/ui'

interface IProps {
  template: IInfoSectionRow[]
  status?: ITransactionDetails['status']
  createdAt?: ITransactionDetails['createdAt']
}

export function SecondaryTransactionInfo({
  template,
  // status,
  // createdAt,
}: IProps) {
  // const [isLoading, setIsLoading] = useState<boolean>(false)

  // const { transactionId } = useParams<TParamsKeys>()
  // const { t } = useTranslation('shared')
  const boxSmAndUp = useBoxMediaQuery(transactionPageId).up('sm')
  // const queryClient = useQueryClient()

  // const { getStatusColor, getStatusText } = useTransactionStatusOptions(status)

  // const handleCancel = async () => {
  //   try {
  //     setIsLoading(true)

  //     const response = await cancelTransaction({ transactionId })

  //     queryClient.setQueryData<ITransactionDetails>(
  //       [queryKeys.transaction, response.transactionId],
  //       (prevData) =>
  //         prevData ? { ...prevData, status: response.status } : undefined
  //     )

  //     toast.success(t('toasts.success'))
  //   } catch {
  //     toast.error(t('toasts.try-again'))
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  return (
    <SecondaryInfoSection
      info={template}
      boxId={transactionPageId}
      sx={{
        flexDirection: !boxSmAndUp ? 'column' : 'row',
      }}
    >
      {/* {status && (
        <StatusApproveReject
          bgcolor={getStatusColor()}
          text={getStatusText()}
          hideApproveButton
          hideRejectButton={
            status !== 'CREATED' ||
            createdAt === undefined ||
            isAfter(new Date(createdAt), subMinutes(new Date(), 15))
          }
          sx={{
            backgroundColor: 'white',
            order: !boxSmAndUp ? -1 : 'initial',
          }}
          onReject={handleCancel}
          loading={isLoading}
        />
      )} */}
    </SecondaryInfoSection>
  )
}
