import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ISingleErrorResponse, IWithdrawalResponse } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchWithdrawal } from '../../api'

export function useWithdrawal(withdrawalId?: string) {
  return useQuery<IWithdrawalResponse, AxiosError<ISingleErrorResponse>>({
    queryKey: [queryKeys.withdrawal, withdrawalId],
    queryFn: () => fetchWithdrawal(withdrawalId),
  })
}
