import * as yup from 'yup'

import { TExchangeLimitSetting } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

export const settingFieldSchema = (tFunc?: typeof defT) => {
  const { numberSchema } = getValidationSchemas(tFunc)

  const schema: yup.ObjectSchema<TExchangeLimitSetting> = yup.object().shape({
    EUR: numberSchema,
  })

  return schema
}
