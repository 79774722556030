import { useTranslation } from 'react-i18next'

import { TTransactionsStatus } from '@common/types'

import { grey } from '@shared/constants'

const mainColor = 'primary.main'
const successColor = 'success.main'
const errorColor = 'error.main'
const warningColor = 'warning.main'

export function useTransactionStatusOptions(status?: TTransactionsStatus) {
  const { t } = useTranslation('entities')

  const colors: Record<TTransactionsStatus, string> = {
    COMPLETED: successColor,
    PENDING: grey[400],
    FAILED: errorColor,
    CANCELED: errorColor,
    CREATED: mainColor,
    ANALYZED: mainColor,
    REJECTED_BY_AML: errorColor,
    RISKED: warningColor,
  }

  const getStatusText = () => {
    return status ? t(`transaction.status.${status}`) : ''
  }

  const getStatusColor = () => {
    return status ? colors[status] : errorColor
  }

  return { getStatusText, getStatusColor }
}
