import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IGetUserResponse } from '@common/types'

import { UserDangerActionsModal } from '@features/user'

interface IProps {
  configs?: IGetUserResponse['configs']
}

export function UserDangerActions({ configs }: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { t } = useTranslation('pages')

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="error"
        onClick={() => setIsOpen(true)}
      >
        {t('user.danger-actions')}
      </Button>

      {configs && (
        <UserDangerActionsModal
          configs={configs}
          handleClose={() => setIsOpen(false)}
          open={isOpen}
        />
      )}
    </>
  )
}
