import { Box, Typography, Stack } from '@mui/material'

import { grey } from '@shared/constants'

import { IExchangeInfoTemplate } from '../../model'

interface IProps {
  title: string
  template: IExchangeInfoTemplate[]
}

const px = 20

export function ExchangeInfoSection({ title, template }: IProps) {
  return (
    <Box
      height="100%"
      borderRadius={3}
      border="1px solid"
      borderColor={grey[100]}
      overflow="hidden"
    >
      <Box
        bgcolor="background.default"
        p={`16px ${px}px 12px`}
        borderBottom="1px solid"
        borderColor={grey[100]}
      >
        <Typography variant="h5">{title}</Typography>
      </Box>

      <Stack spacing={1} p={`12px ${px}px 20px`}>
        {template.map(({ name, value }) => (
          <Stack key={name} direction="row" justifyContent="space-between">
            <Typography variant="body1" color="text.secondary">
              {name}
            </Typography>

            {typeof value === 'string' ? (
              <Typography variant="body1" textTransform="uppercase">
                {value}
              </Typography>
            ) : (
              value
            )}
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
