export type TAdditionalRoutesOfSettings =
  | 'deposit'
  | 'withdrawals'
  | 'centralWallets'
  | 'transactions'
  | 'exchange'
  | 'feePlans'
  | 'updateDataAfterMigration'
  | 'emailTemplates'
  | 'uploadFile'

type TSettingsTab = Record<
  TAdditionalRoutesOfSettings,
  TAdditionalRoutesOfSettings
>

export const additionalRoutesOfSettings: TSettingsTab = {
  deposit: 'deposit',
  withdrawals: 'withdrawals',
  centralWallets: 'centralWallets',
  transactions: 'transactions',
  exchange: 'exchange',
  feePlans: 'feePlans',
  emailTemplates: 'emailTemplates',
  uploadFile: 'uploadFile',
  updateDataAfterMigration: 'updateDataAfterMigration',
}
