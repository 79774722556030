import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ISingleErrorResponse, IGetUserResponse } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchUser } from '../../api'

interface IProps {
  userId?: string
}

export function useUser({ userId }: IProps) {
  return useQuery<IGetUserResponse, AxiosError<ISingleErrorResponse>>({
    queryKey: [queryKeys.user, userId],
    queryFn: () => fetchUser(userId),
    refetchInterval: 5 * 60 * 1000,
    retry: false,
  })
}
