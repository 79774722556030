import { useUploadFileSettings } from '@entities/upload-file-settings'

import { ListLoader } from '@shared/ui'

import { PersonalAccountApplicationLayout } from './personal-account-application-layout'

export function LoaderOrPersonalApplicationLayout() {
  const { isLoading } = useUploadFileSettings()

  return (
    <>
      {isLoading && <ListLoader skeletonsNumber={0} />}
      {!isLoading && <PersonalAccountApplicationLayout />}
    </>
  )
}
