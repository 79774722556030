import { useQuery } from '@tanstack/react-query'

import { TExchangeLimitSetting } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchExchangeLimitSetting } from '../../api'

export const useExchangeLimitSetting = () => {
  return useQuery<TExchangeLimitSetting>({
    queryKey: [queryKeys.exchangeLimitSetting],
    queryFn: () => fetchExchangeLimitSetting(),
    staleTime: Infinity,
  })
}
