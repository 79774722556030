import { SxProps } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IWithdrawalResponse } from '@common/types'

import { IInfoSectionRow, MainInfoSection } from '@shared/ui'

interface IProps
  extends Pick<
    IWithdrawalResponse,
    | 'id'
    | 'amount'
    | 'currency'
    | 'description'
    | 'createdAt'
    | 'amlSignature'
    | 'userSignature'
    | 'iban'
  > {
  sx?: SxProps
}

export function WithdrawalInfo({
  amlSignature,
  amount,
  createdAt,
  currency,
  description,
  id,
  userSignature,
  iban,
  sx,
}: IProps) {
  const { t } = useTranslation('pages')

  const fields = [
    {
      label: t('withdrawal.id'),
      value: id,
      copyable: true,
    },
    { label: t('withdrawal.amount'), value: `${amount} ${currency}` },
    { label: t('withdrawal.description'), value: description },
    {
      label: t('withdrawal.iban'),
      value: iban,
      copyable: true,
    },
    {
      label: t('withdrawal.aml-signature'),
      value: amlSignature,
      copyable: true,
    },
    {
      label: t('withdrawal.user-signature'),
      value: userSignature,
      copyable: true,
    },
    {
      label: t('withdrawal.created-at'),
      value: format(new Date(createdAt || 0), 'dd-MM-yyyy HH:mm:ss'),
    },
  ] satisfies IInfoSectionRow[]

  return <MainInfoSection info={fields} sx={sx} />
}
