import { IAddBalanceBody } from '@common/types'

import { instance } from '@shared/requester'

export async function addBalanceToCentralWalletByID({
  body,
  centralWalletID,
}: IAddBalanceBody) {
  return instance
    .post(`/wallets/central/${centralWalletID}/balances`, body)
    .then((response) => response.data)
}
