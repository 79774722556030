import { Stack } from '@mui/material'

import { CopyButton } from '@shared/ui'

import { StyledEllipsisTypography } from './styled'

interface IProps {
  value: string
}

export function ValueWithCopyButton({ value }: IProps) {
  return (
    <Stack direction="row" alignItems="center" sx={{ height: 20 }}>
      <StyledEllipsisTypography variant="body1" color="text.secondary">
        {value}
      </StyledEllipsisTypography>

      <CopyButton value={value} size="small" />
    </Stack>
  )
}
