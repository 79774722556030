import { useState, useEffect } from 'react'

export function useFullScreen() {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (
      event.key === 'F11' ||
      (event.ctrlKey && event.metaKey && event.key === 'F') // for safari
    ) {
      event.preventDefault()

      toggleFullscreen()
    }
  }

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setIsFullScreen(true)

        document.body.style.overflow = 'hidden'
      } else {
        setIsFullScreen(false)

        document.body.style.overflow = 'visible'
      }
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)

    document.addEventListener('webkitfullscreenchange', handleFullscreenChange) // для Safari

    document.addEventListener('mozfullscreenchange', handleFullscreenChange) // для Firefox

    document.addEventListener('msfullscreenchange', handleFullscreenChange) // для IE/Edge

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)

      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      )

      document.removeEventListener(
        'mozfullscreenchange',
        handleFullscreenChange
      )

      document.removeEventListener('msfullscreenchange', handleFullscreenChange)

      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return { isFullScreen, toggleFullscreen }
}
