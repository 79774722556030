import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IDepositListItem } from '@common/types'

import { IComposeTableCellTemplate, IMobileListItem } from '@shared/ui'

import { DepositStatus } from '../../ui'

export function useDepositListTemplate(deposits: IDepositListItem[]) {
  const { t } = useTranslation('shared')

  const desktopTemplate: IComposeTableCellTemplate<IDepositListItem>[] = [
    {
      key: 'id',
      title: t('compose-table.header.id'),
      component: 'hiddenMiddle',
    },
    {
      key: 'user.fullName',
      title: t('compose-table.header.full-name'),
      component: 'default',
    },
    {
      key: 'status',
      title: t('compose-table.header.status'),
      component: DepositStatus,
    },
    {
      key: 'amount',
      additionalKey: 'currency',
      title: t('compose-table.header.amount'),
      component: 'amount',
    },
    {
      key: 'createdAt',
      title: t('compose-table.header.created'),
      component: 'dateTime',
    },
  ]

  const mobileTemplate: IMobileListItem[] = deposits.map((withdraw) => {
    const {
      amount,
      createdAt,
      currency,
      id,
      user: { fullName },
    } = withdraw

    return {
      id: id,
      firstRow: [fullName || '', <DepositStatus key={id} data={withdraw} />],
      middleRows: [
        format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
        `${amount} ${currency}`,
      ],
      lastRow: [
        <Typography
          variant="body1"
          color="text.secondary"
          key={1}
          className="ellipsis"
        >
          {`id: ${id}`}
        </Typography>,
      ],
    } satisfies IMobileListItem
  })

  return { desktopTemplate, mobileTemplate }
}
