import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { ITransactionSettings } from '@common/types'
import { getTransactionSettingsSchema } from '@common/validation/transaction-schemas'

import { useTFunc } from '@shared/hooks'

export function useTransactionSettingsForm(
  defaultValues: ITransactionSettings
) {
  const tFunc = useTFunc()

  const schema = getTransactionSettingsSchema(tFunc)

  return useForm<ITransactionSettings>({
    resolver: yupResolver(schema),
    defaultValues,
  })
}
