import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton } from '@mui/lab'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ISimpleDrawerProps } from '../model'

import {
  DesktopDrawerBottomActionsWrapper,
  StyledDesktopDrawer,
} from './styled'

export function DesktopDrawer({
  children,
  title,
  sx,
  onClose,
  open,
  loading,
  onSave,
  disableSave,
}: ISimpleDrawerProps) {
  const { t } = useTranslation('shared')

  const handleClose = () => {
    !loading && onClose()
  }

  return (
    <StyledDesktopDrawer
      anchor="right"
      open={open}
      onClose={handleClose}
      ModalProps={{
        keepMounted: false,
      }}
      sx={sx}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={3}
        mb={4}
      >
        <Typography variant="h4">{title}</Typography>

        <IconButton onClick={handleClose} disabled={loading}>
          <CloseRoundedIcon
            sx={{ color: (theme) => theme.palette.text.primary }}
          />
        </IconButton>
      </Stack>

      <Stack px={3} flex={1} overflow="hidden">
        {children}
      </Stack>

      {onSave && (
        <DesktopDrawerBottomActionsWrapper>
          <Stack
            direction="row"
            alignItems="center"
            ml="auto"
            width="min-content"
            spacing={2}
          >
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={handleClose}
              disabled={loading}
            >
              {t('button.cancel')}
            </Button>

            <LoadingButton
              variant="text"
              size="large"
              color="primary"
              onClick={onSave}
              loading={loading}
              disabled={disableSave}
            >
              {t('button.save')}
            </LoadingButton>
          </Stack>
        </DesktopDrawerBottomActionsWrapper>
      )}
    </StyledDesktopDrawer>
  )
}
