import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { AccountCryptoBalancesTable } from '@widgets/account-page'

import { useAccountDetails } from '@entities/account'
import { useCryptoBalanceList } from '@entities/crypto-balances'

import { TParamsKeys } from '@shared/constants'
import { ListLoader, useCustomPagination } from '@shared/ui'
import { getPaginationSkip } from '@shared/utils'

export function AccountCryptoBalanceList() {
  const { t } = useTranslation('shared')
  const { accountId } = useParams<TParamsKeys>()

  const { data: account } = useAccountDetails({ accountId })

  const { page, take, paginationNode, setTotalPages, setListLength } =
    useCustomPagination()

  const { data: cryptoBalances, isLoading } = useCryptoBalanceList({
    take,
    skip: getPaginationSkip(take, page),
    accountId,
    userId: account?.user.id,
  })

  useEffect(() => {
    if (cryptoBalances) {
      const totalPages = Math.ceil(cryptoBalances.total / cryptoBalances.take)

      setTotalPages(totalPages)

      setListLength(cryptoBalances.list.length)
    }
  }, [cryptoBalances?.total])

  return (
    <>
      {isLoading && <ListLoader skeletonsNumber={take} />}

      {cryptoBalances ? (
        <>
          <AccountCryptoBalancesTable cryptoBalances={cryptoBalances.list} />
          {paginationNode}
        </>
      ) : (
        <>{!isLoading && t('no-data')}</>
      )}
    </>
  )
}
