import { Container, Paper, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserList } from '@entities/user'

import {
  // FiltersMenu,
  ListLoader,
  useCustomPagination,
} from '@shared/ui'
import { getPaginationSkip } from '@shared/utils'

import { UserList } from './components'

export function UserListPage() {
  const { t } = useTranslation('pages')

  const { page, take, paginationNode, setTotalPages, setListLength } =
    useCustomPagination()

  // const { currentStatus, filtersOptions } = useUsersFilters()

  const { data, isLoading } = useUserList({
    take: take,
    skip: getPaginationSkip(take, page),
  })

  useEffect(() => {
    if (data) {
      const totalPages = Math.ceil(data.total / data.take)

      setTotalPages(totalPages)

      setListLength(data.list.length)
    }
  }, [data?.take])

  return (
    <Container maxWidth={false}>
      <Paper sx={{ padding: { md: '16px 24px 28px' } }}>
        {isLoading ? (
          <ListLoader skeletonsNumber={take} />
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              p="12px"
            >
              <Typography variant="h4">{t('user-list.title')}</Typography>
            </Stack>

            {data && <UserList users={data.list} />}
          </>
        )}

        {paginationNode}
      </Paper>
    </Container>
  )
}
