import { Chip, styled } from '@mui/material'

interface IProps {
  active: boolean
  visibleContent: boolean
}

export const StyledNotifyCountChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'visibleContent',
})<IProps>(({ theme, active, visibleContent }) => ({
  borderRadius: '50%',
  border: '1px solid',
  borderColor: visibleContent
    ? theme.palette.common.white
    : theme.palette.primary.main,

  height: 24,
  width: 24,

  color: active ? theme.palette.primary.main : theme.palette.common.white,
  textAlign: 'center',

  backgroundColor: active
    ? theme.palette.common.white
    : theme.palette.primary.main,

  transform: visibleContent ? 'translate(0, 0)' : 'translate(-5px, -18px)',

  transition: theme.transitions.create([
    'transform',
    'background-color',
    'color',
    'border',
  ]),

  '& .MuiChip-label': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
