import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  IExchangeListItem,
  IGetExchangeListQueryParams,
  IPaginationResponse,
  ISingleErrorResponse,
} from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchExchangeList } from '../../api'

export function useExchangeList({ take, skip }: IGetExchangeListQueryParams) {
  const query = useQuery<
    IPaginationResponse<IExchangeListItem> | null,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.exchangeList, take, skip],
    queryFn: () =>
      skip !== undefined && take ? fetchExchangeList({ take, skip }) : null,
    refetchInterval: 90 * 1000,
    placeholderData: keepPreviousData,
  })

  return query
}
