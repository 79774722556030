import { Box, Typography } from '@mui/material'
import { Trans } from 'react-i18next'

import { routePaths } from '@shared/constants'
import { ShortInfoBarItemWrapper } from '@shared/ui'
import { currenciesIcons } from '@shared/utils'

import { IAccountItemProps } from '../../model'

export function AccountShortInfoItem({
  balanceAmount,
  balanceCurrency,
  id,
  name,
  variant,
}: IAccountItemProps) {
  return (
    <ShortInfoBarItemWrapper href={`${routePaths.accounts.list}/${id}`}>
      <Box>
        <Typography variant="h5">{name}</Typography>

        <Typography variant="h6">
          <Trans
            ns="widgets"
            i18nKey="short-info-bar.item-names.account"
            values={{ variant: variant }}
            components={{
              span: (
                <Typography
                  key={id}
                  variant="body2"
                  component="span"
                  color="primary.main"
                />
              ),
            }}
          />
        </Typography>

        <Typography variant="body2" color={'text.secondary'}>
          ID: {id}
        </Typography>

        <Box display="flex" alignItems="center" gap={0.5}>
          {currenciesIcons[balanceCurrency]}

          <Typography variant="h6">
            {balanceAmount} {balanceCurrency}
          </Typography>
        </Box>
      </Box>
    </ShortInfoBarItemWrapper>
  )
}
