import { TNotificationLimits } from '@common/types'

import { instance } from '@shared/requester'

export function fetchNotificationLimitsForCentralWalletSettings(): Promise<TNotificationLimits> {
  return instance
    .get<TNotificationLimits>(
      '/settings/notification-limits-for-central-wallet'
    )
    .then((res) => res.data)
}
