import { LoadingButton } from '@mui/lab'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TExchangeLimitSetting } from '@common/types'

import {
  updateExchangeLimitSetting,
  useExchangeExternalLimitForm,
} from '@entities/exchange'

import { queryKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'

import { settingPageId } from '../model'

interface IProps {
  data: TExchangeLimitSetting
}

export function ExchangeLimitExternalSettings({ data }: IProps) {
  const { t } = useTranslation(['widgets', 'shared'])
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const queryClient = useQueryClient()
  const px550AndUp = useBoxMediaQuery(settingPageId).up(550)

  const form = useExchangeExternalLimitForm(data)

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    getValues,
    reset,
  } = form

  const onSubmit = async () => {
    setIsSubmitting(true)

    try {
      const response = await updateExchangeLimitSetting({
        EUR: Number(getValues('EUR')),
      })

      setIsSubmitEnabled(false)

      queryClient.setQueryData([queryKeys.exchangeLimitSetting], response)

      reset(response)
    } catch {
      toast.error(t('shared:toasts.try-again'))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setIsSubmitEnabled(isDirty)
  }, [isDirty])

  return (
    <Box>
      <Typography variant="h2" mb={1}>
        {t('settings.exchange.external-limit.title')}
      </Typography>

      <Typography variant="body1" mb={1}>
        {t('settings.exchange.external-limit.description')}
      </Typography>

      <Grid
        container
        spacing={4}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12} md={px550AndUp ? 6 : 12}>
          <Controller
            control={control}
            name="EUR"
            render={({ field, fieldState }) => (
              <TextField
                onChange={field.onChange}
                value={field.value}
                onBlur={field.onBlur}
                ref={field.ref}
                helperText={fieldState.error?.message}
                label="EUR"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={px550AndUp ? 6 : 12}
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            disabled={!isSubmitEnabled}
            fullWidth
            sx={{ height: 52 }}
          >
            {t('settings.exchange.external-limit.button')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
