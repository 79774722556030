import { LoadingButton } from '@mui/lab'
import {
  Box,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material'
import {
  ChangeEvent,
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TDepositLimits } from '@common/types'

import { createDeposit, TAccountDepositForm } from '@entities/deposit'

import { grey } from '@shared/constants'
import { useFormAmountHelpers } from '@shared/hooks'
import { TConfirmationOptions } from '@shared/types'
import { getTimeToLeftFromTimestamp } from '@shared/utils'

import { IResetFormRef, useAccountDepositForm } from '../../model'

interface IProps {
  reference: string
  openOtpModal: () => void
  setDepositConfirmationOptions: Dispatch<
    SetStateAction<TConfirmationOptions | null>
  >
  sx?: SxProps
  depositLimits: TDepositLimits
}

export const CreateAccountDeposit = forwardRef<IResetFormRef, IProps>(
  function CreateAccountDeposit(
    { reference, openOtpModal, depositLimits, setDepositConfirmationOptions },
    ref
  ) {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { t } = useTranslation(['features', 'shared'])

    const { control, handleSubmit, reset, setValue } =
      useAccountDepositForm(depositLimits)

    useImperativeHandle(ref, () => ({
      resetForm: reset,
    }))

    useEffect(() => {
      setValue('reference', reference)
    }, [])

    const { handleChangeAmount: changeAmountUtil, handleKeyUpAmount } =
      useFormAmountHelpers()

    const onChangeAmount = (value: string) => {
      setValue('amount', value)
    }

    const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.valueAsNumber

      changeAmountUtil(e, onChangeAmount)
    }

    const handleCreateDeposit = async (data: TAccountDepositForm) => {
      try {
        setIsLoading(true)

        const response = await createDeposit({
          amount: +data.amount,
          currency: data.currency,
          reference: data.reference,
        })

        setDepositConfirmationOptions({
          id: response.depositId,
          end: getTimeToLeftFromTimestamp(
            new Date(response.confirmation.end).getTime()
          ),
          attempts: response.confirmation.tries,
        })

        openOtpModal()
      } catch {
        toast.error(t('shared:toasts.try-again'))
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <Box
        p="16px 20px"
        bgcolor="common.white"
        borderRadius={3}
        border={'1px solid'}
        borderColor={{ xs: 'transparent', md: grey[100] }}
      >
        <Typography
          variant="h5"
          mb={1}
          color="primary.main"
          textTransform="uppercase"
        >
          {t('deposit.deposit')}
        </Typography>

        <Stack
          direction="row"
          gap={1.5}
          component="form"
          alignItems="flex-start"
          onSubmit={handleSubmit(handleCreateDeposit)}
        >
          <Controller
            control={control}
            name="amount"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                type="tel"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                placeholder="0.00"
                onKeyUp={handleKeyUpAmount}
                onChange={handleChangeAmount}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                sx={{ flexBasis: '50%' }}
              />
            )}
          />

          <LoadingButton
            variant="contained"
            type="submit"
            loading={isLoading}
            sx={{ flexBasis: '50%', height: 52 }}
          >
            {t('deposit.create-deposit')}
          </LoadingButton>
        </Stack>
      </Box>
    )
  }
)
