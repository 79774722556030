import { SxProps, Box, Grid, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  identityDocuments,
  IIdentityDocumentForm,
} from '@entities/personal-account-application'

import { CountrySelectInput, DateField, SimpleAutocomplete } from '@shared/ui'

interface IProps {
  sx?: SxProps
}

type TDocumentTypeModified = 'ID Card' | 'Passport'

interface IDocumentTypeList {
  key: string
  label: TDocumentTypeModified
}

export function IdentityDocumentForm({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const { control } = useFormContext<IIdentityDocumentForm>()

  const documentTypeList: IDocumentTypeList[] = identityDocuments.map(
    (docType) => ({
      key: docType,
      label: t(
        `personal-account-application.identity-document.${docType === 'IdCard' ? 'id-card' : 'passport'}`
      ),
    })
  )

  return (
    <Box sx={sx}>
      <Grid container rowSpacing={{ xs: 1.5, md: 2 }} columnSpacing={4.5}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="documentType"
            render={({ field, fieldState }) => (
              <SimpleAutocomplete
                value={field.value}
                options={documentTypeList}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                label={t(
                  'personal-account-application.identity-document.document-type'
                )}
                fullWidth
                name={field.name}
                onChange={field.onChange}
                placeholder={t(
                  'personal-account-application.identity-document.document-type'
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="documentNumber"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={field.value || ''}
                inputRef={field.ref}
                ref={undefined}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'personal-account-application.identity-document.document-number'
                )}
                placeholder={t(
                  'personal-account-application.identity-document.document-number'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="issuingCountry"
            render={({ field, fieldState }) => (
              <CountrySelectInput
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'personal-account-application.identity-document.country-of-issue'
                )}
                placeholder={t(
                  'personal-account-application.identity-document.country-of-issue'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="documentIssue"
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                value={new Date(field.value)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                format="dd.MM.yyyy"
                label={t(
                  'personal-account-application.identity-document.date-of-issue'
                )}
                placeholder={t(
                  'personal-account-application.identity-document.date-of-issue'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="documentExpire"
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                value={new Date(field.value)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                format="dd.MM.yyyy"
                label={t(
                  'personal-account-application.identity-document.date-of-expiry'
                )}
                placeholder={t(
                  'personal-account-application.identity-document.date-of-expiry'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="citizenship"
            render={({ field, fieldState }) => (
              <CountrySelectInput
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'personal-account-application.identity-document.country-of-citizenship'
                )}
                placeholder={t(
                  'personal-account-application.identity-document.country-of-citizenship'
                )}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
