import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded'
// import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { TParamsKeys, expandedParamName, routePaths } from '@shared/constants'
import { CustomTabs, ITab } from '@shared/ui'

import { additionalInfoListOfAccount } from '../../model'

export const AccountTabs = memo(function AccountTabs() {
  const { t } = useTranslation('pages')
  const { accountId } = useParams<TParamsKeys>()

  const mainPath = `${routePaths.accounts.list}/${accountId}`

  const tabs: ITab[] = [
    {
      label: t('account.tabs.cryptoBalances'),
      icon: <CurrencyBitcoinIcon />,
      path: `${mainPath}/${additionalInfoListOfAccount.cryptoBalances}`,
    },
    {
      label: t('account.tabs.deposits'),
      icon: <AddCardRoundedIcon />,
      path: `${mainPath}/${additionalInfoListOfAccount.deposits}`,
    },
    // {
    //   label: t('account.tabs.withdraws'),
    //   path: `${mainPath}/${additionalInfoListOfAccount.withdraws}`,
    //   icon: <KeyboardDoubleArrowDownRoundedIcon />,
    // },
    {
      label: t('account.tabs.exchanges'),
      path: `${mainPath}/${additionalInfoListOfAccount.exchanges}`,
      icon: <CurrencyExchangeRoundedIcon />,
    },
    {
      label: t('account.tabs.transactions'),
      icon: <ReceiptLongIcon />,
      path: `${mainPath}/${additionalInfoListOfAccount.transactions}`,
    },
  ]

  return (
    <CustomTabs
      tabsRoutes={tabs}
      searchParamName={expandedParamName}
      tabPanelName="account"
      sx={{ mb: 2 }}
    />
  )
})
