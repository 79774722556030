import { IAccountDetails } from '@common/types'

import { ParticipantCard } from '@entities/account-application'

interface IProps {
  account: IAccountDetails
}

export function Directors({ account }: IProps) {
  return (
    <ParticipantCard
      participants={account.businessApplication?.directors.concat(
        account.businessApplication.authorizedSignatories
      )}
    />
  )
}
