export interface ICountry {
  id: number
  name: string
  abbreviation: string
  allow_payment: boolean
  exist: boolean
  currency_codes: string[]
}

export const countries: ICountry[] = [
  {
    id: 2,
    name: 'Austria',
    abbreviation: 'AT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 22,
    name: 'Belgium',
    abbreviation: 'BE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 25,
    name: 'Bulgaria',
    abbreviation: 'BG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 228,
    name: 'Croatia',
    abbreviation: 'HR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 88,
    name: 'Cyprus',
    abbreviation: 'CY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 232,
    name: 'Czech Republic',
    abbreviation: 'CZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 61,
    name: 'Denmark',
    abbreviation: 'DK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 243,
    name: 'Estonia',
    abbreviation: 'EE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 222,
    name: 'Finland',
    abbreviation: 'FI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 224,
    name: 'France',
    abbreviation: 'FR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 51,
    name: 'Germany',
    abbreviation: 'DE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 58,
    name: 'Greece',
    abbreviation: 'GR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 37,
    name: 'Hungary',
    abbreviation: 'HU',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 76,
    name: 'Ireland',
    abbreviation: 'IE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 79,
    name: 'Italy',
    abbreviation: 'IT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 105,
    name: 'Latvia',
    abbreviation: 'LV',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 110,
    name: 'Lithuania',
    abbreviation: 'LT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 112,
    name: 'Luxembourg',
    abbreviation: 'LU',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 123,
    name: 'Malta',
    abbreviation: 'MT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 140,
    name: 'Netherlands',
    abbreviation: 'NL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 165,
    name: 'Poland',
    abbreviation: 'PL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 166,
    name: 'Portugal',
    abbreviation: 'PT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 172,
    name: 'Romania',
    abbreviation: 'RO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 192,
    name: 'Slovakia',
    abbreviation: 'SK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 193,
    name: 'Slovenia',
    abbreviation: 'SI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 78,
    name: 'Spain',
    abbreviation: 'ES',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 235,
    name: 'Sweden',
    abbreviation: 'SE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 240,
    name: 'Aland Islands',
    abbreviation: 'AX',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 4,
    name: 'Albania',
    abbreviation: 'AL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 5,
    name: 'Algeria',
    abbreviation: 'DZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 9,
    name: 'Andorra',
    abbreviation: 'AD',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 11,
    name: 'Antigua and Barbuda',
    abbreviation: 'AG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 12,
    name: 'Argentina',
    abbreviation: 'AR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 13,
    name: 'Armenia',
    abbreviation: 'AM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 14,
    name: 'Aruba',
    abbreviation: 'AW',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 1,
    name: 'Australia',
    abbreviation: 'AU',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 3,
    name: 'Azerbaijan',
    abbreviation: 'AZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 19,
    name: 'Bahrain',
    abbreviation: 'BH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 20,
    name: 'Belarus',
    abbreviation: 'BY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 21,
    name: 'Belize',
    abbreviation: 'BZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 24,
    name: 'Bermuda',
    abbreviation: 'BM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 35,
    name: 'Bhutan',
    abbreviation: 'BT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 27,
    name: 'Bonaire, Sint Eustatius and Saba',
    abbreviation: 'BQ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 28,
    name: 'Bosnia and Herzegovina',
    abbreviation: 'BA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 30,
    name: 'Brazil',
    abbreviation: 'BR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 134,
    name: 'Burma',
    abbreviation: 'MM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 85,
    name: 'Canada',
    abbreviation: 'CA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 233,
    name: 'Chile',
    abbreviation: 'CL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 91,
    name: 'China',
    abbreviation: 'CN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 93,
    name: 'Colombia',
    abbreviation: 'CO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 149,
    name: 'Cook Islands',
    abbreviation: 'CK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 99,
    name: 'Costa Rica',
    abbreviation: 'CR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 100,
    name: "Cote d'Ivoire",
    abbreviation: 'CI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 103,
    name: 'Curacao',
    abbreviation: 'CW',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 65,
    name: 'Dominican Republic',
    abbreviation: 'DO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 238,
    name: 'Ecuador',
    abbreviation: 'EC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 66,
    name: 'Egypt',
    abbreviation: 'EG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 241,
    name: 'El Salvador',
    abbreviation: 'SV',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 223,
    name: 'Falkland Islands (Malvinas Islands)',
    abbreviation: 'FK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 219,
    name: 'Faroe Islands',
    abbreviation: 'FO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 225,
    name: 'French Guiana',
    abbreviation: 'GF',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 226,
    name: 'French Polynesia',
    abbreviation: 'PF',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 59,
    name: 'Georgia',
    abbreviation: 'GE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 53,
    name: 'Gibraltar',
    abbreviation: 'GI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 57,
    name: 'Greenland',
    abbreviation: 'GL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 56,
    name: 'Grenada',
    abbreviation: 'GD',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 47,
    name: 'Guadeloupe',
    abbreviation: 'GP',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 48,
    name: 'Guatemala',
    abbreviation: 'GT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 52,
    name: 'Guernsey',
    abbreviation: 'GG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 55,
    name: 'Hong Kong',
    abbreviation: 'HK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 77,
    name: 'Iceland',
    abbreviation: 'IS',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 71,
    name: 'India',
    abbreviation: 'IN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 72,
    name: 'Indonesia',
    abbreviation: 'ID',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 152,
    name: 'Isle of Man',
    abbreviation: 'IM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 70,
    name: 'Israel',
    abbreviation: 'IL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 250,
    name: 'Japan',
    abbreviation: 'JP',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 62,
    name: 'Jersey',
    abbreviation: 'JE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 73,
    name: 'Jordan',
    abbreviation: 'JO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 82,
    name: 'Kazakhstan',
    abbreviation: 'KZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 87,
    name: 'Kenya',
    abbreviation: 'KE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 98,
    name: 'Korea, Republic of',
    abbreviation: 'KR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 102,
    name: 'Kuwait',
    abbreviation: 'KW',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 111,
    name: 'Liechtenstein',
    abbreviation: 'LI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 117,
    name: 'Macao',
    abbreviation: 'MO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 119,
    name: 'Malaysia',
    abbreviation: 'MY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 126,
    name: 'Marshall Islands',
    abbreviation: 'MH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 125,
    name: 'Martinique',
    abbreviation: 'MQ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 116,
    name: 'Mayotte',
    abbreviation: 'YT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 127,
    name: 'Mexico',
    abbreviation: 'MX',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 130,
    name: 'Moldova',
    abbreviation: 'MD',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 131,
    name: 'Monaco',
    abbreviation: 'MC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 231,
    name: 'Montenegro',
    abbreviation: 'ME',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 124,
    name: 'Morocco',
    abbreviation: 'MA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 137,
    name: 'Nepal',
    abbreviation: 'NP',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 143,
    name: 'New Zealand',
    abbreviation: 'NZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 168,
    name: 'North Macedonia',
    abbreviation: 'MK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 145,
    name: 'Norway',
    abbreviation: 'NO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 147,
    name: 'Oman',
    abbreviation: 'OM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 158,
    name: 'Palestinian National Authority',
    abbreviation: 'PS',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 162,
    name: 'Paraguay',
    abbreviation: 'PY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 163,
    name: 'Peru',
    abbreviation: 'PE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 221,
    name: 'Philippines',
    abbreviation: 'PH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 167,
    name: 'Puerto Rico',
    abbreviation: 'PR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 86,
    name: 'Qatar',
    abbreviation: 'QA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 169,
    name: 'Reunion',
    abbreviation: 'RE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 170,
    name: 'Russian Federation',
    abbreviation: 'RU',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 185,
    name: 'Saint Kitts and Nevis',
    abbreviation: 'KN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 186,
    name: 'Saint Lucia',
    abbreviation: 'LC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 183,
    name: 'Saint Martin (French Part)',
    abbreviation: 'MF',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 184,
    name: 'Saint Vincent and the Grenadines',
    abbreviation: 'VC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 174,
    name: 'San Marino',
    abbreviation: 'SM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 176,
    name: 'Saudi Arabia',
    abbreviation: 'SA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 188,
    name: 'Serbia',
    abbreviation: 'RS',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 180,
    name: 'Seychelles',
    abbreviation: 'SC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 189,
    name: 'Singapore',
    abbreviation: 'SG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 190,
    name: 'Sint Maarten',
    abbreviation: 'SX',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 196,
    name: 'Solomon Islands',
    abbreviation: 'SB',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 245,
    name: 'South Africa',
    abbreviation: 'ZA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 109,
    name: 'State of Libya',
    abbreviation: 'LY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 199,
    name: 'Suriname',
    abbreviation: 'SR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 234,
    name: 'Switzerland',
    abbreviation: 'CH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 203,
    name: 'Taiwan, Province of China',
    abbreviation: 'TW',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 202,
    name: 'Thailand',
    abbreviation: 'TH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 211,
    name: 'Tunisia',
    abbreviation: 'TN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 213,
    name: 'Turkey',
    abbreviation: 'TR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 216,
    name: 'Ukraine',
    abbreviation: 'UA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 146,
    name: 'United Arab Emirates',
    abbreviation: 'AE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 194,
    name: 'United Kingdom',
    abbreviation: 'GB',
    allow_payment: true,
    exist: true,
    currency_codes: ['GBP'],
  },
  {
    id: 195,
    name: 'United States',
    abbreviation: 'US',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 218,
    name: 'Uruguay',
    abbreviation: 'UY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 215,
    name: 'Uzbekistan',
    abbreviation: 'UZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 41,
    name: 'Vietnam',
    abbreviation: 'VN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 39,
    name: 'Virgin Islands, British',
    abbreviation: 'VG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
]

export type TCountry = (typeof countries)[number]['abbreviation']

export const countriesAbbreviation = countries
  .map((item) => {
    if (item.abbreviation) {
      return item.abbreviation
    }
  })
  .filter(Boolean)
