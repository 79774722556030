import { Divider, Stack } from '@mui/material'
import { useState } from 'react'

import { EmailTemplateDetails, TemplateChooser } from '@widgets/settings'

import {
  useEmailTemplate,
  useEmailTemplateNames,
} from '@entities/email-templates'

import { ListLoader } from '@shared/ui'

import { SettingLayout } from './components'

export function EmailTemplates() {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  )

  const { data: templateNames, isLoading } = useEmailTemplateNames()

  const { data: template, isLoading: isLoadingTemplate } =
    useEmailTemplate(selectedTemplateId)

  return (
    <SettingLayout data={!!templateNames} loading={isLoading}>
      <Stack gap={3}>
        {templateNames && (
          <TemplateChooser
            templateNames={templateNames}
            setSelectedTemplateId={setSelectedTemplateId}
            selectedTemplateId={selectedTemplateId}
          />
        )}

        {isLoadingTemplate && <ListLoader skeletonsNumber={0} />}

        {template && (
          <>
            <Divider />
            <EmailTemplateDetails template={template} />
          </>
        )}
      </Stack>
    </SettingLayout>
  )
}
