import { Stack, styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  readonly?: boolean
}

export const StyledContentWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'readonly',
})<IProps>(({ readonly }) => ({
  padding: '20px 12px 20px',

  gap: '12px',
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
  border: `1px solid ${grey[100]}`,
  height: '100%',

  ...(readonly && {
    alignItems: 'center',
  }),
  flex: 1,
}))
