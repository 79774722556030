// import { useMemo } from 'react'
import {
  // FieldPath,
  // FieldValues,
  FormProvider,
  // UseFormReturn,
} from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'

import { TermsAndConditionsForm } from '@widgets/personal-account-application'

import { IContext } from '../../model'

// interface ISaveErrorParams<T extends FieldValues> {
//   form: UseFormReturn<T>
//   errorKey: FieldPath<T>
//   errorMessage: string
// }

export function TermsAndConditions() {
  const {
    termsAndConditionsForm,
    // checkIfAllFormsAreValid,
    // personalDetailsForm,
    // expectedTurnoverForm,
    // identityDocumentForm,
    // homeAddressForm,
  } = useOutletContext<IContext>()

  // const {
  //   handleSubmit,
  //   formState: { isLoading, isSubmitting },
  // } = termsAndConditionsForm

  // const saveError = <T extends FieldValues>({
  //   form,
  //   errorKey,
  //   errorMessage,
  // }: ISaveErrorParams<T>) => {
  //   const fieldKeys = Object.keys(form.getValues())

  //   if (fieldKeys.includes(errorKey)) {
  //     form.setError(errorKey, {
  //       type: 'apiError',
  //       message: errorMessage,
  //     })
  //   }
  // }

  // const { isDualCitizen, isPep, dualCitizen, taxIdentifier, taxCountry } =
  //   personalDetailsForm.getValues()

  // const {
  //   incomePrimarySourceOther,
  //   incomePrimarySource,
  //   purposeOfAccountOther,
  //   purposeOfAccount,
  //   incomeCountries,
  //   incomeMonthly,
  //   incomeMonthlyFrequency,
  //   outcomeCountries,
  //   outcomeMonthly,
  //   outcomeMonthlyFrequency,
  // } = expectedTurnoverForm.getValues()

  // const incomePrimarySourceComputed = useMemo(() => {
  //   return incomePrimarySource === 'Other'
  //     ? incomePrimarySourceOther || 'Other'
  //     : incomePrimarySource
  // }, [incomePrimarySource, incomePrimarySourceOther])

  // const purposeOfAccountComputed = useMemo(() => {
  //   return purposeOfAccount === 'Other'
  //     ? purposeOfAccountOther || 'Other'
  //     : purposeOfAccount
  // }, [purposeOfAccount, purposeOfAccountOther])

  // const {
  //   addressZip,
  //   addressCity,
  //   addressCountry,
  //   addressStreet,
  //   addressProof,
  // } = homeAddressForm.getValues()

  return (
    <FormProvider {...termsAndConditionsForm}>
      <form>
        <TermsAndConditionsForm sx={{ mb: { xs: 5, md: 6.5 } }} />
      </form>
    </FormProvider>
  )
}
