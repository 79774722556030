import { object, ObjectSchema } from 'yup'

import { IWithdrawalSettings } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

export const getWithdrawalSettingsSchema = (tFunc?: typeof defT) => {
  const t = tFunc || defT

  const { numberSchema } = getValidationSchemas(t)

  const schema: ObjectSchema<IWithdrawalSettings> = object().shape({
    minAmountForSupportingDocs: numberSchema,
  })

  return schema
}
