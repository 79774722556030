import { SxProps, TextField, Typography } from '@mui/material'
import { forwardRef } from 'react'
import { Noop } from 'react-hook-form'

interface IProps {
  preValue?: boolean
  editable: boolean
  onChange: (...event: unknown[]) => void
  onBlur: Noop
  error?: boolean
  helperText?: string
  autoFocus?: boolean
  placeholder?: string
  value: string | number
  sx?: SxProps
}

export const EditableTextField = forwardRef<HTMLDivElement, IProps>(
  function EditableTextField(
    {
      editable,
      value,
      sx,
      onChange,
      onBlur,
      error,
      autoFocus,
      helperText,
      preValue,
      placeholder,
    }: IProps,
    ref
  ) {
    return editable ? (
      <TextField
        label={placeholder}
        placeholder={placeholder}
        ref={null}
        size="small"
        value={value}
        sx={sx}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        error={error}
        helperText={helperText}
        autoFocus={autoFocus}
      />
    ) : (
      <Typography variant="body1" width="fit-content" sx={sx}>
        {preValue && `${placeholder}:`} {value}
      </Typography>
    )
  }
)
