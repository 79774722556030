import { SxProps, useMediaQuery, useTheme } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IFeePlan, IAccountDetails } from '@common/types'

import { changeAccountFeePlan } from '@entities/account'
import { useFeePlans } from '@entities/fee-plans'

import { queryKeys } from '@shared/constants'
import { DesktopDrawer, MobileDrawer } from '@shared/ui'

import { ChangeAccountFeePlanContent } from './change-account-fee-plan-content'

interface IProps {
  open: boolean
  handleClose: () => void
  currentFeePlanId: string
  feePlan: IFeePlan
  accountId: string
  sx?: SxProps
}

export function ChangeAccountFeePlanDrawer({
  handleClose,
  open,
  currentFeePlanId,
  feePlan,
  accountId,
  sx,
}: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [selectedFeePlanId, setSelectedFeePlanId] = useState<string | null>(
    null
  )

  const { t } = useTranslation(['features', 'shared'])
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const queryClient = useQueryClient()

  const { data: feePlans } = useFeePlans()

  const handleChangeFeePlan = async () => {
    if (!selectedFeePlanId || !feePlans) {
      return
    }

    try {
      setIsLoading(true)

      const response = await changeAccountFeePlan(accountId, selectedFeePlanId)

      const newFeePlan = feePlans.find(
        (feePlan) => feePlan.id === response.feePlanId
      )

      if (!newFeePlan) {
        return
      }

      queryClient.setQueryData<IAccountDetails>(
        [queryKeys.account, accountId],
        (prevData) =>
          prevData
            ? {
                ...prevData,
                feePlanId: response.feePlanId,
                feePlan: newFeePlan,
              }
            : undefined
      )

      handleClose()

      toast.error(t('shared:toasts.success'))
    } catch {
      toast.error(t('shared:toasts.try-again'))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!open) {
      setSelectedFeePlanId(null)

      setIsLoading(false)
    }
  }, [open])

  return mdAndUp ? (
    <DesktopDrawer
      title={t('account.short-info-bar.fee-plan.title')}
      open={open}
      onClose={handleClose}
      loading={isLoading}
      onSave={handleChangeFeePlan}
      disableSave={!selectedFeePlanId}
      sx={sx}
    >
      <ChangeAccountFeePlanContent
        feePlan={feePlan}
        currentFeePlanId={currentFeePlanId}
        selectedFeePlanId={selectedFeePlanId}
        setSelectedFeePlanId={setSelectedFeePlanId}
        isLoading={isLoading}
      />
    </DesktopDrawer>
  ) : (
    <MobileDrawer
      title={t('account.short-info-bar.fee-plan.title')}
      open={open}
      onClose={handleClose}
      loading={isLoading}
      onSave={handleChangeFeePlan}
      disableSave={!selectedFeePlanId}
    >
      <ChangeAccountFeePlanContent
        feePlan={feePlan}
        currentFeePlanId={currentFeePlanId}
        selectedFeePlanId={selectedFeePlanId}
        setSelectedFeePlanId={setSelectedFeePlanId}
        isLoading={isLoading}
      />
    </MobileDrawer>
  )
}
