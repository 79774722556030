import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Box, SxProps } from '@mui/material'
import { PropsWithChildren } from 'react'

import { StyledShortInfoBarItemWrapper } from './styled'

interface IOnClickRequired extends IProps {
  onClick?: () => void
  href?: undefined
}

interface IHrefRequired extends IProps {
  href?: string
  onClick?: undefined
}

type TProps = PropsWithChildren<IOnClickRequired | IHrefRequired>

interface IProps {
  sx?: SxProps
}

export function ShortInfoBarItemWrapper({
  href,
  sx,
  onClick,
  children,
}: TProps) {
  return (
    <StyledShortInfoBarItemWrapper
      {...(!href && !onClick ? { component: 'div', disableRipple: true } : {})}
      onClick={onClick}
      href={href}
      hover={!!href || !!onClick}
      sx={sx}
    >
      <Box overflow="hidden" width={'100%'}>
        {children}
      </Box>

      {href && (
        <Box>
          <ArrowOutwardIcon />
        </Box>
      )}
    </StyledShortInfoBarItemWrapper>
  )
}
