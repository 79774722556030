import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import GroupIcon from '@mui/icons-material/Group'
import { Button, Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { GroupSectionTitle } from '../../../group-section-title'

interface IProps {
  index: number
  disabled?: boolean
  onDelete: () => void
}

export function BeneficiaryTitle({ index, disabled, onDelete }: IProps) {
  const { t } = useTranslation(['widgets', 'shared'])

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        component={Stack}
        direction="row"
        justifyContent="space-between"
      >
        <GroupSectionTitle
          icon={
            <Stack
              p="4px"
              justifyContent="center"
              alignItems="center"
              bgcolor="background.default"
              borderRadius={'50%'}
            >
              <GroupIcon sx={{ fontSize: 20 }} />
            </Stack>
          }
          title={`${t('account-application.beneficiary')} #${index + 1}`}
          titleVariant="h4"
        />

        {!disabled && (
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={onDelete}
            startIcon={<CloseRoundedIcon />}
          >
            {t('shared:delete')}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
