import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TIdvStatus } from '@common/types'

import { grey } from '@shared/constants'

export function useGetIdvStatusOptions(status?: TIdvStatus) {
  const theme = useTheme()
  const { t } = useTranslation('entities')

  const colors: Record<TIdvStatus, string> = {
    APPROVED: theme.palette.success.main,
    NEW: grey[400],
    PENDING: theme.palette.primary.main,
    REJECTED: theme.palette.error.main,
  }

  const getIdvStatusText = () => {
    return status ? t(`user.idv-status.${status}`) : ''
  }

  const getIdvStatusColor = () => {
    return status ? colors[status] : theme.palette.error.main
  }

  return { getIdvStatusText, getIdvStatusColor }
}
