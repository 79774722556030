import * as yup from 'yup'

import { currencies, ICreateDeposit, TCurrency } from '../../types'
import { defT } from '../validation-rules'

export const getCreateDepositSchema = (tFunc?: typeof defT) => {
  const t = tFunc || defT

  const schema: yup.ObjectSchema<Omit<ICreateDeposit, 'amount'>> = yup
    .object()
    .shape({
      currency: yup
        .string()
        .required(t('required'))
        .oneOf<TCurrency>(Object.values(currencies) as TCurrency[]),
      reference: yup.string().required(t('required')),
    })

  return schema
}
