import { composeTableTemplates, TComposeTableTemplateName } from '@shared/ui'

import { IComposeTableCellTemplate } from '../types'

interface IProps<T> {
  item: IComposeTableCellTemplate<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalValue?: any
  row: T
}

export function TableCellData<T>({
  item,
  value,
  additionalValue,
  row,
}: IProps<T>) {
  const isComponentUndefined = !item.component

  const isComponentString =
    typeof item.component === 'string' &&
    item.component in composeTableTemplates

  const isValueObject = typeof value === 'object'

  return (
    <>
      {isComponentUndefined && isValueObject && (
        <span>Template component not set</span>
      )}

      {isComponentUndefined && !isValueObject && <span>{value}</span>}

      {isComponentString &&
        composeTableTemplates[item.component as TComposeTableTemplateName]({
          data: value,
          additionalData: additionalValue,
        } as never)}

      {typeof item.component === 'function' && item.component({ data: row })}
    </>
  )
}
