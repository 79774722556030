import { styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  height: number
}

export const StyledSwipeableArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height',
})<IProps>(({ theme, height }) => ({
  height: height,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'absolute',
  top: -height,
  right: 0,
  left: 0,
  visibility: 'visible',

  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: '24px',
  borderTopRightRadius: '24px',
  borderStyle: 'solid',
  borderColor: grey[100],
  borderWidth: '1px 1px 0 1px',
}))
