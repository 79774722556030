import { FormProvider } from 'react-hook-form'

import { IAccountDetails } from '@common/types'

import { useBusinessApplicationForm } from '../model'

interface IProps {
  account: IAccountDetails
}

export function ApplicationFormProvider({
  children,
  account,
}: React.PropsWithChildren<IProps>) {
  const businessApplication = useBusinessApplicationForm({
    businessApplicationStartData: account?.businessApplication,
  })

  return account.variant === 'BUSINESS' ? (
    <FormProvider {...businessApplication}>{children}</FormProvider>
  ) : (
    <>{children}</>
  )
}
