import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { rejectWithdrawal } from '@entities/withdrawal'

import { useUpdateWithdrawalQueryData } from './use-update-withdrawal-data'

export function useRejectWithdrawal(withdrawalId?: string) {
  if (!withdrawalId) {
    throw new Error('withdrawalId is required')
  }

  const [isShowRejectDialog, setIsShowRejectDialog] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const updateWithdrawalQueryData = useUpdateWithdrawalQueryData(withdrawalId)

  const { t } = useTranslation('shared')

  const closeRejectDialog = () => {
    !isLoading && setIsShowRejectDialog(false)
  }

  const handleRejectWithdrawal = async () => {
    try {
      setIsLoading(true)

      const response = await rejectWithdrawal(withdrawalId)

      updateWithdrawalQueryData(response.newStatus)
    } catch {
      toast.error(t('toasts.try-again'))
    } finally {
      setIsLoading(false)

      closeRejectDialog()
    }
  }

  return {
    handleClickReject: () => setIsShowRejectDialog(true),
    handleRejectWithdrawal,
    isShowRejectDialog,
    isLoading,
    closeRejectDialog,
  }
}
