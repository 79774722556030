import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IUploadFile } from '@shared/ui'

import { UploadItem } from './components'

interface IUploadItemListProps {
  fileList?: IUploadFile[]
  removeFile: (index: number) => void
  isRemote: boolean
  error?: boolean
  helperText?: string
}

export const UploadItemList = ({
  fileList,
  removeFile,
  isRemote,
  helperText,
  error,
}: IUploadItemListProps) => {
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation('shared')

  return (
    <>
      {fileList?.length ? (
        fileList.map((file, index) => (
          <UploadItem
            key={file.name + file.size}
            isRemote={isRemote}
            index={index || 0}
            file={file}
            removeFile={removeFile}
          />
        ))
      ) : (
        <Stack
          justifyContent={'center'}
          direction={'row'}
          m={smAndUp ? '16px 10px 4px' : '12px auto'}
        >
          <Typography
            variant={'body2'}
            textAlign="start"
            color={
              error ? theme.palette.error.main : theme.palette.primary.dark
            }
            marginBottom={0.75}
          >
            {error ? helperText : t(`drag-n-drop.upload-a-document`)}
          </Typography>
        </Stack>
      )}
    </>
  )
}
