import { IRejectWithdrawalResponse } from '@common/types'

import { instance } from '@shared/requester'

export function rejectWithdrawal(withdrawalId?: string) {
  if (!withdrawalId) {
    throw new Error()
  }

  return instance
    .patch<IRejectWithdrawalResponse>(`/withdrawals/${withdrawalId}/reject`)
    .then((res) => res.data)
}
