import { Stack, styled } from '@mui/material'

export const StyledModalContentWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: 0,

  background: theme.palette.common.white,

  borderTopRightRadius: '24px',
  borderTopLeftRadius: '24px',

  [theme.breakpoints.up('sm')]: {
    padding: '6px 16px 40px',
    bottom: 0,
    position: 'absolute',
  },
}))
