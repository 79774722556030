import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { uploadWithdrawalDocuments } from '@entities/withdrawal'

import { queryKeys } from '@shared/constants'

export function useAddWithdrawalDocumentsMutation(
  withdrawalId?: string,
  resetForm?: () => void
) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: uploadWithdrawalDocuments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.withdrawal, withdrawalId],
        exact: true,
      })

      resetForm?.()
    },
    onError: () => toast.error(t('oops')),
  })

  return mutation
}
