import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { TransactionListPage } from './ui'

export const TransactionListPageRouter: RouteObject = {
  path: routePaths.transactions.list,
  element: <TransactionListPage />,
}
