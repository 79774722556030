import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { WithdrawalListPage } from './ui'

export const WithdrawalListPageRouter: RouteObject = {
  path: routePaths.withdrawals.list,
  element: <WithdrawalListPage />,
}
