import { Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { PersonalDetailsForm } from '@widgets/personal-account-application'

import { DualCitizenship, Pep } from '@features/accounts-application'

import { IPersonalDetailsForm } from '@entities/personal-account-application'

import { IContext } from '../../model'

export function PersonalDetails() {
  const { t } = useTranslation('pages')
  const { personalDetailsForm: form } = useOutletContext<IContext>()

  return (
    <FormProvider {...form}>
      <form>
        <Typography
          variant="subtitle2"
          textTransform="uppercase"
          mb={{ xs: 2, md: 3 }}
        >
          {t('personal-account-application.steps-titles.1')}
        </Typography>

        <PersonalDetailsForm sx={{ mb: 3.5 }} />

        <DualCitizenship<IPersonalDetailsForm>
          nameDualCitizenShip="isDualCitizen"
          nameDualCitizenshipCountry="dualCitizen"
          radioButtonsLabel={t('personal-account-application.dual-citizenship')}
        />

        <Pep<IPersonalDetailsForm>
          namePep="isPep"
          radioButtonsLabel={t('personal-account-application.pep')}
        />
      </form>
    </FormProvider>
  )
}
