import { Grid, LinearProgress, Paper, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { ShortInfoBar } from '@widgets/short-info-bar'

import { useExchangeDetails, useExchangeStatus } from '@entities/exchange'

import { TParamsKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import {
  ConfirmActionDialog,
  MainInfoSection,
  RefreshButton,
  StatusApproveReject,
} from '@shared/ui'

import {
  exchangePageId,
  useConfirmExchange,
  useExchangeInfoTemplates,
  useExchangeShortInfoBarTemplate,
} from '../model'

import { ExchangeBreadcrumbs, ExchangeInfoSection } from './components'

const mainPartId = 'exchange-main-part'

export function ExchangePage() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { t } = useTranslation(['pages', 'shared'])
  const { exchangeId } = useParams<TParamsKeys>()

  const handleClose = () => {
    setIsOpen(false)
  }

  const {
    data: exchange,
    isLoading,
    isError,
    failureReason,
    refetch,
    isFetching,
  } = useExchangeDetails(exchangeId)

  const confirmExchange = useConfirmExchange({
    exchangeId,
    closeConfirmationModal: handleClose,
  })

  const { getFullStatusText, getStatusColor } = useExchangeStatus(
    exchange?.status
  )

  const boxMdAndUp = useBoxMediaQuery(exchangePageId, isLoading).up('md')
  const box1000AndUp = useBoxMediaQuery(mainPartId, isLoading).up(1000)

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.error}`)
  }, [failureReason])

  const { mainInfoTemplate, basicInfoTemplate, finalInfoTemplate } =
    useExchangeInfoTemplates(exchange)

  const shortInfoBarTemplate = useExchangeShortInfoBarTemplate(exchange)

  const handleUpdateExchange = () => {
    refetch()

    confirmExchange.handleUpdateExchangesNumberNeededToBeApprovedManually()
  }

  return (
    <>
      {isLoading && (
        <Box width="100%">
          <LinearProgress />
        </Box>
      )}

      {!isLoading && exchange && (
        <Paper
          sx={{ p: '32px 24px 20px', display: 'flex', gap: 3 }}
          id={mainPartId}
        >
          <Stack gap={2} flex={1} overflow="hidden" id={exchangePageId}>
            <ExchangeBreadcrumbs />

            <Stack
              direction={boxMdAndUp ? 'row' : 'column'}
              justifyContent="space-between"
              alignItems="flex-start"
              gap={2}
              mb={2}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <StatusApproveReject
                  bgcolor={getStatusColor()}
                  text={getFullStatusText()}
                  hideApproveButton={
                    exchange?.status !== 'KRAKEN_WAIT_FOR_DEPOSIT' &&
                    exchange?.status !== 'CONFIRMED'
                  }
                  hideRejectButton={true}
                  onApprove={() => setIsOpen(true)}
                  loading={confirmExchange.isLoading || isFetching}
                  sx={{ order: boxMdAndUp ? 'initial' : -1 }}
                />

                <RefreshButton
                  loading={isFetching}
                  onClick={handleUpdateExchange}
                />
              </Stack>

              <MainInfoSection info={mainInfoTemplate} />
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={boxMdAndUp ? 6 : 12}>
                <ExchangeInfoSection
                  title={t('exchange.basic-info')}
                  template={basicInfoTemplate}
                />
              </Grid>

              <Grid item xs={boxMdAndUp ? 6 : 12}>
                <ExchangeInfoSection
                  title={t('exchange.final-info')}
                  template={finalInfoTemplate}
                />
              </Grid>
            </Grid>
          </Stack>

          <ShortInfoBar
            template={shortInfoBarTemplate}
            viewMode={box1000AndUp ? 'desktop' : 'mobile'}
          />

          <ConfirmActionDialog
            title={t("exchange.don't-forget")}
            open={isOpen}
            onClose={handleClose}
            onClickConfirm={() =>
              confirmExchange.handleConfirm(exchange.status)
            }
            loading={confirmExchange.isLoading}
          />
        </Paper>
      )}
    </>
  )
}
