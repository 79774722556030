import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { routePaths } from '@shared/constants'

interface IApplicationTabsProps {
  accountId: string
}

export function ApplicationTabs({ accountId }: IApplicationTabsProps) {
  const { t } = useTranslation('pages')
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Tabs
      value={location.pathname}
      sx={{
        mb: { xs: 3.5, md: 4 },
        '& .MuiTabs-flexContainer': {
          justifyContent: 'center',
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'primary.main',
        },
      }}
    >
      <Tab
        label={t('personal-account-application.steps-titles.1')}
        value={routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'personal-details'
        )}
        onClick={() =>
          navigate(
            routePaths.accounts.application.personalSteps.getPath(
              accountId,
              'personal-details'
            )
          )
        }
      />

      <Tab
        label={t('personal-account-application.steps-titles.2')}
        value={routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'expected-turnover'
        )}
        onClick={() =>
          navigate(
            routePaths.accounts.application.personalSteps.getPath(
              accountId,
              'expected-turnover'
            )
          )
        }
      />

      <Tab
        label={t('personal-account-application.steps-titles.3')}
        value={routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'identity-document'
        )}
        onClick={() =>
          navigate(
            routePaths.accounts.application.personalSteps.getPath(
              accountId,
              'identity-document'
            )
          )
        }
      />

      <Tab
        label={t('personal-account-application.steps-titles.4')}
        value={routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'home-address'
        )}
        onClick={() =>
          navigate(
            routePaths.accounts.application.personalSteps.getPath(
              accountId,
              'home-address'
            )
          )
        }
      />

      <Tab
        label={t('personal-account-application.steps-titles.5')}
        value={routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'terms-and-conditions'
        )}
        onClick={() =>
          navigate(
            routePaths.accounts.application.personalSteps.getPath(
              accountId,
              'terms-and-conditions'
            )
          )
        }
      />
    </Tabs>
  )
}
