import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ITransactionDetails } from '@common/types'

import { ShortInfoBar, ITemplateWithTitle } from '@widgets/short-info-bar'

import { useTransactionShortInfoBarTemplate } from '../../model'

interface IProps {
  transaction: ITransactionDetails
  isMobile: boolean
}

export function TransactionShortInfoBar({ transaction, isMobile }: IProps) {
  return isMobile ? (
    <MobileTransactionShortInfoBar transaction={transaction} />
  ) : (
    <DesktopTransactionShortInfoBar transaction={transaction} />
  )
}

interface IPropss {
  transaction: ITransactionDetails
}

function DesktopTransactionShortInfoBar({ transaction }: IPropss) {
  const { t } = useTranslation('pages')

  const { receiverTemplate, senderTemplate } =
    useTransactionShortInfoBarTemplate(transaction)

  return (
    <Stack gap={2}>
      {!!receiverTemplate.length && (
        <Stack gap={1}>
          <Typography variant="h5">{t('transaction.receiver')}</Typography>
          <ShortInfoBar template={receiverTemplate} viewMode="desktop" />
        </Stack>
      )}

      {!!senderTemplate.length && (
        <Stack gap={1}>
          <Typography variant="h5">{t('transaction.sender')}</Typography>
          <ShortInfoBar template={senderTemplate} viewMode="desktop" />
        </Stack>
      )}
    </Stack>
  )
}

function MobileTransactionShortInfoBar({ transaction }: IPropss) {
  const { t } = useTranslation('pages')

  const { receiverTemplate, senderTemplate } =
    useTransactionShortInfoBarTemplate(transaction)

  const mobileTemplate: ITemplateWithTitle[] = [
    {
      title: t('transaction.receiver'),
      template: receiverTemplate,
    },
    {
      title: t('transaction.sender'),
      template: senderTemplate,
    },
  ]

  return <ShortInfoBar template={mobileTemplate} viewMode="mobile" />
}
