import { Typography, useMediaQuery, useTheme } from '@mui/material'

import { CommonItemElementsContainer } from './styled'

interface IProps {
  label: string
  value: string
}

export function InfoRow({ label, value }: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <CommonItemElementsContainer>
      <Typography variant={mdAndUp ? 'body2' : 'body1'}>{label}</Typography>

      <Typography variant={mdAndUp ? 'h6' : 'h5'} textTransform="initial">
        {value}
      </Typography>
    </CommonItemElementsContainer>
  )
}
