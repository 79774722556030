import { useQueryClient } from '@tanstack/react-query'

import {
  IUpdateWithdrawalStatusResponse,
  IVerifyWithdrawalOtpCodeResponse,
  IWithdrawalResponse,
} from '@common/types'

import { queryKeys } from '@shared/constants'

export function useUpdateWithdrawalQueryData(withdrawalId: string) {
  const queryClient = useQueryClient()

  const updateWithdrawalQueryData = (
    newStatus: IUpdateWithdrawalStatusResponse['newStatus'],
    newAvailableBalance?: IVerifyWithdrawalOtpCodeResponse['newAvailableBalance']
  ) => {
    queryClient.setQueryData(
      [queryKeys.withdrawal, withdrawalId],
      (prevData: IWithdrawalResponse) => ({
        ...prevData,
        status: newStatus,
        ...(newAvailableBalance && {
          finalBalance: {
            ...prevData.fiatBalance,
            amount: newAvailableBalance,
          },
        }),
      })
    )

    queryClient.refetchQueries({
      queryKey: [queryKeys.operationsNumberNeededToBeApprovedManually],
    })
  }

  return updateWithdrawalQueryData
}
