import { TAsset, TBlockchain } from '../types'

export const coinsOfBlockchains: Record<
  TBlockchain,
  Extract<TAsset, 'TRX' | 'ETH'> | ''
> = {
  ETHEREUM: 'ETH',
  TRON: 'TRX',
  BITCOIN: '',
}
