import { IUpdateAccountBusinessApplicationData } from '@common/types'

import { instance } from '@shared/requester'

interface IUpdateBusinessApplicationProps {
  accountId: string
  data: IUpdateAccountBusinessApplicationData
}

export async function updateBusinessApplication({
  accountId,
  data,
}: IUpdateBusinessApplicationProps) {
  return await instance.put(`/accounts/${accountId}/business`, data)
}
