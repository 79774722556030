import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { StatusApproveReject } from '@shared/ui'

import { useUpdateAccountStatus } from '../model'

export function UpdateAccountStatus() {
  const { t } = useTranslation('features')

  const {
    handleOpen,
    handleClose,
    disabledButtons,
    showDialog,
    getStatusColor,
    getStatusText,
    modalTexts,
    isApprove,
    approveApplication,
    rejectApplication,
    hiddenButtons,
  } = useUpdateAccountStatus()

  return (
    <>
      <StatusApproveReject
        onApprove={handleOpen('approve')}
        onReject={handleOpen('reject')}
        bgcolor={getStatusColor()}
        text={getStatusText()}
        disabled={disabledButtons}
        hideApproveButton={hiddenButtons}
        hideRejectButton={hiddenButtons}
      />

      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{modalTexts.title}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalTexts.text}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} disabled={disabledButtons}>
            {t('account.cancel')}
          </Button>

          <Button
            variant="contained"
            disabled={disabledButtons}
            onClick={isApprove ? approveApplication : rejectApplication}
            autoFocus
          >
            {modalTexts.button}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
