import { IAccountListItem } from '@common/types'

import { AccountStatus } from '@entities/account'

import { ITemplateProps } from '@shared/ui'

export function AccountStatusTemplate({
  data: { status },
}: ITemplateProps<IAccountListItem>) {
  return <AccountStatus status={status} />
}
