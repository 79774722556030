import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import AddCardIcon from '@mui/icons-material/AddCard'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import TransformIcon from '@mui/icons-material/Transform'
import { SxProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon/SvgIcon'
import { useKeycloak } from '@react-keycloak/web'
import { FC, SVGProps } from 'react'
import { useTranslation } from 'react-i18next'

import { useOperationsNumberNeededToBeApprovedManually } from '@entities/operations'

import { routePaths } from '@shared/constants'

interface IMenuItem {
  id: number
  title: string

  navigationLink?: string
  action?: () => void

  icon: FC<SVGProps<SVGSVGElement>> | typeof SvgIcon
  iconFill: FC<SVGProps<SVGSVGElement>> | typeof SvgIcon

  actionsQuantity?: number
  disabled?: boolean
  divider?: boolean
  sx?: SxProps
}

export const useSidebarItems = (): IMenuItem[] => {
  const { t } = useTranslation('widgets')
  const { keycloak } = useKeycloak()

  const { data: operationsNumberApprovedByUsers } =
    useOperationsNumberNeededToBeApprovedManually()

  return [
    {
      id: 1,
      title: t('appbar.links.users'),
      navigationLink: routePaths.users.list,
      icon: PeopleAltOutlinedIcon,
      iconFill: PeopleAltIcon,
    },
    {
      id: 2,
      title: t('appbar.links.accounts'),
      navigationLink: routePaths.accounts.list,
      icon: AccountBoxOutlinedIcon,
      iconFill: AccountBoxIcon,
      divider: true,
      actionsQuantity: operationsNumberApprovedByUsers?.accounts,
    },
    {
      id: 3,
      title: t('appbar.links.exchanges'),
      navigationLink: routePaths.exchanges.list,
      icon: CurrencyExchangeOutlinedIcon,
      iconFill: CurrencyExchangeOutlinedIcon,
      actionsQuantity: operationsNumberApprovedByUsers?.exchanges,
    },
    {
      id: 4,
      title: t('appbar.links.transactions'),
      navigationLink: routePaths.transactions.list,
      icon: CurrencyBitcoinIcon,
      iconFill: CurrencyBitcoinIcon,
      divider: true,
      actionsQuantity: operationsNumberApprovedByUsers?.transactions,
    },
    {
      id: 5,
      title: t('appbar.links.deposits'),
      navigationLink: routePaths.deposits.list,
      icon: AddCardIcon,
      iconFill: AddCardIcon,
    },
    {
      id: 6,
      title: t('appbar.links.withdrawals'),
      navigationLink: routePaths.withdrawals.list,
      icon: TransformIcon,
      iconFill: TransformIcon,
      actionsQuantity: operationsNumberApprovedByUsers?.withdrawals,
      divider: true,
    },
    {
      id: 7,
      title: t('appbar.links.settings'),
      navigationLink: routePaths.settings.list,
      icon: SettingsOutlinedIcon,
      iconFill: SettingsOutlinedIcon,
    },
    {
      id: 8,
      title: t('appbar.actions.logout'),
      action: () => keycloak.logout(),
      icon: LogoutIcon,
      iconFill: LogoutIcon,
      sx: { mt: 4 },
    },
  ]
}
