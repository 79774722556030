import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'

import { StyledLoadingButton } from './styled'

interface IProps {
  loading: boolean
  onClick: () => void
}

export function RefreshButton({ loading, onClick }: IProps) {
  return (
    <StyledLoadingButton loading={loading} onClick={onClick}>
      <RefreshRoundedIcon />
    </StyledLoadingButton>
  )
}
