import { TExchangeStatus } from '@common/types'

import { useExchangeStatus } from '@entities/exchange'

import { StatusChip } from '@shared/ui'

interface IProps {
  status: TExchangeStatus
}

export function ExchangeStatus({ status }: IProps) {
  const { getStatusColor, getSimpleStatusText } = useExchangeStatus(status)

  return (
    <StatusChip text={getSimpleStatusText()} circleColor={getStatusColor()} />
  )
}
