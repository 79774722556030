import { Stack } from '@mui/material'

import { IMobileListItem, IOnClick } from '../model'

import { MobileListItem } from './components'

interface IProps extends IOnClick {
  mobileList: IMobileListItem[]
}

export function MobileList({ mobileList, onClick }: IProps) {
  return (
    <Stack component="ul" gap={1}>
      {mobileList.map((item) => (
        <MobileListItem
          firstRow={item.firstRow}
          id={item.id}
          lastRow={item.lastRow}
          middleRows={item.middleRows}
          warningTrigger={item.warningTrigger}
          key={item.id}
          onClick={onClick}
        />
      ))}
    </Stack>
  )
}
