import { Stack, SxProps, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { PullUpDrawer } from '@shared/ui'

import { ITemplateWithTitle, TShortInfoBarTemplate } from '../../model'

import { ReactComponent as RelevantInfoIcon } from './assets/relevant-info.svg'
import { RenderMobileTemplateWithoutTitle } from './render-mobile-template-without-title'
import { StyledModalContentWrapper } from './styled'

interface IProps {
  sx?: SxProps
  template: TShortInfoBarTemplate[] | ITemplateWithTitle[]
}

export function MobileShortInfoBar({ template, sx }: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <PullUpDrawer
      sx={sx}
      title={t('short-info-bar.mobile.title')}
      startIcon={<RelevantInfoIcon />}
    >
      <StyledModalContentWrapper
        spacing={template[0] && 'title' in template[0] ? 2 : 1}
      >
        {template.map((item, index) => (
          <Fragment key={index}>
            {item &&
              ('title' in item ? (
                <Stack spacing={1}>
                  <Typography px={{ xs: 2.5, sm: 0 }} variant="h5">
                    {item.title}
                  </Typography>

                  {item.template.map((itemTemplate, index) => (
                    <RenderMobileTemplateWithoutTitle
                      key={itemTemplate.type + index}
                      template={itemTemplate}
                      isFirst={index === 0}
                      isLast={index === item.template.length - 1}
                    />
                  ))}
                </Stack>
              ) : (
                <RenderMobileTemplateWithoutTitle
                  template={item}
                  isFirst={index === 0}
                  isLast={index === template.length - 1}
                />
              ))}
          </Fragment>
        ))}
      </StyledModalContentWrapper>
    </PullUpDrawer>
  )
}
