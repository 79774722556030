import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IExchangeSettings } from '@common/types'

import { updateExchangeSettings } from '@entities/exchange'

import { queryKeys } from '@shared/constants'

interface IProps {
  setIsDisabled: (value: boolean) => void
  resetForm: (data: IExchangeSettings) => void
}

export function useUpdateExchangeSettingsMutation({
  resetForm,
  setIsDisabled,
}: IProps) {
  const queryClient = useQueryClient()
  const { t } = useTranslation('shared')

  const updateExchangeSettingsMutation = useMutation({
    mutationFn: (data: IExchangeSettings) => updateExchangeSettings(data),
    onError: () => toast.error(t('toasts.try-again')),
    onSuccess: (response) => {
      queryClient.setQueryData([queryKeys.exchangeSettings], response)

      setIsDisabled(true)

      resetForm(response)
    },
  })

  return updateExchangeSettingsMutation
}
