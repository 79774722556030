import { IEmailTemplate } from '@common/types'

import { instance } from '@shared/requester'

export function fetchEmailTemplateById(id?: string | null) {
  if (!id) {
    throw 'Template ID is required'
  }

  return instance
    .get<IEmailTemplate>(`/settings/email-templates/${id}`)
    .then((response) => response.data)
}
