import { SxProps, Stack, Typography } from '@mui/material'
import { Fragment } from 'react'

import { TypographyWithCopyButton } from '../../typography-with-copy-button'
import { IInfoSectionRow } from '../model'

interface IProps {
  info: IInfoSectionRow[]
  sx?: SxProps
}

export function MainInfoSection({ info, sx }: IProps) {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      overflow="hidden"
      maxWidth="100%"
      sx={sx}
    >
      <Stack spacing={0.5} color="text.secondary">
        {info.map(({ label, value }, index) => (
          <Fragment key={index}>
            {value && (
              <Typography variant="body2" whiteSpace="nowrap">
                {label}
              </Typography>
            )}
          </Fragment>
        ))}
      </Stack>

      <Stack spacing={0.5} overflow="hidden">
        {info.map(({ value, copyable }, index) => (
          <Fragment key={index}>
            {value && (
              <>
                {copyable ? (
                  <TypographyWithCopyButton
                    variant="body2"
                    value={value}
                    sx={{ height: { xs: 18, md: 20 } }}
                  />
                ) : (
                  <Typography variant="body2" className="ellipsis">
                    {value}
                  </Typography>
                )}
              </>
            )}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  )
}
