import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { ExchangeListPage } from './ui'

export const ExchangeListRouter: RouteObject = {
  path: routePaths.exchanges.list,
  element: <ExchangeListPage />,
}
