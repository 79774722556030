import {
  IExchangeListItem,
  IGetExchangeListQueryParams,
  IPaginationResponse,
} from '@common/types'

import { instance } from '@shared/requester'

export async function fetchExchangeList(params: IGetExchangeListQueryParams) {
  return instance
    .get<IPaginationResponse<IExchangeListItem>>('/exchanges', { params })
    .then((response) => response.data)
}
