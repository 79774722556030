import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { Grid, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IBusinessApplicationForm } from '@entities/account-application'

import { CountrySelectInput } from '@shared/ui'

import { GroupSectionTitle } from '../../../group-section-title'

interface IProps {
  index: number
  disabled: boolean
}

export function BeneficiaryAddress({ index, disabled }: IProps) {
  const { t } = useTranslation('widgets')
  const { control } = useFormContext<IBusinessApplicationForm>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GroupSectionTitle
          icon={<HomeOutlinedIcon sx={{ fontSize: 20 }} />}
          title={t('account-application.address')}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.addressCountry`}
          render={({ field, fieldState }) => (
            <CountrySelectInput
              {...field}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.addressCountry')}
              placeholder={t('account-application.addressCountry')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.addressCity`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.addressCity')}
              placeholder={t('account-application.addressCity')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.addressStreet`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.addressStreet')}
              placeholder={t('account-application.addressStreet')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={`beneficiaries.${index}.addressZip`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('account-application.addressZip')}
              placeholder={t('account-application.addressZip')}
              fullWidth
              disabled={disabled}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
