import { Grid } from '@mui/material'

import { IAccountDetails } from '@common/types'

import {
  ExpectedTurnover,
  HomeAddress,
  IdentityDocument,
  PersonalDetails,
  Terms,
} from './group'

interface IProps {
  personalApplication: IAccountDetails['personalApplication']
}

export function PersonalAccountApplication({ personalApplication }: IProps) {
  return (
    personalApplication && (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <PersonalDetails personalApplication={personalApplication} />
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <HomeAddress personalApplication={personalApplication} />
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <IdentityDocument personalApplication={personalApplication} />
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <ExpectedTurnover personalApplication={personalApplication} />
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Terms personalApplication={personalApplication} />
        </Grid>
      </Grid>
    )
  )
}
