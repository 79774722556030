import { Box, Button, Chip, useTheme } from '@mui/material'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { getTypeOfFeePlanByIsDefaultValue } from '@entities/fee-plans'

interface IProps {
  value: boolean
  editable: boolean
  onChange: (...event: unknown[]) => void
}

export const EditableType = forwardRef<HTMLDivElement, IProps>(
  function EditableType({ value, editable, onChange }: IProps, ref) {
    const { t } = useTranslation('widgets')
    const theme = useTheme()

    const isChangeablePlan = !value && editable

    return (
      <Box sx={{ gap: 1, justifyContent: 'center', display: 'flex' }} ref={ref}>
        <Chip
          variant="outlined"
          sx={theme.typography.h6}
          label={getTypeOfFeePlanByIsDefaultValue(value)}
        />

        {isChangeablePlan && (
          <Button
            onClick={() => onChange(true)}
            variant="outlined"
            sx={{
              borderColor: 'error.main',
              color: 'error.main',
              p: 0.75,
              maxHeight: 'fit-content',
            }}
          >
            {t('fee-plan.set-to-default')}
          </Button>
        )}
      </Box>
    )
  }
)
