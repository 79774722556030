import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import to from 'await-to-js'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { assets, TAsset } from '@common/types'

import { addBalanceToCentralWalletByID } from '@entities/wallet'

import { queryKeys } from '@shared/constants'
import { CustomTextField } from '@shared/ui'

import { useCreateBalanceForm } from '../model'

interface IProps {
  centralWalletID: string
}

export function AddBalance({ centralWalletID }: IProps) {
  const [isShow, setIsShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState<TAsset>(assets[0])
  const queryClient = useQueryClient()
  const { t } = useTranslation('features')

  const handleOpen = () => setIsShow(true)

  const { control, handleSubmit, reset } = useCreateBalanceForm()

  const handleClose = () => {
    setIsShow(false)

    setTimeout(reset, 300)
  }

  const onSubmit = async (balance: number) => {
    try {
      setIsLoading(true)

      await to(
        addBalanceToCentralWalletByID({
          body: { balance, asset: selectedValue },
          centralWalletID,
        })
      )

      queryClient.prefetchQuery({
        queryKey: [queryKeys.centralWalletsSetting],
      })

      toast.success(t('wallet.toast.success'))
    } catch {
      toast.error(t('wallet.toast.error'))
    } finally {
      handleClose()

      setIsLoading(false)
    }
  }

  return (
    <>
      <Dialog
        open={isShow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        sx={{ '& .MuiPaper-root': { p: '80px 120px', gap: 5 } }}
      >
        <Typography variant="h4">{t('wallet.add-balance-setting')}</Typography>

        <Stack
          component={'form'}
          onSubmit={handleSubmit((data) => onSubmit(data.balance))}
        >
          <Autocomplete
            options={assets}
            defaultValue={assets[0]}
            disableClearable
            freeSolo={false}
            onChange={(_, newValue) => setSelectedValue(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('wallet.text-fields.asset')}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
            sx={{ mb: 4 }}
          />

          <Controller
            control={control}
            name="balance"
            render={({ field, fieldState }) => (
              <CustomTextField
                onChange={field.onChange}
                value={field.value.toString()}
                onBlur={field.onBlur}
                ref={field.ref}
                errorMessage={fieldState.error?.message}
                label={t('wallet.text-fields.balance')}
                sx={{ mb: 4 }}
                autoFocus
              />
            )}
          />

          <LoadingButton
            type={'submit'}
            loading={isLoading}
            variant="contained"
            fullWidth
          >
            {t('wallet.btn-names.send')}
          </LoadingButton>
        </Stack>
      </Dialog>

      <Button
        sx={{ mt: 1, py: 0.5, width: 'fit-content' }}
        onClick={handleOpen}
        variant="outlined"
      >
        {t('wallet.btn-names.balance')}
      </Button>
    </>
  )
}
