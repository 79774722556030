import { Stack, Typography } from '@mui/material'
import { Fragment } from 'react'

import { useBoxMediaQuery } from '@shared/hooks'
import { TypographyWithCopyButton } from '@shared/ui/typography-with-copy-button'

import { IInfoSectionRow } from '../../model'

interface IProps {
  info: IInfoSectionRow[]
  boxId?: string
}

export function SecondaryInfoColumn({ info, boxId }: IProps) {
  const boxSmAndUp = useBoxMediaQuery(boxId).up('sm')

  return (
    <Stack
      direction="row"
      spacing={1.5}
      overflow="hidden"
      alignSelf={boxSmAndUp ? 'center' : 'stretch'}
      justifyContent="space-between"
      maxWidth="100%"
    >
      <Stack spacing={0.5} color="text.secondary">
        {info.map(({ label, value }, index) => (
          <Fragment key={index}>
            {value && (
              <Typography
                variant="body2"
                whiteSpace="nowrap"
                textTransform="uppercase"
              >
                {label}
              </Typography>
            )}
          </Fragment>
        ))}
      </Stack>

      <Stack spacing={0.5} overflow="hidden">
        {info.map(({ value, copyable }, index) => (
          <Fragment key={index}>
            {value && (
              <>
                {copyable ? (
                  <TypographyWithCopyButton
                    variant="h6"
                    value={value}
                    sx={{ height: 20 }}
                  />
                ) : (
                  <Typography variant="h6" className="ellipsis">
                    {value}
                  </Typography>
                )}
              </>
            )}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  )
}
