import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import * as yup from 'yup'

import { getValidationSchemas } from '@common/validation'

import { useAccountDetails } from '@entities/account'
import { IPersonalDetailsForm } from '@entities/personal-account-application'

import { TParamsKeys } from '@shared/constants'

export function usePersonalDetailsForm() {
  const { t } = useTranslation('shared')
  const { accountId } = useParams<TParamsKeys>()

  const { data } = useAccountDetails({ accountId })

  const { countrySchema, birthDateSchema, getGeneralFieldSchema } =
    getValidationSchemas()

  const schema: yup.ObjectSchema<IPersonalDetailsForm> = yup.object({
    firstName: getGeneralFieldSchema(1, 50),
    lastName: getGeneralFieldSchema(1, 50),
    birthDate: birthDateSchema,
    nationality: countrySchema,
    isPep: yup.boolean().required(t('validation.required')),
    taxCountry: countrySchema,
    taxIdentifier: getGeneralFieldSchema(1, 100),
    isDualCitizen: yup.boolean().required(t('validation.required')),
    dualCitizen: yup
      .string()
      .test('dualCitizenFlag', t('validation.required'), (value, context) => {
        const isDualCitizen = context.parent.isDualCitizen

        const isValueValid = countrySchema.isValidSync(value)

        if (
          (isDualCitizen && isValueValid) ||
          (!isDualCitizen && isValueValid) ||
          !value
        ) {
          return true
        }

        return false
      })
      .test(
        'sameNationality',
        t('validation.the-same-second-nationality'),
        (value, context) => {
          const nationality = context.parent.nationality

          return nationality !== value
        }
      ),
  })

  const personalDetailsForm = useForm<IPersonalDetailsForm>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const { reset } = personalDetailsForm

  useEffect(() => {
    reset({
      firstName: data?.personalApplication?.firstName,
      lastName: data?.personalApplication?.lastName,
      birthDate: new Date(data?.personalApplication?.birthDate || 0),
      nationality: data?.personalApplication?.nationality,
      taxIdentifier: data?.personalApplication?.taxIdentifier,
      taxCountry: data?.personalApplication?.taxCountry,
      dualCitizen: data?.personalApplication?.dualCitizen,
      isDualCitizen: data?.personalApplication?.isDualCitizen,
      isPep: data?.personalApplication?.isPep,
    })
  }, [data])

  // const birthDate = watch('birthDate')
  // const isDualCitizen = watch('isDualCitizen')
  // const dualCitizen = watch('dualCitizen')

  // useEffect(() => {
  //   if (typeof birthDate === 'string') {
  //     setValue('birthDate', new Date(birthDate), {
  //       shouldDirty: true,
  //     })
  //   }
  // }, [birthDate])

  // useEffect(() => {
  //   if (dualCitizen === '' && !isDualCitizen) {
  //     unregister('dualCitizen')
  //   }
  // }, [dualCitizen, isDualCitizen])

  return { personalDetailsForm }
}
