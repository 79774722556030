import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { TParamsKeys } from '@shared/constants'

import { DeleteFeePlanModal } from './components'

interface IProps {
  disabled?: boolean
}

export function DeleteFeePlan({ disabled }: IProps) {
  const { t } = useTranslation('pages')
  const [showModal, setShowModal] = useState(false)
  const { feePlanId } = useParams<TParamsKeys>()

  return (
    <>
      <DeleteFeePlanModal
        show={showModal}
        onClose={() => setShowModal(false)}
        id={feePlanId || ''}
      />

      <Button
        onClick={() => setShowModal(true)}
        variant="outlined"
        fullWidth
        disabled={disabled}
        color="error"
      >
        {t('fee-plan-list.btns.remove-page')}
      </Button>
    </>
  )
}
