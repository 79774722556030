import {
  IGetTransactionListQueryParams,
  IPaginationResponse,
  ITransactionListItem,
} from '@common/types'

import { instance } from '@shared/requester'

export function fetchTransactionList(params: IGetTransactionListQueryParams) {
  return instance
    .get<IPaginationResponse<ITransactionListItem>>('/transactions', {
      params,
    })
    .then((res) => res.data)
}
