import { Drawer, styled } from '@mui/material'

export const StyledDesktopDrawer = styled(Drawer)({
  '& > .MuiPaper-root': {
    width: 520,
    height: '100vh',

    borderRadius: 0,

    display: 'flex',
    flexDirection: 'column',
  },
})
