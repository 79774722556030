import { Stack, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { IGetUserResponse } from '@common/types'

import { configTemplate } from '../../../model'

interface IProps {
  configs: IGetUserResponse['configs']
}

export function UserConfigs({ configs }: IProps) {
  const { t } = useTranslation('pages')

  return (
    <Stack spacing={2}>
      <Typography variant="h4">{t('user.configs.title')}</Typography>

      <Stack spacing={1}>
        {configs.map((config) => {
          const copiedConfig = JSON.parse(JSON.stringify(config)) as Omit<
            IGetUserResponse['configs'][number],
            'key'
          > & { key?: string }

          delete copiedConfig.key

          return (
            <Fragment key={config.key}>
              {configTemplate[config.key](copiedConfig)}
            </Fragment>
          )
        })}
      </Stack>
    </Stack>
  )
}
