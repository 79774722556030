export const currencies = ['EUR'] as const
export type TCurrency = (typeof currencies)[number]

export const blockchains = ['TRON', 'ETHEREUM', 'BITCOIN'] as const
export type TBlockchain = (typeof blockchains)[number]

export const riskLevels = ['LOW', 'MEDIUM', 'HIGH', 'NONE', 'SEVERE'] as const
export type TRiskLevel = (typeof riskLevels)[number]

export const assets = ['TRX', 'USDT', 'ETH', 'USDC'] as const
export type TAsset = (typeof assets)[number]

export type TDirector = 'PERSONAL' | 'BUSINESS'

export type TLegalDocumentType = 'COMPANY_EXTRACT'
export type TPersonDocumentType = 'PASSPORT' | 'ID'

export interface IBusinessApplicationLegalDocument {
  type: TLegalDocumentType
  images: string[] // Array of s3Id=string
}

export interface IS3Link {
  s3Link: string
  contentType: 'image/jpeg' | 'image/jpg' | 'image/png' | 'application/pdf'
}
