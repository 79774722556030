import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IAccountDetails } from '@common/types'

import { UpdateAccountStatus } from '@features/account'

import { convertValueToDate } from '@shared/utils'

import { AccountLabelValue } from './account-label-value'

interface IProps {
  account: IAccountDetails
}

export function AccountMainInfo({ account }: IProps) {
  const { t } = useTranslation('pages')

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      width={'100%'}
    >
      <Stack gap={0.5}>
        <AccountLabelValue label={t('account.id')} value={account.id} />

        <AccountLabelValue
          label={t('account.created-at')}
          value={convertValueToDate({ value: account.createdAt })}
        />

        <AccountLabelValue
          label={t('account.updated-at')}
          value={convertValueToDate({ value: account.updatedAt })}
        />
      </Stack>

      <UpdateAccountStatus />
    </Stack>
  )
}
