import { styled } from '@mui/material'

interface IProps {
  boxMdAndUp?: boolean
  boxSmAndUp?: boolean
}

export const SecondaryInfoWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'boxMdAndUp' && prop !== 'boxSmAndUp',
})<IProps>(({ theme, boxMdAndUp, boxSmAndUp }) => ({
  padding: boxMdAndUp ? '14px 20px' : '8px 16px',

  display: 'flex',
  gap: '12px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: !boxSmAndUp ? 'flex-start' : 'center',

  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
}))
