import { TypographyOwnProps, Typography } from '@mui/material'

import { TStringOrJSX } from '../../model'

interface IProps {
  value: TStringOrJSX
  variant: TypographyOwnProps['variant']
  color?: string
}

export function RowItem({ value, variant, color }: IProps) {
  return (
    <>
      {typeof value === 'string' ? (
        <Typography variant={variant} color={color}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </>
  )
}
