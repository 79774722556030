import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TFormattedBusinessApplication } from '@common/types'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

import { getCountryNameByAbbreviation } from '@shared/utils'

const mainTranslationPath = 'account-application.business-application'
const mainEntitiesTranslationPath = 'entities:account.business-application'

export function CompanyAddress({
  addressCity,
  addressCountry,
  addressStreet,
  addressZip,
}: TFormattedBusinessApplication) {
  const { t } = useTranslation(['widgets', 'entities'])

  return (
    <GroupFieldsCard>
      <Typography variant="h3" mb={1}>
        {t(`${mainTranslationPath}.company-address`)}
      </Typography>

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.city`)}
        value={addressCity}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.country`)}
        value={getCountryNameByAbbreviation(addressCountry)}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.street`)}
        value={addressStreet}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.zip`)}
        value={addressZip}
      />
    </GroupFieldsCard>
  )
}
