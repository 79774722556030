import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { updateEmailTemplate } from '@entities/email-templates'

import { queryKeys } from '@shared/constants'

export function useUpdateEmailTemplateMutation() {
  const { t } = useTranslation('shared')
  const queryClient = useQueryClient()

  const updateEmailTemplateMutation = useMutation({
    mutationFn: updateEmailTemplate,
    onError: () => toast.error(t('toasts.try-again')),
    onSuccess: (response) => {
      queryClient.setQueryData([queryKeys.emailTemplate, response.id], response)
    },
  })

  return updateEmailTemplateMutation
}
