import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import * as yup from 'yup'

import { IUploadFileSettings } from '@common/types'
import { getValidationSchemas } from '@common/validation'

import { useAccountDetails } from '@entities/account'
import { IHomeAddressForm } from '@entities/personal-account-application'

import { TParamsKeys } from '@shared/constants'
import { useTFunc } from '@shared/hooks'

export function useHomeAddressForm(uploadFileSettings: IUploadFileSettings) {
  const { t } = useTranslation('shared')
  const tFunc = useTFunc()

  const { accountId } = useParams<TParamsKeys>()

  const { data } = useAccountDetails({ accountId })

  const { getFileSchema } = getValidationSchemas(tFunc)

  const { countrySchema, getGeneralFieldSchema } = getValidationSchemas()

  const schema: yup.ObjectSchema<IHomeAddressForm> = yup.object({
    addressCountry: countrySchema,
    addressCity: getGeneralFieldSchema(2, 255),
    addressStreet: getGeneralFieldSchema(2, 100),
    addressZip: yup.string().required(t('validation.required')).max(12),
    addressProof: getFileSchema(uploadFileSettings),
  })

  const homeAddressForm = useForm<IHomeAddressForm>({
    resolver: yupResolver(schema),
  })

  const { reset } = homeAddressForm

  useEffect(() => {
    reset({
      addressCountry: data?.personalApplication?.addressCountry,
      addressCity: data?.personalApplication?.addressCity,
      addressStreet: data?.personalApplication?.addressStreet,
      addressZip: data?.personalApplication?.addressZip,
      addressProof: undefined,
    })
  }, [data])

  return { homeAddressForm }
}
