import { Button, Stack } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IAccountDetails } from '@common/types'

import {
  beneficiaryDefaultValues,
  IBusinessApplicationForm,
  useUpdateBusinessApplication,
} from '@entities/account-application'

import { BeneficiaryAdd } from './beneficiary-add'
import { BeneficiaryItem } from './beneficiary-item'

interface IProps {
  account: IAccountDetails
}

export function Beneficiaries({ account }: IProps) {
  const { control, handleSubmit } = useFormContext<IBusinessApplicationForm>()

  const { onSubmit } = useUpdateBusinessApplication({ accountId: account.id })
  const { t } = useTranslation(['widgets', 'shared'])

  const {
    fields: beneficiaries,
    append,
    remove: removeBeneficiary,
  } = useFieldArray({
    control,
    name: 'beneficiaries',
    keyName: 'localId',
  })

  const addBeneficiary = () => {
    append(beneficiaryDefaultValues, { shouldFocus: true })
  }

  return (
    <Stack
      gap={3}
      py={3}
      overflow="hidden"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {account.status === 'NEW' && (
        <Stack direction="row" justifyContent="flex-end">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            sx={{ minWidth: 160 }}
            type="submit"
          >
            {t('shared:save')}
          </Button>
        </Stack>
      )}

      <Stack direction="row" height={'100%'} gap={2} overflow={'scroll'}>
        {beneficiaries.map((item, index) => (
          <BeneficiaryItem
            key={item.localId}
            index={index}
            remove={() => removeBeneficiary(index)}
            disabled={account.status !== 'NEW'}
          />
        ))}

        {account.status === 'NEW' && (
          <Stack>
            <BeneficiaryAdd onClick={addBeneficiary} />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
