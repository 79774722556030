import { Box, Stack, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IWithdrawalResponse } from '@common/types'

import { ThumbnailDocumentViewer } from '@shared/ui'

interface IProps extends Pick<IWithdrawalResponse, 's3Links'> {
  sx?: SxProps
}

export function WithdrawalDocumentList({ s3Links, sx }: IProps) {
  const { t } = useTranslation('pages')

  return (
    <Box
      bgcolor="background.default"
      p="14px 20px 18px"
      borderRadius="20px"
      sx={sx}
    >
      <Typography variant="body2" color="text.secondary">
        {t('withdrawal.documents')}
      </Typography>

      <Stack direction="row" spacing={1} flexWrap="wrap">
        {s3Links?.map((s3Link, index) => (
          <ThumbnailDocumentViewer {...s3Link} key={index} />
        ))}
      </Stack>
    </Box>
  )
}
