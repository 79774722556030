import { IDepositListItem } from '@common/types'

import { useDepositStatusOptions } from '@entities/deposit'

import { ITemplateProps, StatusChip } from '@shared/ui'

export function DepositStatus({
  data: { status },
}: ITemplateProps<IDepositListItem>) {
  const { getStatusColor, getStatusText } = useDepositStatusOptions(status)

  return (
    <StatusChip
      circleColor={getStatusColor()}
      text={getStatusText()}
      variant="text"
    />
  )
}
