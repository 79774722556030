import { Box, SxProps } from '@mui/material'
import { memo } from 'react'

import { IncomeOutgoingFundsFields, IncomePurposeFields } from './components'

interface IProps {
  sx?: SxProps
}

export const ExpectedTurnoverForm = memo(function ExpectedTurnoverForm({
  sx,
}: IProps) {
  return (
    <Box sx={sx}>
      <IncomePurposeFields sx={{ mb: { xs: 4, md: 5.5 } }} />
      <IncomeOutgoingFundsFields type="income" sx={{ mb: 3.5 }} />
      <IncomeOutgoingFundsFields type="outgoing" />
    </Box>
  )
})
