import { useTranslation } from 'react-i18next'

import { UploadFileSettingsForm } from '@widgets/settings'

import {
  uploadFileSettingsPageId,
  useUploadFileSettings,
} from '@entities/upload-file-settings'

import { SettingLayout } from './components'

export function UploadFileSettings() {
  const { t } = useTranslation('pages')
  const { data, isLoading } = useUploadFileSettings()

  return (
    <SettingLayout
      data={!!data}
      loading={isLoading}
      title={t('settings.upload-file-settings')}
      id={uploadFileSettingsPageId}
    >
      {data && <UploadFileSettingsForm data={data} />}
    </SettingLayout>
  )
}
