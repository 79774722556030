import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { routePaths, TParamsKeys } from '@shared/constants'
import { CustomBreadcrumbs } from '@shared/ui'

interface IProps {
  sx?: SxProps
}

export function FeePlanBreadcrumbs({ sx }: IProps) {
  const location = useLocation()
  const { feePlanId } = useParams<TParamsKeys>()
  const { t } = useTranslation(['pages', 'shared'])

  const pathnames = location.pathname.split('/').filter((path) => path)

  const breadcrumbNameMap = {
    [routePaths.settings.list]: t('settings.title'),
    [routePaths.settings.feePlan.list]: t('fee-plan-list.title'),
    [`${routePaths.settings.feePlan.list}/${feePlanId}`]: t(
      'fee-plan-list.fee-plan'
    ),
  }

  return (
    <CustomBreadcrumbs
      breadcrumbNameMap={breadcrumbNameMap}
      pathnames={pathnames}
      sx={sx}
    />
  )
}
