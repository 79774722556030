import { Box, LinearProgress, Paper } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ShortInfoBar } from '@widgets/short-info-bar'

import { useUser } from '@entities/user'

import { TParamsKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'

import {
  userInfoSectionId,
  userPageId,
  useUserShortInfoBarTemplate,
} from '../model'

import { /* Statements, */ UserBreadcrumbs, UserInfo } from './components'

export function UserPage() {
  const { userId } = useParams<TParamsKeys>()

  const { data: user, isLoading, isError, failureReason } = useUser({ userId })

  const boxMdAndUp = useBoxMediaQuery(userPageId, isLoading).up('md')
  const box1000AndUp = useBoxMediaQuery(userPageId, isLoading).up(1000)

  const shortInfoBarTemplate = useUserShortInfoBarTemplate(user?.accounts)

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.error}`)
  }, [failureReason])

  return (
    <>
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      {!isLoading && (
        <Paper
          sx={{
            p: '32px 24px 20px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 3,
            mb: !box1000AndUp ? 8 : 0,
          }}
          id={userPageId}
        >
          <Box flex={1} overflow="hidden" id={userInfoSectionId}>
            <UserBreadcrumbs
              userName={user?.fullName}
              sx={{ mb: boxMdAndUp ? 0 : 2 }}
            />

            <UserInfo user={user} />
          </Box>

          {/* TODO: fix by necessary content
          <Statements title={`Statements ${mdAndUp ? 'desktop' : 'mobile'}`}>
            temporary content
          </Statements> */}

          <ShortInfoBar
            template={shortInfoBarTemplate}
            viewMode={box1000AndUp ? 'desktop' : 'mobile'}
          />
        </Paper>
      )}
    </>
  )
}
