import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { TransactionPage } from './ui'

export const TransactionPageRouter: RouteObject = {
  path: routePaths.transactions.transaction,
  element: <TransactionPage />,
}
