import { SxProps, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IUserWithStatuses } from '@common/types'

import { useGetIdvStatusOptions } from '@entities/user'

import { IInfoSectionRow, SecondaryInfoSection, StatusChip } from '@shared/ui'

import { userPageId } from '../../../model'

import { PopoverUserMetaData } from './popover-user-meta-data'

interface IProps
  extends Partial<
    Pick<
      IUserWithStatuses,
      'email' | 'phoneNumber' | 'idvStatus' | 'keycloakId' | 'createdAt'
    >
  > {
  sx?: SxProps
}

export function SecondaryUserInfo({
  email,
  idvStatus,
  phoneNumber,
  createdAt,
  keycloakId,
  sx,
}: IProps) {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const { getIdvStatusColor, getIdvStatusText } =
    useGetIdvStatusOptions(idvStatus)

  const info: IInfoSectionRow[] = [
    { label: t('user.email'), value: email, copyable: true },
    { label: t('user.phone'), value: phoneNumber, copyable: true },
  ]

  return (
    <SecondaryInfoSection info={info} boxId={userPageId} sx={sx}>
      {mdAndUp && (
        <StatusChip
          circleColor={getIdvStatusColor()}
          text={getIdvStatusText()}
          variant="filled"
        />
      )}

      {!mdAndUp && (
        <PopoverUserMetaData createdAt={createdAt} keycloakId={keycloakId} />
      )}
    </SecondaryInfoSection>
  )
}
