import { instance } from '@shared/requester'

interface IProps {
  fileList: string[]
  withdrawalId: string
  userId: string
}

export function uploadWithdrawalDocuments({
  fileList,
  withdrawalId,
  userId,
}: IProps) {
  return instance
    .patch(`/withdrawals/${withdrawalId}/documents`, { fileList, userId })
    .then((res) => res.data)
}
