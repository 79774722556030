import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton } from '@mui/material'
import { toast } from 'react-toastify'

import { IAccountListItem } from '@common/types'

import { ITemplateProps } from '@shared/ui'

export function AccountActions({
  data: { status },
}: ITemplateProps<IAccountListItem>) {
  return (
    <IconButton>
      <MoreHorizIcon
        onClick={(e) => {
          e.stopPropagation()

          toast.success(`Account status - ${status}`)
        }}
      />
    </IconButton>
  )
}
