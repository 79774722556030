import { IGetUserResponse } from '@common/types'

import { TShortInfoBarTemplate } from '@widgets/short-info-bar'

export function useUserShortInfoBarTemplate(
  accounts?: IGetUserResponse['accounts']
): TShortInfoBarTemplate[] {
  if (!accounts || accounts.length === 0) {
    return []
  }

  const getAccountName = (account: IGetUserResponse['accounts'][number]) => {
    return (
      account.businessApplication?.name ||
      `${account.personalApplication?.firstName} ${account.personalApplication?.lastName}`
    )
  }

  return [
    ...accounts.map(
      (account) =>
        ({
          type: 'account',
          data: {
            balanceAmount: account.balances[0].available,
            balanceCurrency: account.balances[0].currency,
            id: account.id,
            name: getAccountName(account),
            variant: account.variant,
          },
        }) satisfies TShortInfoBarTemplate
    ),
  ]
}
