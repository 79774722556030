import { Box, Stack } from '@mui/material'

interface IProps {
  text?: string
  textLength?: number
  maxLength?: number
}

export function MultilineFieldHelperText({
  text = '',
  textLength = 0,
  maxLength = 250,
}: IProps) {
  return (
    <Stack direction="row" justifyContent="space-between" component="span">
      <Box component="span" display="block">
        {text}
      </Box>

      <Box component="span" display="block">{`${textLength}/${maxLength}`}</Box>
    </Stack>
  )
}
