import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { IAccountDetails } from '@common/types'
import { getUpdateAccountBusinessApplicationSchema } from '@common/validation/account-schemas/update-account-business-application'

import { useTFunc } from '@shared/hooks'

import { beneficiaryDefaultValues } from '../constants'
import { IBusinessApplicationForm } from '../types'

import { useConvertApplicationData } from './use-convert-application-data'

interface IProps {
  businessApplicationStartData: IAccountDetails['businessApplication']
}

export function useBusinessApplicationForm({
  businessApplicationStartData,
}: IProps) {
  const tFunc = useTFunc()

  const { convertResponseDataToFormValues } = useConvertApplicationData()

  const { frontBeneficiariesSchema } =
    getUpdateAccountBusinessApplicationSchema(tFunc)

  const schema: yup.ObjectSchema<IBusinessApplicationForm> = yup
    .object()
    .shape({
      beneficiaries: frontBeneficiariesSchema,
    })

  let defaultValues: IBusinessApplicationForm = {
    beneficiaries: [beneficiaryDefaultValues],
  }

  if (businessApplicationStartData) {
    defaultValues = convertResponseDataToFormValues(
      businessApplicationStartData
    )
  }

  const form = useForm<IBusinessApplicationForm>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onSubmit',
  })

  return form
}
