import {
  IAccountCryptoBalance,
  IGetCryptoBalanceListQueryParams,
  IPaginationResponse,
} from '@common/types'

import { instance } from '@shared/requester'

export function fetchCryptoBalanceList(
  params: IGetCryptoBalanceListQueryParams
) {
  return instance
    .get<
      IPaginationResponse<IAccountCryptoBalance>
    >('/crypto-balances', { params })
    .then((res) => res.data)
}
