import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Popover, useTheme } from '@mui/material'
import { useState, MouseEvent } from 'react'

import { IUserWithStatuses } from '@common/types'

import { UserMetaData } from '../user-meta-data'

export function PopoverUserMetaData({
  createdAt,
  keycloakId,
}: Partial<Pick<IUserWithStatuses, 'keycloakId' | 'createdAt'>>) {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const id = anchorEl ? 'user-meta-data-popover' : undefined

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <MoreVertIcon sx={{ color: theme.palette.text.primary }} />
      </IconButton>

      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserMetaData createdAt={createdAt} keycloakId={keycloakId} />
      </Popover>
    </>
  )
}
