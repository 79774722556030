import { SxProps, Stack } from '@mui/material'
import { useMemo } from 'react'

import { IFeePlan } from '@common/types'

import { ScaleListItem } from './scale-list-item'

interface IProps {
  feePlan: IFeePlan
  sx?: SxProps
}

export function ScaleList({ feePlan, sx }: IProps) {
  const minAmount = useMemo(
    () => Math.min(...feePlan.scales.map((scale) => scale.min)),
    [feePlan]
  )

  const minFeeIndex = useMemo(
    () => feePlan.scales.findIndex((scale) => scale.min === minAmount),
    [minAmount]
  )

  return (
    <Stack spacing={1} sx={sx}>
      {feePlan.scales.map((scale, index) => (
        <ScaleListItem
          currency={feePlan.currency}
          key={index}
          scale={scale}
          minFee={feePlan.minFee}
          isMin={index === minFeeIndex}
        />
      ))}
    </Stack>
  )
}
