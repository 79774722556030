import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { updateUserEmailsAndPhoneNumbers } from '@entities/user'
import { createWalletForAllUsersWithBlockchain } from '@entities/wallet'

import { ConfirmActionDialog } from '@shared/ui'

export function UpdateDataAfterMigration() {
  const [show, setShow] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const { t } = useTranslation('shared')

  const handleUpdate = async () => {
    try {
      setIsLoading(true)

      await Promise.all([
        updateUserEmailsAndPhoneNumbers(),
        createWalletForAllUsersWithBlockchain(),
      ])

      setShow(false)

      toast.success(
        'Request for updating data after migration has been sent. Wait for a few minutes and check DB.'
      )
    } catch {
      toast.error(t('toasts.try-again'))
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 300)

      setIsDisabled(true)
    }
  }

  return (
    <Box p="40px">
      <Typography variant="body1" mb={2}>
        You must do this action after initial migration from old DB for updating
        user emails, phone numbers and create wallets.
      </Typography>

      <Button
        variant="contained"
        onClick={() => setShow(true)}
        disabled={isDisabled}
      >
        Initiate
      </Button>

      <ConfirmActionDialog
        title={'Are you sure?'}
        open={show}
        onClose={() => setShow(false)}
        onClickConfirm={handleUpdate}
        loading={isLoading}
      />
    </Box>
  )
}
