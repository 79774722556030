import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { LoadingButton } from '@mui/lab'
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IUploadFileSettings } from '@common/types'

import { uploadFileSettingsPageId } from '@entities/upload-file-settings'

import { useBoxMediaQuery } from '@shared/hooks'

import {
  convertUploadFileSettingsToFormData,
  TUploadFileSettingsForm,
  useUploadFileMutation,
  useUploadFileSettingsForm,
} from '../model'

interface IProps {
  data: IUploadFileSettings
}

export function UploadFileSettingsForm({ data }: IProps) {
  const { t } = useTranslation(['widgets', 'shared'])

  const mdAndUp = useBoxMediaQuery(uploadFileSettingsPageId).up('md')
  const smAndUp = useBoxMediaQuery(uploadFileSettingsPageId).up('sm')

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useUploadFileSettingsForm(convertUploadFileSettingsToFormData(data))

  const {
    fields: allowedTypes,
    append,
    remove: removeAllowedType,
  } = useFieldArray({
    control,
    name: 'allowedTypes',
    keyName: 'localId',
  })

  const { mutate, isPending } = useUploadFileMutation((data) =>
    reset(convertUploadFileSettingsToFormData(data))
  )

  const onSubmit = (data: TUploadFileSettingsForm) => {
    mutate({
      allowedTypes: data.allowedTypes.map((allowedType) => allowedType.value),
      maxFileSize: data.maxFileSize * (1024 * 1024),
      fileMaxNumber: data.fileMaxNumber,
    })
  }

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={5}>
      <Stack gap={2.5}>
        <Stack gap={0.75}>
          <Typography variant="caption">
            {t('settings.upload-file-settings.allowed-types')}
          </Typography>

          <Grid container spacing={1.5}>
            {allowedTypes.map((allowedType, index) => (
              <Grid
                item
                xs={mdAndUp ? 4 : smAndUp ? 6 : 12}
                key={allowedType.localId}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 2,
                }}
              >
                <Controller
                  control={control}
                  name={`allowedTypes.${index}.value`}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      ref={null}
                      inputRef={field.ref}
                      fullWidth
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={() => removeAllowedType(index)}>
                            <CancelRoundedIcon color="error" />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />

                {allowedTypes.length - 1 === index && (
                  <IconButton
                    color="success"
                    onClick={() => append({ value: '' })}
                  >
                    <AddCircleRoundedIcon />
                  </IconButton>
                )}
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Stack direction="row" gap={1.5}>
          <Controller
            control={control}
            name="maxFileSize"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t('settings.upload-file-settings.max-size-label')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">MB</InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="fileMaxNumber"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t('settings.upload-file-settings.max-number-label')}
              />
            )}
          />
        </Stack>
      </Stack>

      <LoadingButton
        type="submit"
        loading={isPending}
        disabled={!isDirty}
        variant="contained"
        fullWidth
      >
        {t('settings.upload-file-settings.button')}
      </LoadingButton>
    </Stack>
  )
}
