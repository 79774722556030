import { LoadingButton } from '@mui/lab'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { assets, IExchangeSettings, TTradeVariant } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'

import {
  settingPageId,
  useExchangeSettingsForm,
  useUpdateExchangeSettingsMutation,
} from '../model'

interface IProps {
  data: IExchangeSettings
}

export function ExchangeSettingsForm({ data }: IProps) {
  const [isDisabled, setIsDisabled] = useState<boolean>(true)

  const { t } = useTranslation('widgets')
  const px550AndUp = useBoxMediaQuery(settingPageId).up(550)

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useExchangeSettingsForm(data)

  const { mutate, isPending } = useUpdateExchangeSettingsMutation({
    setIsDisabled,
    resetForm: reset,
  })

  useEffect(() => {
    setIsDisabled(!isDirty)
  }, [isDirty])

  return (
    <Stack
      gap={3}
      component="form"
      onSubmit={handleSubmit((data) => mutate(data))}
    >
      <Stack gap={1.5}>
        <Typography variant="h2" mb={1}>
          {t('settings.exchange.settings.title')}
        </Typography>

        <Typography variant="h5">
          {t('settings.exchange.settings.available-assets-for')}
        </Typography>

        {Object.entries(data.availableAssets).map((trade) => {
          const tradeVariant = trade[0] as TTradeVariant

          return (
            <Box key={tradeVariant}>
              <Typography variant="h6">
                {t(`settings.exchange.settings.${tradeVariant}`)}
              </Typography>

              <Grid container spacing={1}>
                {assets.map((asset) => (
                  <Grid item xs={px550AndUp ? 2 : 4} key={asset}>
                    <Controller
                      control={control}
                      name={`availableAssets.${tradeVariant}.${asset}`}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={field.onChange}
                            />
                          }
                          label={asset}
                        />
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )
        })}
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} md={px550AndUp ? 6 : 12}>
          <Box>
            <Typography variant="h5" mb={1}>
              {t('settings.exchange.settings.ttl-description')}
            </Typography>

            <Controller
              control={control}
              name="TTL"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  ref={null}
                  inputRef={field.ref}
                  helperText={fieldState.error?.message}
                  label={t('settings.exchange.settings.ttl')}
                  fullWidth
                />
              )}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={px550AndUp ? 6 : 12}
          sx={{ display: 'flex', alignItems: 'end' }}
        >
          <LoadingButton
            type="submit"
            loading={isPending}
            variant="contained"
            fullWidth
            disabled={isDisabled}
            sx={{ height: 52 }}
          >
            {t('settings.exchange.settings.button')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Stack>
  )
}
