import { IUploadFile } from '@shared/ui'

export const getFormattedFileList = (files: File[]): IUploadFile[] => {
  if (Array.isArray(files)) {
    return files.map((item: File) => ({
      id: Date.now().toString(),
      name: item.name,
      size: item.size,
    }))
  }

  if (typeof files === 'object' && files !== null) {
    const singleFile: File = files as File

    return [
      {
        name: singleFile.name,
        size: singleFile.size,
        id: Date.now().toString(),
      },
    ]
  }

  return []
}
