import {
  Box,
  Checkbox,
  FormControlLabel,
  SxProps,
  Typography,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ITermsAndConditionsForm } from '@entities/personal-account-application'

interface IProps {
  sx?: SxProps
}

export function UsCitizenshipSection({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const { control } = useFormContext<ITermsAndConditionsForm>()

  return (
    <Box sx={sx}>
      <Typography variant="subtitle2" textTransform="uppercase" mb={2}>
        {t(
          'personal-account-application.terms-and-conditions.us-citizenship-title'
        )}
      </Typography>

      <Controller
        control={control}
        name="isUsCitizen"
        render={({ field }) => (
          <FormControlLabel
            sx={{ mr: 0 }}
            {...field}
            control={
              <Checkbox
                {...field}
                checked={!!field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked)
                }}
              />
            }
            label={
              <Typography variant="body2">
                {t(
                  'personal-account-application.terms-and-conditions.us-citizenship'
                )}
              </Typography>
            }
          />
        )}
      />
    </Box>
  )
}
