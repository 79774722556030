import { IFeePlan, TFeePlanForm } from '@common/types'

import { instance } from '@shared/requester'

export async function updateFeePlan(data: TFeePlanForm, feePlanId?: string) {
  if (!feePlanId) {
    throw new Error()
  }

  return instance
    .put<IFeePlan>(`/settings/fee-plans/${feePlanId}`, data)
    .then((res) => res.data)
}
