import { IUploadFileSettings } from '@common/types'

import { TUploadFileSettingsForm } from '../types'

export function convertUploadFileSettingsToFormData(
  data: IUploadFileSettings
): TUploadFileSettingsForm {
  return {
    maxFileSize: data.maxFileSize / (1024 * 1024),
    allowedTypes: data.allowedTypes.map((value) => ({ value })),
    fileMaxNumber: data.fileMaxNumber,
  }
}
