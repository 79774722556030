import { Collapse, Grid, SxProps, TextField, useTheme } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  IExpectedTurnoverForm,
  primarySourceIncomeTranslationKeys,
  purposeOfAccountTranslationKeys,
} from '@entities/personal-account-application'

import { SimpleAutocomplete, MultilineFieldHelperText } from '@shared/ui'

interface IProps {
  sx?: SxProps
}

const maxTextLength = 250

export function IncomePurposeFields({ sx }: IProps) {
  const [isOther, setIsOther] = useState<{
    source: boolean
    purpose: boolean
  }>({ source: false, purpose: false })

  const { t } = useTranslation('widgets')
  const theme = useTheme()
  const { control, watch } = useFormContext<IExpectedTurnoverForm>()

  const primarySourceIncomeOptions = useMemo(
    () =>
      Object.entries(primarySourceIncomeTranslationKeys).map(
        ([key, labelKey]) => ({
          key,
          label: t(
            `personal-account-application.expected-turnover-form.primary-source.${labelKey}`
          ),
        })
      ),
    []
  )

  const purposeOfAccountOptions = useMemo(
    () =>
      Object.entries(purposeOfAccountTranslationKeys).map(
        ([key, labelKey]) => ({
          key,
          label: t(
            `personal-account-application.expected-turnover-form.purpose-of-account.${labelKey}`
          ),
        })
      ),
    []
  )

  useEffect(() => {
    const { unsubscribe } = watch((formValues) => {
      const source = formValues.incomePrimarySource
      const purpose = formValues.purposeOfAccount

      if (source === 'Other') {
        setIsOther({ ...isOther, source: true })
      }

      if (purpose === 'Other') {
        setIsOther({ ...isOther, purpose: true })
      }
    })

    return () => unsubscribe()
  }, [watch('incomePrimarySource'), watch('purposeOfAccount')])

  return (
    <Grid container columnSpacing={{ xs: 0, md: 4.5 }} rowSpacing={3} sx={sx}>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'incomePrimarySource'}
          render={({ field, fieldState }) => (
            <SimpleAutocomplete
              {...field}
              inputRef={field.ref}
              ref={undefined}
              options={primarySourceIncomeOptions}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              label={t(
                'personal-account-application.expected-turnover-form.primary-source-income'
              )}
              fullWidth
              placeholder={t(
                'personal-account-application.expected-turnover-form.primary-source-income'
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'purposeOfAccount'}
          render={({ field, fieldState }) => (
            <SimpleAutocomplete
              {...field}
              inputRef={field.ref}
              ref={undefined}
              options={purposeOfAccountOptions}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              label={t(
                'personal-account-application.expected-turnover-form.purpose-of-account.title'
              )}
              fullWidth
              placeholder={t(
                'personal-account-application.expected-turnover-form.purpose-of-account.title'
              )}
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        order={{ xs: 2, md: 'initial' }}
        sx={{
          transition: theme.transitions.create('padding'),
          ...(!isOther.source && {
            py: 0 + '!important',
          }),
        }}
      >
        <Collapse in={isOther.source}>
          <Controller
            control={control}
            name={`incomePrimarySourceOther`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={field.value || ''}
                inputRef={field.ref}
                ref={undefined}
                multiline
                error={!!fieldState.error}
                helperText={
                  <MultilineFieldHelperText
                    text={fieldState.error?.message}
                    textLength={field.value?.length}
                    maxLength={maxTextLength}
                  />
                }
                inputProps={{ maxLength: maxTextLength }}
                label={t(
                  'personal-account-application.expected-turnover-form.primary-source-income-other'
                )}
                fullWidth
                placeholder={t(
                  'personal-account-application.expected-turnover-form.primary-source-income-other'
                )}
              />
            )}
          />
        </Collapse>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        order={{ xs: 5, md: 'initial' }}
        sx={{
          transition: theme.transitions.create('padding'),
          ...(!isOther.purpose && {
            py: 0 + '!important',
          }),
        }}
      >
        <Collapse in={isOther.purpose}>
          <Controller
            control={control}
            name={`purposeOfAccountOther`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={field.value || ''}
                inputRef={field.ref}
                ref={undefined}
                multiline
                error={!!fieldState.error}
                helperText={
                  <MultilineFieldHelperText
                    text={fieldState.error?.message}
                    textLength={field.value?.length}
                    maxLength={maxTextLength}
                  />
                }
                inputProps={{ maxLength: maxTextLength }}
                label={t(
                  'personal-account-application.expected-turnover-form.name-of-activity'
                )}
                fullWidth
                placeholder={t(
                  'personal-account-application.expected-turnover-form.name-of-activity'
                )}
              />
            )}
          />
        </Collapse>
      </Grid>
    </Grid>
  )
}
