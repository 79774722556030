import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ITransactionDetails } from '@common/types'

import { ThumbnailDocumentViewer } from '@shared/ui'

export function TransactionDocumentList({
  s3Links,
}: Pick<ITransactionDetails, 's3Links'>) {
  const { t } = useTranslation('pages')

  return (
    <Box bgcolor="background.default" p="14px 20px 18px" borderRadius="20px">
      <Typography variant="body2" color="text.secondary">
        {t('withdrawal.documents')}
      </Typography>

      <Stack direction="row" gap={1} flexWrap="wrap">
        {s3Links?.map((s3Link, index) => (
          <ThumbnailDocumentViewer {...s3Link} key={index} />
        ))}
      </Stack>
    </Box>
  )
}
