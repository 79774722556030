import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TAccountStatus } from '@common/types'

import { grey } from '@shared/constants'

export function useGetAccountStatusOptions(status?: TAccountStatus) {
  const theme = useTheme()
  const { t } = useTranslation('entities')

  const colors: Record<TAccountStatus, string> = {
    APPROVED: theme.palette.success.main,
    NEW: grey[400],
    REJECTED: theme.palette.error.main,
  }

  const getStatusText = () => {
    return status ? t(`account.status.${status}`) : ''
  }

  const getStatusColor = () => {
    return status ? colors[status] : theme.palette.error.main
  }

  return { getStatusText, getStatusColor }
}
