import { Container, Paper, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// import { useAccountsFilters } from '@features/account'

import { useAccountList } from '@entities/account'

import { ListLoader, StyledToolbar, useCustomPagination } from '@shared/ui'
import { getPaginationSkip } from '@shared/utils'

import { accountListPageId } from '../model'

import { AccountListTable } from './components'

export function AccountListPage() {
  const { t } = useTranslation('pages')

  const {
    page,
    take,
    paginationNode,
    setTotalPages,
    setListLength,
    // setPage,
  } = useCustomPagination()

  // TODO future filter
  // const { currentVariant, filtersOptions } = useAccountsFilters()

  const { data: accounts, isLoading } = useAccountList({
    take,
    skip: getPaginationSkip(take, page),
  })

  useEffect(() => {
    if (accounts) {
      const totalPages = Math.ceil(accounts.total / accounts.take)

      setTotalPages(totalPages)

      setListLength(accounts.list.length)
    }
  }, [accounts?.total])

  return (
    <Container maxWidth={false}>
      <Paper sx={{ padding: { md: '16px 24px 28px' } }} id={accountListPageId}>
        <StyledToolbar variant="dense">
          <Typography variant={'h4'}>{t('account-list.title')}</Typography>
          {/*<FiltersMenu filtersOptions={filtersOptions} />*/}
        </StyledToolbar>

        {isLoading ? (
          <ListLoader skeletonsNumber={take} />
        ) : (
          <AccountListTable accounts={accounts?.list || []} />
        )}

        {paginationNode}
      </Paper>
    </Container>
  )
}
