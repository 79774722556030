import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IUploadFileSettings } from '@common/types'

export function useFileValidation({
  maxFileSize,
  allowedTypes,
}: Omit<IUploadFileSettings, 'fileMaxNumber'>) {
  const { t } = useTranslation('shared')

  return (file: File) => {
    try {
      if (file.size > maxFileSize) {
        throw t('file-upload.file-size-error', {
          name: file.name,
          size: (maxFileSize / 1024 / 1024).toFixed() + 'MB',
        })
      }

      if (!allowedTypes.includes(file.type)) {
        throw t('file-upload.file-type-error', {
          name: file.name,
        })
      }

      return true
    } catch (error) {
      toast.error(error as string)

      return false
    }
  }
}
