import { Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { HomeAddressForm } from '@widgets/personal-account-application'

import { IContext } from '../../model'

export function HomeAddress() {
  const { t } = useTranslation('pages')
  const { homeAddressForm: form } = useOutletContext<IContext>()

  return (
    <FormProvider {...form}>
      <form>
        <Typography variant="subtitle2" textTransform="uppercase" mb={2}>
          {t('personal-account-application.steps-titles.4')}
        </Typography>

        <HomeAddressForm sx={{ mb: 4 }} />
      </form>
    </FormProvider>
  )
}
