import { Box, SxProps } from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { YesNoRadioButtons } from '@shared/ui'

interface IProps<T extends FieldValues> {
  namePep: FieldPath<T>
  radioButtonsLabel: string
  sx?: SxProps
}

export function Pep<T extends FieldValues>({
  namePep,
  radioButtonsLabel,
  sx,
}: IProps<T>) {
  const { control } = useFormContext<T>()
  const { t } = useTranslation('shared')

  return (
    <Box sx={sx}>
      <Controller
        control={control}
        name={namePep}
        render={({ field, fieldState }) => (
          <YesNoRadioButtons
            {...field}
            onChange={(e) => {
              const value = e.target.value

              field.onChange(value === 'yes')

              field.onBlur()
            }}
            label={radioButtonsLabel}
            ref={field.ref}
            error={!!fieldState.error}
            helperText={fieldState.error ? t('validation.required') : ''}
            sx={{ minHeight: 76 }}
          />
        )}
      />
    </Box>
  )
}
