import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ISingleErrorResponse, ITransactionDetails } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchTransaction } from '../../api'

export function useTransaction(transactionId?: string) {
  return useQuery<ITransactionDetails, AxiosError<ISingleErrorResponse>>({
    queryKey: [queryKeys.transaction, transactionId],
    queryFn: () => fetchTransaction({ transactionId }),
    enabled: !!transactionId,
    refetchInterval: (data) =>
      data?.state.data?.status !== 'COMPLETED' ? 5 * 1000 : false,
  })
}
