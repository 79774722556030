import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IWithdrawalSettings } from '@common/types'

import { updateWithdrawalSettings } from '@entities/withdrawal'

import { queryKeys } from '@shared/constants'

export function useUpdateWithdrawalSettingsMutation(
  reset: (data: IWithdrawalSettings) => void
) {
  const queryClient = useQueryClient()
  const { t } = useTranslation('shared')

  const updateWithdrawalSettingsMutation = useMutation({
    mutationFn: updateWithdrawalSettings,
    onError: () => toast.error(t('toasts.try-again')),
    onSuccess: (response) => {
      queryClient.setQueryData([queryKeys.withdrawalSettings], response)

      reset(response)
    },
  })

  return updateWithdrawalSettingsMutation
}
