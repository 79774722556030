import { format } from 'date-fns'
import Decimal from 'decimal.js'
import { useTranslation } from 'react-i18next'

import { coinsOfBlockchains } from '@common/constants'
import { ITransactionDetails } from '@common/types'

import { IInfoSectionRow } from '@shared/ui'

type TInputTransaction = Pick<
  ITransactionDetails,
  | 'amount'
  | 'asset'
  | 'blockchain'
  | 'createdAt'
  | 'exchangeId'
  | 'externalId'
  | 'hash'
  | 'id'
  | 'networkFee'
  | 'spamAddressId'
  | 'userSignature'
  | 'receiver'
  | 'sender'
>

export function useInfoTemplates(transaction?: TInputTransaction) {
  const { t } = useTranslation('pages')

  if (!transaction) {
    return {
      mainInfoTemplate: [],
      secondaryInfoTemplate: [],
      senderReceiverInfoTemplate: [],
    }
  }

  const {
    amount,
    asset,
    blockchain,
    createdAt,
    exchangeId,
    externalId,
    hash,
    id,
    networkFee,
    spamAddressId,
    userSignature,
    receiver,
    sender,
  } = transaction

  const mainInfoTemplate = [
    { label: t('transaction.id'), value: id },
    { label: t('transaction.exchange-id'), value: exchangeId, copyable: true },
    { label: t('transaction.external-id'), value: externalId, copyable: true },
    {
      label: t('transaction.spam-address-id'),
      value: spamAddressId,
      copyable: true,
    },
    {
      label: t('transaction.user-signature'),
      value: userSignature,
      copyable: true,
    },
    {
      label: t('transaction.created-at'),
      value: format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
    },
  ] satisfies IInfoSectionRow[]

  const secondaryInfoTemplate = [
    { label: t('transaction.amount'), value: `${amount} ${asset}` },
    { label: t('transaction.blockchain'), value: blockchain },
    { label: t('transaction.hash'), value: hash, copyable: true },
    {
      label: t('transaction.network-fee'),
      value:
        networkFee &&
        `${new Decimal(networkFee).toFixed()} ${coinsOfBlockchains[blockchain]}`,
    },
  ] satisfies IInfoSectionRow[]

  const senderReceiverInfoTemplate = [
    {
      label: t('transaction.sender'),
      value: sender,
      copyable: true,
    },
    {
      label: t('transaction.receiver'),
      value: receiver,
      copyable: true,
    },
  ] satisfies IInfoSectionRow[]

  return {
    mainInfoTemplate,
    secondaryInfoTemplate,
    senderReceiverInfoTemplate,
  }
}
