import { Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { ExpectedTurnoverForm } from '@widgets/personal-account-application'

import { IContext } from '../../model'

export function ExpectedTurnover() {
  const { expectedTurnoverForm: form } = useOutletContext<IContext>()
  const { t } = useTranslation('pages')

  return (
    <FormProvider {...form}>
      <form>
        <Typography
          variant="subtitle2"
          textTransform="uppercase"
          mb={{ xs: 2, md: 5 }}
        >
          {t('personal-account-application.steps-titles.2')}
        </Typography>

        <ExpectedTurnoverForm sx={{ mb: 5 }} />
      </form>
    </FormProvider>
  )
}
