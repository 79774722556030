import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  IGetUserListQueryParams,
  IPaginationResponse,
  ISingleErrorResponse,
  IUserWithStatuses,
} from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchUserList } from '../../api'

export function useUserList(params: IGetUserListQueryParams = {}) {
  return useQuery<
    IPaginationResponse<IUserWithStatuses> | null,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.userList, params],
    queryFn: () =>
      params.skip !== undefined && params.take ? fetchUserList(params) : null,
    refetchInterval: 5 * 60 * 1000,
  })
}
