import { ITransactionDetails, ITransactionWallet } from '@common/types'

import { TShortInfoBarTemplate } from '@widgets/short-info-bar'

export function useTransactionShortInfoBarTemplate(
  transaction: ITransactionDetails
) {
  const { receiverWallet, senderWallet } = transaction

  const createTemplate = (
    wallet: ITransactionWallet | null
  ): TShortInfoBarTemplate[] => {
    if (!wallet) {
      return []
    }

    return [
      ...(wallet.user
        ? ([
            {
              type: 'user',
              data: {
                fullName: wallet.user?.fullName || '',
                id: wallet.user?.id || '',
              },
            },
          ] satisfies TShortInfoBarTemplate[])
        : []),
      ...(wallet.account
        ? ([
            {
              type: 'account',
              data: {
                balanceAmount: wallet.account?.balances[0].available,
                balanceCurrency: wallet.account?.balances[0].currency || 'EUR',
                id: wallet.account?.id || '',
                name:
                  (wallet.account?.variant === 'BUSINESS'
                    ? wallet.account?.businessApplication?.name
                    : `${
                        wallet.account?.personalApplication?.firstName
                      } ${wallet.account?.personalApplication?.lastName}`) ||
                  '',
                variant: wallet.account?.variant || 'PERSONAL',
              },
            },
          ] satisfies TShortInfoBarTemplate[])
        : []),
      {
        type: 'wallet',
        data: {
          isCentral: wallet.isCentral,
          address: wallet.address,
          blockchain: transaction.blockchain,
          fullName: wallet.user?.fullName || '',
          balanceList:
            wallet.balances.map((balance) => ({
              asset: balance.asset,
              amount: balance.available,
            })) || [],
        },
      },
    ]
  }

  const receiverTemplate = createTemplate(receiverWallet)
  const senderTemplate = createTemplate(senderWallet)

  return { receiverTemplate, senderTemplate }
}
