import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import FiberSmartRecordOutlinedIcon from '@mui/icons-material/FiberSmartRecordOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded'
import { SxProps, Tab, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TBusinessAccountApplicationTab } from '@entities/account-application'

import { grey } from '@shared/constants'
import { ITab, StyledTabs } from '@shared/ui'

interface IBusinessTab extends Omit<ITab, 'path'> {
  path: TBusinessAccountApplicationTab
}

interface IProps {
  currentTab: TBusinessAccountApplicationTab
  changeTab: (tab: TBusinessAccountApplicationTab) => void
  sx?: SxProps
}

export function BusinessApplicationTabs({ currentTab, changeTab, sx }: IProps) {
  const theme = useTheme()
  const { t } = useTranslation('widgets')

  const tabs: IBusinessTab[] = [
    {
      label: t('account-application.mainInfo'),
      icon: <FiberSmartRecordOutlinedIcon />,
      path: 'main',
    },
    {
      label: t('account-application.directors'),
      icon: <KeyboardDoubleArrowDownRoundedIcon />,
      path: 'directors',
    },
    {
      label: t('account-application.shareholders'),
      icon: <CurrencyExchangeOutlinedIcon />,
      path: 'shareholders',
    },
    {
      label: t('account-application.beneficiaries'),
      icon: <GroupsIcon />,
      path: 'beneficiaries',
    },
  ]

  return (
    <StyledTabs
      value={currentTab}
      scrollButtons="auto"
      variant="scrollable"
      sx={sx}
    >
      {tabs.map(({ label, path: value, icon, activeIcon }, index) => (
        <Tab
          id={`business-account-application-tab-${index}`}
          aria-controls={`business-account-application-tabpanel-${index}`}
          label={
            typeof label === 'string' ? label : label(value === currentTab)
          }
          value={value}
          icon={currentTab === value && activeIcon ? activeIcon : icon}
          iconPosition="start"
          key={index}
          sx={{
            padding: '4px 12px 4px 8px',
            minHeight: 'initial',
            color: grey[700],
            transition: theme.transitions.create(['color']),
          }}
          onClick={() => changeTab(value)}
        />
      ))}
    </StyledTabs>
  )
}
