import { Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ITransactionDetails } from '@common/types'

import { useTransactionStatusOptions } from '@entities/transaction'

import { useBoxMediaQuery } from '@shared/hooks'
import {
  StatusApproveReject,
  RefreshButton,
  ConfirmActionDialog,
} from '@shared/ui'

import {
  transactionPageId,
  useApproveRejectRiskedTransaction,
} from '../../model'

interface IProps {
  transactionId: ITransactionDetails['id']
  transactionStatus: ITransactionDetails['status']
  loading: boolean
  isFetching: boolean
  refetch: () => void
}

export function ApproveRejectRiskedTransaction({
  transactionId,
  transactionStatus,
  loading,
  isFetching,
  refetch,
}: IProps) {
  const [action, setAction] = useState<'approve' | 'reject' | null>(null)

  const boxMdAndUp = useBoxMediaQuery(transactionPageId, loading).up('md')
  const { t } = useTranslation('shared')

  const { getStatusColor, getStatusText } =
    useTransactionStatusOptions(transactionStatus)

  const { approve, isLoading, reject } = useApproveRejectRiskedTransaction({
    transactionId,
    onSuccess: () => setAction(null),
  })

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <StatusApproveReject
          bgcolor={getStatusColor()}
          text={getStatusText()}
          hideApproveButton={transactionStatus !== 'RISKED'}
          hideRejectButton={transactionStatus !== 'RISKED'}
          onApprove={() => setAction('approve')}
          onReject={() => setAction('reject')}
          loading={isLoading}
          sx={{ order: boxMdAndUp ? 'initial' : -1 }}
        />

        <RefreshButton loading={isFetching} onClick={refetch} />
      </Stack>

      <ConfirmActionDialog
        title={t('confirm-action-dialog.title')}
        open={!!action}
        onClose={() => setAction(null)}
        onClickConfirm={() =>
          action === 'approve' ? approve(transactionId) : reject(transactionId)
        }
        colorConfirmButton={action === 'approve' ? 'success' : 'error'}
        loading={isLoading}
      />
    </>
  )
}
