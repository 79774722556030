import { useNavigate } from 'react-router'

import { ITransactionListItem } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'
import { ComposeTable, MobileList } from '@shared/ui'

import { transactionListPageId, useTransactionListTemplate } from '../../model'

interface IProps {
  transactions: ITransactionListItem[]
}

export function TransactionList({ transactions }: IProps) {
  const boxMdAndUp = useBoxMediaQuery(transactionListPageId).up('md')

  const navigate = useNavigate()

  const { desktopTemplate, mobileTemplate } =
    useTransactionListTemplate(transactions)

  return (
    <>
      {boxMdAndUp ? (
        <ComposeTable
          template={desktopTemplate}
          data={transactions}
          itemIdProperty="id"
          onClickItem={navigate}
        />
      ) : (
        <MobileList mobileList={mobileTemplate} onClick={navigate} />
      )}
    </>
  )
}
