import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { ChangeEvent } from 'react'

interface IProps {
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void
  value: unknown
  valueVariants: Array<{ label: string; value: unknown }>
  error?: boolean
  helperText?: string
  label: string
}

export function RadioGroupField({
  onChange,
  value,
  error,
  helperText,
  valueVariants,
  label,
}: IProps) {
  return (
    <FormControl>
      <Typography variant="subtitle2" mb={0.5}>
        {label}
      </Typography>

      <RadioGroup onChange={onChange} value={value}>
        <Grid container rowSpacing={0.5} columnSpacing={4.5}>
          {valueVariants.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <FormControlLabel
                value={item.value}
                control={
                  <Radio
                    color={error ? 'error' : undefined}
                    checked={item.value === value}
                  />
                }
                label={<Typography variant="body2">{item.label}</Typography>}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>

      {error && (
        <FormHelperText error={error}>{helperText || ' '}</FormHelperText>
      )}
    </FormControl>
  )
}
