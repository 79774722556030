import {
  TUpdateUserStatusConfigPayload,
  TUserStatusesConfig,
} from '@common/types'

import { instance } from '@shared/requester'

export function updateUserStatusConfigs(
  payload: TUpdateUserStatusConfigPayload
) {
  return instance
    .patch<TUserStatusesConfig['value']>('/users/update-status', payload)
    .then((res) => res.data)
}
