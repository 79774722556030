import { TPath } from '../types'

export function getValueByPath<T>(
  obj: T,
  path: TPath<T> | false,
  additionalPath?: TPath<T>
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let value: any = obj
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let additionalValue: any = additionalPath ? obj : undefined

  if (path === false) {
    return value
  }

  const keys: string[] = path.split('.')

  for (const key of keys) {
    // If the current value is not an object or array, return undefined
    if (typeof value !== 'object' || value === null) {
      return undefined
    }

    value = value[key as keyof T]
  }

  if (!additionalValue) {
    return [value]
  }

  const additionalKeys: string[] = additionalPath?.split('.') || []

  for (const key of additionalKeys) {
    // If the current value is not an object or array, return undefined
    if (typeof additionalValue !== 'object' || additionalValue === null) {
      return undefined
    }

    additionalValue = additionalValue[key as keyof T]
  }

  return [value, additionalValue]
}
