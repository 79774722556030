import { TAccountVariant } from '@common/types'

export type TParamsKeys =
  | 'userId'
  | 'accountId'
  | 'depositId'
  | 'withdrawalId'
  | 'exchangeId'
  | 'transactionId'
  | 'feePlanId'

export const params: Record<TParamsKeys, TParamsKeys> = {
  userId: 'userId',
  accountId: 'accountId',
  depositId: 'depositId',
  withdrawalId: 'withdrawalId',
  exchangeId: 'exchangeId',
  transactionId: 'transactionId',
  feePlanId: 'feePlanId',
}

const accountMainPath = `/accounts/:${params.accountId}`
const personalApplicationMainPath = `${accountMainPath}/${('PERSONAL' satisfies TAccountVariant).toLowerCase()}`

const personalApplicationSteps = {
  personalDetails: 'personal-details',
  expectedTurnover: 'expected-turnover',
  homeAddress: 'home-address',
  identityDocument: 'identity-document',
  terms: 'terms-and-conditions',
} as const

type TPersonalApplicationSteps =
  (typeof personalApplicationSteps)[keyof typeof personalApplicationSteps]

export const routePaths = {
  dashboard: '/',
  users: {
    list: '/users',
    user: `/users/:${params.userId}`,
  },
  exchanges: {
    list: '/exchanges',
    exchange: `/exchanges/:${params.exchangeId}`,
  },
  accounts: {
    list: '/accounts',
    account: accountMainPath,
    application: {
      personal: personalApplicationMainPath,
      business: `${accountMainPath}/${('BUSINESS' satisfies TAccountVariant).toLowerCase()}`,
      getPath: (accountId: string, application: TAccountVariant) => {
        return `/accounts/${accountId}/${application.toLowerCase()}`
      },
      personalSteps: {
        personalDetails: `${personalApplicationMainPath}/${personalApplicationSteps.personalDetails}`,
        expectedTurnover: `${personalApplicationMainPath}/${personalApplicationSteps.expectedTurnover}`,
        homeAddress: `${personalApplicationMainPath}/${personalApplicationSteps.homeAddress}`,
        identityDocument: `${personalApplicationMainPath}/${personalApplicationSteps.identityDocument}`,
        terms: `${personalApplicationMainPath}/${personalApplicationSteps.terms}`,
        getPath: (accountId: string, step: TPersonalApplicationSteps) => {
          return `/accounts/${accountId}/${('PERSONAL' satisfies TAccountVariant).toLowerCase()}/${step}`
        },
      },
    },
  },
  deposits: {
    list: '/deposits',
    deposit: `/deposits/:${params.depositId}`,
  },
  withdrawals: {
    list: '/withdrawals',
    withdrawal: `/withdrawals/:${params.withdrawalId}`,
  },
  transactions: {
    list: '/transactions',
    transaction: `/transactions/:${params.transactionId}`,
  },
  settings: {
    list: '/settings',
    deposit: '/settings/deposit',
    exchange: '/settings/exchange',
    centralWallets: '/settings/centralWallets',
    transaction: '/settings/transactions',
    feePlan: {
      list: '/settings/feePlans',
      feePlan: `/settings/feePlans/:${params.feePlanId}`,
    },
  },
}

type TKeys = keyof typeof routePaths

export type TRoutePaths = (typeof routePaths)[TKeys]

export const externalLinks = {
  privacyPolicyArbiex: 'https://arbiex.eu/privacy-policy',
  termsAndConditions: 'https://arbiex.eu/service-agreement',
}
