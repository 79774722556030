import { Divider } from '@mui/material'

import { IFeePlan } from '@common/types'

import { ChangeAccountFeePlanList } from './change-account-fee-plan-list'
import { FeePlanAccordion } from './fee-plan-accordion'

interface IProps {
  feePlan: IFeePlan
  currentFeePlanId: string
  selectedFeePlanId: string | null
  setSelectedFeePlanId: (id: string) => void
  isLoading: boolean
}

export function ChangeAccountFeePlanContent({
  currentFeePlanId,
  feePlan,
  selectedFeePlanId,
  isLoading,
  setSelectedFeePlanId,
}: IProps) {
  return (
    <>
      <FeePlanAccordion
        feePlan={feePlan}
        currentFeePlanId={currentFeePlanId}
        sx={{
          '&.Mui-expanded': { m: 0 },
          mb: { xs: '12px !important', md: '20px !important' },
        }}
      />

      <Divider sx={{ mb: { xs: 1.5, md: 2.5 } }} />

      <ChangeAccountFeePlanList
        selectedFeePlanId={selectedFeePlanId}
        setSelectedFeePlanId={setSelectedFeePlanId}
        currentFeePlanId={currentFeePlanId}
        loading={isLoading}
      />
    </>
  )
}
