import { useNavigate } from 'react-router'

import { IFeePlan } from '@common/types'

import { routePaths } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import { ComposeTable, MobileList } from '@shared/ui'

import { useFeePlanListTableTemplate, settingPageId } from '../model'

interface IProps {
  data: IFeePlan[]
}

export function FeePlanList({ data }: IProps) {
  const mdAndUp = useBoxMediaQuery(settingPageId).up('md')
  const { desktopTemplate, mobileTemplate } = useFeePlanListTableTemplate(data)
  const navigate = useNavigate()

  const handleClick = (feePlanId: string) => {
    navigate(`${routePaths.settings.feePlan.list}/${feePlanId}`)
  }

  return (
    <>
      {mdAndUp && (
        <ComposeTable
          data={data}
          template={desktopTemplate}
          onClickItem={handleClick}
          itemIdProperty="id"
        />
      )}

      {!mdAndUp && mobileTemplate && (
        <MobileList mobileList={mobileTemplate} onClick={handleClick} />
      )}
    </>
  )
}
