import { SxProps, Stack } from '@mui/material'
import { useState, useMemo, useRef, useEffect } from 'react'

import { useFeePlans } from '@entities/fee-plans'

import { ListLoader } from '@shared/ui'

import { FeePlanAccordion } from './fee-plan-accordion'

interface IProps {
  currentFeePlanId: string
  loading: boolean
  selectedFeePlanId: string | null
  setSelectedFeePlanId: (id: string) => void
  sx?: SxProps
}

export function ChangeAccountFeePlanList({
  currentFeePlanId,
  loading,
  selectedFeePlanId,
  setSelectedFeePlanId,
  sx,
}: IProps) {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const [hasScroll, setHasScroll] = useState(false)

  const [expandedFeePlanId, setExpandedFeePlanId] = useState<string | null>(
    null
  )

  useEffect(() => {
    const checkScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, clientHeight } = scrollableRef.current

        setHasScroll(scrollHeight > clientHeight)
      }
    }

    checkScroll()

    window.addEventListener('resize', checkScroll)

    return () => window.removeEventListener('resize', checkScroll)
  }, [])

  const { data: feePlans, isLoading: feePlansLoading } = useFeePlans()

  const visibleFeePlans = useMemo(
    () => feePlans?.filter((feePlan) => feePlan.id !== currentFeePlanId),
    [currentFeePlanId, feePlans]
  )

  const handleExpandFeePlan = (feePlanId: string) => {
    if (expandedFeePlanId === feePlanId) {
      setExpandedFeePlanId(null)
    } else {
      setExpandedFeePlanId(feePlanId)
    }
  }

  return (
    <Stack
      spacing={1}
      sx={{
        flex: 1,
        overflow: 'auto',
        pr: hasScroll ? 1.5 : 0,
        pb: { md: 1.5 },
        ...sx,
      }}
      ref={scrollableRef}
    >
      {feePlansLoading && <ListLoader skeletonsNumber={1} />}

      {visibleFeePlans?.map((feePlan, index) => (
        <FeePlanAccordion
          key={feePlan.id + index}
          expandedFeePlanId={expandedFeePlanId}
          feePlan={feePlan}
          currentFeePlanId={currentFeePlanId}
          onExpand={() => handleExpandFeePlan(feePlan.id)}
          loading={loading}
          selectedFeePlanId={selectedFeePlanId}
          onSelect={() => setSelectedFeePlanId(feePlan.id)}
        />
      ))}
    </Stack>
  )
}
