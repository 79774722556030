import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ISingleErrorResponse, IDepositResponse } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchDeposit } from '../../api'

export function useDeposit(depositId?: string) {
  return useQuery<IDepositResponse, AxiosError<ISingleErrorResponse>>({
    queryKey: [queryKeys.deposit, depositId],
    queryFn: () => fetchDeposit(depositId),
  })
}
