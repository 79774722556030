import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { DepositPage } from './ui'

export const DepositPageRouter: RouteObject = {
  path: routePaths.deposits.deposit,
  element: <DepositPage />,
}
