import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { IDepositDetails } from '@common/types'
import { getDepositDetailsSchema } from '@common/validation/deposit-schema/deposit-details-schema'

import { useTFunc } from '@shared/hooks'

export function useDepositDetailsForm({
  IBAN,
  BIC,
  bankName,
  companyAddress,
  name,
}: IDepositDetails) {
  const tFunc = useTFunc()

  const { depositDetailsSchema } = getDepositDetailsSchema(tFunc)

  return useForm<IDepositDetails>({
    resolver: yupResolver(depositDetailsSchema),
    defaultValues: {
      bankName,
      name,
      companyAddress,
      IBAN,
      BIC,
    },
    mode: 'onSubmit',
  })
}
