import { Box, Divider, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { TFormattedBusinessParticipant } from '@common/types'

import { ShowOrDownloadFile } from '@shared/ui'
import { getCountryNameByAbbreviation } from '@shared/utils'

import { LabelValue } from '../label-value'
import { GroupFieldsCard } from '../styled'

const mainTranslationPath = 'account.business-application'

export function BusinessParticipantCard({
  addressCity,
  addressCountry,
  addressStreet,
  addressZip,
  createdAt,
  email,
  phoneNumber,
  type,
  updatedAt,
  percentageOwnership,
  proofOfAddress,
  country,
  legalDocumentImages,
  name,
  registrationNumber,
  website,
  id,
}: TFormattedBusinessParticipant) {
  const { t } = useTranslation('entities')

  return (
    <GroupFieldsCard>
      <Box>
        <Typography variant="h3">
          {t(`${mainTranslationPath}.business-${type}`)}
        </Typography>

        <Typography variant="subtitle2" mb={1}>
          {`ID: ${id}`}
        </Typography>
      </Box>

      <Typography variant="h5">
        {t(`${mainTranslationPath}.main-info`)}
      </Typography>

      <LabelValue
        label={t(`${mainTranslationPath}.company-name`)}
        value={name}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.country`)}
        value={getCountryNameByAbbreviation(country)}
      />

      <LabelValue label={t(`${mainTranslationPath}.website`)} value={website} />
      <LabelValue label={t(`${mainTranslationPath}.email`)} value={email} />

      <LabelValue
        label={t(`${mainTranslationPath}.phone-number`)}
        value={phoneNumber}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.registration-number`)}
        value={registrationNumber}
      />

      <Divider />

      <Typography variant="h5">
        {t(`${mainTranslationPath}.company-legal-documents`)}
      </Typography>

      {legalDocumentImages.length > 0 &&
        legalDocumentImages.map((doc, index) => (
          <Box key={index}>
            <Typography variant="body1" mb={0.5}>
              {t(`${mainTranslationPath}.${doc.type}`)}
            </Typography>

            <Stack direction="row" gap={1} flexWrap="wrap">
              {doc.images && doc.images.length
                ? doc.images.map((image) => (
                    <ShowOrDownloadFile
                      options={image}
                      key={JSON.stringify(image)}
                    />
                  ))
                : 'empty'}
            </Stack>
          </Box>
        ))}

      <Divider />

      <Typography variant="h5">
        {t(`${mainTranslationPath}.address`)}
      </Typography>

      <LabelValue
        label={t(`${mainTranslationPath}.city`)}
        value={addressCity}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.country`)}
        value={getCountryNameByAbbreviation(addressCountry)}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.street`)}
        value={addressStreet}
      />

      <LabelValue label={t(`${mainTranslationPath}.zip`)} value={addressZip} />
      <Divider />

      {percentageOwnership && (
        <LabelValue
          label={t(`${mainTranslationPath}.percentage-ownership`)}
          value={percentageOwnership ? `${percentageOwnership}%` : ''}
        />
      )}

      <Box>
        <Typography variant="body1" mb={0.5}>
          {t(`${mainTranslationPath}.address-proof`)}
        </Typography>

        <Stack direction="row" gap={1} flexWrap="wrap">
          {proofOfAddress?.length
            ? proofOfAddress.map((doc, index) => (
                <ShowOrDownloadFile options={doc} key={index} />
              ))
            : 'empty'}
        </Stack>
      </Box>

      <LabelValue
        label={t(`${mainTranslationPath}.created`)}
        value={format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.updated`)}
        value={format(new Date(updatedAt), 'dd.MM.yyyy HH:mm')}
      />
    </GroupFieldsCard>
  )
}
