import { LoadingButton } from '@mui/lab'
import { Stack, TextField /* , Typography */ } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { /* assets, */ ITransactionSettings } from '@common/types'

import {
  updateTransactionSettings,
  useTransactionSettingsForm,
} from '@entities/transaction'

import { queryKeys } from '@shared/constants'

interface IProps {
  data: ITransactionSettings
}

export function TransactionSettingsForm({ data }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const { t } = useTranslation(['widgets', 'shared'])

  const form = useTransactionSettingsForm(data)

  const { control, handleSubmit, reset } = form

  const onSubmit = async (updateData: ITransactionSettings) => {
    try {
      if (isEqual(data, updateData)) {
        toast.info(t('shared:toasts.no-changes'))

        return
      }

      setIsLoading(true)

      const response = await updateTransactionSettings(updateData)

      toast.success(t('shared:toasts.success'))

      queryClient.setQueryData([queryKeys.transactionSettings], response)

      reset(response)
    } catch {
      toast.error(t('shared:toasts.try-again'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={5}>
      <Stack direction="row" gap={1.5}>
        <Controller
          control={control}
          name="min"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('settings.transaction-settings.min-label')}
              autoFocus
            />
          )}
        />

        <Controller
          control={control}
          name="max"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('settings.transaction-settings.max-label')}
            />
          )}
        />
      </Stack>

      <Stack direction="row" gap={1.5}>
        <Controller
          control={control}
          name="minTrx"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('settings.transaction-settings.min-trx')}
            />
          )}
        />

        <Controller
          control={control}
          name="minEth"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('settings.transaction-settings.min-eth')}
            />
          )}
        />

        <Controller
          control={control}
          name="minAmountInEurForSupportingDocs"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              ref={null}
              inputRef={field.ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={t('settings.transaction-settings.min-amount-for-docs')}
            />
          )}
        />
      </Stack>

      {/* TODO: settings for minFiatThresholdForCrypto */}
      {/* <Stack gap={1}>
        <Typography variant="body1">
          {t('settings.transaction-settings.min-fiat-threshold-description')}
        </Typography>

        <Stack direction="row" gap={1.5} flexWrap="wrap">
          {assets.map((asset) => (
            <Controller
              key={asset}
              control={control}
              name={`minFiatThresholdForCrypto.${asset}`}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  ref={null}
                  inputRef={field.ref}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label={t('settings.transaction-settings.min-fiat-threshold', {
                    asset,
                  })}
                />
              )}
            />
          ))}
        </Stack>
      </Stack> */}

      <LoadingButton
        type="submit"
        loading={isLoading}
        variant="contained"
        fullWidth
      >
        {t('settings.transaction-settings.button')}
      </LoadingButton>
    </Stack>
  )
}
