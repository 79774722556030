import { Stack, FormControlLabel, Checkbox, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TUpdateUserStatusConfigsForm } from '@common/types'

export function ChangeCommonUserStatusesForm() {
  const { control } = useFormContext<TUpdateUserStatusConfigsForm>()
  const { t } = useTranslation('features')

  return (
    <Stack gap={0.5}>
      <Controller
        control={control}
        name="common.blocked"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox checked={field.value} onChange={field.onChange} />
            }
            label={t('user.danger-actions.statuses.common.blocked-label')}
          />
        )}
      />

      <Controller
        control={control}
        name="common.blockedReason"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            ref={null}
            inputRef={field.ref}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            placeholder={t(
              'user.danger-actions.statuses.common.blocked-reason-label'
            )}
            label={t(
              'user.danger-actions.statuses.common.blocked-reason-label'
            )}
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
        )}
      />
    </Stack>
  )
}
