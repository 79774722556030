import { ITransactionListItem } from '@common/types'

import { useTransactionStatusOptions } from '@entities/transaction'

import { ITemplateProps, StatusChip } from '@shared/ui'

export function TransactionStatus({
  data: { status },
}: ITemplateProps<ITransactionListItem>) {
  const { getStatusColor, getStatusText } = useTransactionStatusOptions(status)

  return (
    <StatusChip
      circleColor={getStatusColor()}
      text={getStatusText()}
      variant="text"
    />
  )
}
