import { IAccountDetails } from '@common/types'

import { instance } from '@shared/requester'

export async function fetchAccount(id?: string) {
  if (!id) {
    throw new Error()
  }

  return instance
    .get<IAccountDetails>(`/accounts/${id}`)
    .then((response) => response.data)
}
