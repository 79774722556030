import { Stack } from '@mui/material'

import { IMobileListItem, IOnClick } from '../../model'

import { RowItem } from './row-item'
import { StyledMobileListItemWrapper } from './styled'

export function MobileListItem({
  firstRow,
  id,
  lastRow,
  middleRows,
  warningTrigger,
  onClick,
}: IMobileListItem & IOnClick) {
  return (
    <StyledMobileListItemWrapper
      component="li"
      key={id}
      warningTrigger={warningTrigger}
      onClick={() => onClick?.(id)}
      sx={{ ...(onClick && { cursor: 'pointer' }) }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={0.5}
      >
        {firstRow.map((rowItem, index) => (
          <RowItem key={index} variant="h5" value={rowItem} />
        ))}
      </Stack>

      <Stack>
        {middleRows.map((rowItem, index) => (
          <RowItem
            key={index}
            variant="body1"
            value={rowItem}
            color="text.secondary"
          />
        ))}
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {lastRow.map((rowItem, index) => (
          <RowItem
            key={index}
            variant="body1"
            value={rowItem}
            color="text.secondary"
          />
        ))}
      </Stack>
    </StyledMobileListItemWrapper>
  )
}
