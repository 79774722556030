import { Stack, Typography } from '@mui/material'

interface IProps {
  label: string
  value: string
}

export function AccountLabelValue({ label, value }: IProps) {
  return (
    <Stack
      direction="row"
      gap={2.5}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>
        {label}
      </Typography>

      <Typography variant="body2" sx={{ flex: 1, textAlign: 'end' }}>
        {value}
      </Typography>
    </Stack>
  )
}
