import { SxProps } from '@mui/material'

import { ITemplateWithTitle, TShortInfoBarTemplate } from '../model'

import { DesktopShortInfoBar, MobileShortInfoBar } from './components'

interface IMobileProps {
  template: TShortInfoBarTemplate[] | ITemplateWithTitle[]
  viewMode: 'mobile'
}

interface IDesktopProps {
  template: TShortInfoBarTemplate[]
  viewMode: 'desktop'
}

interface IMainProps {
  sx?: SxProps
}

type TProps = IMainProps & (IDesktopProps | IMobileProps)

export function ShortInfoBar({ template, viewMode, sx }: TProps) {
  const isMobileMode = viewMode === 'mobile'

  return isMobileMode ? (
    <MobileShortInfoBar template={template} sx={sx} />
  ) : (
    <DesktopShortInfoBar template={template} sx={sx} />
  )
}
