import { TNotificationLimits } from '@common/types'

import { instance } from '@shared/requester'

export function updateNotificationLimitsForCentralWalletSettings(
  payload: TNotificationLimits
): Promise<TNotificationLimits> {
  return instance
    .put<TNotificationLimits>(
      '/settings/notification-limits-for-central-wallet',
      payload
    )
    .then((res) => res.data)
}
