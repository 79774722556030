import {
  SxProps,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IUserWithStatuses } from '@common/types'

import { useGetIdvStatusOptions } from '@entities/user'

import { useBoxMediaQuery } from '@shared/hooks'
import { StatusChip } from '@shared/ui'

import { userInfoSectionId } from '../../../model'
import { UserMetaData } from '../user-meta-data'

import { UserMainInfoChips } from './user-main-info-chips'

interface IProps
  extends Partial<
    Pick<
      IUserWithStatuses,
      | 'active'
      | 'blocked'
      | 'keycloakId'
      | 'createdAt'
      | 'fullName'
      | 'id'
      | 'idvStatus'
    >
  > {
  sx?: SxProps
}

export function MainUserInfo({
  active,
  blocked,
  createdAt,
  fullName,
  keycloakId,
  id,
  idvStatus,
  sx,
}: IProps) {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const boxMdAndUp = useBoxMediaQuery(userInfoSectionId, false).up('md')
  const box700AndUp = useBoxMediaQuery(userInfoSectionId, false).up(700)

  const { getIdvStatusColor, getIdvStatusText } =
    useGetIdvStatusOptions(idvStatus)

  return (
    <Stack
      direction={box700AndUp ? 'row' : 'column'}
      gap={2.5}
      justifyContent="space-between"
      alignItems={boxMdAndUp ? 'flex-end' : 'initial'}
      sx={sx}
    >
      <Stack
        direction={boxMdAndUp ? 'row' : 'column'}
        alignItems={boxMdAndUp ? 'center' : 'flex-start'}
        spacing={boxMdAndUp ? 4 : 1}
      >
        <Typography variant="h4">{fullName || t('user.unverified')}</Typography>
        <UserMainInfoChips active={active} blocked={blocked} id={id} />
      </Stack>

      {!mdAndUp && (
        <StatusChip
          text={getIdvStatusText()}
          circleColor={getIdvStatusColor()}
        />
      )}

      {mdAndUp && (
        <UserMetaData createdAt={createdAt} keycloakId={keycloakId} />
      )}
    </Stack>
  )
}
