import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IFeePlan } from '@common/types'

import { getTypeOfFeePlanByIsDefaultValue } from '@entities/fee-plans'

import { IComposeTableCellTemplate, IMobileListItem } from '@shared/ui'

import { FeePlanType } from '../../ui'

export function useFeePlanListTableTemplate(data: IFeePlan[]) {
  const { t } = useTranslation('widgets')

  const desktopTemplate: IComposeTableCellTemplate<IFeePlan>[] = [
    {
      key: 'id',
      title: t('account-list-table.id'),
      component: 'hiddenMiddle',
    },
    {
      key: 'name',
      title: t('account-list-table.name'),
      component: 'default',
    },
    {
      key: 'description',
      title: t('account-list-table.variant'),
      component: 'default',
    },
    {
      key: 'currency',
      title: t('fee-plans-unique-keys.currency'),
      component: 'default',
    },
    {
      key: 'isDefault',
      title: t('fee-plans-unique-keys.type'),
      component: FeePlanType,
    },
    {
      key: 'createdAt',
      title: t('account-list-table.created-at'),
      component: 'dateTime',
    },
  ]

  const mobileTemplate: IMobileListItem[] = data.map((plan) => {
    const { id, name, createdAt, currency, isDefault, description } = plan

    return {
      id: id,
      firstRow: [name, getTypeOfFeePlanByIsDefaultValue(!!isDefault)],
      middleRows: [
        currency,
        description,
        format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
      ],
      lastRow: [
        <Typography
          className="ellipsis"
          variant="body1"
          color="text.secondary"
          key={1}
        >
          {`id: ${id}`}
        </Typography>,
      ],
    } satisfies IMobileListItem
  })

  return { desktopTemplate, mobileTemplate }
}
