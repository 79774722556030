import { LinearProgress, Paper, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { ShortInfoBar } from '@widgets/short-info-bar'

import { useDeposit, useDepositStatusOptions } from '@entities/deposit'

import { TParamsKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import { StatusChip } from '@shared/ui'

import { depositPageId, useDepositShortInfoBarTemplate } from '../model'

import { DepositBreadcrumbs, DepositInfo } from './components'

const mainPartId = 'deposit-main-part'

export function DepositPage() {
  const { depositId } = useParams<TParamsKeys>()

  const {
    data: deposit,
    isLoading,
    isError,
    failureReason,
  } = useDeposit(depositId)

  const boxMdAndUp = useBoxMediaQuery(depositPageId, isLoading).up('md')
  const box1000AndUp = useBoxMediaQuery(mainPartId, isLoading).up(1000)

  const shortInfoBarTemplate = useDepositShortInfoBarTemplate(deposit)

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.error}`)
  }, [failureReason])

  const { getStatusColor, getStatusText } = useDepositStatusOptions(
    deposit?.status
  )

  return (
    <>
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      {!isLoading && (
        <Paper sx={{ p: '32px 24px 20px' }} id={mainPartId}>
          <Stack direction="row" spacing={3}>
            <Box flex={1} id={depositPageId}>
              <DepositBreadcrumbs
                userName={deposit?.user.fullName}
                sx={{ mb: 2 }}
              />

              <Stack
                direction={boxMdAndUp ? 'row' : 'column'}
                justifyContent="space-between"
                alignItems="flex-start"
                gap={2}
                mb={2}
              >
                {deposit && <DepositInfo {...deposit} />}

                <StatusChip
                  circleColor={getStatusColor()}
                  text={getStatusText()}
                />
              </Stack>
            </Box>

            <ShortInfoBar
              template={shortInfoBarTemplate}
              viewMode={box1000AndUp ? 'desktop' : 'mobile'}
            />
          </Stack>
        </Paper>
      )}
    </>
  )
}
