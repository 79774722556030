import { SxProps, Stack } from '@mui/material'
import { Fragment, PropsWithChildren } from 'react'

import { useBoxMediaQuery } from '@shared/hooks'

import { IInfoSectionRow } from '../model'

import { SecondaryInfoRow, SecondaryInfoColumn } from './components'
import { SecondaryInfoWrapper } from './styled'

interface IProps {
  boxId?: string
  info: IInfoSectionRow[]
  sx?: SxProps
}

export function SecondaryInfoSection({
  boxId,
  info,
  children,
  sx,
}: IProps & PropsWithChildren) {
  const boxMdAndUp = useBoxMediaQuery(boxId).up('md')
  const boxSmAndUp = useBoxMediaQuery(boxId).up('sm')

  return (
    <SecondaryInfoWrapper
      boxMdAndUp={boxMdAndUp}
      boxSmAndUp={boxSmAndUp}
      sx={sx}
    >
      {boxMdAndUp && (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          columnGap={8}
          rowGap={3}
        >
          {info.map(({ label, value, copyable }, index) => (
            <Fragment key={index}>
              {value && (
                <SecondaryInfoRow
                  label={label}
                  value={value}
                  copyable={copyable}
                />
              )}
            </Fragment>
          ))}
        </Stack>
      )}

      {!boxMdAndUp && <SecondaryInfoColumn info={info} boxId={boxId} />}
      {children}
    </SecondaryInfoWrapper>
  )
}
