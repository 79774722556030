import CloseIcon from '@mui/icons-material/Close'
import {
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'

interface IProps {
  title: string
  onClose: () => void
}

export function ModalToolbar({ title, onClose }: IProps) {
  return (
    <Toolbar
      sx={{ minHeight: { xs: 'initial' }, py: 1, bgcolor: 'background.paper' }}
    >
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4">{title}</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Container>
    </Toolbar>
  )
}
