import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  IGetWithdrawalListQueryParams,
  IPaginationResponse,
  ISingleErrorResponse,
  IWithdrawalListItem,
} from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchWithdrawalList } from '../../api'

export function useWithdrawalList(params: IGetWithdrawalListQueryParams = {}) {
  return useQuery<
    IPaginationResponse<IWithdrawalListItem> | null,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.withdrawalList, params],
    queryFn: () =>
      params.skip !== undefined && params.take
        ? fetchWithdrawalList(params)
        : null,
    refetchInterval: 5 * 60 * 1000,
  })
}
