import { LoadingButton } from '@mui/lab'
import {
  ButtonOwnProps,
  Dialog,
  Typography,
  Stack,
  Button,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  title?: string
  open: boolean
  onClose: () => void
  onClickConfirm: () => void
  loading?: boolean
  colorConfirmButton?: ButtonOwnProps['color']
}

export function ConfirmActionDialog({
  title,
  loading,
  onClickConfirm,
  onClose,
  open,
  colorConfirmButton = 'error',
}: IProps) {
  const { t } = useTranslation('shared')

  return (
    <Dialog open={open} onClose={onClose}>
      <Typography variant="h4" mb={2} textAlign="center">
        {title || t('confirm-action-dialog.title')}
      </Typography>

      <Stack spacing={2} direction="row">
        <Button
          onClick={onClose}
          variant="outlined"
          disabled={loading}
          fullWidth
        >
          {t('button.close')}
        </Button>

        <LoadingButton
          variant="contained"
          color={colorConfirmButton}
          loading={loading}
          onClick={onClickConfirm}
          fullWidth
        >
          {t('button.yes')}
        </LoadingButton>
      </Stack>
    </Dialog>
  )
}
