import { useTranslation } from 'react-i18next'

import { DepositDetailsSettings } from '@widgets/settings'

import { useDepositDetails } from '@entities/deposit'

import { SettingLayout } from './setting-layout'

export function DepositDetails() {
  const { t } = useTranslation('pages')
  const { data, isLoading } = useDepositDetails()

  return (
    <SettingLayout
      data={!!data}
      loading={isLoading}
      title={t('settings.deposit-details')}
    >
      {data && <DepositDetailsSettings data={data} />}
    </SettingLayout>
  )
}
