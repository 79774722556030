import { Button, styled } from '@mui/material'
import { ElementType } from 'react'

import { grey } from '@shared/constants'

interface IProps {
  component?: ElementType
  hover?: boolean
}

export const StyledShortInfoBarItemWrapper = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'hover',
})<IProps>(({ theme, hover = true }) => ({
  width: '100%',
  padding: '16px 20px',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '8px',

  backgroundColor: theme.palette.common.white,

  color: theme.palette.text.primary,

  ...(!hover && {
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      cursor: 'default',
    },
  }),
  border: '1px solid',
  borderColor: 'transparent',

  [theme.breakpoints.up('md')]: {
    borderRadius: '12px',
    borderColor: grey[100],
  },
}))
