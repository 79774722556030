import { useTranslation } from 'react-i18next'

import { IAccountListItem } from '@common/types'

import { IComposeTableCellTemplate } from '@shared/ui'

import { AccountActions, AccountStatusTemplate } from '../../ui'

export function useAccountListTableTemplate() {
  const { t } = useTranslation('widgets')

  const template: IComposeTableCellTemplate<IAccountListItem>[] = [
    {
      key: 'id',
      title: t('account-list-table.id'),
      component: 'hiddenMiddle',
    },
    {
      key: 'name',
      title: t('account-list-table.name'),
      component: 'default',
    },
    {
      key: 'variant',
      title: t('account-list-table.variant'),
      component: 'default',
    },
    {
      key: 'status',
      title: t('account-list-table.status'),
      component: AccountStatusTemplate,
    },
    {
      key: 'feePlan.name',
      title: t('account-list-table.fee-plan'),
      component: 'default',
    },
    {
      key: 'createdAt',
      title: t('account-list-table.created-at'),
      component: 'dateTime',
    },
    {
      key: false,
      title: t('account-list-table.actions'),
      component: AccountActions,
    },
  ]

  return template
}
