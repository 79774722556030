import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { routePaths, TParamsKeys } from '@shared/constants'
import { CustomBreadcrumbs } from '@shared/ui'

interface IProps {
  sx?: SxProps
}

export function TransactionBreadcrumbs({ sx }: IProps) {
  const location = useLocation()
  const { transactionId } = useParams<TParamsKeys>()
  const { t } = useTranslation(['pages', 'shared'])

  const pathnames = location.pathname.split('/').filter((path) => path)

  const breadcrumbNameMap = {
    [routePaths.transactions.list]: t('transaction-list.title'),
    [`${routePaths.transactions.list}/${transactionId}`]: t(
      'transaction.transaction'
    ),
  }

  return (
    <CustomBreadcrumbs
      breadcrumbNameMap={breadcrumbNameMap}
      pathnames={pathnames}
      sx={sx}
    />
  )
}
