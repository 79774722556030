import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { DepositListPage } from './ui'

export const DepositListRouter: RouteObject = {
  path: routePaths.deposits.list,
  element: <DepositListPage />,
}
