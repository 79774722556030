import { Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { IdentityDocumentForm } from '@widgets/personal-account-application'

import { IContext } from '../../model'

export function IdentityDocument() {
  const { t } = useTranslation('pages')
  const { identityDocumentForm: form } = useOutletContext<IContext>()

  return (
    <FormProvider {...form}>
      <form>
        <Typography
          variant="subtitle2"
          textTransform="uppercase"
          mb={{ xs: 2, md: 3 }}
        >
          {t('personal-account-application.steps-titles.3')}
        </Typography>

        <IdentityDocumentForm sx={{ mb: { xs: 5, md: 6.5 } }} />
      </form>
    </FormProvider>
  )
}
