import { SxProps, Box, Typography } from '@mui/material'
import { PropsWithChildren, ReactNode } from 'react'

import { NoData, ListLoader } from '@shared/ui'

interface IProps {
  loading: boolean
  data: boolean
  title?: string | ReactNode
  sx?: SxProps
  id?: string
}

export function SettingLayout({
  data,
  loading,
  title,
  sx,
  children,
  id,
}: IProps & PropsWithChildren) {
  return (
    <Box
      sx={{ padding: { xs: '16px 0 0', md: '16px 24px 28px' }, ...sx }}
      id={id}
    >
      {title &&
        (typeof title === 'string' ? (
          <Typography variant="h2" mb={2}>
            {title}
          </Typography>
        ) : (
          title
        ))}

      {!data && !loading && <NoData />}
      {loading && <ListLoader skeletonsNumber={0} />}
      {children}
    </Box>
  )
}
