import { useTranslation } from 'react-i18next'

import { TExchangeStatus } from '@common/types'

const mainColor = 'primary.main'
const successColor = 'success.main'
const errorColor = 'error.main'
const warningColor = 'warning.main'

export const useExchangeStatus = (status?: TExchangeStatus) => {
  const { t } = useTranslation('entities')

  const statusColors: Record<TExchangeStatus, string> = {
    NEW: mainColor,
    CONFIRMED: warningColor, // TODO: approve manually
    REJECTED: errorColor,
    KRAKEN_ORDER_INITIATED: mainColor,
    KRAKEN_ORDER_CREATED: mainColor,
    KRAKEN_ORDER_CLOSED: mainColor,
    KRAKEN_WITHDRAW_CREATED: mainColor,
    KRAKEN_WITHDRAW_COMPLETED: mainColor,
    KRAKEN_WAIT_FOR_DEPOSIT: warningColor, // TODO: approve manually
    SEND_TO_USER_WALLET_CREATED: mainColor,
    SEND_TO_CENTRAL_WALLET_CREATED: mainColor,
    SEND_TO_KRAKEN_WALLET_CREATED: mainColor,
    RECEIVED_TO_CENTRAL_WALLET: mainColor,
    COMPLETED: successColor,
    FAILED: errorColor,
  }

  const getStatusColor = () => (status ? statusColors[status] : 'error.main')

  const getSimpleStatusText = () =>
    status ? t(`exchanges.simple-status.${status}`) : ''

  const getFullStatusText = () =>
    status ? t(`exchanges.status.${status}`) : ''

  return { getStatusColor, getSimpleStatusText, getFullStatusText }
}
