import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material'

import { TableCellList } from './components'
import { StyledTableRow } from './styles'
import { IComposeTableCellTemplate } from './types'

interface IBaseProps<T> {
  data: T[]
  template: IComposeTableCellTemplate<T>[]
  sx?: SxProps
}

type TOnClickItemProps<T, I extends keyof T> =
  | {
      onClickItem?: never
      itemIdProperty?: never
    }
  | {
      onClickItem: (id: T[I]) => void
      itemIdProperty: I
    }

type ArrayElement<
  ArrayType extends readonly unknown[],
  T,
> = ArrayType extends readonly (infer ElementType extends keyof T)[]
  ? T[ElementType]
  : never

type TBodyTableRowSxProps<T, R extends keyof T, A extends Array<keyof T>> =
  | {
      bodyTableRowSxTrigger?: never
      bodyTableRowSxWithTrigger?: never
    }
  | {
      bodyTableRowSxTrigger: R
      bodyTableRowSxWithTrigger: (value: T[R]) => SxProps<Theme>
    }
  | {
      bodyTableRowSxTrigger: A
      bodyTableRowSxWithTrigger: (value: ArrayElement<A, T>[]) => SxProps<Theme>
    }

type TProps<
  T,
  I extends keyof T,
  R extends keyof T,
  A extends (keyof T)[],
> = IBaseProps<T> & TOnClickItemProps<T, I> & TBodyTableRowSxProps<T, R, A>

export function ComposeTable<
  T,
  I extends keyof T,
  R extends keyof T,
  A extends (keyof T)[],
>({
  data,
  template,
  onClickItem,
  itemIdProperty,
  bodyTableRowSxWithTrigger,
  bodyTableRowSxTrigger,
  sx,
}: TProps<T, I, R, A>) {
  return (
    <Table sx={sx}>
      <TableHead>
        <TableRow>
          {template.map((item, index) => (
            <TableCell key={index}>{item.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map((row, rowIndex) => (
          <StyledTableRow
            key={rowIndex}
            onClick={() => itemIdProperty && onClickItem?.(row[itemIdProperty])}
            sx={bodyTableRowSxWithTrigger?.(
              (Array.isArray(bodyTableRowSxTrigger)
                ? bodyTableRowSxTrigger.map((item) => row[item])
                : row[bodyTableRowSxTrigger]) as ArrayElement<A, T>[] & T[R]
            )}
            clickable={!!onClickItem}
          >
            <TableCellList template={template} row={row} />
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  )
}
