import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { TExchangeLimitSetting } from '@common/types'
import { settingFieldSchema } from '@common/validation/exchanges-schemas/setting-field-schema'

import { useTFunc } from '@shared/hooks'

export function useExchangeExternalLimitForm({
  EUR = 0,
}: TExchangeLimitSetting) {
  const tFunc = useTFunc()

  const schema = settingFieldSchema(tFunc)

  return useForm<TExchangeLimitSetting>({
    resolver: yupResolver(schema),
    defaultValues: {
      EUR,
    },
    mode: 'onSubmit',
  })
}
