import { useQuery } from '@tanstack/react-query'

import { IUploadFileSettings } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchUploadFileSettings } from '../../api'

export const useUploadFileSettings = () => {
  return useQuery<IUploadFileSettings>({
    queryKey: [queryKeys.uploadFileSettings],
    queryFn: fetchUploadFileSettings,
    staleTime: Infinity,
  })
}
