import { InputAdornment, SxProps, TextField } from '@mui/material'
import { forwardRef } from 'react'

import { CopyButton } from '@shared/ui'

interface IProps {
  label?: string
  errorMessage?: string
  onBlur?: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string | number
  sx?: SxProps
  autoFocus?: boolean
  fullWidth?: boolean
}

export const CustomTextField = forwardRef<HTMLInputElement, IProps>(
  function CustomTextField(
    {
      value,
      onChange,
      onBlur,
      label = '',
      errorMessage,
      autoFocus,
      sx,
      fullWidth,
    }: IProps,
    ref
  ) {
    return (
      <TextField
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        error={!!errorMessage}
        helperText={errorMessage}
        label={label}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        sx={{ minWidth: 300, minHeight: 93, ...sx }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyButton value={value?.toString()} />
            </InputAdornment>
          ),
        }}
      />
    )
  }
)
