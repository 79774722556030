import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { FeePlanPage } from './ui'

const mainPath = routePaths.settings.feePlan.feePlan

export const FeePlanPageRouter: RouteObject = {
  path: mainPath,
  element: <FeePlanPage />,
}
