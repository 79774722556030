import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { ShortInfoBarItemWrapper } from '@shared/ui'
import { currenciesIcons } from '@shared/utils'

import { IWalletItemProps } from '../../model'

import { StyledIconButton } from './styled'

export function WalletsShortInfoItem({
  fullName,
  balanceList,
  address,
  blockchain,
  isCentral,
}: IWalletItemProps) {
  const { t } = useTranslation('widgets')

  const tronScanLink = (() => {
    switch (blockchain) {
      case 'TRON':
        return `https://tronscan.org/#/address/${address}`
      case 'ETHEREUM':
        return `https://etherscan.io/address/${address}`
      default:
        return ``
    }
  })()

  return (
    <ShortInfoBarItemWrapper>
      <Box>
        {fullName && <Typography variant={'h5'}>{fullName}</Typography>}

        <Typography variant="h6">
          <Trans
            ns="widgets"
            i18nKey="short-info-bar.item-names.wallet"
            values={{ blockchain }}
            components={{
              span: (
                <Typography
                  key={blockchain}
                  variant="body2"
                  component={'span'}
                  color={'primary.main'}
                />
              ),
            }}
          />

          {isCentral && ` (${t('short-info-bar.wallet.central')})`}
        </Typography>
      </Box>

      <Stack gap={0.5}>
        <Box sx={{ p: 0, height: 20, display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            component="span"
            className="ellipsis"
            color="primary.main"
          >
            {address}
          </Typography>

          <StyledIconButton href={tronScanLink} target="_blank">
            <OpenInNewIcon />
          </StyledIconButton>
        </Box>

        {balanceList.map((balance, index) => (
          <Box key={index} display={'flex'} alignItems={'center'} gap={0.5}>
            {currenciesIcons[balance.asset]}

            <Typography variant="h6">
              {balance.amount} {balance.asset}
            </Typography>
          </Box>
        ))}
      </Stack>
    </ShortInfoBarItemWrapper>
  )
}
