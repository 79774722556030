import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  current: boolean
  selected: boolean
}

export const SelectButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'current' && prop !== 'selected',
})<IProps>(({ theme, current, selected }) => ({
  padding: '2px 6px',

  borderRadius: '8px',
  border: '2px solid',
  borderColor: theme.palette.primary.main,

  color: theme.palette.primary.main,
  textTransform: 'initial',

  '&.Mui-disabled': {
    borderColor: 'transparent',
    backgroundColor: theme.palette.background.default,
    color: grey[600],

    ...(selected && {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    }),

    ...(current && {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    }),
  },
}))
