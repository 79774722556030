import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { routePaths } from '@shared/constants'
import { ShortInfoBarItemWrapper } from '@shared/ui'
import { getInitialsByFullName } from '@shared/utils'

import { IUserItemProps } from '../../model'

import { AbbreviationWrap } from './styled'

export function UserShortInfoItem({ fullName, id }: IUserItemProps) {
  const initials = getInitialsByFullName(fullName)

  const { t } = useTranslation('widgets')

  return (
    <ShortInfoBarItemWrapper href={`${routePaths.users.list}/${id}`}>
      <Box display={'flex'} gap={1.5}>
        <AbbreviationWrap fullName={fullName}>{initials}</AbbreviationWrap>

        <Stack overflow="hidden">
          <Typography variant={'h5'}>{fullName}</Typography>

          <Typography variant={'h6'}>
            {t('short-info-bar.item-names.user')}
          </Typography>

          <Typography
            variant={'body2'}
            color={'text.secondary'}
            className="ellipsis"
          >
            ID: {id}
          </Typography>
        </Stack>
      </Box>
    </ShortInfoBarItemWrapper>
  )
}
