import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'
import { ChangeEvent } from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  PathValue,
  useFormContext,
} from 'react-hook-form'

import { ITermsItem } from '../../model'

import { CustomLabel } from './custom-label'

export function TermsItem<T extends FieldValues>({
  fieldName,
  i18nKey,
  link,
}: ITermsItem<T>) {
  const { control, setValue } = useFormContext<T>()

  const handleChangeCheckbox =
    (fieldName: FieldPath<T>) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue(fieldName, true as PathValue<T, FieldPath<T>>, {
          shouldValidate: true,
        })
      } else {
        setValue(fieldName, false as PathValue<T, FieldPath<T>>, {
          shouldValidate: true,
        })
      }
    }

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field, fieldState }) => (
        <FormControl>
          <FormControlLabel
            {...field}
            sx={{ mr: 0, alignItems: 'center' }}
            control={
              <Checkbox
                {...field}
                checked={!!field.value}
                onChange={handleChangeCheckbox(field.name)}
              />
            }
            label={<CustomLabel i18nKey={i18nKey} link={link} />}
          />

          <FormHelperText error={fieldState.invalid}>
            {fieldState.error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}
