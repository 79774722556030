import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { IWithdrawalSettings } from '@common/types'
import { getWithdrawalSettingsSchema } from '@common/validation'

import { useTFunc } from '@shared/hooks'

export function useWithdrawalSettingsForm(defaultValues: IWithdrawalSettings) {
  const tFunc = useTFunc()

  const schema = getWithdrawalSettingsSchema(tFunc)

  const form = useForm<IWithdrawalSettings>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  return form
}
