import { IAccountCryptoBalance } from '@common/types'

import { accountPageId } from '@entities/account'

import { useBoxMediaQuery } from '@shared/hooks'
import { ComposeTable, MobileList } from '@shared/ui'

import { useAccountCryptoBalancesTemplate } from '../model'

interface IProps {
  cryptoBalances: IAccountCryptoBalance[]
}

export function AccountCryptoBalancesTable({ cryptoBalances }: IProps) {
  const boxMdAndUp = useBoxMediaQuery(accountPageId).up('md')

  const { desktopTemplate, mobileTemplate } =
    useAccountCryptoBalancesTemplate(cryptoBalances)

  return (
    <>
      {boxMdAndUp ? (
        <ComposeTable template={desktopTemplate} data={cryptoBalances} />
      ) : (
        <MobileList mobileList={mobileTemplate} />
      )}
    </>
  )
}
