import { Paper, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useWithdrawalList } from '@entities/withdrawal'

import { ListLoader, useCustomPagination } from '@shared/ui'
import { getPaginationSkip } from '@shared/utils'

import { withdrawalsPageId } from '../model'

import { WithdrawalList } from './components'

export function WithdrawalListPage() {
  // TODO: use this hook when the filters are ready
  // const { currentStatus, filtersOptions } = useWithdrawsFilters()
  const { t } = useTranslation(['pages', 'shared'])

  const {
    page,
    take,
    paginationNode,
    setTotalPages,
    setListLength,
    // setPage,
  } = useCustomPagination()

  const { data: withdrawals, isLoading } = useWithdrawalList({
    take,
    skip: getPaginationSkip(take, page),
  })

  useEffect(() => {
    if (withdrawals) {
      const totalPages = Math.ceil(withdrawals.total / withdrawals.take)

      setTotalPages(totalPages)

      setListLength(withdrawals.list.length)
    }
  }, [withdrawals?.take])

  // useEffect(() => {
  //   setPage(1)
  // }, [currentStatus])

  return (
    <Paper sx={{ padding: '16px 24px 28px' }} id={withdrawalsPageId}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p="12px"
      >
        <Typography variant="h4">{t('withdrawal-list.title')}</Typography>
      </Stack>

      {isLoading && <ListLoader skeletonsNumber={take} />}

      {!isLoading && withdrawals && withdrawals?.list.length > 0 && (
        <WithdrawalList withdrawals={withdrawals.list} />
      )}

      {!isLoading && !withdrawals?.list.length && (
        <Typography variant="h6">{t('shared:no-data')}</Typography>
      )}

      {paginationNode}
    </Paper>
  )
}
