export const queryKeys = {
  depositList: 'depositList',
  deposit: 'deposit',

  account: 'account',
  accountList: 'accountList',

  feePlans: 'feePlans',
  feePlanDetails: 'feePlanDetails',
  updateFeePlanDetails: 'updateFeePlanDetails',
  minFeeSetting: 'minFeeSetting',
  depositDetails: 'depositDetails',
  depositLimits: 'depositLimits',
  transactionSettings: 'transactionSettings',

  uploadFileSettings: 'uploadFileSettings',

  cryptoBalanceList: 'cryptoBalanceList',

  exchangeLimitSetting: 'exchangeLimitSetting',
  centralWalletsSetting: 'centralWalletsSetting',
  centralWalletBalanceSetting: 'centralWalletBalanceSetting',
  addCentralWalletSetting: 'addCentralWalletSetting',
  notificationLimitsForCentralWallet: 'notificationLimitsForCentralWallet',

  withdrawalList: 'withdrawalList',
  withdrawal: 'withdrawal',
  withdrawalSettings: 'withdrawalSettings',

  transactionList: 'transactionList',
  transaction: 'transaction',

  user: 'user',
  userList: 'userList',

  exchangeList: 'exchangeList',
  exchangeDetails: 'exchangeDetails',
  exchangeSettings: 'exchangeSettings',

  operationsNumberNeededToBeApprovedManually:
    'operationsNumberNeededToBeApprovedManually',

  events: 'events',

  emailTemplateNames: 'emailTemplateNames',
  emailTemplate: 'emailTemplate',
} as const
