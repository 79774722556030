import { Accordion, styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  current: boolean
}

export const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'current',
})<IProps>(({ theme, current }) => ({
  padding: '8px 12px',

  borderRadius: '10px !important',
  border: '1px solid',
  borderColor: grey[100],

  ...(current && {
    backgroundColor: theme.palette.background.default,
  }),

  '&:before': {
    content: 'none',
  },

  [theme.breakpoints.up('md')]: {
    padding: '8px 20px',
  },
}))
