import { useTranslation } from 'react-i18next'

import { TFormattedPersonalApplication } from '@common/types'

import { AccountApplicationCardTitle } from '@features/account'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

import { booleanToYesNo, convertValueToDate } from '@shared/utils'

interface IProps {
  personalApplication: TFormattedPersonalApplication
}

export function Terms({ personalApplication }: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <GroupFieldsCard>
      <AccountApplicationCardTitle
        title={t('account-application.personalData')}
        sx={{ mb: 2 }}
      />

      <LabelValue
        label={t('account-application.terms')}
        value={booleanToYesNo(personalApplication.terms)}
      />

      <LabelValue
        label={t('account-application.privacy')}
        value={booleanToYesNo(personalApplication.privacy)}
      />

      <LabelValue
        label={t('account-application.personalData')}
        value={booleanToYesNo(personalApplication.personalData)}
      />

      <LabelValue
        label={t('account-application.createdAt')}
        value={convertValueToDate({
          value: personalApplication.createdAt,
        })}
      />

      <LabelValue
        label={t('account-application.updatedAt')}
        value={convertValueToDate({
          value: personalApplication.updatedAt,
        })}
      />
    </GroupFieldsCard>
  )
}
