import { useQuery } from '@tanstack/react-query'

import { IExchangeSettings } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchExchangeSettings } from '../../api'

export const useExchangeSettings = () => {
  return useQuery<IExchangeSettings>({
    queryKey: [queryKeys.exchangeSettings],
    queryFn: fetchExchangeSettings,
    staleTime: Infinity,
  })
}
