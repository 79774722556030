import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import * as yup from 'yup'

import { getValidationSchemas } from '@common/validation'

import { useAccountDetails } from '@entities/account'
import {
  IExpectedTurnoverForm,
  primarySourceIncomeListType,
  primarySourceIncomeList,
  purposeOfAccountList,
  purposeOfAccountListType,
  primarySourceIncomeTranslationKeys,
  purposeOfAccountTranslationKeys,
} from '@entities/personal-account-application'

import { TParamsKeys } from '@shared/constants'

export function useExpectedTurnoverForm() {
  const { t } = useTranslation('shared')
  const { countrySchema, getGeneralFieldSchema } = getValidationSchemas()
  const { accountId } = useParams<TParamsKeys>()

  const { data } = useAccountDetails({ accountId })

  const requiredMessage = t('validation.required')

  const primarySourceIncomeEnum = yup
    .string()
    .required(requiredMessage)
    .test(
      'is-valid-primary-source-income',
      {
        message: requiredMessage,
      },
      (value) => {
        return primarySourceIncomeList.includes(
          value as primarySourceIncomeListType
        )
      }
    )

  const purposeOfAccountEnum = yup
    .string()
    .required(requiredMessage)
    .test(
      'is-valid-purpose-of-account',
      {
        message: requiredMessage,
      },
      (value) => {
        return purposeOfAccountList.includes(value as purposeOfAccountListType)
      }
    )

  const schema: yup.ObjectSchema<IExpectedTurnoverForm> = yup.object({
    incomeCountries: yup
      .array()
      .required()
      .of(countrySchema)
      .min(1, requiredMessage),
    outcomeCountries: yup
      .array()
      .required()
      .of(countrySchema)
      .min(1, requiredMessage),
    incomeMonthlyFrequency: getGeneralFieldSchema(),
    outcomeMonthlyFrequency: getGeneralFieldSchema(),
    incomeMonthly: getGeneralFieldSchema(),
    outcomeMonthly: getGeneralFieldSchema(),
    incomePrimarySource: primarySourceIncomeEnum,
    incomePrimarySourceOther: yup
      .string()
      .test(
        'is-valid-primary-source-income',
        requiredMessage,
        (value, context) => {
          const isValidValue = getGeneralFieldSchema().isValidSync(value)

          return context.parent.incomePrimarySource === 'Other'
            ? isValidValue
            : true
        }
      ),
    purposeOfAccount: purposeOfAccountEnum,
    purposeOfAccountOther: yup
      .string()
      .test(
        'is-valid-purpose-of-account',
        requiredMessage,
        (value, context) => {
          const isValidValue = getGeneralFieldSchema().isValidSync(value)

          return context.parent.purposeOfAccount === 'Other'
            ? isValidValue
            : true
        }
      ),
  })

  const expectedTurnoverForm = useForm<IExpectedTurnoverForm>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const { reset } = expectedTurnoverForm

  useEffect(() => {
    if (data?.personalApplication) {
      const incomePrimarySource = data.personalApplication.incomePrimarySource
      const purposeOfAccount = data.personalApplication.purposeOfAccount

      reset({
        incomeCountries: data.personalApplication.incomeCountries,
        outcomeCountries: data.personalApplication.outcomeCountries,
        incomeMonthlyFrequency: data.personalApplication.incomeMonthlyFrequency,
        outcomeMonthlyFrequency:
          data.personalApplication.outcomeMonthlyFrequency,
        incomeMonthly: data.personalApplication.incomeMonthly,
        outcomeMonthly: data.personalApplication.outcomeMonthly,
        incomePrimarySource: Object.keys(
          primarySourceIncomeTranslationKeys
        ).includes(incomePrimarySource)
          ? incomePrimarySource
          : 'Other',
        purposeOfAccount: Object.keys(purposeOfAccountTranslationKeys).includes(
          purposeOfAccount
        )
          ? purposeOfAccount
          : 'Other',
        incomePrimarySourceOther: !Object.keys(
          primarySourceIncomeTranslationKeys
        ).includes(incomePrimarySource)
          ? incomePrimarySource
          : '',
        purposeOfAccountOther: !Object.keys(
          purposeOfAccountTranslationKeys
        ).includes(purposeOfAccount)
          ? purposeOfAccount
          : '',
      })
    }
  }, [data])

  return { expectedTurnoverForm }
}
