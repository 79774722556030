import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  useTheme,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { TUserStatusesConfig } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'
import { IInfoSectionRow, SecondaryInfoSection, StatusChip } from '@shared/ui'

import { userInfoSectionId } from '../../../model'

import { ConfigItemWrapper } from './styled'

export function ConfigStatuses({
  createdAt,
  id,
  updatedAt,
  value,
}: Omit<TUserStatusesConfig, 'key' | 'userId' | 'accountId'>) {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const boxMdAndUp = useBoxMediaQuery(userInfoSectionId).up('md')

  const mainConfigInfoTemplate = [
    {
      label: t('user.configs.id'),
      value: id,
      copyable: true,
    },
    {
      label: t('user.configs.created'),
      value: format(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss'),
    },
    {
      label: t('user.configs.updated'),
      value: format(new Date(updatedAt), 'dd.MM.yyyy HH:mm:ss'),
    },
  ] satisfies IInfoSectionRow[]

  return (
    <ConfigItemWrapper gap={1}>
      <Typography variant="h5">
        {t('user.configs.main-statuses-info')}
      </Typography>

      <SecondaryInfoSection
        info={mainConfigInfoTemplate}
        boxId={userInfoSectionId}
      />

      {Object.keys(value).map((key, index) => (
        <Accordion
          key={index}
          sx={{
            '&:before': { content: 'none' },
            '& .MuiAccordionSummary-expandIconWrapper': { top: 'initial' },
            px: boxMdAndUp ? '20px' : '16px',
          }}
        >
          <AccordionSummary
            aria-controls="panel1-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
              },
            }}
          >
            <Typography variant="body1" display="block">
              {t('user.configs.common-status')}
            </Typography>

            <StatusChip
              circleColor={
                value[key as keyof typeof value].blocked
                  ? theme.palette.error.main
                  : theme.palette.success.main
              }
              text={
                value[key as keyof typeof value].blocked
                  ? t('user.configs.blocked')
                  : t('user.configs.unblocked')
              }
              variant="filled"
            />
          </AccordionSummary>

          <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2">
              {`${t('user.configs.reason')} ${value[key as keyof typeof value].blockedReason}`}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </ConfigItemWrapper>
  )
}
