import { useQuery } from '@tanstack/react-query'

import { TNotificationLimits } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchNotificationLimitsForCentralWalletSettings } from '../../api'

export function useNotificationLimitsForCentralWallet() {
  return useQuery<TNotificationLimits>({
    queryKey: [queryKeys.notificationLimitsForCentralWallet],
    queryFn: fetchNotificationLimitsForCentralWalletSettings,
    staleTime: Infinity,
  })
}
