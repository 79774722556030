import { ChangeEvent, DragEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IUploadFileSettings } from '@common/types'

import { useFileValidation } from '@shared/hooks'
import { IUploadFile } from '@shared/ui'

import { clearInput } from '../helpers'

interface IProps extends IUploadFileSettings {
  saveFiles: (files: File[]) => void
  single?: boolean
  fileList?: IUploadFile[]
}

export function useLocalFileUpload({
  allowedTypes,
  maxFileSize,
  fileMaxNumber,
  saveFiles,
  single,
  fileList,
}: IProps) {
  const validateFile = useFileValidation({ allowedTypes, maxFileSize })
  const { t } = useTranslation('shared')

  const selectFilesHandle = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const files = target.files

    if (!files) {
      return
    }

    const arrayFiles = Array.from(files)

    const validFiles = arrayFiles.filter((file) => {
      return validateFile(file)
    })

    if (validFiles.length === 0) {
      return
    }

    if (single) {
      saveFiles([validFiles[0]])

      clearInput(target)

      return
    }

    if (fileList && fileList.length >= fileMaxNumber) {
      toast.error(
        t('file-upload.max-number-of-files', { number: fileMaxNumber })
      )

      clearInput(target)

      return
    }

    const isExists = fileList?.findIndex(
      (file) =>
        file.name === validFiles[0].name && file.size === validFiles[0].size
    )

    if (isExists !== undefined && isExists !== -1) {
      toast.error(t('file-upload.file-already-exists'))

      clearInput(target)

      return
    }

    saveFiles(validFiles)

    clearInput(target)
  }

  const dropHandle = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const itemsIndexes = Array.from(
      Array(event.dataTransfer.items.length).keys()
    )

    const items = event.dataTransfer.items
    const validFiles: File[] = []

    if (!items) {
      return
    }

    // Use DataTransferItemList interface to access the file(s)
    let firstFileFounded = false

    itemsIndexes.forEach((index) => {
      if (firstFileFounded && single) {
        return
      }

      const item = items[index]

      // If dropped items aren't files, reject them
      if (item.kind !== 'file') {
        return
      }

      const file = item.getAsFile()

      if (!file || !validateFile(file)) {
        return
      }

      validFiles.push(file)

      firstFileFounded = true
    })

    saveFiles(validFiles)
  }

  const dragOverHandle = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  return {
    selectFilesHandle,
    dropHandle,
    dragOverHandle,
  }
}
