import { useTheme } from '@mui/material/styles'
import { useContext, useEffect } from 'react'

import { ResizeObserverContext } from '@shared/providers'

/**
 * Custom hook to observe and respond to changes in the width of a specific block element.
 * This hook must be used within a `ResizeObserverProvider` context.
 *
 * @template T - Optional type for the update trigger.
 * @param {string} blockId - The unique identifier for the block element to observe.
 * @param {T} [updateTrigger] - Optional parameter to trigger updates when its value changes. Needed if the hook has been called before the block with the specified blockId is rendered.
 * @returns {Object} An object containing methods to evaluate the block's width against breakpoints:
 * - `up(breakpoint: TBreakpoint | number): boolean` - Returns true if the block's width is greater than or equal to the specified breakpoint.
 * - `down(breakpoint: TBreakpoint | number): boolean` - Returns true if the block's width is less than the specified breakpoint.
 * - `between(min: TBreakpoint | number, max: TBreakpoint | number): boolean` - Returns true if the block's width is between the specified min and max breakpoints.
 * - `width: number | null` - The current width of the block element, or null if not available.
 *
 * @throws {Error} If the hook is used outside of a `ResizeObserverProvider`.
 */
export const useBoxMediaQuery = (
  blockId: string = '',
  updateTrigger?: boolean
) => {
  const theme = useTheme()
  const breakpoints = theme.breakpoints.values
  const resizeObserverContext = useContext(ResizeObserverContext)

  type TBreakpoint = keyof typeof breakpoints

  if (!resizeObserverContext) {
    throw new Error(
      'useBoxMediaQuery must be used within a ResizeObserverProvider'
    )
  }

  const { observe, unobserve, sizes } = resizeObserverContext
  const width = sizes[blockId] || null

  useEffect(() => {
    if (!blockId) {
      return
    }

    observe(blockId)

    return () => {
      unobserve(blockId)
    }
  }, [blockId, observe, unobserve, updateTrigger])

  const getBreakpointValue = (breakpoint: TBreakpoint | number): number => {
    return typeof breakpoint === 'number' ? breakpoint : breakpoints[breakpoint]
  }

  const up = (breakpoint: TBreakpoint | number) => {
    const breakpointValue = getBreakpointValue(breakpoint)

    return width !== null && width >= breakpointValue
  }

  const down = (breakpoint: TBreakpoint | number) => {
    const breakpointValue = getBreakpointValue(breakpoint)

    return width !== null && width < breakpointValue
  }

  const between = (min: TBreakpoint | number, max: TBreakpoint | number) => {
    const minValue = getBreakpointValue(min)
    const maxValue = getBreakpointValue(max)

    return width !== null && width >= minValue && width <= maxValue
  }

  return { up, down, between, width }
}
