import to from 'await-to-js'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { verifyDepositOtpCode, sendDepositOtpCode } from '@entities/deposit'

import { TConfirmationOptions } from '@shared/types'
import {
  TOtpConfirmationStatuses,
  TOtpConfirmationMethod,
  TOtpResendCodeMethod,
  OtpDialog,
} from '@shared/ui'
import { getTimeToLeftFromTimestamp } from '@shared/utils'

interface IProps {
  depositConfirmationOptions: TConfirmationOptions | null
  setDepositConfirmationOptions: Dispatch<
    SetStateAction<TConfirmationOptions | null>
  >
  onClose: () => void
  show: boolean
  onConfirm: () => void
}

export function AccountDepositConfirmation({
  depositConfirmationOptions,
  onClose,
  show,
  setDepositConfirmationOptions,
  onConfirm,
}: IProps) {
  const { t } = useTranslation('shared')

  const otpErrorResolver = (error: unknown): TOtpConfirmationStatuses => {
    switch (error) {
      case '422':
        return 'NOT_VALID'
      case '400':
        return 'EXPIRE'
      default:
        throw new Error('Something went wrong. Please try again later.')
    }
  }

  const confirmationMethod: TOtpConfirmationMethod = async ({ otp }) => {
    if (!depositConfirmationOptions?.id) {
      throw new Error('Deposit id is not defined')
    }

    const [error] = await to(
      verifyDepositOtpCode({
        depositId: depositConfirmationOptions?.id,
        otpCode: otp,
      })
    )

    if (!error) {
      return 'SUCCESS'
    }

    return otpErrorResolver(error?.message)
  }

  const handleConfirm = () => {
    toast.success(t('toasts.success'))

    onClose()

    onConfirm()
  }

  const resendOtp: TOtpResendCodeMethod = async () => {
    const [error, response] = await to(
      sendDepositOtpCode(depositConfirmationOptions?.id)
    )

    if (error || !response) {
      toast.error(t('toasts.try-again'))

      throw { result: false, ttl: 0 }
    }

    setDepositConfirmationOptions({
      id: response.depositId,
      end: getTimeToLeftFromTimestamp(
        new Date(response.confirmation.end).getTime()
      ),
      attempts: response.confirmation.tries,
    })

    return {
      result: true,
      ttl: getTimeToLeftFromTimestamp(
        new Date(response.confirmation.end).getTime()
      ),
    }
  }

  return (
    <OtpDialog
      show={show}
      onClose={onClose}
      confirmationMethod={confirmationMethod}
      onConfirm={handleConfirm}
      resendCodeMethod={resendOtp}
      timer={depositConfirmationOptions?.end}
    />
  )
}
