import { SwipeableDrawer, Button, Box, SxProps } from '@mui/material'
import { ReactNode, useState } from 'react'

import { StyledSwipeableArea } from './styled'

interface IProps {
  children?: ReactNode
  title: string | ReactNode
  sx?: SxProps
  startIcon?: ReactNode
}

const swipeableAreaHeight = 54

export function PullUpDrawer({ children, title, sx, startIcon }: IProps) {
  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={swipeableAreaHeight}
      disableSwipeToOpen={false}
      ModalProps={{ keepMounted: true }}
      allowSwipeInChildren
      sx={{ '& .MuiPaper-root': { overflow: 'visible' }, ...sx }}
    >
      <StyledSwipeableArea height={swipeableAreaHeight}>
        <Button
          sx={{ width: '100%', '&:hover': { backgroundColor: 'transparent' } }}
          onClick={toggleDrawer(!open)}
          size="small"
          startIcon={startIcon}
          color="primary"
          variant="text"
        >
          {title}
        </Button>
      </StyledSwipeableArea>

      <Box overflow="auto" pb={2} height="100%">
        {children}
      </Box>
    </SwipeableDrawer>
  )
}
