import { useQuery } from '@tanstack/react-query'

import { ITransactionSettings } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchTransactionSettings } from '../../api'

export const useTransactionSettings = () => {
  return useQuery<ITransactionSettings>({
    queryKey: [queryKeys.transactionSettings],
    queryFn: fetchTransactionSettings,
    staleTime: Infinity,
  })
}
