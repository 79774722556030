import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Typography } from '@mui/material'

import { IExchangeListItem } from '@common/types'

type TProps = Pick<
  IExchangeListItem,
  | 'exchangedAmount'
  | 'amountToExchange'
  | 'currencyToExchange'
  | 'exchangedCurrency'
>

export function ExchangeFlow({
  exchangedAmount,
  amountToExchange,
  currencyToExchange,
  exchangedCurrency,
}: TProps) {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2">{`${amountToExchange} ${currencyToExchange}`}</Typography>
      <ArrowRightAltIcon />
      <Typography variant="body2">{`${exchangedAmount} ${exchangedCurrency}`}</Typography>
    </Box>
  )
}
