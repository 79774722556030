import { useQuery } from '@tanstack/react-query'

import { IFeePlan } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchFeePlans } from '../api'

export const useFeePlans = () => {
  return useQuery<IFeePlan[]>({
    queryKey: [queryKeys.feePlans],
    queryFn: fetchFeePlans,
  })
}
