import {
  Typography,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IFeePlan } from '@common/types'

import { InfoRow } from './info-row'
import { ScaleList } from './scale-list'
import { StyledAccordionDetails } from './styled'

interface IProps {
  feePlan: IFeePlan
}

export function FeePlanAccordionDetails({ feePlan }: IProps) {
  const { t } = useTranslation('features')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <StyledAccordionDetails>
      <Typography variant={mdAndUp ? 'body2' : 'body1'} color="text.secondary">
        {feePlan.description}
      </Typography>

      <Divider />

      <Stack spacing={0.5}>
        <InfoRow
          label={t('account.short-info-bar.fee-plan.currency')}
          value={feePlan.currency}
        />

        <InfoRow
          label={t('account.short-info-bar.fee-plan.created')}
          value={format(new Date(feePlan.createdAt), 'dd.MM.yyyy HH:mm')}
        />

        <InfoRow
          label={t('account.short-info-bar.fee-plan.updated')}
          value={format(new Date(feePlan.updatedAt), 'dd.MM.yyyy HH:mm')}
        />
      </Stack>

      <Divider />
      <ScaleList feePlan={feePlan} sx={{ py: { md: 1 } }} />
    </StyledAccordionDetails>
  )
}
