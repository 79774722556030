import {
  AccountShortInfoItem,
  TariffShortInfoItem,
  UserShortInfoItem,
  WalletsShortInfoItem,
} from '../../ui'
import {
  IAccountItemProps,
  ITariffItemProps,
  IUserItemProps,
  IWalletItemProps,
  TShortInfoBarTemplate,
} from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const templates: Record<TShortInfoBarTemplate['type'], any> = {
  user: (data: IUserItemProps) => <UserShortInfoItem {...data} />,
  account: (data: IAccountItemProps) => <AccountShortInfoItem {...data} />,
  tariff: (data: ITariffItemProps) => <TariffShortInfoItem {...data} />,
  wallet: (data: IWalletItemProps) => <WalletsShortInfoItem {...data} />,
  custom: null,
}
