import {
  ICreateDepositResponse,
  IDepositRequestPathParams,
  IVerifyDepositOptBody,
} from '@common/types'

import { instance } from '@shared/requester'

export function verifyDepositOtpCode({
  depositId,
  otpCode,
}: IDepositRequestPathParams & IVerifyDepositOptBody) {
  if (!depositId) {
    throw new Error('Deposit id is not defined')
  }

  return instance
    .put<ICreateDepositResponse>(`/deposits/${depositId}/verify-otp`, {
      otpCode,
    })
    .then((res) => res.data)
}
