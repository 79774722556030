import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import to from 'await-to-js'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { blockchains, TBlockchain } from '@common/types'

import { addCentralWallet } from '@entities/wallet'

import { queryKeys } from '@shared/constants'
import { CustomTextField } from '@shared/ui'

import { useCreateCentralWalletForm } from '../model'

export function AddCentralWallet() {
  const [isShow, setIsShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation('features')

  const [selectedValue, setSelectedValue] = useState<TBlockchain>(
    blockchains[0]
  )

  const handleOpen = () => setIsShow(true)

  const { control, handleSubmit, reset } = useCreateCentralWalletForm()

  const handleClose = () => {
    setIsShow(false)

    setTimeout(reset, 300)
  }

  const onSubmit = async (address: string) => {
    try {
      setIsLoading(true)

      await to(addCentralWallet({ address, blockchain: selectedValue }))

      queryClient.prefetchQuery({
        queryKey: [queryKeys.centralWalletsSetting],
      })

      toast.success(t('wallet.toast.success'))
    } catch {
      toast.error(t('wallet.toast.error'))
    } finally {
      setIsLoading(false)

      handleClose()
    }
  }

  return (
    <>
      <Dialog
        open={isShow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        sx={{ '& .MuiPaper-root': { p: '80px 120px', gap: 5 } }}
      >
        <Typography variant="h4">
          {t('wallet.create-central-wallet')}
        </Typography>

        <Stack
          component={'form'}
          onSubmit={handleSubmit((data) => onSubmit(data.address))}
        >
          <Controller
            control={control}
            name="address"
            render={({ field, fieldState }) => (
              <CustomTextField
                onChange={field.onChange}
                value={field.value.toString()}
                onBlur={field.onBlur}
                ref={field.ref}
                errorMessage={fieldState.error?.message}
                label={t('wallet.text-fields.address')}
                autoFocus
                sx={{ mb: 4 }}
              />
            )}
          />

          <Autocomplete
            options={blockchains}
            defaultValue={blockchains[0]}
            disableClearable
            freeSolo={false}
            onChange={(_, newValue) => setSelectedValue(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('wallet.text-fields.blockchain')}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
            sx={{ mb: 4 }}
          />

          <LoadingButton
            type={'submit'}
            loading={isLoading}
            variant="contained"
            fullWidth
          >
            {t('wallet.btn-names.send')}
          </LoadingButton>
        </Stack>
      </Dialog>

      <Button onClick={handleOpen} variant="contained">
        {t('wallet.btn-names.wallet')}
      </Button>
    </>
  )
}
