import { FilterListSharp } from '@mui/icons-material'
import { IconButton, Icon, Button, Menu, MenuItem } from '@mui/material'
import {
  useState,
  MouseEvent,
  useLayoutEffect,
  useEffect,
  memo,
  ReactNode,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { FilterSelect } from '@shared/ui'

import { IFilterOptions } from '../model'

import { StyledMenuItem } from './styled'

interface IProps {
  filtersOptions: IFilterOptions[]
  dateFilter?: ReactNode
  resetDateFilter?: () => void
}

export const FiltersMenu = memo(function FiltersMenu({
  filtersOptions,
  dateFilter,
  resetDateFilter,
}: IProps) {
  const { t } = useTranslation('shared')
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleReset = () => {
    filtersOptions.forEach((filterOption) =>
      filterOption.setCurrentOption('all')
    )

    resetDateFilter && resetDateFilter()

    handleCloseUserMenu()
  }

  useLayoutEffect(() => {
    filtersOptions.forEach((filterOption) => {
      const optionFromParams = searchParams.get(filterOption.filterName)

      optionFromParams && filterOption.setCurrentOption(optionFromParams)
    })
  }, [])

  useEffect(() => {
    filtersOptions.forEach((filterOption) => {
      const currentOption = filterOption.currentOption
      const filterName = filterOption.filterName

      searchParams.set(filterName, currentOption)
    })

    setSearchParams(searchParams)
  }, [...filtersOptions.map((option) => option.currentOption)])

  return (
    <>
      <Button onClick={handleOpenUserMenu}>
        {t('filters-menu.title')}

        <IconButton component="div" color="inherit" size="small" disableRipple>
          <Icon>
            <FilterListSharp />
          </Icon>
        </IconButton>
      </Button>

      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {filtersOptions.map((filterOption, index) => (
          <StyledMenuItem disableRipple key={index}>
            <FilterSelect {...filterOption} sx={{ width: '100%' }} />
          </StyledMenuItem>
        ))}

        {dateFilter && (
          <StyledMenuItem disableRipple>{dateFilter}</StyledMenuItem>
        )}

        <MenuItem onClick={handleReset} sx={{ justifyContent: 'center' }}>
          {t('filters-menu.reset')}
        </MenuItem>
      </Menu>
    </>
  )
})
