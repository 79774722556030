import { yupResolver } from '@hookform/resolvers/yup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from '@mui/material'
import to from 'await-to-js'
import { ForwardedRef, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { IUploadFileSettings } from '@common/types'
import { getValidationSchemas } from '@common/validation'

import { useBoxMediaQuery, useTFunc } from '@shared/hooks'
import { UploadFile } from '@shared/ui'
import { fileToBase64 } from '@shared/utils'

export interface IResetFormAddDocumentsRef {
  resetForm: () => void
}

interface IProps {
  uploadFileSettings: IUploadFileSettings
  pageId: string
  onSubmit: (fileList: string[]) => void
  loading?: boolean
  ref?: ForwardedRef<IResetFormAddDocumentsRef>
}

interface IFileListForm {
  fileList?: File[]
}

export function AddDocuments({
  uploadFileSettings,
  pageId,
  onSubmit,
  loading,
  ref,
}: IProps) {
  const { t } = useTranslation(['features', 'shared', 'common'])
  const tFunc = useTFunc()

  const { getFileSchema } = getValidationSchemas(tFunc)
  const fileSchema = getFileSchema(uploadFileSettings)

  const boxMdAndUp = useBoxMediaQuery(pageId).up('md')

  const schema: yup.ObjectSchema<IFileListForm> = yup.object().shape({
    fileList: yup.array().required().min(1).of(fileSchema.required()),
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<IFileListForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      fileList: undefined,
    },
  })

  useImperativeHandle(ref, () => ({
    resetForm: reset,
  }))

  const onClickSubmit = async (data: IFileListForm) => {
    const fileList = data.fileList

    if (!fileList?.length) {
      return
    }

    const [errorBase64, base64FileList] = await to(
      Promise.all(fileList.map(async (file) => await fileToBase64(file)))
    )

    if (errorBase64) {
      toast.error(t('common:oops'))

      return
    }

    onSubmit(base64FileList)
  }

  return (
    <Accordion
      sx={{
        '&:before': { content: 'none' },
        '& .MuiAccordionSummary-expandIconWrapper': { top: 'initial' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h5">{t('add-documents.title')}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
          onSubmit={handleSubmit(onClickSubmit)}
        >
          <Controller
            control={control}
            name="fileList"
            render={({ field, fieldState }) => (
              <UploadFile
                uploadFileSettings={uploadFileSettings}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                variant="tiny"
                uploadDocTitle={t(
                  `shared:drag-n-drop.${boxMdAndUp ? 'upload-a-document' : 'tap-to-upload-file'}`
                )}
                removeFile={(index) =>
                  field.onChange(field.value?.filter((_, i) => i !== index))
                }
                data={{
                  files: field.value || [],
                  saveFiles: (files: File[]) => {
                    field.onChange([...(field.value || []), ...files])
                  },
                  single: false,
                  isRemote: false,
                }}
              />
            )}
          />

          <LoadingButton
            loading={loading}
            disabled={!isDirty}
            fullWidth
            type="submit"
            variant="contained"
          >
            {t('add-documents.button')}
          </LoadingButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
