import { Box, Divider, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { TFormattedPersonalParticipant } from '@common/types'

import { ShowOrDownloadFile } from '@shared/ui'
import { getCountryNameByAbbreviation } from '@shared/utils'

import { LabelValue } from '../label-value'
import { GroupFieldsCard } from '../styled'

const mainTranslationPath = 'account.business-application'

export function PersonalParticipantCard({
  addressCity,
  addressCountry,
  addressStreet,
  addressZip,
  createdAt,
  email,
  phoneNumber,
  type,
  updatedAt,
  percentageOwnership,
  birthDate,
  documents,
  firstName,
  isDualCitizen,
  isPep,
  lastName,
  nationality,
  personalNumber,
  dualCitizen,
  proofOfAddress,
  id,
}: TFormattedPersonalParticipant) {
  const { t } = useTranslation('entities')

  return (
    <GroupFieldsCard>
      <Box>
        <Typography variant="h3">
          {t(`${mainTranslationPath}.personal-${type}`)}
        </Typography>

        <Typography variant="subtitle2" mb={1}>
          {`ID: ${id}`}
        </Typography>
      </Box>

      <Typography variant="h5">
        {t(`${mainTranslationPath}.main-info`)}
      </Typography>

      <LabelValue
        label={t(`${mainTranslationPath}.first-name`)}
        value={firstName}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.last-name`)}
        value={lastName}
      />

      <LabelValue label={t(`${mainTranslationPath}.email`)} value={email} />

      <LabelValue
        label={t(`${mainTranslationPath}.phone-number`)}
        value={phoneNumber}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.birth-date`)}
        value={format(new Date(birthDate), 'dd.MM.yyyy HH:mm')}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.nationality`)}
        value={getCountryNameByAbbreviation(nationality)}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.personal-number`)}
        value={personalNumber}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.is-dual-citizen`)}
        value={JSON.stringify(isDualCitizen)}
      />

      {dualCitizen && (
        <LabelValue
          label={t(`${mainTranslationPath}.dual-citizen`)}
          value={getCountryNameByAbbreviation(dualCitizen)}
        />
      )}

      <LabelValue
        label={t(`${mainTranslationPath}.is-pep`)}
        value={JSON.stringify(isPep)}
      />

      <Divider />

      {!!documents.length &&
        documents.map((doc, index) => (
          <Fragment key={index}>
            <Typography variant="h5">
              {t(`${mainTranslationPath}.document`, { number: index + 1 })}
            </Typography>

            <LabelValue
              label={t(`${mainTranslationPath}.type`)}
              value={doc.type}
            />

            <LabelValue
              label={t(`${mainTranslationPath}.number`)}
              value={doc.number}
            />

            <LabelValue
              label={t(`${mainTranslationPath}.issue-country`)}
              value={getCountryNameByAbbreviation(doc.issueCountry)}
            />

            <Stack direction="row" flexWrap="wrap" gap={1}>
              {!!doc.images?.length &&
                doc.images.map((image, index) => (
                  <Fragment key={index}>
                    {image && (
                      <ShowOrDownloadFile
                        options={{
                          contentType: image.contentType,
                          s3Link: image.s3Link,
                        }}
                      />
                    )}
                  </Fragment>
                ))}
            </Stack>

            <Divider />
          </Fragment>
        ))}

      <Typography variant="h5">
        {t(`${mainTranslationPath}.address`)}
      </Typography>

      <LabelValue
        label={t(`${mainTranslationPath}.city`)}
        value={addressCity}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.country`)}
        value={getCountryNameByAbbreviation(addressCountry)}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.street`)}
        value={addressStreet}
      />

      <LabelValue label={t(`${mainTranslationPath}.zip`)} value={addressZip} />
      <Divider />

      {percentageOwnership && (
        <LabelValue
          label={t(`${mainTranslationPath}.percentage-ownership`)}
          value={percentageOwnership ? `${percentageOwnership}%` : ''}
        />
      )}

      <Box>
        <Typography variant="body1" mb={0.5}>
          {t(`${mainTranslationPath}.address-proof`)}
        </Typography>

        <Stack direction="row" gap={1} flexWrap="wrap">
          {proofOfAddress?.length
            ? proofOfAddress.map((doc, index) => (
                <ShowOrDownloadFile options={doc} key={index} />
              ))
            : 'empty'}
        </Stack>
      </Box>

      <LabelValue
        label={t(`${mainTranslationPath}.created`)}
        value={format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}
      />

      <LabelValue
        label={t(`${mainTranslationPath}.updated`)}
        value={format(new Date(updatedAt), 'dd.MM.yyyy HH:mm')}
      />
    </GroupFieldsCard>
  )
}
