import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IExchangeListItem } from '@common/types'

import { IComposeTableCellTemplate, IMobileListItem } from '@shared/ui'

import { ExchangeFlow, ExchangeStatus } from '../../ui'

export function useExchangeListTemplate(exchanges: IExchangeListItem[]) {
  const { t } = useTranslation('pages')

  const desktopTemplate: IComposeTableCellTemplate<IExchangeListItem>[] = [
    {
      key: 'id',
      title: t('exchange-list.table-header.id'),
      component: 'hiddenMiddle',
    },
    {
      key: 'walletName',
      title: t('exchange-list.table-header.wallet-name'),
      component: 'short',
    },
    {
      key: 'amountToExchange',
      title: t('exchange-list.table-header.exchange'),
      component: ({ data }) => <ExchangeFlow {...data} />,
    },
    {
      key: 'type',
      title: t('exchange-list.table-header.type'),
      component: ({ data }) => t(`exchange-list.types.${data.type}`),
    },
    {
      key: 'processing',
      title: t('exchange-list.table-header.method'),
      component: ({ data }) => t(`exchange-list.methods.${data.processing}`),
    },
    {
      key: 'status',
      title: t('exchange-list.table-header.status'),
      component: ({ data }) => <ExchangeStatus status={data.status} />,
    },
    {
      key: 'createdAt',
      title: t('exchange-list.table-header.created'),
      component: 'dateTime',
    },
  ]

  const mobileTemplate: IMobileListItem[] = exchanges.map(
    ({
      createdAt,
      id,
      amountToExchange,
      currencyToExchange,
      exchangedAmount,
      exchangedCurrency,
      type,
      processing,
      status,
      walletName,
    }) => {
      return {
        id: id,
        firstRow: [
          <ExchangeFlow
            key={id}
            amountToExchange={amountToExchange}
            currencyToExchange={currencyToExchange}
            exchangedAmount={exchangedAmount}
            exchangedCurrency={exchangedCurrency}
          />,

          <ExchangeStatus status={status} key={id} />,
        ],
        middleRows: [
          walletName,
          type,
          processing,
          format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
        ],
        lastRow: [
          <Typography
            variant="body1"
            color="text.secondary"
            key={1}
            className="ellipsis"
          >
            {`id: ${id}`}
          </Typography>,
        ],
      } satisfies IMobileListItem
    }
  )

  return { desktopTemplate, mobileTemplate }
}
