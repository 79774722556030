import { IFeePlan } from '@common/types'

import { instance } from '@shared/requester'

export async function deleteFeePlan(feePlanId?: string) {
  if (!feePlanId) {
    throw new Error()
  }

  return instance
    .delete<IFeePlan>(`/settings/fee-plan/${feePlanId}`)
    .then((res) => res.status)
}
