import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Box, IconButton, LinearProgress, Typography } from '@mui/material'

import { IUploadFile } from '../../model'
import { ReactComponent as CloseIcon } from '../assets/close-icon.svg'
import { ReactComponent as DocumentImg } from '../assets/document-img.svg'

import { StyledFileUploadListItem } from './styled'

interface IProps {
  index: number
  file: IUploadFile
  removeFile: (index: number) => void
  isRemote: boolean
}

export const UploadItem = ({ index, file, removeFile, isRemote }: IProps) => {
  return (
    <StyledFileUploadListItem>
      <DocumentImg />

      <Box ml={1.25} flex={1} overflow={'hidden'}>
        <Box display="flex">
          <Box
            color={isRemote ? '#004766' : ''}
            fontSize={isRemote ? 16 : 'auto'}
            sx={isRemote ? { overflow: 'hidden' } : { overflowX: 'hidden' }}
          >
            <Typography
              variant="subtitle2"
              color={'tertiary.main'}
              sx={{
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {file.name}
            </Typography>

            <Typography variant={'body2'} color={'tertiary.main'}>
              {file.size}
            </Typography>
          </Box>

          <Box ml="auto">
            <IconButton onClick={() => removeFile(index)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {isRemote && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Box flex={0.95}>
              <LinearProgress
                variant="determinate"
                value={file.loaded}
                sx={{ borderRadius: '100px' }}
              />
            </Box>

            {file?.loaded && (
              <>
                {file.loaded >= 100 && (
                  <CheckCircleRoundedIcon
                    color={'primary'}
                    sx={{ mr: 1.25, fontSize: 20 }}
                  />
                )}

                {file.loaded < 100 && (
                  <Typography color={'primary.dark'} variant={'body2'}>
                    {file.loaded}%
                  </Typography>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </StyledFileUploadListItem>
  )
}
