import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import { IconButton, SxProps } from '@mui/material'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface IProps {
  value?: string | number | null
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  color?: string
  sx?: SxProps
}

export function CopyButton({ disabled, value, size = 'small', sx }: IProps) {
  const { t } = useTranslation('shared')

  async function copyHandle(
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) {
    event.stopPropagation()

    try {
      if (!value) {
        return
      }

      const input = document.createElement('input')

      input.value = String(value)

      input.style.position = 'fixed'

      input.style.left = '-1000000000px'

      window.document.body.appendChild(input)

      input.focus()

      input.select()

      await navigator.clipboard.writeText(String(value))

      input.remove()

      toast.success(t('copy-button.success'))
    } catch {
      toast.error(t('copy-button.error'))
    }
  }

  return (
    <IconButton
      sx={sx}
      size={size}
      onClick={copyHandle}
      disabled={disabled || !value}
    >
      <ContentCopyRoundedIcon fontSize={size} />
    </IconButton>
  )
}
