import { Grid } from '@mui/material'

import { TFormattedBusinessApplication } from '@common/types'

import { CompanyAddress } from './company-address'
import { CompanyData } from './company-data'
import { CompanyDetails } from './company-details'
import { CompanyLegalDocumentImages } from './company-legal-document-images'
import { CompanyPlannedMonthlyTurnover } from './company-planned-monthly-turnover'

interface IProps {
  businessApplication?: TFormattedBusinessApplication | null
}

export function MainInfo({ businessApplication }: IProps) {
  return (
    businessApplication && (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <CompanyDetails {...businessApplication} />
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <CompanyAddress {...businessApplication} />
        </Grid>

        {businessApplication.legalDocumentImages?.length &&
          businessApplication.legalDocumentImages.length > 0 && (
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <CompanyLegalDocumentImages
                legalDocumentImages={businessApplication.legalDocumentImages}
              />
            </Grid>
          )}

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <CompanyPlannedMonthlyTurnover {...businessApplication} />
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <CompanyData {...businessApplication} />
        </Grid>
      </Grid>
    )
  )
}
