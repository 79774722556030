import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
} from 'react-router-dom'

import { AccountPageRouter } from '@pages/account'
import { AccountListRouter } from '@pages/account-list'
import { DashboardPageRouter } from '@pages/dashboard'
import { DepositPageRouter } from '@pages/deposit'
import { DepositListRouter } from '@pages/deposit-list'
import { ExchangePageRouter } from '@pages/exchange'
import { ExchangeListRouter } from '@pages/exchange-list'
import { FeePlanPageRouter } from '@pages/fee-plan'
import { PersonalAccountApplicationRoute } from '@pages/personal-account-application'
import { SettingsPageRouter } from '@pages/settings'
import { TransactionPageRouter } from '@pages/transaction'
import { TransactionListPageRouter } from '@pages/transaction-list'
import { UserRouter } from '@pages/user'
import { UserListRouter } from '@pages/users-list'
import { WithdrawalPageRouter } from '@pages/withdrawal'
import { WithdrawalListPageRouter } from '@pages/withdrawal-list'

import { MainLayout } from './layouts'

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      UserListRouter,
      UserRouter,
      ExchangeListRouter,
      AccountListRouter,
      AccountPageRouter,
      DepositListRouter,
      DepositPageRouter,
      WithdrawalListPageRouter,
      WithdrawalPageRouter,
      TransactionPageRouter,
      TransactionListPageRouter,
      ExchangePageRouter,
      DashboardPageRouter,
      SettingsPageRouter,
      FeePlanPageRouter,
      PersonalAccountApplicationRoute,
    ],
  },
])

export function RouterProvider() {
  return <ReactRouterProvider router={router} />
}
