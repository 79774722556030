import { IAccountDetails } from '@common/types'

import { ParticipantCard } from '@entities/account-application'

interface IProps {
  account: IAccountDetails
}

export function Shareholders({ account }: IProps) {
  return (
    <ParticipantCard participants={account.businessApplication?.shareholders} />
  )
}
