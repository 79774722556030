// import { LoadingButton } from '@mui/lab'
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  // Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IWallet } from '@common/types'

import { ShortInfoBarItemWrapper, TypographyWithCopyButton } from '@shared/ui'

import { CommonItemElementsContainer } from './components'

interface IProps {
  wallets: Pick<IWallet, 'address' | 'blockchain'>[]
}

export function AccountWalletsShortInfoItem({ wallets }: IProps) {
  const { t } = useTranslation('features')

  return (
    <ShortInfoBarItemWrapper sx={{ flexDirection: 'column', gap: 1.5 }}>
      <CommonItemElementsContainer>
        <Typography variant="h5" color="primary.main">
          {t('account.short-info-bar.wallets')}
        </Typography>
      </CommonItemElementsContainer>

      <Box width="100%">
        {wallets.map(({ blockchain, address }) => (
          <CommonItemElementsContainer
            key={address}
            sx={{ overflow: 'hidden' }}
          >
            <Typography
              className="first-letter-up"
              variant="body2"
              minWidth={100}
            >
              {blockchain}:
            </Typography>

            <TypographyWithCopyButton
              value={address}
              variant="h6"
              sx={{ overflow: 'hidden' }}
            />
          </CommonItemElementsContainer>
        ))}
      </Box>

      {/* <Accordion sx={{ '&:before': { content: 'none' } }}>
        <AccordionSummary
          sx={{
            bgcolor: 'red',
            borderRadius: 1,
            padding: '4px 8px !important',
          }}
        >
          <Typography variant="body1" color="common.white">
            Danger actions
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ textTransform: 'initial' }}>
          <Stack spacing={1}>
            <Typography variant="body2">
              Here you can synchronize wallet balances with Crypto API. Be
              careful with this action!
            </Typography>

            <LoadingButton variant="contained">
              Synch TRON wallet balances
            </LoadingButton>

            <LoadingButton variant="contained">
              Synch ETHEREUM wallet balances
            </LoadingButton>
          </Stack>
        </AccordionDetails>
      </Accordion> */}
    </ShortInfoBarItemWrapper>
  )
}
