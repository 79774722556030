import { Typography } from '@mui/material'
// import { useTranslation } from 'react-i18next'

// import { DownloadAllStatistic } from '@widgets/statistic'

// import { ContentPaper, StyledToolbar } from '@shared/ui'

export function DashboardPage() {
  // const { t } = useTranslation('pages')

  return (
    <>
      <Typography variant="h6" color="GrayText" fontWeight={100}>
        Dashboard
      </Typography>

      {/* <StyledToolbar> */}
      {/* <Typography variant="h6" color="GrayText" fontWeight={100}> */}
      {/* {t('main.title')} */}
      {/* </Typography> */}
      {/* </StyledToolbar> */}

      {/* <Container maxWidth={false}> */}
      {/* <ContentPaper> */}
      {/* <DownloadAllStatistic /> */}
      {/* </ContentPaper> */}
      {/* </Container> */}
    </>
  )
}
