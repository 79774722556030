import { Typography, Stack, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TLegalDocumentImage } from '@common/types'

import { GroupFieldsCard } from '@entities/account-application'

import { ShowOrDownloadFile } from '@shared/ui'

const mainTranslationPath = 'account.business-application'

interface IProps {
  legalDocumentImages: TLegalDocumentImage[]
}

export function CompanyLegalDocumentImages({ legalDocumentImages }: IProps) {
  const { t } = useTranslation('entities')

  return (
    <GroupFieldsCard>
      <Typography variant="h3" mb={1}>
        {t(`${mainTranslationPath}.company-legal-documents`)}
      </Typography>

      <Stack gap={2}>
        {legalDocumentImages.map(({ type, images }, index) => (
          <Box key={index}>
            <Typography variant="body1" mb={0.5}>
              {t(`${mainTranslationPath}.${type}`)}
            </Typography>

            <Stack direction="row" gap={1} flexWrap="wrap">
              {images && images.length
                ? images.map((image, index) => (
                    <ShowOrDownloadFile options={image} key={index} />
                  ))
                : 'empty'}
            </Stack>
          </Box>
        ))}
      </Stack>
    </GroupFieldsCard>
  )
}
