import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as AddItem } from '../../../assets/add-item.svg'

interface IProps {
  onClick: () => void
}

export function BeneficiaryAdd({ onClick }: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <Stack
      borderRadius="12px"
      border={`1px dashed`}
      justifyContent="center"
      alignItems="center"
      height="100%"
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <AddItem />

      <Stack direction={'row'} alignItems={'flex-end'} gap={1}>
        <AddRoundedIcon sx={{ fontSize: 24, color: 'info.main' }} />

        <Typography variant="button" color="info.main">
          {t('account-application.addBeneficiary')}
        </Typography>
      </Stack>
    </Stack>
  )
}
