import * as yup from 'yup'

import { currencies, IFeePlanScale, TFeePlanForm } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

const minScaleValue = 0
const minScaleQuantity = 1

export const getFeePlanSchema = (tFunc?: typeof defT) => {
  const t = tFunc || defT
  const { nameSchema, numberSchema } = getValidationSchemas(tFunc)

  const scaleSchema: yup.ObjectSchema<IFeePlanScale> = yup.object().shape({
    min: numberSchema.min(
      minScaleValue,
      t('min-amount', { amount: minScaleValue, currency: 'EUR' })
    ),
    max: numberSchema.moreThan(
      yup.ref('min'),
      t('greater-than', { targetValue: '"min"' })
    ),
    percents: numberSchema
      .min(
        minScaleValue,
        t('min-amount', { amount: minScaleValue, currency: '%' })
      )
      .max(100, t('max-amount', { amount: 100 })),
  })

  const scalesSchema: yup.ArraySchema<IFeePlanScale[], yup.AnyObject, ''> = yup
    .array()
    .of(scaleSchema)
    .required(t('required'))
    .test('validate-order', t('scales'), function (value) {
      if (!Array.isArray(value)) {
        return true
      }

      for (let i = 0; i < value.length - 1; i++) {
        if (value[i].max >= value[i + 1].min) {
          return this.createError({
            path: `scales.${i}.max`,
            message: '',
          })
        }
      }

      return true
    })

  const minFeeSchema: yup.StringSchema<TFeePlanForm['minFee']> = yup
    .string()
    .matches(/^\d+$/, t('integer-number'))
    .required(t('required'))

  const feePlanSchema: yup.ObjectSchema<TFeePlanForm> = yup.object().shape({
    name: nameSchema,
    description: nameSchema,
    scales: scalesSchema
      .required()
      .min(
        minScaleQuantity,
        t('min-array-items_interval', { count: minScaleQuantity })
      ),
    currency: yup.string().oneOf(currencies).required(t('required')),
    isDefault: yup.boolean(),
    minFee: minFeeSchema,
  })

  return { feePlanSchema }
}
