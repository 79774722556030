import { ReactComponent as ETHIcon } from './assets/eth.svg'
import { ReactComponent as EurIcon } from './assets/eur-icon.svg'
import { ReactComponent as TRXIcon } from './assets/trx-icon.svg'
import { ReactComponent as USDCIcon } from './assets/usdc.svg'
import { ReactComponent as USDTIcon } from './assets/usdt-icon.svg'

export const currenciesIcons = {
  EUR: <EurIcon />,
  TRX: <TRXIcon />,
  USDT: <USDTIcon />,
  ETH: <ETHIcon />,
  USDC: <USDCIcon />,
}
