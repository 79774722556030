import { RouteObject } from 'react-router'

import { routePaths } from '@shared/constants'

import { additionalRoutesOfSettings } from './model'
import {
  CentralWalletsSettings,
  DepositSettings,
  EmailTemplates,
  ExchangeSettings,
  FeePlanListPage,
  SettingsPage,
  TransactionSettings,
  UpdateDataAfterMigration,
  UploadFileSettings,
  WithdrawalSettings,
} from './ui'

const mainPath = routePaths.settings.list

export const SettingsPageRouter: RouteObject = {
  path: mainPath,
  element: <SettingsPage />,
  children: [
    { path: routePaths.settings.feePlan.list, element: <FeePlanListPage /> },
    {
      path: `${mainPath}/${additionalRoutesOfSettings.deposit}`,
      element: <DepositSettings />,
    },
    {
      path: `${mainPath}/${additionalRoutesOfSettings.withdrawals}`,
      element: <WithdrawalSettings />,
    },
    {
      path: `${mainPath}/${additionalRoutesOfSettings.centralWallets}`,
      element: <CentralWalletsSettings />,
    },
    {
      path: `${mainPath}/${additionalRoutesOfSettings.transactions}`,
      element: <TransactionSettings />,
    },
    {
      path: `${mainPath}/${additionalRoutesOfSettings.exchange}`,
      element: <ExchangeSettings />,
    },
    {
      path: `${mainPath}/${additionalRoutesOfSettings.uploadFile}`,
      element: <UploadFileSettings />,
    },
    {
      path: `${mainPath}/${additionalRoutesOfSettings.updateDataAfterMigration}`,
      element: <UpdateDataAfterMigration />,
    },
    {
      path: `${mainPath}/${additionalRoutesOfSettings.emailTemplates}`,
      element: <EmailTemplates />,
    },
  ],
}
