import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { ICentralWalletBody } from '@common/types'

type TSchema = Omit<ICentralWalletBody, 'blockchain'>

export function useCreateCentralWalletForm() {
  const { t } = useTranslation('shared')

  const schema: yup.ObjectSchema<TSchema> = yup.object().shape({
    address: yup.string().required(t('validation.required')),
  })

  return useForm<TSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      address: '',
    },
  })
}
