import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton } from '@mui/lab'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ISimpleDrawerProps } from '../model'

import { StyledLine, StyledMobileDrawer } from './styled'

const px = 2

export function MobileDrawer({
  children,
  title,
  sx,
  onClose,
  open,
  loading,
  onSave,
  disableSave,
}: ISimpleDrawerProps) {
  const { t } = useTranslation('shared')

  const handleClose = () => {
    !loading && onClose()
  }

  return (
    <StyledMobileDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: false,
      }}
      swipeAreaWidth={0}
      disableSwipeToOpen={false}
      allowSwipeInChildren
      sx={sx}
    >
      <Stack
        justifyContent="flex-end"
        direction="row"
        position="relative"
        py={2}
        px={px}
      >
        <StyledLine />

        <IconButton onClick={handleClose} disabled={loading}>
          <CloseRoundedIcon
            sx={{ color: (theme) => theme.palette.text.primary }}
          />
        </IconButton>
      </Stack>

      <Typography variant="h3" mb={1.5} px={px}>
        {title}
      </Typography>

      <Stack flex={1} overflow="hidden" mb={2} px={px}>
        {children}
      </Stack>

      {onSave && (
        <Stack spacing={1.5} px={px}>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={handleClose}
            disabled={loading}
          >
            {t('button.cancel')}
          </Button>

          <LoadingButton
            variant="contained"
            size="large"
            color="primary"
            onClick={onSave}
            loading={loading}
            disabled={disableSave}
          >
            {t('button.save')}
          </LoadingButton>
        </Stack>
      )}
    </StyledMobileDrawer>
  )
}
