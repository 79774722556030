import { Stack, SxProps } from '@mui/material'

import { HomeAddressFields } from '@features/accounts-application'

import { IHomeAddressForm } from '@entities/personal-account-application'

import { ProofOfAddressField } from './components'

interface IProps {
  sx?: SxProps
}

export function HomeAddressForm({ sx }: IProps) {
  return (
    <Stack sx={sx} gap={5}>
      <HomeAddressFields<IHomeAddressForm>
        addressName={'addressStreet'}
        cityName={'addressCity'}
        countryName={'addressCountry'}
        zipCodeName={'addressZip'}
      />

      <ProofOfAddressField />
    </Stack>
  )
}
