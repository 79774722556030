import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IFeePlan } from '@common/types'

import { IInfoSectionRow } from '@shared/ui'

export function useInfoTemplates(feePlan?: IFeePlan | null) {
  const { t } = useTranslation('pages')

  if (!feePlan) {
    return {
      mainInfoTemplate: [],
      secondaryInfoTemplate: [],
    }
  }

  const { createdAt, updatedAt, id } = feePlan

  const mainInfoTemplate = [
    { label: 'ID', value: id },
    {
      label: t('transaction.created-at'),
      value: format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
    },
    {
      label: t('transaction.created-at'),
      value: format(new Date(updatedAt), 'dd.MM.yyyy HH:mm'),
    },
  ] satisfies IInfoSectionRow[]

  return {
    mainInfoTemplate,
  }
}
