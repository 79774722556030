import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Link, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { grey, routePaths } from '@shared/constants'

interface IProps {
  sx?: SxProps
  pathnames: string[]
  skipParamValue?: string
  breadcrumbNameMap: {
    [x: string]: string
  }
}

export function CustomBreadcrumbs({
  pathnames,
  skipParamValue,
  breadcrumbNameMap,
  sx,
}: IProps) {
  const { t } = useTranslation('shared')

  return (
    <Breadcrumbs
      aria-label="user-breadcrumb"
      separator={
        <NavigateNextIcon fontSize="small" sx={{ color: grey[700] }} />
      }
      sx={sx}
    >
      <Link
        underline="hover"
        color={grey[700]}
        href={routePaths.dashboard}
        variant="body1"
      >
        {t('dashboard')}
      </Link>

      {pathnames.map((pathname, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`

        if (skipParamValue && pathname === skipParamValue) {
          return
        }

        return last ? (
          <Typography key={to} color="info.main" variant="body1">
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <Link
            underline="hover"
            color={grey[700]}
            href={to}
            key={to}
            variant="body1"
          >
            {breadcrumbNameMap[to]}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}
