import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import * as yup from 'yup'

import { useAccountDetails } from '@entities/account'
import { ITermsAndConditionsForm } from '@entities/personal-account-application'

import { TParamsKeys } from '@shared/constants'

export function useTermsAndConditionsForm() {
  const { t } = useTranslation('shared')

  const { accountId } = useParams<TParamsKeys>()

  const { data } = useAccountDetails({ accountId })

  const schema: yup.ObjectSchema<ITermsAndConditionsForm> = yup.object({
    terms: yup
      .boolean()
      .required(t('validation.required'))
      .oneOf<boolean>([true], t('validation.required')),
    privacy: yup
      .boolean()
      .required(t('validation.required'))
      .oneOf<boolean>([true], t('validation.required')),
    personalData: yup.boolean().required(t('validation.required')),
    isUsCitizen: yup.boolean().required(t('validation.required')),
  })

  const termsAndConditionsForm = useForm<ITermsAndConditionsForm>({
    resolver: yupResolver(schema),
  })

  const { reset } = termsAndConditionsForm

  useEffect(() => {
    reset({
      terms: data?.personalApplication?.terms,
      privacy: data?.personalApplication?.privacy,
      personalData: data?.personalApplication?.personalData,
      isUsCitizen: data?.personalApplication?.isUsCitizen,
    })
  }, [data])

  return { termsAndConditionsForm }
}
