import { useTranslation } from 'react-i18next'

import { ITransactionDetails } from '@common/types'

import { IInfoSectionRow } from '@shared/ui'

export function useReportAlertInfoTemplates() {
  const { t } = useTranslation('pages')

  const createReportAlertInfoTemplate = (
    alert: ITransactionDetails['analysisReports'][number]['alerts'][number]
  ) => {
    const {
      alertAmount,
      alertLevel,
      category,
      categoryId,
      exposureType,
      externalId,
      service,
    } = alert

    return [
      {
        label: t('transaction.alert-amount'),
        value: alertAmount,
      },
      {
        label: t('transaction.alert-level'),
        value: alertLevel,
      },
      {
        label: t('transaction.category'),
        value: category,
      },
      {
        label: t('transaction.category-id'),
        value: categoryId,
        copyable: true,
      },
      {
        label: t('transaction.exposure-type'),
        value: exposureType,
      },
      {
        label: t('transaction.external-id'),
        value: externalId,
        copyable: true,
      },
      {
        label: t('transaction.service'),
        value: service,
      },
    ] satisfies IInfoSectionRow[]
  }

  return createReportAlertInfoTemplate
}
