import { TAccountStatus } from '@common/types'

import { useGetAccountStatusOptions } from '@entities/account'

import { StatusChip } from '@shared/ui'

interface IProps {
  status: TAccountStatus
}

export function AccountStatus({ status }: IProps) {
  const { getStatusText, getStatusColor } = useGetAccountStatusOptions(status)

  return (
    <StatusChip
      circleColor={getStatusColor()}
      text={getStatusText()}
      variant="text"
    />
  )
}
