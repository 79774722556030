import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const DesktopDrawerBottomActionsWrapper = styled('div')(({ theme }) => ({
  padding: '12px 24px',

  backgroundColor: theme.palette.background.default,
  borderTop: '1px solid',
  borderColor: grey[100],
}))
