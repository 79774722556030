type TAdditionalInfoListOfAccount =
  | 'cryptoBalances'
  | 'deposits'
  | 'withdrawals'
  | 'exchanges'
  | 'transactions'

export const additionalInfoListOfAccount: Record<
  TAdditionalInfoListOfAccount,
  TAdditionalInfoListOfAccount
> = {
  cryptoBalances: 'cryptoBalances',
  deposits: 'deposits',
  withdrawals: 'withdrawals',
  exchanges: 'exchanges',
  transactions: 'transactions',
}
