import { styled } from '@mui/material'

import { sidebarAdditionalHoverArea } from '@shared/constants'

interface IProps {
  minWidth?: number
}

export const StickyWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})<IProps>(({ theme, minWidth }) => ({
  position: 'sticky',
  zIndex: 100,
  top: 0,
  minHeight: '100vh',
  height: 'min-content',
  boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.08)',
  backgroundColor: theme.palette.background.paper,
  maxWidth: sidebarAdditionalHoverArea,
  minWidth: minWidth,
  transition: theme.transitions.create('min-width'),
}))
