import { Box, Collapse, Grid, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CountrySelectInput, YesNoRadioButtons } from '@shared/ui'

interface IProps<T extends FieldValues> {
  nameDualCitizenShip: FieldPath<T>
  nameDualCitizenshipCountry: FieldPath<T>
  radioButtonsLabel: string
  sx?: SxProps
}

export function DualCitizenship<T extends FieldValues>({
  nameDualCitizenShip,
  nameDualCitizenshipCountry,
  radioButtonsLabel,
  sx,
}: IProps<T>) {
  const { t } = useTranslation(['features', 'shared'])
  const { control, watch, resetField } = useFormContext<T>()
  const [isDualCitizenship, setIsDualCitizenship] = useState<boolean>(false)

  useEffect(() => {
    if (!isDualCitizenship) {
      setTimeout(resetField, 300, nameDualCitizenshipCountry)
    }
  }, [isDualCitizenship])

  useEffect(() => {
    const { unsubscribe } = watch((formValues) => {
      const dualCitizenship = formValues[nameDualCitizenShip]

      setIsDualCitizenship(dualCitizenship)
    })

    return () => unsubscribe()
  }, [watch(nameDualCitizenShip)])

  return (
    <Box sx={sx}>
      <Grid container columnSpacing={{ xs: 0, md: 4.5 }} rowSpacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={nameDualCitizenShip}
            render={({ field, fieldState }) => (
              <YesNoRadioButtons
                {...field}
                onChange={(e) => {
                  const value = e.target.value

                  field.onChange(value === 'yes')

                  field.onBlur()
                }}
                label={radioButtonsLabel}
                error={!!fieldState.error}
                helperText={
                  fieldState.error ? t('shared:validation.required') : ''
                }
                sx={{ minHeight: 82 }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Collapse in={isDualCitizenship}>
            <Controller
              control={control}
              name={nameDualCitizenshipCountry}
              render={({ field, fieldState }) => (
                <CountrySelectInput
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label={t('accounts-application.second-citizenship')}
                  placeholder={t('accounts-application.second-citizenship')}
                  fullWidth
                />
              )}
            />
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  )
}
