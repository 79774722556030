import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const StyledHeaderWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '12px 20px',

  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  border: `1px solid ${grey[100]}`,

  display: 'flex',
  justifyContent: 'space-between',
}))
