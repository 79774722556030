import { styled, TableRow } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  clickable?: boolean
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'clickable',
})<IProps>(({ clickable }) => ({
  userSelect: 'none',

  ...(clickable && {
    cursor: 'pointer',
  }),

  '&:hover': {
    backgroundColor: grey[50],
  },
}))
