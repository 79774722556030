import { format } from 'date-fns'

import {
  IUpdateAccountBusinessApplicationData,
  TFormattedBusinessApplication,
} from '@common/types'

import { IBusinessApplicationForm } from '../types'

export function useConvertApplicationData() {
  const convertFormValuesToRequestData = (
    data: IBusinessApplicationForm
  ): IUpdateAccountBusinessApplicationData => {
    return {
      beneficiaries: data.beneficiaries.map((beneficiary) => ({
        ...beneficiary,
        birthDate: format(beneficiary.birthDate, 'yyyy-MM-dd'),
        addressZip: beneficiary.addressZip || '',
      })),
    }
  }

  const convertResponseDataToFormValues = (
    data: TFormattedBusinessApplication
  ): IBusinessApplicationForm => {
    return {
      beneficiaries:
        data?.beneficiaries.map((beneficiary) => {
          return {
            firstName: beneficiary.firstName,
            lastName: beneficiary.lastName,
            birthDate: new Date(beneficiary.birthDate),
            nationality: beneficiary.nationality,
            addressCity: beneficiary.addressCity,
            addressCountry: beneficiary.addressCountry,
            addressStreet: beneficiary.addressStreet,
            addressZip: beneficiary.addressZip || '',
            documents: [
              {
                issueCountry: beneficiary.documents[0].issueCountry,
                number: beneficiary.documents[0].number,
                type: beneficiary.documents[0].type,
              },
            ],
            email: beneficiary.email,
            personalNumber: beneficiary.personalNumber,
            phoneNumber: beneficiary.phoneNumber,
          }
        }) || [],
    }
  }

  return { convertFormValuesToRequestData, convertResponseDataToFormValues }
}
