import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { WithdrawalPage } from './ui'

export const WithdrawalPageRouter: RouteObject = {
  path: routePaths.withdrawals.withdrawal,
  element: <WithdrawalPage />,
}
