import { Stack, SxProps, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { IExpectedTurnoverForm } from '@entities/personal-account-application'

import { RadioGroupField, MultipleCountrySelectInput } from '@shared/ui'

interface IProps {
  type: 'income' | 'outgoing'
  sx?: SxProps
}

const turnovers = {
  'Up to 500 EUR': 'up-to-500-eur',
  'From 500 EUR to 1000 EUR': 'from-500-eur-to-1000-eur',
  'From 1000 EUR to 5000 EUR': 'from-1000-eur-to-5000-eur',
  'More than 5000 EUR': 'more-than-5000-eur',
} as const

const frequencies = {
  'Up to 5': 'up-to-5',
  'From 5 to 20': 'from-5-to-20',
  'From 20 to 50': 'from-20-to-50',
  'More than 50': 'more-than-50',
} as const

export function IncomeOutgoingFundsFields({ type, sx }: IProps) {
  const { t } = useTranslation('widgets')
  const { control } = useFormContext<IExpectedTurnoverForm>()

  const turnoverOptions = Object.entries(turnovers).map(([value, label]) => ({
    value,
    label: t(
      `personal-account-application.expected-turnover-form.turnover.${label}`
    ),
  }))

  const frequencyOptions = Object.entries(frequencies).map(
    ([value, label]) => ({
      value,
      label: t(
        `personal-account-application.expected-turnover-form.frequency.${label}`
      ),
    })
  )

  return (
    <Stack gap={3.5} sx={sx}>
      <Typography variant={'body2'}>
        <Trans
          i18nKey={
            type === 'income'
              ? 'personal-account-application.expected-turnover-form.please-enter-information-about-incoming-payments'
              : 'personal-account-application.expected-turnover-form.please-enter-information-about-outgoing-payments'
          }
          ns={'widgets'}
        />
      </Typography>

      <Controller
        control={control}
        name={type === 'income' ? 'incomeCountries' : 'outcomeCountries'}
        render={({ field, fieldState }) => (
          <MultipleCountrySelectInput
            {...field}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            label={t(
              'personal-account-application.expected-turnover-form.countries-to-work-with'
            )}
            placeholder={t(
              'personal-account-application.expected-turnover-form.countries-to-work-with'
            )}
            fullWidth
          />
        )}
      />

      <Controller
        control={control}
        name={type === 'income' ? 'incomeMonthly' : 'outcomeMonthly'}
        render={({ field, fieldState }) => (
          <RadioGroupField
            label={t(
              type === 'income'
                ? 'personal-account-application.expected-turnover-form.planned-monthly-turnover-of-incoming'
                : 'personal-account-application.expected-turnover-form.planned-monthly-turnover-of-outgoing'
            )}
            onChange={field.onChange}
            value={field.value}
            valueVariants={turnoverOptions}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name={
          type === 'income'
            ? 'incomeMonthlyFrequency'
            : 'outcomeMonthlyFrequency'
        }
        render={({ field, fieldState }) => (
          <RadioGroupField
            label={t(
              type === 'income'
                ? 'personal-account-application.expected-turnover-form.monthly-frequency-of-incoming-payments'
                : 'personal-account-application.expected-turnover-form.monthly-frequency-of-outgoing-payments'
            )}
            onChange={field.onChange}
            value={field.value}
            valueVariants={frequencyOptions}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </Stack>
  )
}
