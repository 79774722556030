import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IHomeAddressForm } from '@entities/personal-account-application'
import { useUploadFileSettings } from '@entities/upload-file-settings'

import { UploadFile } from '@shared/ui'

interface IProps {
  sx?: SxProps
}

export function ProofOfAddressField({ sx }: IProps) {
  const { t } = useTranslation(['widgets', 'shared'])
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const { control, setValue } = useFormContext<IHomeAddressForm>()

  const { data: uploadFileSettings } = useUploadFileSettings()

  return (
    <Box sx={sx}>
      <Typography variant="subtitle2" textTransform="uppercase" mb={2}>
        {t('personal-account-application.home-address-form.proof-of-address')}
      </Typography>

      <Typography variant="body2" mb={2.5}>
        {t(
          'personal-account-application.home-address-form.proof-of-address-subtitle'
        )}
      </Typography>

      {uploadFileSettings && (
        <Controller
          control={control}
          name="addressProof"
          render={({ field, fieldState }) => (
            <UploadFile
              error={!!fieldState.error}
              variant="tiny"
              uploadDocTitle={t(
                `shared:drag-n-drop.${mdAndUp ? 'upload-a-document' : 'tap-to-upload-file'}`
              )}
              underAreaText={t('shared:drag-n-drop.upload-a-document')}
              removeFile={() => setValue('addressProof', undefined)}
              data={{
                files: field.value ? [field.value] : null,
                saveFiles: (files) => field.onChange(files[0]),
                single: true,
                isRemote: false,
              }}
              uploadFileSettings={uploadFileSettings}
            />
          )}
        />
      )}
    </Box>
  )
}
