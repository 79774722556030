import { IFeePlan } from '@common/types'

import { instance } from '@shared/requester'

export async function fetchFeePlan(feePlanId?: string) {
  if (!feePlanId) {
    throw new Error()
  }

  return instance
    .get<IFeePlan>(`/settings/fee-plans/${feePlanId}`)
    .then((res) => res.data)
}
