import { SxProps } from '@mui/material'

import { IUploadFileSettings } from '@common/types'

import { UploadItemList, IUploadFile } from '@shared/ui'

import { DragNDropAreaWrapper } from './drag-n-drop-area-wrapper'

interface IProps {
  removeFile: (index: number) => void
  clickHandle: () => void
  fileList?: IUploadFile[]
  inputRef: React.MutableRefObject<HTMLInputElement | null>
  isRemote?: boolean
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void
  onSelectFiles: ({ target }: React.ChangeEvent<HTMLInputElement>) => void
  uploadDocTitle: string
  error?: boolean
  helperText?: string
  uploadFileSettings: IUploadFileSettings
  sx?: SxProps
}

export function UploadFileNormal({
  removeFile,
  onSelectFiles,
  fileList,
  onDrop,
  onDragOver,
  isRemote,
  inputRef,
  clickHandle,
  uploadDocTitle,
  uploadFileSettings,
  error,
  helperText,
  sx,
}: IProps) {
  return (
    <DragNDropAreaWrapper
      uploadDocTitle={uploadDocTitle}
      onClickUpload={clickHandle}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onSelectFiles={onSelectFiles}
      ref={inputRef}
      error={error}
      uploadFileSettings={uploadFileSettings}
      sx={sx}
      fileList={
        <UploadItemList
          error={error}
          helperText={helperText}
          fileList={fileList}
          removeFile={removeFile}
          isRemote={isRemote || false}
        />
      }
    />
  )
}
