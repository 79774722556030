import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TRiskLevel } from '@common/types'

import { grey } from '@shared/constants'

const successColor = 'success.main'
const errorColor = 'error.main'

export function useTransactionRiskOptions(riskLevel?: TRiskLevel) {
  const theme = useTheme()
  const { t } = useTranslation('entities')

  const colors: Record<TRiskLevel, string> = {
    LOW: successColor,
    MEDIUM: grey[400],
    HIGH: errorColor,
    NONE: successColor,
    SEVERE: errorColor,
  }

  const getRiskLevelText = () => {
    return riskLevel ? t(`transaction.risk-level.${riskLevel}`) : ''
  }

  const getRiskLevelColor = () => {
    return riskLevel ? colors[riskLevel] : theme.palette.error.main
  }

  return { getRiskLevelText, getRiskLevelColor }
}
