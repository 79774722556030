import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { TNotificationLimits } from '@common/types'
import { getNotificationLimitsForCentralWalletSchema } from '@common/validation'

import { useTFunc } from '@shared/hooks'

export function useNotificationLimitsForCentralWalletSettingsForm(
  defaultValues: TNotificationLimits
) {
  const tFunc = useTFunc()

  const schema = getNotificationLimitsForCentralWalletSchema(tFunc)

  const form = useForm<TNotificationLimits>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  return form
}
