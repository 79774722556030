import { useTranslation } from 'react-i18next'

import { TFormattedPersonalApplication } from '@common/types'

import { AccountApplicationCardTitle } from '@features/account'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

import { getCountryNameByAbbreviation } from '@shared/utils'

interface IProps {
  personalApplication: TFormattedPersonalApplication
}

export function ExpectedTurnover({ personalApplication }: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <GroupFieldsCard>
      <AccountApplicationCardTitle
        title={t('account-application.expectedTurnover')}
        sx={{ mb: 2 }}
      />

      <LabelValue
        label={t('account-application.purposeOfAccount')}
        value={personalApplication.purposeOfAccount}
      />

      <LabelValue
        label={t('account-application.incomePrimarySource')}
        value={personalApplication.incomePrimarySource}
      />

      <LabelValue
        label={t('account-application.incomeCountries')}
        value={personalApplication.incomeCountries
          .map((country: string) => getCountryNameByAbbreviation(country))
          .join(', ')}
      />

      <LabelValue
        label={t('account-application.incomeMonthly')}
        value={personalApplication.incomeMonthly}
      />

      <LabelValue
        label={t('account-application.incomeMonthlyFrequency')}
        value={personalApplication.incomeMonthlyFrequency}
      />

      <LabelValue
        label={t('account-application.outcomeCountries')}
        value={personalApplication.outcomeCountries
          .map((country: string) => getCountryNameByAbbreviation(country))
          .join(', ')}
      />

      <LabelValue
        label={t('account-application.outcomeMonthly')}
        value={personalApplication.outcomeMonthly}
      />

      <LabelValue
        label={t('account-application.outcomeMonthlyFrequency')}
        value={personalApplication.outcomeMonthlyFrequency}
      />
    </GroupFieldsCard>
  )
}
