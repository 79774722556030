import { TAccountStatus } from '@common/types'

import { instance } from '@shared/requester'

interface IUpdateAccountStatus {
  accountId: string
  status: TAccountStatus
}

export async function updateAccountStatus({
  accountId,
  status,
}: IUpdateAccountStatus) {
  return await instance.patch(`/accounts/${accountId}/status`, {
    status,
  })
}
