import { boolean, object, ObjectSchema, string } from 'yup'

import { TUpdateUserStatusConfigsForm } from '../../types'
import { defT } from '../validation-rules'

const minReasonLength = 15

export function getUpdateUserStatusConfigsFormSchema(tFunc?: typeof defT) {
  const t = tFunc || defT

  const schema: ObjectSchema<TUpdateUserStatusConfigsForm> = object({
    common: object({
      blocked: boolean().required(t('required')),
      blockedReason: string()
        .required(t('required'))
        .test(
          'min-chars',
          t('min-chars', { count: minReasonLength }),
          (value, context) =>
            context.parent.blocked ? value.length >= minReasonLength : true
        ),
    }).required(t('required')),
  })

  return schema
}
