import { Box, Stack, SxProps, Typography } from '@mui/material'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { IUploadFileSettings } from '@common/types'

import { formatBytes, formatAllowedFileTypes } from '@shared/utils'

import { ReactComponent as UploadCloud } from './assets/upload-cloud.svg'
import { StyledDragNDropAreaWrap } from './styled'

interface IProps {
  onClickUpload: () => void
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void
  onSelectFiles: (event: React.ChangeEvent<HTMLInputElement>) => void
  uploadDocTitle: string
  uploadFileSettings: IUploadFileSettings
  sx?: SxProps
}

export const DragNDropArea = forwardRef(function DragNDropArea(
  {
    onClickUpload,
    onDrop,
    onDragOver,
    onSelectFiles,
    uploadDocTitle,
    uploadFileSettings,
    sx,
  }: IProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { t } = useTranslation('shared')
  const allowedTypes = uploadFileSettings.allowedTypes

  return (
    <StyledDragNDropAreaWrap
      variant="outlined"
      onClick={onClickUpload}
      onDrop={onDrop}
      onDragOver={onDragOver}
      sx={sx}
    >
      <input
        onChangeCapture={onSelectFiles}
        ref={ref}
        type="file"
        name="files"
        accept={allowedTypes.join(',')}
        multiple
        hidden
      />

      <Stack justifyContent={'center'} alignItems={'center'} mb={1}>
        <UploadCloud />
      </Stack>

      <Box>
        <Typography variant={'body2'} component="p" textAlign="center" mb={0.5}>
          {uploadDocTitle}
        </Typography>

        <Typography
          variant={'body2'}
          textAlign="center"
          color={'text.secondary'}
        >
          {t('drag-n-drop.only-formats-like', {
            formats: formatAllowedFileTypes(allowedTypes),
            size: formatBytes(uploadFileSettings.maxFileSize),
          })}
        </Typography>
      </Box>
    </StyledDragNDropAreaWrap>
  )
})
