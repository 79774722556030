import { createContext, PropsWithChildren, useCallback, useState } from 'react'

interface ISizes {
  [key: string]: number
}

interface IObservers {
  [key: string]: ResizeObserver
}

type TObserveCallback = (blockId: string) => void

interface IResizeObserverContextType {
  observe: TObserveCallback
  unobserve: TObserveCallback
  sizes: ISizes
}

export const ResizeObserverContext =
  createContext<IResizeObserverContextType | null>(null)

export const ResizeObserverProvider = ({ children }: PropsWithChildren) => {
  const [sizes, setSizes] = useState<ISizes>({})
  const [observers, setObservers] = useState<IObservers>({})

  const observe = useCallback((blockId: string) => {
    const element = document.getElementById(blockId)

    if (element && !observers[blockId]) {
      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          setSizes((prev) => ({
            ...prev,
            [blockId]: entry.contentRect.width,
          }))
        })
      })

      resizeObserver.observe(element)

      setObservers((prev) => ({
        ...prev,
        [blockId]: resizeObserver,
      }))
    }
  }, [])

  const unobserve = useCallback((blockId: string) => {
    if (observers[blockId]) {
      observers[blockId].disconnect()

      setObservers((prev) => {
        const { [blockId]: removedObserver, ...rest } = prev

        return rest
      })
    }
  }, [])

  return (
    <ResizeObserverContext.Provider value={{ observe, unobserve, sizes }}>
      {children}
    </ResizeObserverContext.Provider>
  )
}
