import { useQuery } from '@tanstack/react-query'

import { TDepositLimits } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchDepositLimits } from '../../api'

export const useDepositLimits = () => {
  return useQuery<TDepositLimits>({
    queryKey: [queryKeys.depositLimits],
    queryFn: fetchDepositLimits,
    staleTime: Infinity,
  })
}
