import { styled } from '@mui/material'

import { convertStringToRGBColor } from '@shared/utils'

interface IProps {
  fullName: string
}

export const AbbreviationWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullName',
})<IProps>(({ fullName, theme }) => {
  const bgColor = convertStringToRGBColor(fullName)

  return {
    width: 64,
    height: 64,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: bgColor,
    borderRadius: '8px',

    color: theme.palette.getContrastText(bgColor),
    ...theme.typography.focus2,
  }
})
