import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  TUserStatusesConfig,
  TUpdateUserStatusConfigsForm,
  EUserConfigKeys,
} from '@common/types'
import { getUpdateUserStatusConfigsFormSchema } from '@common/validation'

import { useTFunc } from '@shared/hooks'

import { useMutateStatusConfigs } from '../../model'

import { CommonUserStatusesAccordion } from './common-user-statuses-accordion'

interface IProps {
  statusConfigs: Pick<TUserStatusesConfig, 'value' | 'userId'>
}

export function ChangeUserStatuses({ statusConfigs }: IProps) {
  const { t } = useTranslation('features')

  const tFunc = useTFunc()

  const schema = getUpdateUserStatusConfigsFormSchema(tFunc)

  const form = useForm<TUpdateUserStatusConfigsForm>({
    resolver: yupResolver(schema),
    defaultValues: statusConfigs.value,
  })

  const { handleSubmit, reset } = form

  const { mutate: mutateStatusConfigs, isPending } = useMutateStatusConfigs(
    reset,
    statusConfigs.userId
  )

  const handleMutate = (formData: TUpdateUserStatusConfigsForm) =>
    mutateStatusConfigs({
      key: EUserConfigKeys.STATUSES,
      value: {
        common: {
          blocked: formData.common.blocked,
          blockedReason: formData.common.blocked
            ? formData.common.blockedReason
            : 'No reason',
        },
      },
      userId: statusConfigs.userId || '',
    })

  return (
    <FormProvider {...form}>
      <Stack gap={2} component="form" onSubmit={handleSubmit(handleMutate)}>
        <Typography variant="h5" textAlign="center">
          {t('user.danger-actions.statuses.title')}
        </Typography>

        <CommonUserStatusesAccordion />

        <LoadingButton
          type="submit"
          loading={isPending}
          fullWidth
          variant="contained"
        >
          {t('user.danger-actions.statuses.button')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}
