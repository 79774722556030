import { Box, Dialog, useMediaQuery, useTheme } from '@mui/material'
import { to } from 'await-to-js'
import { Fragment, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  TOnCloseMethod,
  TOtpConfirmationMethod,
  TOtpResendCodeMethod,
  validateChar as validateCharDefault,
  IOtpDialogForm,
} from '../model'

import { Actions, CloseButton, OtpInput } from './components'
import { ContentWrapper, DialogWrapper, ErrorText, Title } from './styled'

interface IProps {
  onChange?: (value: string) => void
  validateChar?: (value: string, index: number) => boolean
  onTimerFinish?: () => void
  confirmationMethod?: TOtpConfirmationMethod
  resendCodeMethod?: TOtpResendCodeMethod
  onConfirm?: () => void

  show?: boolean
  onClose?: TOnCloseMethod
  length?: number
  timer?: number
}

/**
 * OTP Dialog
 * @param {boolean} show - show dialog
 * @param {TOnCloseMethod} onClose - close dialog callback
 * @param {number} length - length of OTP code
 * @param {Function} onChange - callback on change OTP code
 * @param {Function} validateChar - validate char in OTP code
 * @param {number} timer - timer for resend code
 * @param {Function} onTimerFinish - callback on timer finish
 * @param {Function} confirmationMethod - method for confirmation OTP code
 * @param {Function} resendCodeMethod - method for resend OTP code
 * @param {Function} onConfirm - callback on confirm OTP code
 *
 * This component for send OTP code and confirm it
 * Please use it with model
 *
 * Required model:
 * TConfirmationMethod - method for confirmation OTP code
 * TResendCodeMethod - method for resend OTP code
 * TOnCloseMethod - callback on close dialog
 */
export function OtpDialog({
  show = true,
  onClose,
  length = 6,
  onChange,
  validateChar,
  timer = 120,
  onTimerFinish,
  confirmationMethod,
  resendCodeMethod,
  onConfirm,
}: IProps) {
  const [showResend, setShowResend] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation('shared')
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  const { control, handleSubmit, setValue, reset, setError, resetField } =
    useForm<IOtpDialogForm>({
      defaultValues: {
        otp: '',
      },
      mode: 'onSubmit',
    })

  const handleClose: TOnCloseMethod = (target) => {
    if (onClose) {
      setTimeout(reset, 1000)

      onClose(target)
    }
  }

  const handleChange = (value: string) => {
    setValue('otp', value)

    if (onChange) {
      onChange(value)
    }
  }

  const submitHandler = async (form: IOtpDialogForm) => {
    try {
      if (!confirmationMethod) {
        // eslint-disable-next-line no-console
        return console.error('OTPDialog: confirmationMethod is required')
      }

      setLoading(true)

      const [error, result] = await to(confirmationMethod(form))

      setLoading(false)

      if (error || !result) {
        toast.error(t('otp dialog.errors.failedSendOtpCode'))

        handleClose('sendOtpFail')

        return
      }

      if (result === 'EXPIRE') {
        setShowResend(true)

        return
      }

      if (result === 'NOT_VALID') {
        resetField('otp')

        setError('otp', {
          message: t('otp dialog.errors.notValid'),
        })

        return
      }

      if (result === 'SUCCESS') {
        onConfirm && onConfirm()

        handleClose('sendOtpSuccess')
      }
    } catch {
      handleClose('sendOtpFail')
    }
  }

  return (
    <Fragment>
      <Dialog
        open={show}
        onClose={(_event, target) => handleClose(target)}
        PaperComponent={DialogWrapper}
      >
        {smAndUp && (
          <CloseButton
            onClose={() => handleClose('closeButton')}
            sx={{ position: 'absolute', right: 24, top: 29 }}
          />
        )}

        <ContentWrapper onSubmit={handleSubmit(submitHandler)}>
          <Title>
            <Trans ns="shared" i18nKey="otp dialog.title" />
          </Title>

          <Controller
            control={control}
            name="otp"
            rules={{
              minLength: {
                value: length,
                message: t('otp dialog.errors.min', {
                  count: length,
                }),
              },
              required: {
                value: true,
                message: t('otp dialog.errors.required'),
              },
            }}
            render={({ field, fieldState }) => (
              <Box>
                <OtpInput
                  onBlur={field.onBlur}
                  value={field.value}
                  onChange={handleChange}
                  error={!!fieldState.error}
                  length={length}
                  validateChar={validateChar || validateCharDefault}
                  disabled={loading || showResend}
                />

                {!!fieldState.error && (
                  <ErrorText sx={{ mt: 2 }}>
                    {fieldState.error.message}
                  </ErrorText>
                )}
              </Box>
            )}
          />

          <Actions
            handleClose={handleClose}
            loading={loading}
            reset={reset}
            setLoading={setLoading}
            setShowResend={setShowResend}
            showResend={showResend}
            timer={timer}
            onTimerFinish={onTimerFinish}
            resendCodeMethod={resendCodeMethod}
          />
        </ContentWrapper>
      </Dialog>
    </Fragment>
  )
}
