import { Box } from '@mui/material'

import { ITemplateProps } from './index'

export function ShortTemplate({ data }: ITemplateProps<string>) {
  return (
    <Box component="span" display="block" width={100} className="ellipsis">
      {data}
    </Box>
  )
}
