import { useTheme, Typography, Stack } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { TUserTermsConfig } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'
import { IInfoSectionRow, SecondaryInfoSection, StatusChip } from '@shared/ui'

import { userInfoSectionId } from '../../../model'

import { ConfigItemWrapper } from './styled'

export function ConfigTermsAndPrivacy({
  createdAt,
  id,
  updatedAt,
  value,
}: Omit<TUserTermsConfig, 'key' | 'userId' | 'accountId'>) {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const boxMdAndUp = useBoxMediaQuery(userInfoSectionId).up('md')

  const mainConfigInfoTemplate = [
    {
      label: t('user.configs.id'),
      value: id,
      copyable: true,
    },
    {
      label: t('user.configs.created'),
      value: format(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss'),
    },
    {
      label: t('user.configs.updated'),
      value: format(new Date(updatedAt), 'dd.MM.yyyy HH:mm:ss'),
    },
  ] satisfies IInfoSectionRow[]

  return (
    <ConfigItemWrapper gap={1}>
      <Typography variant="h5">
        {t('user.configs.main-terms-and-privacy-info')}
      </Typography>

      <SecondaryInfoSection
        info={mainConfigInfoTemplate}
        boxId={userInfoSectionId}
      />

      <Stack
        gap={2}
        direction="row"
        alignItems="center"
        px={boxMdAndUp ? '20px' : '16px'}
      >
        <Typography variant="body2">
          {t('user.configs.terms-and-privacy-was-singed')}
        </Typography>

        <StatusChip
          circleColor={
            value.blocked
              ? theme.palette.error.main
              : theme.palette.success.main
          }
          text={value.blocked ? t('user.configs.no') : t('user.configs.yes')}
          variant="filled"
        />
      </Stack>
    </ConfigItemWrapper>
  )
}
