import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IAccountCryptoBalance } from '@common/types'

import { IComposeTableCellTemplate, IMobileListItem } from '@shared/ui'

export function useAccountCryptoBalancesTemplate(
  cryptoBalances: IAccountCryptoBalance[]
) {
  const { t } = useTranslation('widgets')

  const labelUpdated = t('account-page.crypto-balances.table-header.updated')
  const labelCreated = t('account-page.crypto-balances.table-header.created')
  const labelCurrent = t('account-page.crypto-balances.table-header.current')

  const labelAvailable = t(
    'account-page.crypto-balances.table-header.available'
  )

  const desktopTemplate: IComposeTableCellTemplate<IAccountCryptoBalance>[] = [
    {
      key: 'id',
      title: t('account-page.crypto-balances.table-header.id'),
      component: 'hiddenMiddle',
    },
    {
      key: 'asset',
      title: t('account-page.crypto-balances.table-header.asset'),
      component: 'default',
    },

    {
      key: 'available',
      title: labelAvailable,
      component: 'default',
    },
    {
      key: 'current',
      title: labelCurrent,
      component: 'default',
    },
    {
      key: 'updatedAt',
      title: labelUpdated,
      component: 'dateTime',
    },
    {
      key: 'createdAt',
      title: labelCreated,
      component: 'dateTime',
    },
  ]

  const mobileTemplate: IMobileListItem[] = cryptoBalances.map(
    ({ createdAt, id, asset, available, current, updatedAt }) => {
      return {
        id: id,
        firstRow: [asset],
        middleRows: [
          `${labelAvailable}: ${available}`,
          `${labelCurrent}: ${current}`,
          `${labelUpdated}: ${format(new Date(updatedAt), 'dd.MM.yyyy HH:mm')}`,
          `${labelCreated}: ${format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}`,
        ],
        lastRow: [
          <Typography
            variant="body1"
            color="text.secondary"
            key={1}
            className="ellipsis"
          >
            {`id: ${id}`}
          </Typography>,
        ],
      } satisfies IMobileListItem
    }
  )

  return { desktopTemplate, mobileTemplate }
}
