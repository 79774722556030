import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Stack,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ITransactionDetails } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'
import { SecondaryInfoWrapper, SecondaryInfoColumn } from '@shared/ui'

import {
  mainTransactionPagePartId,
  useReportAlertInfoTemplates,
} from '../../model'

interface IProps {
  reports?: ITransactionDetails['analysisReports']
}

export function AnalysisReports({ reports }: IProps) {
  const { t } = useTranslation('pages')

  const createReportAlertInfoTemplate = useReportAlertInfoTemplates()
  const boxMdAndUp = useBoxMediaQuery(mainTransactionPagePartId).up('md')
  const boxSmAndUp = useBoxMediaQuery(mainTransactionPagePartId).up('sm')

  return (
    <Accordion
      sx={{
        '&:before': { content: 'none' },
        '& .MuiAccordionSummary-expandIconWrapper': { top: 'initial' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h5">{t('transaction.reports')}</Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
      >
        {reports?.map(({ alerts }, index) => (
          <Stack gap={1.5} key={index}>
            {alerts.map((alert, alertIndex) => {
              const template = createReportAlertInfoTemplate(alert)

              return (
                <SecondaryInfoWrapper
                  key={alertIndex}
                  boxMdAndUp={boxMdAndUp}
                  boxSmAndUp={boxSmAndUp}
                >
                  <SecondaryInfoColumn
                    info={template}
                    boxId={mainTransactionPagePartId}
                  />
                </SecondaryInfoWrapper>
              )
            })}
          </Stack>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
