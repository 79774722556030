import { object, ObjectSchema } from 'yup'

import { TAsset, TNotificationLimits, assets } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

export const getNotificationLimitsForCentralWalletSchema = (
  tFunc?: typeof defT
) => {
  const t = tFunc || defT
  const { numberSchema } = getValidationSchemas(tFunc)

  const redLevelAssetsSchema = object(
    assets.reduce(
      (acc, asset) => {
        acc[asset] = numberSchema.required(t('required'))

        return acc
      },
      {} as Record<TAsset, typeof numberSchema>
    )
  )

  const yellowLevelAssetsSchema = object(
    assets.reduce(
      (acc, asset) => {
        acc[asset] = numberSchema
          .test(
            `moreThanRed${asset}`,
            t('greater-than', { targetValue: `"red level ${asset}"` }),
            (value, context) => {
              const redLimit = context.from?.find((item) => 'red' in item.value)
                ?.value.red[asset]

              return !!redLimit && value > redLimit
            }
          )
          .required(t('required'))

        return acc
      },
      {} as Record<TAsset, typeof numberSchema>
    )
  )

  const schema: ObjectSchema<TNotificationLimits> = object({
    red: redLevelAssetsSchema,
    yellow: yellowLevelAssetsSchema,
  })

  return schema
}
