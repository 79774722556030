import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IEmailTemplate, TUpdateEmailTemplateBody } from '@common/types'

import { EditableTypography } from '@shared/ui'

import {
  useUpdateEmailTemplateForm,
  useUpdateEmailTemplateMutation,
} from '../model'

import { EmailTemplateEditorWithPreview } from './components'

interface IProps {
  template: IEmailTemplate
}

export function EmailTemplateDetails({ template }: IProps) {
  const { t } = useTranslation('widgets')

  const { form, minSubjectLength } = useUpdateEmailTemplateForm(template)

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  const { mutate, isPending } = useUpdateEmailTemplateMutation()

  const onSubmit = async (data: TUpdateEmailTemplateBody) => {
    mutate({ id: template.id, ...data })
  }

  useEffect(() => {
    reset(template)
  }, [template])

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" gap={5} alignItems="center">
        <Typography variant="body1">
          {`${t('settings.email-templates.template-name')} ${template?.name}`}
        </Typography>

        <Stack direction="row" gap={1} height={52} alignItems="center">
          <Typography variant="body1">
            {t('settings.email-templates.subject')}
          </Typography>

          <Controller
            control={control}
            name="subject"
            render={({ field }) => (
              <EditableTypography
                onChange={field.onChange}
                value={field.value}
                minLength={minSubjectLength}
                variant="body1"
                sx={{ width: 400 }}
              />
            )}
          />
        </Stack>
      </Stack>

      <Controller
        control={control}
        name="html"
        render={({ field, fieldState }) => (
          <EmailTemplateEditorWithPreview
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <LoadingButton
        variant="contained"
        fullWidth
        loading={isPending}
        disabled={!isDirty}
        type="submit"
      >
        {t('settings.email-templates.button')}
      </LoadingButton>
    </Box>
  )
}
