import { Box, Fade, SxProps, Typography, useTheme } from '@mui/material'
import { useMemo, useRef, useState, useEffect } from 'react'

import Logo from '@shared/assets/logo.svg'
import { maxSidebarWidth, minSidebarWidth } from '@shared/constants'

import { useSidebarStore } from '../model'

import { RollButton, SidebarMenuList } from './components'
import {
  BorderBackground,
  DesktopSidebarBox,
  StickyWrapper,
  LogoWrapper,
} from './styled'

interface IProps {
  sx?: SxProps
}

export function Sidebar({ sx }: IProps) {
  const { open, setOpen } = useSidebarStore()
  const [hover, setHover] = useState(false)
  const timeout = useRef<null | NodeJS.Timeout>(null)
  const theme = useTheme()

  const [rollButtonHover, setRollButtonHover] = useState(false)
  const [hideRollButton, setHideRollButton] = useState(false)
  const [showContent, setShowContent] = useState(false)

  const wrapperSx: SxProps | undefined = useMemo(
    () => ({
      width: open ? maxSidebarWidth : minSidebarWidth,
      transition: theme.transitions.create('width'),
    }),
    [open, theme]
  )

  const stickyWrapperWidth = useMemo(() => {
    return open ? maxSidebarWidth : minSidebarWidth
  }, [open])

  const borderBackgroundSx: SxProps | undefined = useMemo(() => {
    return {
      ...(rollButtonHover && { filter: 'blur(2px)' }),
    }
  }, [rollButtonHover])

  const closeSidebar = () => {
    setOpen(false)

    mouseLeaveRollButton()

    setHover(false)

    setRollButtonHover(false)

    setHideRollButton(true)

    setTimeout(() => {
      setHideRollButton(false)
    }, 100)
  }

  const mouseLeaveRollButton = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }

  const isSidebarContentVisible = useMemo(
    () => open || rollButtonHover,
    [open, rollButtonHover]
  )

  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => setShowContent(true), 300)

      return () => clearTimeout(timeoutId)
    } else {
      setShowContent(false)
    }
  }, [open])

  return (
    <StickyWrapper minWidth={stickyWrapperWidth} sx={sx}>
      <Box
        width={'100%'}
        height={'100%'}
        onMouseOver={() => {
          !hideRollButton && setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)

          setRollButtonHover(false)
        }}
        sx={wrapperSx}
      >
        <BorderBackground sx={borderBackgroundSx} />

        <DesktopSidebarBox>
          <Fade in={hover || showContent}>
            <div>
              <RollButton
                open={open}
                setOpen={setOpen}
                onClose={closeSidebar}
              />
            </div>
          </Fade>

          <LogoWrapper open={isSidebarContentVisible} href={'/'}>
            <Box component={'img'} src={Logo} width={'100%'} />

            <Typography
              color={'info.main'}
              textAlign={'end'}
              variant={'body1'}
              sx={{
                fontSize: isSidebarContentVisible
                  ? { xs: 16, md: 18 }
                  : { xs: 10 },
                transition: theme.transitions.create('font-size'),
              }}
            >
              Backoffice
            </Typography>
          </LogoWrapper>

          <SidebarMenuList
            visibleContent={isSidebarContentVisible}
            open={open}
            hover={rollButtonHover}
            sx={{ p: 0, flex: 1, display: 'flex', flexDirection: 'column' }}
          />
        </DesktopSidebarBox>
      </Box>
    </StickyWrapper>
  )
}
