import { Stack, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const ConfigItemWrapper = styled(Stack)(() => ({
  overflow: 'hidden',
  padding: '16px',
  border: '1px solid',
  borderColor: grey[100],
  borderRadius: '16px',
}))
