import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@shared/constants'

import { fetchEmailTemplateById } from '../../api'

export function useEmailTemplate(id?: string | null) {
  return useQuery({
    queryKey: [queryKeys.emailTemplate, id],
    queryFn: () => fetchEmailTemplateById(id),
    staleTime: Infinity,
    enabled: !!id,
  })
}
