import { useTranslation } from 'react-i18next'

import { TransactionSettingsForm } from '@widgets/settings'

import { useTransactionSettings } from '@entities/transaction'

import { SettingLayout } from './components'

export function TransactionSettings() {
  const { t } = useTranslation('pages')
  const { data, isLoading } = useTransactionSettings()

  return (
    <SettingLayout
      data={!!data}
      loading={isLoading}
      title={t('settings.transaction-settings')}
    >
      {data && <TransactionSettingsForm data={data} />}
    </SettingLayout>
  )
}
