import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: 8,
  minWidth: 'initial',
  borderRadius: 100,
  '& .MuiLoadingButton-loadingIndicator': {
    color: theme.palette.primary.main,
  },
}))
