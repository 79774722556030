import { Chip, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IUserWithStatuses } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'
import { StatusChip } from '@shared/ui'

import { userPageId } from '../../../model'

export function UserMainInfoChips({
  active,
  blocked,
  id,
}: Partial<Pick<IUserWithStatuses, 'active' | 'blocked' | 'id'>>) {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const mdAndUp = useBoxMediaQuery(userPageId).up('md')

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      {mdAndUp && (
        <>
          <Chip label={`ID: ${id}`} />

          <StatusChip
            circleColor={
              active ? theme.palette.success.main : theme.palette.error.main
            }
            text={active ? t('user.active') : t('user.deactivated')}
          />

          {blocked && (
            <StatusChip
              circleColor={theme.palette.error.main}
              text={t('user.blocked')}
            />
          )}
        </>
      )}

      {!mdAndUp && (
        <>
          <Typography variant="body2">{`ID: ${'...' + id?.slice(-6)}`}</Typography>

          <StatusChip
            circleColor={
              active ? theme.palette.success.main : theme.palette.error.main
            }
            text={active ? t('user.active') : t('user.deactivated')}
            variant="text"
          />

          {blocked && (
            <StatusChip
              circleColor={theme.palette.error.main}
              text={t('user.blocked')}
              variant="text"
            />
          )}
        </>
      )}
    </Stack>
  )
}
