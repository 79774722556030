import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { UserPage } from './ui'

export const UserRouter: RouteObject = {
  path: routePaths.users.user,
  element: <UserPage />,
}
