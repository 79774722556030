import { Stack, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { ReactNode } from 'react'

interface IProps {
  icon: ReactNode
  title: string
  titleVariant?: Variant
}

export function GroupSectionTitle({
  icon,
  title,
  titleVariant = 'h5',
}: IProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {icon}

      <Typography variant={titleVariant} fontWeight={700}>
        {title}
      </Typography>
    </Stack>
  )
}
