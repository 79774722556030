import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { additionalInfoListOfAccount } from './model'
import {
  AccountPage,
  AccountTopUpsTable,
  // AccountWithdrawsTable,
  AccountExchangesTable,
  AccountCryptoBalanceList,
} from './ui'

const mainPath = routePaths.accounts.account

export const AccountPageRouter: RouteObject = {
  path: mainPath,
  element: <AccountPage />,
  children: [
    {
      path: `${mainPath}/${additionalInfoListOfAccount.cryptoBalances}`,
      element: <AccountCryptoBalanceList />,
    },
    {
      path: `${mainPath}/${additionalInfoListOfAccount.deposits}`,
      element: <AccountTopUpsTable />,
    },
    // {
    //   path: `${mainPath}/${accountLists.withdraws}`,
    //   element: <AccountWithdrawsTable />,
    // },
    {
      path: `${mainPath}/${additionalInfoListOfAccount.exchanges}`,
      element: <AccountExchangesTable />,
    },
    {
      path: `${mainPath}/${additionalInfoListOfAccount.transactions}`,
      element: <></>,
    },
  ],
}
