import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TCurrency } from '@common/types'

import { ShortInfoBarItemWrapper } from '@shared/ui'
import { currenciesIcons } from '@shared/utils'

interface IProps {
  currency?: TCurrency
  availableAmount: string
  amount: string
}

export function FiatBalanceShortInfoItem({
  currency = 'EUR',
  amount,
  availableAmount,
}: IProps) {
  const { t } = useTranslation('features')

  return (
    <ShortInfoBarItemWrapper sx={{ flexDirection: 'column', gap: 1.5 }}>
      <Typography variant={'h5'} color={'primary.main'}>
        {t('balance.fiat-balances')}
      </Typography>

      <Stack justifyContent={'space-between'}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography className="first-letter-up" variant="body2">
            {t('balance.current')}:
          </Typography>

          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant="h6">{amount}</Typography>

            <Box display={'flex'} alignItems={'flex-start'} gap={1}>
              {currenciesIcons[currency]} {currency}
            </Box>
          </Box>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography className="first-letter-up" variant="body2">
            {t('balance.available')}:
          </Typography>

          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant="h6">{availableAmount}</Typography>

            <Box display={'flex'} alignItems={'flex-start'} gap={1}>
              {currenciesIcons[currency]} {currency}
            </Box>
          </Box>
        </Box>
      </Stack>
    </ShortInfoBarItemWrapper>
  )
}
