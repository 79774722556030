import { IconButton, styled } from '@mui/material'

interface IProps {
  href: string
  target: string
}

export const StyledIconButton = styled(IconButton)<IProps>(({ theme }) => ({
  minWidth: 20,
  '& .MuiSvgIcon-root': {
    color: theme.palette.info.main,
    width: 20,
    height: 20,
  },
}))
