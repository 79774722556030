import * as yup from 'yup'

import { TPersonDocumentType } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

export const getUpdateAccountBusinessApplicationSchema = (
  tFunc?: typeof defT
) => {
  const t = tFunc || defT

  const {
    getGeneralFieldSchema,
    countrySchema,
    phoneNumbersSchema,
    birthDateStringSchema,
    birthDateSchema,
    emailSchema,
    zipCodeSchema,
    minArrayItems,
  } = getValidationSchemas(t)

  const documentsSchema = yup
    .array()
    .required(t('required'))
    .min(minArrayItems, t('min-array-items_interval', { count: minArrayItems }))
    .of(
      yup
        .object({
          type: yup
            .string()
            .oneOf<TPersonDocumentType>(['PASSPORT', 'ID'], t('invalid-value'))
            .required(t('required')),
          number: getGeneralFieldSchema(),
          issueCountry: countrySchema,
        })
        .required(t('required'))
        .noUnknown(true)
    )

  const apiBeneficiarySchema = yup
    .object()
    .shape({
      id: yup.string().optional(),
      firstName: getGeneralFieldSchema(),
      lastName: getGeneralFieldSchema(),
      birthDate: birthDateStringSchema,
      nationality: countrySchema,
      personalNumber: getGeneralFieldSchema(),
      documents: documentsSchema,
      addressCountry: countrySchema,
      addressCity: getGeneralFieldSchema(),
      addressStreet: getGeneralFieldSchema(),
      addressZip: zipCodeSchema,
      phoneNumber: phoneNumbersSchema,
      email: emailSchema,
    })
    .noUnknown(true)

  const apiBeneficiariesSchema = yup
    .array()
    .required(t('required'))
    .min(minArrayItems, t('min-array-items_interval', { count: minArrayItems }))
    .of(apiBeneficiarySchema)

  const frontBeneficiarySchema = apiBeneficiarySchema.concat(
    yup.object().shape({
      birthDate: birthDateSchema,
    })
  )

  const frontBeneficiariesSchema = yup
    .array()
    .required(t('required'))
    .min(minArrayItems, t('min-array-items_interval', { count: minArrayItems }))
    .of(frontBeneficiarySchema)

  return { frontBeneficiariesSchema, apiBeneficiariesSchema }
}
