import { useQuery } from '@tanstack/react-query'

import { IFeePlan } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchFeePlan } from '../api'

interface IProps {
  feePlanId?: string
}

export const useFeePlan = ({ feePlanId }: IProps) => {
  return useQuery<IFeePlan | null>({
    queryKey: [queryKeys.feePlanDetails, feePlanId],
    queryFn: () => (feePlanId !== undefined ? fetchFeePlan(feePlanId) : null),
    enabled: feePlanId !== 'NEW',
  })
}
