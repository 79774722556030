import { Container, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'

import { settingPageId } from '@widgets/settings'

import { routePaths } from '@shared/constants'
import { CustomTabs } from '@shared/ui'

import {
  additionalRoutesOfSettings,
  TAdditionalRoutesOfSettings,
} from '../model'

const mainPath = routePaths.settings.list

export function SettingsPage() {
  const { t } = useTranslation('pages')

  const tabs = () => {
    return Object.entries(additionalRoutesOfSettings).map(([key, path]) => ({
      label: t(`settings.tab-names.${key as TAdditionalRoutesOfSettings}`),
      path: `${mainPath}/${path}`,
    }))
  }

  return (
    <Container maxWidth={false}>
      <Paper sx={{ padding: { md: '16px 24px 28px' } }} id={settingPageId}>
        <Typography variant="h4" mb={4}>
          {t('settings.title')}
        </Typography>

        <CustomTabs tabsRoutes={tabs()} />
        <Outlet />
      </Paper>
    </Container>
  )
}
