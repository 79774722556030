import { SxProps } from '@mui/material'
import React, { useRef } from 'react'

import { IUploadFileSettings } from '@common/types'

import { IUploadFile } from '@shared/ui'

import { getFormattedFileList, useLocalFileUpload } from '../model'

import { UploadFileTiny, UploadFileNormal } from './components'

interface ILocalFileUploadProps {
  files: File[] | null
  saveFiles: (files: File[]) => void
  single?: boolean
  isRemote?: false
}

interface ICustomFileUpload {
  filesCustom: IUploadFile[]
  dragOverHandleCustom: (event: React.DragEvent<HTMLDivElement>) => void
  selectFieldsHandleCustom: ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => void
  dropHandleCustom: (event: React.DragEvent<HTMLDivElement>) => void
  isRemote: true
}

interface IProps {
  removeFile: (index: number) => void
  data: ICustomFileUpload | ILocalFileUploadProps
  variant?: 'tiny' | 'normal'
  uploadDocTitle: string
  error?: boolean
  helperText?: string
  sx?: SxProps
  underAreaText?: string
  uploadFileSettings: IUploadFileSettings
}

export function UploadFile({
  removeFile,
  data,
  variant = 'normal',
  uploadDocTitle,
  error,
  helperText,
  sx,
  underAreaText,
  uploadFileSettings,
}: IProps) {
  const inputRef = useRef<null | HTMLInputElement>(null)

  let fileList: IUploadFile[] | undefined
  let single: boolean = true

  let saveFiles: (files: File[]) => void = () => {}

  if (data.isRemote) {
    fileList = data.filesCustom
  } else {
    fileList = data.files?.[0] && getFormattedFileList(data.files)

    saveFiles = data.saveFiles

    single = data.single || false
  }

  function clickHandle() {
    inputRef.current?.click()
  }

  const { dragOverHandle, selectFilesHandle, dropHandle } = useLocalFileUpload({
    saveFiles,
    single,
    fileList,
    ...uploadFileSettings,
  })

  return (
    <>
      {variant === 'tiny' && (
        <UploadFileTiny
          uploadFileSettings={uploadFileSettings}
          underAreaText={underAreaText}
          uploadDocTitle={uploadDocTitle}
          isRemote={data.isRemote}
          error={error}
          helperText={helperText}
          removeFile={removeFile}
          onDrop={data.isRemote ? data.dropHandleCustom : dropHandle}
          onDragOver={
            data.isRemote ? data.dragOverHandleCustom : dragOverHandle
          }
          onSelectFiles={
            data.isRemote ? data.selectFieldsHandleCustom : selectFilesHandle
          }
          clickHandle={clickHandle}
          fileList={fileList}
          inputRef={inputRef}
          sx={sx}
        />
      )}

      {variant === 'normal' && (
        <UploadFileNormal
          uploadDocTitle={uploadDocTitle}
          error={error}
          isRemote={data.isRemote}
          removeFile={removeFile}
          clickHandle={clickHandle}
          fileList={fileList}
          inputRef={inputRef}
          uploadFileSettings={uploadFileSettings}
          onDrop={data.isRemote ? data.dropHandleCustom : dropHandle}
          onDragOver={
            data.isRemote ? data.dragOverHandleCustom : dragOverHandle
          }
          onSelectFiles={
            data.isRemote ? data.selectFieldsHandleCustom : selectFilesHandle
          }
        />
      )}
    </>
  )
}
