import { TableCell } from '@mui/material'

import { IComposeTableCellTemplate } from '@shared/ui'

import { getValueByPath } from '../utils'

import { TableCellData } from './table-cell-data'

interface IProps<T> {
  template: IComposeTableCellTemplate<T>[]
  row: T
}

export function TableCellList<T>({ template, row }: IProps<T>) {
  return (
    <>
      {template.map((item, index) => (
        <TableCell key={index}>
          <TableCellData
            item={item}
            value={getValueByPath(row, item.key)[0]}
            additionalValue={
              getValueByPath(row, item.key, item.additionalKey)[1]
            }
            row={row}
          />
        </TableCell>
      ))}
    </>
  )
}
