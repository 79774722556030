import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { routePaths, TParamsKeys } from '@shared/constants'
import { CustomBreadcrumbs } from '@shared/ui'

interface IProps {
  sx?: SxProps
}

export function ExchangeBreadcrumbs({ sx }: IProps) {
  const location = useLocation()
  const { exchangeId } = useParams<TParamsKeys>()
  const { t } = useTranslation(['pages', 'shared'])

  const pathnames = location.pathname.split('/').filter((path) => path)

  const breadcrumbNameMap = {
    [routePaths.exchanges.list]: t('exchange-list.title'),
    [`${routePaths.exchanges.list}/${exchangeId}`]: t('exchange.exchange'),
  }

  return (
    <CustomBreadcrumbs
      breadcrumbNameMap={breadcrumbNameMap}
      pathnames={pathnames}
      sx={sx}
    />
  )
}
