import { styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  hiddenButtons?: boolean
}

export const StatusApproveRejectWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hiddenButtons',
})<IProps>(({ hiddenButtons }) => ({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  padding: '8px',
  borderRadius: 12,
  borderColor: grey[100],
  borderWidth: 1,
  borderStyle: 'solid',

  ...(hiddenButtons && {
    padding: '8px 12px',
  }),
}))
