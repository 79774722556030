import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { IFeePlan, TFeePlanForm } from '@common/types'
import { getFeePlanSchema } from '@common/validation'

import { createFeePlan, updateFeePlan } from '@entities/fee-plans'

import { queryKeys, routePaths, TParamsKeys } from '@shared/constants'
import { useTFunc } from '@shared/hooks'

interface IProps {
  editable: boolean
  defaultData: IFeePlan
  setEditable: (editable: boolean) => void
}

export function useFeePlanForm({ defaultData, editable, setEditable }: IProps) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [activeField, setActiveField] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { feePlanId } = useParams<TParamsKeys>()
  const { t } = useTranslation('widgets')
  const tFunc = useTFunc()

  const { feePlanSchema } = getFeePlanSchema(tFunc)

  const form = useForm<TFeePlanForm>({
    resolver: yupResolver(feePlanSchema),
    defaultValues: defaultData,
  })

  const { control, handleSubmit, reset, formState } = form
  const { isValid, isDirty } = formState

  const {
    fields: scales,
    append: appendScale,
    remove: removeScale,
  } = useFieldArray({
    control,
    name: 'scales',
  })

  const handleReturnToList = () => {
    navigate(routePaths.settings.feePlan.list)
  }

  const handleAddScale = () => {
    setActiveField('scales')

    if (!editable) {
      setEditable(true)
    }

    appendScale({
      min: scales[scales.length - 1].max + 1 / 100,
      max: scales[scales.length - 1].max + 2 / 100,
      percents: 0,
    })
  }

  const handleCancel = () => {
    if (!defaultData.id) {
      reset()

      handleReturnToList()

      return
    }

    reset()

    setActiveField('')

    setEditable(false)
  }

  const handleUpdateFeePlan = async (data: TFeePlanForm) => {
    const response = await updateFeePlan(data, feePlanId)

    queryClient.setQueryData([queryKeys.feePlanDetails, feePlanId], response)

    return response
  }

  const handleCreateFeePlan = async (data: TFeePlanForm) => {
    const response = await createFeePlan(data)

    const createdFeePlanId = response.id

    queryClient.setQueryData(
      [queryKeys.feePlanDetails, createdFeePlanId],
      response
    )

    navigate(`${routePaths.settings.feePlan.list}/${createdFeePlanId}`)

    return response
  }

  const onSubmit = async (data: TFeePlanForm) => {
    setIsSubmitting(true)

    try {
      if (!defaultData.id) {
        handleCreateFeePlan(data)
      } else {
        reset(await handleUpdateFeePlan(data))
      }

      toast.success(t('fee-plan.form.success'))
    } catch {
      handleCancel()

      toast.error(t('fee-plan.form.error'))
    } finally {
      setActiveField('')

      setEditable(false)

      setIsSubmitting(false)
    }
  }

  return {
    handleAddScale,
    onSubmit,
    handleCancel,
    removeScale,
    handleSubmit,

    isValid,
    isDirty,
    form,
    activeField,
    isSubmitting,
    control,
    scales,
  }
}
