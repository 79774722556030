import { SxProps, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TUserConfig, TUserStatusesConfig } from '@common/types'

import { DesktopDrawer, MobileDrawer } from '@shared/ui'

import { ChangeUserStatuses } from './components'

interface IProps {
  open: boolean
  handleClose: () => void
  configs: Pick<TUserConfig, 'key' | 'value' | 'userId'>[]
  sx?: SxProps
}

export function UserDangerActionsModal({
  handleClose,
  open,
  sx,
  configs,
}: IProps) {
  const { t } = useTranslation(['features', 'shared'])
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const statusConfigs = configs.find((config) => config.key === 'STATUSES') as
    | Pick<TUserStatusesConfig, 'value' | 'userId'>
    | undefined

  return mdAndUp ? (
    <DesktopDrawer
      title={'Change user configs'}
      open={open}
      onClose={handleClose}
      sx={sx}
    >
      {statusConfigs && <ChangeUserStatuses statusConfigs={statusConfigs} />}
    </DesktopDrawer>
  ) : (
    <MobileDrawer
      title={t('account.short-info-bar.fee-plan.title')}
      open={open}
      onClose={handleClose}
    >
      {statusConfigs && <ChangeUserStatuses statusConfigs={statusConfigs} />}
    </MobileDrawer>
  )
}
