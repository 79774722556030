import { SxProps, Typography } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { IFeePlan } from '@common/types'

import { ShortInfoBarItemWrapper } from '@shared/ui'

import { ScaleList } from './scale-list'
import { CommonItemElementsContainer } from './styled'

interface IProps {
  feePlan: IFeePlan
  setIsOpen: (isOpen: boolean) => void
  sx?: SxProps
}

export const AccountFeePlanShortInfoItem = memo(function ChangeAccountFeePlan({
  feePlan,
  setIsOpen,
}: IProps) {
  const { t } = useTranslation('features')

  return (
    <ShortInfoBarItemWrapper
      sx={{ flexDirection: 'column', gap: 1.5 }}
      onClick={() => setIsOpen(true)}
    >
      <CommonItemElementsContainer sx={{ mb: 1.5, gap: 1, overflow: 'hidden' }}>
        <Typography
          variant="h5"
          textAlign="start"
          className="ellipsis"
          sx={{ whiteSpace: 'wrap' }}
        >
          {`${t('account.short-info-bar.fee-plan.tariff')} ${feePlan.name}`}
        </Typography>

        <Typography variant="body2" color="info.main" textTransform="initial">
          {t('account.short-info-bar.fee-plan.change')}
        </Typography>
      </CommonItemElementsContainer>

      <ScaleList feePlan={feePlan} />
    </ShortInfoBarItemWrapper>
  )
})
