import * as yup from 'yup'

import { IDepositLimits, TCurrency } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

export const getDepositLimitsSchema = (
  currencies: TCurrency[],
  tFunc?: typeof defT
) => {
  const t = tFunc || defT
  const min = 1

  const { numberSchema } = getValidationSchemas(tFunc)

  const currencySchema: yup.ObjectSchema<IDepositLimits> = yup.object({
    min: numberSchema.min(min, t('min-amount', { amount: min })),
    max: numberSchema.moreThan(
      yup.ref('min'),
      t('greater-than', { targetValue: '"min"' })
    ),
  })

  const shape = currencies.reduce(
    (acc, currency) => {
      acc[currency] = currencySchema

      return acc
    },
    {} as Record<TCurrency, yup.ObjectSchema<IDepositLimits>>
  )

  return yup.object().shape(shape)
}
