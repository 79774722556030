import { SxProps, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  sx?: SxProps
  title: string
  onClick?: () => void
}

export function AccountApplicationCardTitle({ sx, title, onClick }: IProps) {
  const { t } = useTranslation('shared')

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={sx}
    >
      <Typography variant="h3">{title}</Typography>

      {onClick && (
        <Button variant="outlined" size="small" onClick={onClick}>
          {t('button.edit')}
        </Button>
      )}
    </Stack>
  )
}
