import { IFeePlan, TCurrency } from '@common/types'

import { InfoRow } from './info-row'

interface IProps {
  scale: IFeePlan['scales'][0]
  minFee: string
  currency: TCurrency
  isMin: boolean
}

export function ScaleListItem({ scale, currency, minFee, isMin }: IProps) {
  return (
    <InfoRow
      label={`${scale.min} - ${scale.max} ${currency}`}
      value={`${scale.percents}%${isMin ? ` (min. ${minFee} ${currency})` : ''}`}
    />
  )
}
