import { IChangeAccountFeePlanResponse } from '@common/types'

import { instance } from '@shared/requester'

export async function changeAccountFeePlan(
  accountId: string,
  feePlanId: string
) {
  return instance
    .patch<IChangeAccountFeePlanResponse>(`/accounts/${accountId}/fee-plan`, {
      feePlanId,
    })
    .then((res) => res.data)
}
