import {
  Box,
  CircularProgress,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { Sidebar, SidebarMobile } from '@widgets/sidebar'

export function MainLayout() {
  const [loading, setLoading] = useState(true)
  const { keycloak } = useKeycloak()
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (keycloak.authenticated) {
      setLoading(false)
    }
  }, [keycloak.authenticated])

  if (loading) {
    return (
      <Box
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Stack
      direction={mdAndUp ? 'row' : 'column'}
      gap={3}
      pr={{ md: 4 }}
      minHeight="100vh"
    >
      {mdAndUp ? <Sidebar /> : <SidebarMobile />}

      <Stack component="main" flex={1} overflow="hidden">
        {mdAndUp && <Toolbar />}
        <Outlet />
      </Stack>
    </Stack>
  )
}
