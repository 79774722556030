import { SxProps, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TermsSection } from '@features/accounts-application'

import { ITermsAndConditionsForm } from '@entities/personal-account-application'

interface IProps {
  sx?: SxProps
}

export function TermsAndConditionsSection({ sx }: IProps) {
  const { t } = useTranslation('pages')

  return (
    <Box sx={sx}>
      <Typography variant="subtitle2" textTransform="uppercase" mb={2}>
        {t('personal-account-application.steps-titles.5')}
      </Typography>

      <TermsSection<ITermsAndConditionsForm>
        termsAndConditionsArbiex="terms"
        privacyPolicyArbiex="privacy"
        usePersonalData="personalData"
      />
    </Box>
  )
}
