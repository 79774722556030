import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const ShortInfoBarWrapper = styled('div')(({ theme }) => ({
  width: 420,
  padding: theme.spacing(2.5),

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  backgroundColor: theme.palette.background.default,
  border: `1px solid ${grey[100]}`,
  borderRadius: '12px',
}))
