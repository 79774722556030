import { IUserWithStatuses } from '@common/types'

import { useGetIdvStatusOptions } from '@entities/user'

import { ITemplateProps, StatusChip } from '@shared/ui'

export function UserIdvStatus({
  data: { idvStatus },
}: ITemplateProps<IUserWithStatuses>) {
  const { getIdvStatusColor, getIdvStatusText } =
    useGetIdvStatusOptions(idvStatus)

  return (
    <StatusChip
      circleColor={getIdvStatusColor()}
      text={getIdvStatusText()}
      variant="text"
    />
  )
}
