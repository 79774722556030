import { Box, Stack } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ITransactionListItem } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'

import { transactionListPageId } from '../../model'

interface IProps {
  data: ITransactionListItem
  type: 'from' | 'to'
  mobile?: boolean
  // betweenMdAndLg?: boolean
}

export const TransactionFromTo = memo(function TransactionFromTo({
  data,
  type,
  mobile = false,
  // betweenMdAndLg,
}: IProps) {
  const boxBetweenMdAndLg = useBoxMediaQuery(transactionListPageId).between(
    'md',
    'lg'
  )

  const { t } = useTranslation('shared')

  const isFrom = type === 'from'

  const fullName = isFrom
    ? data.senderWallet?.user?.fullName
    : data.receiverWallet?.user?.fullName

  const address = isFrom ? data.sender : data.receiver

  const fromTo = isFrom
    ? t('compose-table.header.from')
    : t('compose-table.header.to')

  return (
    <Stack>
      <Box
        component="span"
        className="ellipsis"
        color={mobile ? 'text.secondary' : 'text.primary'}
        maxWidth={boxBetweenMdAndLg ? 150 : 'initial'}
      >
        {mobile && fullName ? `${fromTo}: ${fullName}` : fromTo + ':'}
      </Box>

      <Box
        component="span"
        className="ellipsis"
        color={mobile ? 'text.secondary' : 'text.primary'}
        maxWidth={boxBetweenMdAndLg ? 150 : 'initial'}
        width="100%"
      >
        {address}
      </Box>
    </Stack>
  )
})
