import { IDepositResponse } from '@common/types'

import { instance } from '@shared/requester'

export function fetchDeposit(depositId?: string) {
  if (!depositId) {
    throw new Error()
  }

  return instance
    .get<IDepositResponse>(`/deposits/${depositId}`)
    .then((res) => res.data)
}
