import { SxProps, Box } from '@mui/material'

import { TermsAndConditionsSection, UsCitizenshipSection } from './components'

interface IProps {
  sx?: SxProps
}

export function TermsAndConditionsForm({ sx }: IProps) {
  return (
    <Box sx={sx}>
      <UsCitizenshipSection sx={{ mb: { xs: 4.5, md: 6.5 } }} />
      <TermsAndConditionsSection />
    </Box>
  )
}
