import { styled } from '@mui/material'

export const StyledUploadTinyWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    height: 'min-content',
  },

  '& .MuiStack-root.error_message': {
    paddingLeft: '8px',
  },
}))
