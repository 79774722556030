import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { routePaths, TParamsKeys } from '@shared/constants'
import { CustomBreadcrumbs } from '@shared/ui'

interface IProps {
  userName?: string | null
  sx?: SxProps
}

export function WithdrawalBreadcrumbs({ userName, sx }: IProps) {
  const location = useLocation()
  const { withdrawalId } = useParams<TParamsKeys>()
  const { t } = useTranslation(['pages', 'shared'])

  const pathnames = location.pathname.split('/').filter((path) => path)

  const breadcrumbNameMap = {
    [routePaths.withdrawals.list]: t('withdrawal-list.title'),
    [`${routePaths.withdrawals.list}/${withdrawalId}`]: t(
      'withdrawal.withdrawal-of',
      { name: userName }
    ),
  }

  return (
    <CustomBreadcrumbs
      breadcrumbNameMap={breadcrumbNameMap}
      pathnames={pathnames}
      sx={sx}
    />
  )
}
