import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TNotificationLimits } from '@common/types'

import { updateNotificationLimitsForCentralWalletSettings } from '@entities/wallet'

import { queryKeys } from '@shared/constants'

interface IProps {
  setIsDisabled: (value: boolean) => void
  resetForm: (data: TNotificationLimits) => void
}

export function useUpdateNotificationLimitsForCentralWalletSettingsMutation({
  resetForm,
  setIsDisabled,
}: IProps) {
  const queryClient = useQueryClient()
  const { t } = useTranslation('shared')

  const updateNotificationLimitsForCentralWalletSettingsMutation = useMutation({
    mutationFn: (data: TNotificationLimits) =>
      updateNotificationLimitsForCentralWalletSettings(data),
    onError: () => toast.error(t('toasts.try-again')),
    onSuccess: (response) => {
      queryClient.setQueryData(
        [queryKeys.notificationLimitsForCentralWallet],
        response
      )

      setIsDisabled(true)

      resetForm(response)
    },
  })

  return updateNotificationLimitsForCentralWalletSettingsMutation
}
