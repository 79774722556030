import { useEffect, useState } from 'react'

import { IAccountDetails } from '@common/types'

import {
  TBusinessAccountApplicationTab,
  useAccountApplicationModalState,
} from '@entities/account-application'

import { BusinessApplicationTabs } from './business-application-tabs'
import { Beneficiaries, Directors, MainInfo, Shareholders } from './groups'

interface IProps {
  account: IAccountDetails
}

export function BusinessAccountApplication({ account }: IProps) {
  const [currentTab, setCurrentTab] =
    useState<TBusinessAccountApplicationTab>('main')

  const priorityTab = useAccountApplicationModalState(
    (state) => state.priorityTab
  )

  useEffect(() => {
    if (priorityTab) {
      setCurrentTab(priorityTab)
    }
  }, [priorityTab])

  return (
    <>
      <BusinessApplicationTabs
        currentTab={currentTab}
        changeTab={(tab) => setCurrentTab(tab)}
        sx={{ mb: 3 }}
      />

      {currentTab === 'main' && (
        <MainInfo businessApplication={account.businessApplication} />
      )}

      {currentTab === 'directors' && <Directors account={account} />}
      {currentTab === 'shareholders' && <Shareholders account={account} />}
      {currentTab === 'beneficiaries' && <Beneficiaries account={account} />}
    </>
  )
}
