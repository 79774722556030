import { Link, Typography } from '@mui/material'
import { ParseKeys } from 'i18next'
import { Trans } from 'react-i18next'

interface IProps {
  i18nKey: ParseKeys<'features'>
  link?: string
}

export function CustomLabel({ i18nKey, link }: IProps) {
  return (
    <Typography variant="body2">
      <Trans
        ns="features"
        i18nKey={i18nKey}
        components={{
          a: (
            <Link
              target="_blank"
              href={link}
              sx={{ display: 'inline', fontWeight: 500 }}
            />
          ),
        }}
      />
    </Typography>
  )
}
