import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { currencies, TDepositLimits } from '@common/types'
import { getDepositLimitsSchema } from '@common/validation/deposit-schema/deposit-limits-schema'

import { useTFunc } from '@shared/hooks'

export function useDepositLimitsForm(defaultValues: TDepositLimits) {
  const tFunc = useTFunc()

  const schema = getDepositLimitsSchema([...currencies], tFunc)

  return useForm<TDepositLimits>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onSubmit',
  })
}
