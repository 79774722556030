export enum EUserConfigKeys {
  STATUSES = 'STATUSES',
  TERMS_AND_PRIVACY = 'TERMS_AND_PRIVACY',
}
export const userConfigKeys = Object.keys(EUserConfigKeys) as TUserConfigKey[]
export type TUserConfigKey = keyof typeof EUserConfigKeys

export interface IConfig<K extends EUserConfigKeys, T> {
  accountId?: string
  userId?: string
  key: K
  value: T
  updatedAt: string
  createdAt: string
  id: string
}
