import { Paper, Stack, Box, LinearProgress } from '@mui/material'
import { useRef } from 'react'
import { useParams } from 'react-router'

import {
  AddDocuments,
  IResetFormAddDocumentsRef,
} from '@features/add-documents'

import { useTransaction } from '@entities/transaction'
import { useUploadFileSettings } from '@entities/upload-file-settings'

import { TParamsKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import { MainInfoSection } from '@shared/ui'

import {
  mainTransactionPagePartId,
  transactionPageId,
  useAddTransactionDocumentsMutation,
  useInfoTemplates,
} from '../model'

import {
  TransactionBreadcrumbs,
  TransactionShortInfoBar,
  TransactionChips,
  SecondaryTransactionInfo,
  TransactionDocumentList,
  ApproveRejectRiskedTransaction,
  AnalysisReports,
} from './components'

export function TransactionPage() {
  const { transactionId } = useParams<TParamsKeys>()
  const addDocumentsRef = useRef<IResetFormAddDocumentsRef>(null)

  const {
    data: transaction,
    isLoading,
    isFetching,
    refetch,
  } = useTransaction(transactionId)

  const { data: uploadFileSettings } = useUploadFileSettings()

  const boxMdAndUp = useBoxMediaQuery(transactionPageId, isLoading).up('md')

  const box1000AndUp = useBoxMediaQuery(
    mainTransactionPagePartId,
    isLoading
  ).up(1000)

  const {
    mainInfoTemplate,
    secondaryInfoTemplate,
    senderReceiverInfoTemplate,
  } = useInfoTemplates(transaction)

  const { mutate: addDocuments, isPending } =
    useAddTransactionDocumentsMutation(
      addDocumentsRef.current?.resetForm,
      transactionId
    )

  return (
    <>
      {isLoading && (
        <Box width="100%">
          <LinearProgress />
        </Box>
      )}

      {!isLoading && transaction && (
        <Paper
          sx={{ p: '32px 24px 20px', display: 'flex', gap: 3 }}
          id={mainTransactionPagePartId}
        >
          <Stack gap={2} flex={1} overflow="hidden" id={transactionPageId}>
            <TransactionBreadcrumbs />

            <Stack
              direction={boxMdAndUp ? 'row' : 'column'}
              justifyContent="space-between"
              gap={2}
              overflow="hidden"
            >
              <Stack gap={2}>
                <ApproveRejectRiskedTransaction
                  refetch={refetch}
                  loading={isLoading}
                  transactionId={transaction.id}
                  transactionStatus={transaction.status}
                  isFetching={isFetching}
                />

                <TransactionChips {...transaction} />
              </Stack>

              <MainInfoSection info={mainInfoTemplate} />
            </Stack>

            <SecondaryTransactionInfo
              template={secondaryInfoTemplate}
              status={transaction.status}
              createdAt={transaction.createdAt}
            />

            <SecondaryTransactionInfo template={senderReceiverInfoTemplate} />

            {!!transaction?.s3Links?.length && (
              <TransactionDocumentList s3Links={transaction.s3Links} />
            )}

            <AnalysisReports reports={transaction?.analysisReports} />

            {uploadFileSettings && (
              <AddDocuments
                pageId={mainTransactionPagePartId}
                uploadFileSettings={uploadFileSettings}
                onSubmit={(fileList) =>
                  transactionId && addDocuments({ fileList, transactionId })
                }
                loading={isPending}
                ref={addDocumentsRef}
              />
            )}
          </Stack>

          <TransactionShortInfoBar
            transaction={transaction}
            isMobile={!box1000AndUp}
          />
        </Paper>
      )}
    </>
  )
}
