import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  approveRiskedTransaction,
  rejectRiskedTransaction,
} from '@entities/transaction'

import { queryKeys } from '@shared/constants'

interface IProps {
  transactionId?: string
  onSuccess?: () => void
}

export function useApproveRejectRiskedTransaction({
  onSuccess,
  transactionId,
}: IProps) {
  const { t } = useTranslation('shared')
  const queryClient = useQueryClient()

  const handleSuccess = () => {
    queryClient.refetchQueries({
      queryKey: [queryKeys.transaction, transactionId],
    })

    queryClient.refetchQueries({
      queryKey: [queryKeys.operationsNumberNeededToBeApprovedManually],
    })

    onSuccess?.()
  }

  const approveMutation = useMutation({
    mutationFn: approveRiskedTransaction,
    onSuccess: handleSuccess,
    onError: () => toast.error(t('toasts.try-again')),
  })

  const rejectMutation = useMutation({
    mutationFn: rejectRiskedTransaction,
    onSuccess: handleSuccess,
    onError: () => toast.error(t('toasts.try-again')),
  })

  return {
    approve: approveMutation.mutate,
    reject: rejectMutation.mutate,
    isLoading: approveMutation.isPending || rejectMutation.isPending,
  }
}
