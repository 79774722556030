import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { currencies, IFeePlan } from '@common/types'

import { MainInfoSection } from '@shared/ui'

import { commonBorderStyle, useFeePlanForm, useInfoTemplates } from '../model'

import {
  EditableSelect,
  EditableTextField,
  EditableType,
  ScalesListItem,
} from './components'
import { StyledCancelBtn, StyledScalesHeader } from './styled'

interface IProps {
  data: IFeePlan
  editable: boolean
  setEditable: (editable: boolean) => void
}

export function FeePlanForm({
  data: defaultData,
  editable,
  setEditable,
}: IProps) {
  const theme = useTheme()
  const { t } = useTranslation(['widgets', 'shared'])
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)

  const {
    handleAddScale,
    onSubmit,
    handleCancel,
    removeScale,
    handleSubmit,

    isValid,
    isDirty,
    form,
    activeField,
    isSubmitting,
    control,
    scales,
  } = useFeePlanForm({
    defaultData,
    editable,
    setEditable,
  })

  const { mainInfoTemplate } = useInfoTemplates(
    !defaultData.id ? null : defaultData
  )

  useEffect(() => {
    setIsSubmitEnabled(isDirty)
  }, [isDirty, isValid])

  return (
    <FormProvider {...form}>
      <Stack
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        overflow="hidden"
      >
        <Box display="flex" justifyContent="space-between" gap={2} mb={2}>
          <Stack gap={1.5} width="65%">
            <Box
              gap={4.5}
              justifyContent={'space-between'}
              alignItems={'center'}
              display={'flex'}
            >
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState }) => (
                  <EditableTextField
                    preValue
                    placeholder={t('fee-plan.form.fields.name')}
                    editable={editable}
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoFocus={!activeField}
                    sx={{ ...theme.typography.h4, width: '50%' }}
                  />
                )}
              />

              <Controller
                control={control}
                name="isDefault"
                render={({ field }) => (
                  <EditableType
                    editable={editable}
                    {...field}
                    value={!!field.value}
                  />
                )}
              />

              <Box sx={{ gap: 3, justifyContent: 'center', display: 'flex' }}>
                <Box display="flex" gap={1}>
                  <Controller
                    control={control}
                    name="currency"
                    render={({ field }) => (
                      <EditableSelect
                        {...field}
                        selectList={[...currencies]}
                        editable={editable}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>

            <Box display={'flex'} gap={2}>
              <Controller
                control={control}
                name="description"
                render={({ field, fieldState }) => (
                  <EditableTextField
                    preValue
                    placeholder={t('fee-plan.form.fields.description')}
                    editable={editable}
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ ...theme.typography.body2, color: 'primary.main' }}
                  />
                )}
              />

              <Controller
                control={control}
                name="minFee"
                render={({ field, fieldState }) => (
                  <EditableTextField
                    preValue
                    placeholder={t('fee-plan.form.fields.minFee')}
                    editable={editable}
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Stack>

          {defaultData.id && <MainInfoSection info={mainInfoTemplate} />}
        </Box>

        <Stack maxWidth="70%">
          <StyledScalesHeader>
            <Typography variant="h5">
              {t('fee-plan.form.scales.header')}
            </Typography>

            <Button
              onClick={handleAddScale}
              sx={{
                ...commonBorderStyle,
                borderRadius: 10,
                bgcolor: 'common.white',
              }}
            >
              {t('fee-plan.add-fee-plan')}
            </Button>
          </StyledScalesHeader>

          <Box sx={{ p: 2, borderTop: 0, ...commonBorderStyle }}>
            {scales.map((scale, index) => (
              <ScalesListItem
                key={scale.id}
                index={index}
                editable={editable}
                currency={defaultData.currency}
                values={scale}
                autoFocus={activeField === 'scales'}
                remove={removeScale}
              />
            ))}
          </Box>
        </Stack>

        <Box
          display="flex"
          justifyContent={editable ? 'space-between' : 'flex-end'}
          gap={2}
          mt={5}
        >
          {editable && (
            <StyledCancelBtn onClick={handleCancel} variant="contained">
              {t('shared:button.cancel')}
            </StyledCancelBtn>
          )}

          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            disabled={!isSubmitEnabled}
            sx={{ width: '50%' }}
          >
            {defaultData.id
              ? t('fee-plan.form.update')
              : t('fee-plan.form.create')}
          </LoadingButton>
        </Box>
      </Stack>
    </FormProvider>
  )
}
