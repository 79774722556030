import { Box, Popper, SxProps } from '@mui/material'
import { ReactNode, useState, MouseEvent } from 'react'

import { red } from '@shared/constants'

import { ReactComponent as ErrorInfoIcon } from './assets/info-icon.svg'

interface IInfoButtonProps {
  label: ReactNode | string
  error?: boolean
  popperSx?: SxProps
  iconSx?: SxProps
}

export function InfoButton({
  label,
  popperSx,
  error,
  iconSx,
}: IInfoButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMouseEnter = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const closePopup = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <>
      <Box
        component="span"
        aria-describedby={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={closePopup}
        sx={{
          width: 20,
          height: 20,
          ...(error && {
            '& path[fill-rule="evenodd"]': {
              fill: red[500],
            },
          }),
          ...iconSx,
        }}
      >
        <ErrorInfoIcon />
      </Box>

      <Popper
        sx={{ whiteSpace: 'pre-wrap', ...popperSx }}
        id={id}
        open={open}
        anchorEl={anchorEl}
      >
        {label}
      </Popper>
    </>
  )
}
