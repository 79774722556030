import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import {
  ClickAwayListener,
  TextField,
  Stack,
  IconButton,
  Typography,
  SxProps,
  TypographyOwnProps,
  TextFieldProps,
} from '@mui/material'
import { useEffect, useState } from 'react'

interface IProps {
  value: string
  onChange: (newText: string) => void
  variant?: TypographyOwnProps['variant']
  textFieldVariant?: TextFieldProps['variant']
  minLength?: number
  sx?: SxProps
}

export function EditableTypography({
  onChange,
  value,
  sx,
  variant = 'body1',
  textFieldVariant = 'outlined',
  minLength = 5,
}: IProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [newValue, setNewValue] = useState<string>(value)
  const [helperText, setHelperText] = useState<string | null>(null)
  const [isFirstSubmit, setIsFirstSubmit] = useState<boolean>(true)

  const handleClose = () => {
    setIsEditing(false)

    setIsFirstSubmit(true)

    setNewValue(value)
  }

  useEffect(() => {
    setNewValue(value)
  }, [value])

  const validate = () => {
    if (newValue.length < minLength) {
      setHelperText('Enter at least 5 characters')

      return false
    }

    setHelperText(null)

    return true
  }

  useEffect(() => {
    !isFirstSubmit && validate()
  }, [newValue])

  const handleSubmit = () => {
    const isValid = validate()

    setIsFirstSubmit(false)

    if (!isValid) {
      return
    }

    onChange(newValue)

    handleClose()
  }

  return isEditing ? (
    <ClickAwayListener onClickAway={handleClose}>
      <TextField
        variant={textFieldVariant}
        value={newValue}
        onChange={(e) => setNewValue(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        error={Boolean(helperText)}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <Stack direction="row" alignItems="center" gap={1}>
              <IconButton onClick={handleClose} size="small">
                <CloseRoundedIcon fontSize="small" />
              </IconButton>

              <IconButton onClick={handleSubmit} size="small" color="success">
                <DoneRoundedIcon fontSize="small" />
              </IconButton>
            </Stack>
          ),
        }}
        sx={{
          '& .MuiInputBase-root': {
            px: 1.5,
            '& input': {
              px: 1,
            },
          },
          ...sx,
        }}
      />
    </ClickAwayListener>
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      onClick={(e) => {
        e.stopPropagation()

        setIsEditing(true)
      }}
      px={1.5}
    >
      <Typography variant={variant}>{value}</Typography>
    </Stack>
  )
}
