import { Navigate, RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import {
  LoaderOrPersonalApplicationLayout,
  PersonalDetails,
  ExpectedTurnover,
  IdentityDocument,
  HomeAddress,
  TermsAndConditions,
} from './ui'

export const PersonalAccountApplicationRoute: RouteObject = {
  Component: LoaderOrPersonalApplicationLayout,
  children: [
    {
      path: routePaths.accounts.application.personal,
      element: (
        <Navigate
          to={routePaths.accounts.application.personalSteps.personalDetails}
        />
      ),
    },
    {
      path: routePaths.accounts.application.personalSteps.personalDetails,
      Component: PersonalDetails,
    },
    {
      path: routePaths.accounts.application.personalSteps.expectedTurnover,
      Component: ExpectedTurnover,
    },
    {
      path: routePaths.accounts.application.personalSteps.identityDocument,
      Component: IdentityDocument,
    },
    {
      path: routePaths.accounts.application.personalSteps.homeAddress,
      Component: HomeAddress,
    },
    {
      path: routePaths.accounts.application.personalSteps.terms,
      Component: TermsAndConditions,
    },
  ],
}
