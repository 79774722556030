import { Box, Button, Paper } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { currencies, IFeePlan } from '@common/types'

import { FeePlanForm } from '@widgets/fee-plan'

import { DeleteFeePlan } from '@features/fee-plan'

import { useFeePlan } from '@entities/fee-plans'

import { TParamsKeys } from '@shared/constants'
import { ListLoader, NoData } from '@shared/ui'

import { FeePlanBreadcrumbs } from './components'

const emptyData: IFeePlan = {
  id: '',
  name: '',
  description: '',
  scales: [{ min: 0, max: 1, percents: 0 }],
  isDefault: false,
  currency: currencies[0],
  createdAt: '',
  updatedAt: '',
  minFee: '',
}

export function FeePlanPage() {
  const { feePlanId } = useParams<TParamsKeys>()

  const { data, isLoading } = useFeePlan({ feePlanId })
  const { t } = useTranslation(['pages', 'shared'])
  const [isEditable, setIsEditable] = useState<boolean>(feePlanId === 'NEW')

  const isDisabled = isLoading || data?.isDefault

  return (
    <Paper sx={{ p: '32px 24px 20px' }} id={feePlanId}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={3}
      >
        <FeePlanBreadcrumbs sx={{ mb: 2 }} />

        <Box display={'flex'} gap={2}>
          <Button
            variant="outlined"
            onClick={() => setIsEditable(!isEditable)}
            disabled={isEditable || isLoading}
          >
            {t('shared:button.edit')}
          </Button>

          {data !== undefined && data !== null && (
            <DeleteFeePlan disabled={isDisabled} />
          )}
        </Box>
      </Box>

      {isLoading && <ListLoader skeletonsNumber={1} />}

      {(feePlanId === 'NEW' || data) && (
        <FeePlanForm
          data={data || emptyData}
          editable={isEditable}
          setEditable={setIsEditable}
        />
      )}

      {!data && !isLoading && feePlanId !== 'NEW' && <NoData />}
    </Paper>
  )
}
