import { useTranslation } from 'react-i18next'

import { TFormattedPersonalApplication } from '@common/types'

import { AccountApplicationCardTitle } from '@features/account'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

import { ShowOrDownloadFile } from '@shared/ui'
import { getCountryNameByAbbreviation } from '@shared/utils'

interface IProps {
  personalApplication: TFormattedPersonalApplication
}

export function HomeAddress({ personalApplication }: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <GroupFieldsCard>
      <AccountApplicationCardTitle
        title={t('account-application.homeAddress')}
        sx={{ mb: 2 }}
      />

      <LabelValue
        label={t('account-application.addressCountry')}
        value={getCountryNameByAbbreviation(personalApplication.addressCountry)}
      />

      <LabelValue
        label={t('account-application.addressCity')}
        value={personalApplication.addressCity}
      />

      <LabelValue
        label={t('account-application.addressStreet')}
        value={personalApplication.addressStreet}
      />

      <LabelValue
        label={t('account-application.addressZip')}
        value={personalApplication.addressZip}
      />

      <LabelValue
        label={t('account-application.addressProof')}
        value={
          <ShowOrDownloadFile options={personalApplication.addressProof} />
        }
      />

      <LabelValue
        label={t('account-application.taxCountry')}
        value={getCountryNameByAbbreviation(personalApplication.taxCountry)}
      />
    </GroupFieldsCard>
  )
}
