import { LoadingButton } from '@mui/lab'
import { Stack, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IWithdrawalSettings } from '@common/types'

import {
  useUpdateWithdrawalSettingsMutation,
  useWithdrawalSettingsForm,
} from '../model'

interface IProps {
  data: IWithdrawalSettings
}

export function WithdrawalSettingsForm({ data }: IProps) {
  const { t } = useTranslation(['widgets', 'shared'])

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useWithdrawalSettingsForm(data)

  const { mutate, isPending } = useUpdateWithdrawalSettingsMutation(reset)

  const onSubmit = (data: IWithdrawalSettings) => {
    mutate(data)
  }

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={5}>
      <Controller
        control={control}
        name="minAmountForSupportingDocs"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            ref={null}
            label={t('settings.withdrawals.min-amount-for-docs')}
            inputRef={field.ref}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <LoadingButton
        type="submit"
        loading={isPending}
        disabled={!isDirty}
        variant="contained"
        fullWidth
      >
        {t('settings.withdrawals.button')}
      </LoadingButton>
    </Stack>
  )
}
