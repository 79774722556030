import to from 'await-to-js'
import { AxiosError } from 'axios'
import { useFormContext } from 'react-hook-form'

import { IMultiErrorResponse } from '@common/types'

import { useAccountDetails } from '@entities/account'

import { updateBusinessApplication } from '../../api'
import { IBusinessApplicationForm } from '../types'

import { useConvertApplicationData } from './use-convert-application-data'

interface IProps {
  accountId: string
}

export function useUpdateBusinessApplication({ accountId }: IProps) {
  const { formState, reset, handleSubmit, setError } =
    useFormContext<IBusinessApplicationForm>()

  const { convertFormValuesToRequestData, convertResponseDataToFormValues } =
    useConvertApplicationData()

  const { refetch } = useAccountDetails({ accountId })

  const onSubmit = async () => {
    const { isDirty } = formState

    if (!isDirty) {
      return
    }

    await handleSubmit(async (data) => {
      const requestData = convertFormValuesToRequestData(data)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const [error] = await to<any, AxiosError<IMultiErrorResponse>>(
        updateBusinessApplication({ accountId, data: requestData })
      )

      if (error) {
        const errorMessages = error.response?.data?.errors

        if (errorMessages) {
          Object.keys(errorMessages).forEach((key) => {
            setError(
              key as keyof IBusinessApplicationForm,
              {
                type: 'manual',
                message: errorMessages[key][0],
              },
              { shouldFocus: true }
            )
          })
        }

        return
      }

      const { data: updatedAccount } = await refetch()

      if (!updatedAccount || !updatedAccount?.businessApplication) {
        reset({
          beneficiaries: [],
        })

        return
      }

      reset(convertResponseDataToFormValues(updatedAccount.businessApplication))
    })()
  }

  return {
    onSubmit,
  }
}
