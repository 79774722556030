import {
  IGetWithdrawalListQueryParams,
  IPaginationResponse,
  IWithdrawalListItem,
} from '@common/types'

import { instance } from '@shared/requester'

export function fetchWithdrawalList(params: IGetWithdrawalListQueryParams) {
  return instance
    .get<IPaginationResponse<IWithdrawalListItem>>('/withdrawals', { params })
    .then((res) => res.data)
}
