import { Box, Typography } from '@mui/material'
import { SxProps } from '@mui/system'

import { grey } from '@shared/constants'

import { TStatusChipVariant } from '../model'

import { StyledStatusChipWrapper } from './styled'

interface IProps {
  text: string
  circleColor?: string
  variant?: TStatusChipVariant
  sx?: SxProps
}

export function StatusChip({
  text,
  circleColor = grey[400],
  sx,
  variant = 'outlined',
}: IProps) {
  return (
    <StyledStatusChipWrapper sx={sx} variant={variant}>
      <Box borderRadius="100%" bgcolor={circleColor} width={8} height={8} />

      <Typography variant={variant === 'text' ? 'body1' : 'h6'}>
        {text}
      </Typography>
    </StyledStatusChipWrapper>
  )
}
