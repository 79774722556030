import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ITransaction } from '@common/types'

import { useTransactionStatusOptions } from '@entities/transaction'

import { routePaths } from '@shared/constants'
import { ShortInfoBarItemWrapper, StatusChip } from '@shared/ui'

type TProps = Pick<
  ITransaction,
  'id' | 'status' | 'amount' | 'asset' | 'blockchain' | 'type'
>

export function TransactionShortInfoItem({
  amount,
  asset,
  blockchain,
  id,
  status,
  type,
}: TProps) {
  const { t } = useTranslation('pages')

  const { getStatusText, getStatusColor } = useTransactionStatusOptions(status)

  return (
    <ShortInfoBarItemWrapper href={`${routePaths.transactions.list}/${id}`}>
      <Stack overflow="hidden">
        <Stack direction="row" spacing={2}>
          <Typography variant="h5">{t('exchange.transaction')}</Typography>

          <StatusChip
            text={getStatusText()}
            circleColor={getStatusColor()}
            variant="text"
          />
        </Stack>

        <Stack direction="row" spacing={2}>
          <Typography variant="h6">{`${amount} ${asset}`}</Typography>

          <Typography variant="h6" color="primary.main">
            {blockchain}
          </Typography>
        </Stack>

        <Typography variant="body1">{t(`exchange.types.${type}`)}</Typography>

        <Typography variant="body2" color="text.secondary" className="ellipsis">
          ID: {id}
        </Typography>
      </Stack>
    </ShortInfoBarItemWrapper>
  )
}
