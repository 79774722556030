import { IBeneficiaryForm } from '../types'

export const beneficiaryDefaultValues: IBeneficiaryForm = {
  id: undefined,
  firstName: '',
  lastName: '',
  birthDate: new Date(),
  nationality: '',
  addressCity: '',
  addressCountry: '',
  addressStreet: '',
  addressZip: '',
  documents: [
    {
      issueCountry: '',
      number: '',
      type: 'PASSPORT',
    },
  ],
  email: '',
  personalNumber: '',
  phoneNumber: '',
}
