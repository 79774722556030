import { Box } from '@mui/material'

import { TShortInfoBarTemplate, templates } from '@widgets/short-info-bar/model'

import { grey } from '@shared/constants'

interface IProps {
  template: TShortInfoBarTemplate
  isFirst: boolean
  isLast: boolean
}

export function RenderMobileTemplateWithoutTitle({
  template,
  isFirst,
  isLast,
}: IProps) {
  return (
    <Box
      sx={{
        borderBottom: {
          xs: `1px solid ${!isLast ? grey[100] : 'transparent'}`,
          md: 'none',
        },
        borderTop: {
          xs: `1px solid ${isFirst ? grey[100] : 'transparent'}`,
          md: 'none',
        },
      }}
    >
      {template.type !== 'custom'
        ? templates[template.type](template.data)
        : template.data}
    </Box>
  )
}
