import { Toolbar, styled } from '@mui/material'

export const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'second',
})({
  justifyContent: 'space-between',
  zIndex: 2,
  paddingTop: 16,
  paddingBottom: 16,
})
