import { AmountTemplate } from './amount-template'
import { DateTimeTemplate } from './date-time-template'
import { DefaultTemplate } from './default-template'
import { HiddenMiddleTemplate } from './hidden-middle-template'
import { ShortTemplate } from './short-template'
import { WithCopyButtonTemplate } from './with-copy-button-template'
// eslint-disable-next-line react-refresh/only-export-components
export * from './info-cell-template'

export interface ITemplateProps<T> {
  data: T
  additionalData?: T
}

export const composeTableTemplates = {
  dateTime: (data: ITemplateProps<string | null>) => (
    <DateTimeTemplate {...data} />
  ),
  default: (data: ITemplateProps<string>) => <DefaultTemplate {...data} />,
  amount: (data: ITemplateProps<number | string | undefined>) => (
    <AmountTemplate {...data} />
  ),
  copyButton: (data: ITemplateProps<string>) => (
    <WithCopyButtonTemplate {...data} />
  ),
  short: (data: ITemplateProps<string>) => <ShortTemplate {...data} />,
  hiddenMiddle: (data: ITemplateProps<string>) => (
    <HiddenMiddleTemplate {...data} />
  ),
}

export type TComposeTableTemplateName = keyof typeof composeTableTemplates
