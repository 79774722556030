import { html } from '@codemirror/lang-html'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded'
import { Grid, Box, IconButton, debounce, Typography } from '@mui/material'
import CodeMirror from '@uiw/react-codemirror'
import { useCallback } from 'react'

import { grey } from '@shared/constants'
import { useFullScreen } from '@shared/hooks'
import { FullScreenContainer, fullScreenContainerPadding } from '@shared/ui'

interface IProps {
  onChange: (code: string) => void
  value: string
  error?: boolean
  helperText?: string
}

export function EmailTemplateEditorWithPreview({
  onChange,
  value,
  error,
  helperText,
}: IProps) {
  const debouncedOnChange = useCallback(debounce(onChange, 500), [onChange])

  const { isFullScreen, toggleFullscreen } = useFullScreen()

  return (
    <FullScreenContainer fullScreen={isFullScreen}>
      <Grid container spacing={2} height="100%">
        <Grid item xs={12} md={6}>
          <Box
            border={`1px solid ${grey[100]}`}
            borderRadius="5px"
            height="100%"
            sx={{ '& .cm-theme-light': { height: '100%' } }}
          >
            <CodeMirror
              value={value}
              height={
                isFullScreen
                  ? `calc(100vh - ${fullScreenContainerPadding} * 2)`
                  : '350px'
              }
              extensions={[html()]}
              onChange={debouncedOnChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <iframe
            style={{ width: '100%', height: '100%', border: 'none' }}
            srcDoc={value}
            title="Preview"
          />
        </Grid>

        <IconButton
          onClick={toggleFullscreen}
          sx={{
            position: 'absolute',
            top: isFullScreen ? 24 : -12,
            right: isFullScreen ? 24 : -12,
          }}
        >
          {!isFullScreen ? (
            <OpenInFullRoundedIcon />
          ) : (
            <CloseFullscreenRoundedIcon />
          )}
        </IconButton>
      </Grid>

      {helperText && (
        <Typography
          variant="body1"
          color={error ? 'error' : 'text.primary'}
          mt={1}
        >
          {helperText}
        </Typography>
      )}
    </FullScreenContainer>
  )
}
