import {
  IAccountListItem,
  IGetAccountListQueryParams,
  IPaginationResponse,
} from '@common/types'

import { instance } from '@shared/requester'

export async function fetchAccountsList(params: IGetAccountListQueryParams) {
  return instance
    .get<IPaginationResponse<IAccountListItem>>('/accounts', {
      params: params,
    })
    .then((response) => response.data)
}
