import { AccordionDetails, styled } from '@mui/material'

export const StyledAccordionDetails = styled(AccordionDetails)({
  '&.MuiAccordionDetails-root': {
    padding: 0 + ' !important',
    marginTop: '4px',
    borderTop: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
})
