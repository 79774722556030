import { IDepositResponse } from '@common/types'

import { TShortInfoBarTemplate } from '@widgets/short-info-bar'

export function useDepositShortInfoBarTemplate(
  deposit?: Pick<IDepositResponse, 'account' | 'fiatBalance' | 'user'>
): TShortInfoBarTemplate[] {
  if (!deposit) {
    return []
  }

  const { account, fiatBalance, user } = deposit

  return [
    {
      type: 'user',
      data: {
        fullName: user.fullName || '',
        id: user.id,
      },
    },
    {
      type: 'account',
      data: {
        balanceAmount: fiatBalance.available,
        balanceCurrency: fiatBalance.currency,
        id: account.id,
        name:
          (account.variant === 'BUSINESS'
            ? account.businessApplication?.name
            : `${account.personalApplication?.firstName} ${account.personalApplication?.lastName}`) ||
          '',
        variant: account.variant,
      },
    },
  ]
}
