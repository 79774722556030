import { styled } from '@mui/material'

import { fullScreenContainerPadding } from './full-screen-container-padding'

/**
 * A container that can either take up the full screen or remain within its normal size.
 *
 * @component
 * @param {boolean} fullScreen - Determines whether the container should be fullscreen.
 *
 * @example
 * // Usage in a component:
 * <FullScreenContainer fullScreen={true}>
 *   Content
 * </FullScreenContainer>
 */
export const FullScreenContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullScreen',
})<{ fullScreen: boolean }>(({ theme, fullScreen }) => ({
  height: '100%',
  padding: fullScreenContainerPadding,
  position: 'relative',
  ...(fullScreen && {
    maxHeight: '100vh',
    overflow: 'hidden',

    backgroundColor: theme.palette.background.default,

    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9999,
  }),
}))
