import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'

import { StyledIconButton } from '../styled'

interface IProps {
  open: boolean
  setOpen: (open: boolean) => void
  onClose: () => void
}

export function RollButton({ open, setOpen, onClose }: IProps) {
  return (
    <StyledIconButton
      open={open}
      onClick={() => {
        open ? onClose() : setOpen(true)
      }}
    >
      <ArrowBackIosRoundedIcon sx={{ fontSize: 20 }} />
    </StyledIconButton>
  )
}
