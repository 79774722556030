import {
  IGetUserListQueryParams,
  IPaginationResponse,
  IUserWithStatuses,
} from '@common/types'

import { instance } from '@shared/requester'

export function fetchUserList(params: IGetUserListQueryParams) {
  return instance
    .get<IPaginationResponse<IUserWithStatuses>>(`/users`, { params })
    .then((res) => res.data)
}
