import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, Paper, TextField, TextFieldProps } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'

interface IOptionsProps {
  options: Array<{ key: string; label: string }>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...event: any[]) => void
}

type IProps = IOptionsProps & TextFieldProps

export const SimpleAutocomplete = forwardRef(function Named(
  props: IProps,
  ref
) {
  const {
    placeholder,
    error,
    helperText,
    label,
    onChange,
    name,
    disabled,
    fullWidth,
    options,
    value,
  } = props

  const [autocompleteValue, setAutocompleteValue] = useState<{
    key: string
    label: string
  } | null>(options.find((item) => item.key === value) || null)

  useEffect(() => {
    setAutocompleteValue(options.find((item) => item.key === value) || null)
  }, [value])

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth={fullWidth}
      clearOnBlur
      disablePortal
      value={autocompleteValue}
      options={options}
      getOptionLabel={(option) => option.label}
      popupIcon={!disabled && <ExpandMoreIcon />}
      onChange={(_, option) => {
        if (onChange) {
          onChange(option?.key)
        }
      }}
      PaperComponent={({ children }) => <Paper>{children}</Paper>}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          fullWidth={fullWidth}
          placeholder={placeholder}
          error={error}
          name={name}
          inputRef={ref}
          label={label}
          helperText={helperText || ''}
          InputProps={params.InputProps}
          disabled={disabled}
        />
      )}
    />
  )
})
