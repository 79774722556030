import { useQuery } from '@tanstack/react-query'

import { ICentralWalletWithBalanceList } from '@common/types'

import { queryKeys } from '@shared/constants'

import { getCentralWalletList } from '../../api'

export function useCentralWallets() {
  return useQuery<ICentralWalletWithBalanceList[]>({
    queryKey: [queryKeys.centralWalletsSetting],
    queryFn: () => getCentralWalletList(),
  })
}
