import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TAccountVariant } from '@common/types'

import { ShortInfoBarItemWrapper } from '@shared/ui'

interface IProps {
  onOpen: () => void
  accountName: string
  accountVariant: TAccountVariant
}

export function CardLink({ onOpen, accountName, accountVariant }: IProps) {
  const { t } = useTranslation('shared')

  return (
    <ShortInfoBarItemWrapper onClick={onOpen}>
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack alignItems={'flex-start'}>
          <Typography variant="body2" color="primary.main">
            {accountVariant}
          </Typography>

          <Typography variant="h5">{accountName}</Typography>
        </Stack>

        <Typography
          variant="body2"
          color="info.main"
          textTransform="initial"
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          {t('button.open')}
        </Typography>
      </Stack>
    </ShortInfoBarItemWrapper>
  )
}
