import { ICreateDepositResponse } from '@common/types'

import { instance } from '@shared/requester'

export function sendDepositOtpCode(depositId?: string) {
  if (!depositId) {
    throw new Error('Deposit id is not defined')
  }

  return instance
    .put<ICreateDepositResponse>(`/deposits/${depositId}/send-otp`)
    .then((res) => res.data)
}
