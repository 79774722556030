import HelpIcon from '@mui/icons-material/Help'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import { IFeePlanScale, TFeePlanForm } from '@common/types'

import { EditableTextField } from './editable-text-field'
import { StyledGrid } from './styled'

interface IProps {
  editable: boolean
  currency: string
  autoFocus?: boolean
  index: number
  values: IFeePlanScale
  remove: (index: number) => void
}

export function ScalesListItem({
  editable,
  currency,
  index,
  values,
  autoFocus,
  remove,
}: IProps) {
  const { control, formState, trigger, getValues } =
    useFormContext<TFeePlanForm>()

  const scales = getValues('scales')
  const { min, max, percents } = values

  const minError = formState.errors?.scales?.[index]?.min?.message
  const maxError = formState.errors?.scales?.[index]?.max?.message
  const percentsError = formState.errors?.scales?.[index]?.percents?.message
  const isRemoveAvailable = editable && scales.length >= 2

  return (
    <Grid container spacing={1}>
      <StyledGrid item md={3}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems="center"
          gap={1}
        >
          <Typography variant="h6"> min: </Typography>

          <Controller
            name={`scales.${index}.min`}
            control={control}
            defaultValue={min}
            render={({ field }) => (
              <EditableTextField
                editable={editable}
                {...field}
                onBlur={() => trigger(`scales.${index}.min`)}
                error={!!minError}
              />
            )}
          />

          <Typography variant="body1">{currency}</Typography>
        </Box>
      </StyledGrid>

      <StyledGrid
        item
        md={0.5}
        sx={{
          justifyContent: editable ? 'center' : 'flex-start',
        }}
      >
        <Typography variant="body1">-</Typography>
      </StyledGrid>

      <StyledGrid item md={4}>
        <Box
          sx={{
            gap: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6"> max: </Typography>

          <Controller
            name={`scales.${index}.max`}
            control={control}
            defaultValue={max}
            render={({ field }) => (
              <EditableTextField
                editable={editable}
                {...field}
                onBlur={() => trigger(`scales.${index}.max`)}
                error={!!maxError}
              />
            )}
          />

          <Typography variant="body1">{currency}</Typography>
        </Box>
      </StyledGrid>

      <StyledGrid item md={4.5} sx={{ justifyContent: 'flex-end' }}>
        <Controller
          name={`scales.${index}.percents`}
          control={control}
          defaultValue={percents}
          render={({ field }) => (
            <>
              <EditableTextField
                editable={editable}
                {...field}
                onBlur={() => trigger(`scales.${index}.percents`)}
                autoFocus={autoFocus}
                error={!!percentsError}
              />

              <Typography variant="body1">%</Typography>
            </>
          )}
        />

        <Tooltip
          title={minError || maxError || percentsError || 'No error'}
          placement="right"
        >
          <IconButton
            disabled={!minError && !maxError && !percentsError}
            sx={{
              color:
                minError || maxError || percentsError ? 'error.main' : 'none',
            }}
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>

        <IconButton disabled={!isRemoveAvailable} onClick={() => remove(index)}>
          <RemoveCircleIcon color={isRemoveAvailable ? 'error' : 'disabled'} />
        </IconButton>
      </StyledGrid>
    </Grid>
  )
}
