import { Grid, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  TGetEmailTemplateNamesResponse,
  TEmailTemplateType,
} from '@common/types'

import { CustomSelect } from '@shared/ui'

interface IProps {
  templateNames: TGetEmailTemplateNamesResponse
  setSelectedTemplateId: (id: string) => void
  selectedTemplateId?: string | null
}

export function TemplateChooser({
  templateNames,
  setSelectedTemplateId,
  selectedTemplateId,
}: IProps) {
  const [type, setType] = useState<TEmailTemplateType | null>(null)

  const { t } = useTranslation('widgets')

  const templateTypes = Object.keys(templateNames)
  const templates = type && templateNames[type]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <CustomSelect
          value={type}
          onChange={(e) => setType(e.target.value as TEmailTemplateType | null)}
          placeholder={t('settings.email-templates.choose-template-type')}
          menuComponent={templateTypes?.map((type) => (
            <MenuItem value={type} key={type} sx={{ height: 34 }}>
              <Typography variant="body2">{type}</Typography>
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomSelect
          disabled={!type}
          value={selectedTemplateId}
          onChange={(e) => setSelectedTemplateId(e.target.value as string)}
          placeholder={t('settings.email-templates.choose-template')}
          menuComponent={templates?.map((template) => (
            <MenuItem value={template.id} key={template.id} sx={{ height: 34 }}>
              <Typography variant="body1">{template.name}</Typography>
            </MenuItem>
          ))}
        />
      </Grid>
    </Grid>
  )
}
