import { SxProps, Stack } from '@mui/material'

import { GroupFieldsCard } from '@entities/account-application'

import { BeneficiaryAddress } from './beneficiary-address'
import { BeneficiaryContacts } from './beneficiary-contacts'
import { BeneficiaryDocuments } from './beneficiary-documents'
import { BeneficiaryPersonalDetails } from './beneficiary-personal-details'
import { BeneficiaryTitle } from './beneficiary-title'

interface IProps {
  sx?: SxProps
  index: number
  remove: () => void
  disabled: boolean
}

export function BeneficiaryItem({ sx, index, remove, disabled }: IProps) {
  return (
    <GroupFieldsCard
      sx={{
        height: '600px',
        maxWidth: 504,
        minWidth: 504,
        overflowY: 'scroll',
        ...sx,
      }}
    >
      <Stack gap={3}>
        <BeneficiaryTitle index={index} disabled={disabled} onDelete={remove} />
        <BeneficiaryPersonalDetails index={index} disabled={disabled} />
        <BeneficiaryDocuments index={index} disabled={disabled} />
        <BeneficiaryAddress index={index} disabled={disabled} />
        <BeneficiaryContacts index={index} disabled={disabled} />
      </Stack>
    </GroupFieldsCard>
  )
}
