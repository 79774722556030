import { SxProps } from '@mui/material'
import { Fragment } from 'react'

import { TShortInfoBarTemplate } from '@widgets/short-info-bar'

import { templates } from '../../model'
import { ShortInfoBarWrapper } from '../styled'

interface IProps {
  sx?: SxProps
  template: TShortInfoBarTemplate[]
}

export function DesktopShortInfoBar({ template, sx }: IProps) {
  return (
    <ShortInfoBarWrapper sx={sx}>
      {template.map((template, index) => (
        <Fragment key={index}>
          {template.type !== 'custom'
            ? templates[template.type](template.data)
            : template.data}
        </Fragment>
      ))}
    </ShortInfoBarWrapper>
  )
}
