import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { IUploadFile } from '@shared/ui'
import { formatBytes } from '@shared/utils'

import { ReactComponent as CloseIcon } from '../assets/close-icon.svg'
import { ReactComponent as DocumentImg } from '../assets/document-img.svg'

import {
  StyledFileTitleWrap,
  StyledFileUploadListItem,
  StyledLinearProgressLine,
  StyledTypography,
  StyledUploadLineNTextContainer,
} from './styled'

interface IProps {
  index: number
  file: IUploadFile
  removeFile: (index: number) => void
  isRemote?: boolean
}

export const UploadItemTiny = ({
  index,
  file,
  removeFile,
  isRemote = true,
}: IProps) => {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const isLoading = file?.loaded && file.loaded >= 100
  const isLoaded = file?.loaded && file.loaded < 100

  return (
    <StyledFileUploadListItem>
      <DocumentImg />

      <StyledUploadLineNTextContainer>
        <StyledFileTitleWrap isRemote={isRemote}>
          <StyledTypography variant="body1">{file.name}</StyledTypography>

          <Typography
            variant={mdAndUp ? 'body1' : 'body2'}
            color={'tertiary.main'}
            textAlign={'end'}
          >
            {formatBytes(file.size)}
          </Typography>
        </StyledFileTitleWrap>

        {isRemote && (
          <StyledLinearProgressLine>
            <Box flex={0.95}>
              <LinearProgress
                variant="determinate"
                value={file.loaded || 100}
                sx={{ borderRadius: '100px' }}
              />
            </Box>
          </StyledLinearProgressLine>
        )}
      </StyledUploadLineNTextContainer>

      {isRemote && (
        <Stack justifyContent={'center'} width={50}>
          {!!isLoading && (
            <CheckCircleRoundedIcon color={'primary'} sx={{ fontSize: 20 }} />
          )}

          {!!isLoaded && (
            <Typography color={'primary.dark'} variant={'body2'}>
              {file.loaded}%
            </Typography>
          )}
        </Stack>
      )}

      <Box>
        <IconButton onClick={() => removeFile(index)} sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      </Box>
    </StyledFileUploadListItem>
  )
}
