import { useQuery } from '@tanstack/react-query'

import { IWithdrawalSettings } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchWithdrawalSettings } from '../../api'

export const useWithdrawalSettings = () => {
  return useQuery<IWithdrawalSettings>({
    queryKey: [queryKeys.withdrawalSettings],
    queryFn: fetchWithdrawalSettings,
    staleTime: Infinity,
  })
}
