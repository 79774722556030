import { ObjectSchema, object, string } from 'yup'

import { TUpdateEmailTemplateBody } from '../../types'
import { defT } from '../validation-rules'

const minSubjectLength = 5
const minHtmlLength = 30

export function getUpdateEmailTemplateSchema(tFunc?: typeof defT) {
  const t = tFunc || defT

  const schema: ObjectSchema<TUpdateEmailTemplateBody> = object({
    subject: string()
      .min(
        minSubjectLength,
        t('min-chars', {
          count: minSubjectLength,
        })
      )
      .required(t('required')),
    html: string()
      .min(
        minHtmlLength,
        t('min-chars', {
          count: minHtmlLength,
        })
      )
      .required(t('required')),
  })

  return { schema, minSubjectLength }
}
