import { LoadingButton } from '@mui/lab'
import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IDepositDetails } from '@common/types'

import { updateDepositDetails, useDepositDetailsForm } from '@entities/deposit'

import { queryKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import { CustomTextField } from '@shared/ui'

import { settingPageId } from '../model'

interface IProps {
  data: IDepositDetails
}

export function DepositDetailsSettings({ data }: IProps) {
  const { t } = useTranslation('widgets')
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const queryClient = useQueryClient()
  const mdAndUp = useBoxMediaQuery(settingPageId).up(800)

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    getValues,
    reset,
    trigger,
  } = useDepositDetailsForm(data)

  const onSubmit = async () => {
    setIsSubmitting(true)

    try {
      const dataToSend = getValues()

      const response = await updateDepositDetails(dataToSend)

      toast.success(t('deposit.form.success'))

      setIsSubmitEnabled(false)

      queryClient.setQueryData([queryKeys.depositDetails], response)

      reset(response)
    } catch {
      toast.error(t('deposit.form.error'))
    } finally {
      setIsSubmitting(false)
    }

    trigger()
  }

  useEffect(() => {
    setIsSubmitEnabled(isDirty)
  }, [isDirty, isValid])

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      rowSpacing={{ xs: 1, md: 2 }}
      columnSpacing={{ xs: 2, md: 5 }}
    >
      <Grid item xs={12} md={mdAndUp ? 6 : 12}>
        <Controller
          control={control}
          name={'name'}
          render={({ field, fieldState }) => (
            <CustomTextField
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              ref={field.ref}
              errorMessage={fieldState.error?.message}
              label={t('deposit.name')}
              autoFocus
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={mdAndUp ? 6 : 12}>
        <Controller
          control={control}
          name={'BIC'}
          render={({ field, fieldState }) => (
            <CustomTextField
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              ref={field.ref}
              errorMessage={fieldState.error?.message}
              label={'BIC'}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={mdAndUp ? 6 : 12}>
        <Controller
          control={control}
          name={'IBAN'}
          render={({ field, fieldState }) => (
            <CustomTextField
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              ref={field.ref}
              errorMessage={fieldState.error?.message}
              label={'IBAN'}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={mdAndUp ? 6 : 12}>
        <Controller
          control={control}
          name={'bankName'}
          render={({ field, fieldState }) => (
            <CustomTextField
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              ref={field.ref}
              errorMessage={fieldState.error?.message}
              label={t('deposit.bank-name')}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={mdAndUp ? 6 : 12}>
        <Controller
          control={control}
          name={'companyAddress'}
          render={({ field, fieldState }) => (
            <CustomTextField
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              ref={field.ref}
              errorMessage={fieldState.error?.message}
              label={t('deposit.company-address')}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={mdAndUp ? 6 : 12}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          autoFocus
          fullWidth
          disabled={!isSubmitEnabled}
        >
          {t('deposit.form.send')}
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
