import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@shared/constants'

import { fetchEmailTemplateNames } from '../../api'

export function useEmailTemplateNames() {
  return useQuery({
    queryKey: [queryKeys.emailTemplateNames],
    queryFn: fetchEmailTemplateNames,
    staleTime: Infinity,
  })
}
