import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Box, Button, Link, Modal } from '@mui/material'
import { useState } from 'react'

import { IS3Link } from '@common/types'

export function ThumbnailDocumentViewer({ contentType, s3Link }: IS3Link) {
  const [showImageOnFullScreen, setShowImageOnFullScreen] =
    useState<boolean>(false)

  return (
    <>
      {contentType === 'application/pdf' ? (
        <Link href={s3Link} target="_blank">
          <PictureAsPdfIcon fontSize="large" />
        </Link>
      ) : (
        <Button
          sx={{ p: 0, minHeight: 20 }}
          onClick={() => setShowImageOnFullScreen(true)}
        >
          <Box component="img" src={s3Link} sx={{ width: 100 }} />
        </Button>
      )}

      <Modal
        open={showImageOnFullScreen}
        onClose={() => setShowImageOnFullScreen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box component="img" src={s3Link} sx={{ width: 800 }} />
      </Modal>
    </>
  )
}
