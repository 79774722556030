import { useTranslation } from 'react-i18next'

import { IUserWithStatuses } from '@common/types'

import { IComposeTableCellTemplate } from '@shared/ui'

import { IdWithInfo, UserIdvStatus } from '../../ui'

export function useUserListTableTemplate() {
  const { t } = useTranslation(['pages', 'shared'])

  const template: IComposeTableCellTemplate<IUserWithStatuses>[] = [
    {
      key: 'id',
      title: t('shared:compose-table.header.id'),
      component: IdWithInfo,
    },
    {
      key: 'fullName',
      title: t('shared:compose-table.header.full-name'),
      component: 'default',
    },
    {
      key: 'idvStatus',
      title: t('user-list.table-header.idv-status'),
      component: UserIdvStatus,
    },
    {
      key: 'email',
      title: t('user-list.table-header.email'),
      component: 'copyButton',
    },
    {
      key: 'phoneNumber',
      title: t('user-list.table-header.phone-number'),
      component: 'copyButton',
    },
    {
      key: 'createdAt',
      title: t('shared:compose-table.header.created'),
      component: 'dateTime',
    },
  ]

  return template
}
