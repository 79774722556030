import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ShortInfoBarItemWrapper } from '@shared/ui'

import { ITariffItemProps } from '../../model'

export function TariffShortInfoItem({
  sellingAmount,
  scales,
  name,
  minFee,
  currency,
}: ITariffItemProps) {
  const [isOpenTariffs, setIsOpenTariffs] = useState<boolean>(false)

  const usedScaleForTransaction =
    scales.find((el) => sellingAmount >= el.min && sellingAmount <= el.max) ||
    scales[0]

  const { t } = useTranslation('widgets')

  return (
    <ShortInfoBarItemWrapper
      sx={{ flexDirection: 'column', '& > div': { overflow: 'initial' } }}
      onClick={() => setIsOpenTariffs((prev) => !prev)}
    >
      <Accordion sx={{ width: '100%' }} expanded={isOpenTariffs}>
        <AccordionSummary
          expandIcon={
            <Box>
              <ExpandMoreIcon />
            </Box>
          }
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            '& .MuiAccordionSummary-content': {
              flexDirection: 'column',
              overflow: 'hidden',
            },
          }}
        >
          <Typography variant="h5" textAlign="start" className="ellipsis">
            {t('short-info-bar.item-names.tariff')}: {name}
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            gap={0.5}
            width="100%"
          >
            <Typography variant="h6">
              {usedScaleForTransaction.min} - {usedScaleForTransaction.max}
            </Typography>

            <Typography
              variant="h6"
              color="primary.main"
              textTransform="initial"
            >
              {`${usedScaleForTransaction.percents}% [min. ${minFee} ${currency}]`}
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Stack gap={1}>
            {scales.map((el, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                gap={0.5}
              >
                <Typography variant="body2">
                  {el.min} - {el.max}
                </Typography>

                <Typography variant="h6" textTransform="initial">
                  {`${el.percents}% ${index === 0 ? `[min. ${minFee} ${currency}]` : ''}`}
                </Typography>
              </Box>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </ShortInfoBarItemWrapper>
  )
}
