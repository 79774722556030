import { SxProps, Box, Grid, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IPersonalDetailsForm } from '@entities/personal-account-application'

import {
  // dateFieldSx,
  // countrySelectSx,
  CountrySelectInput,
  DateField,
} from '@shared/ui'

interface IProps {
  sx?: SxProps
}

export function PersonalDetailsForm({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const { control } = useFormContext<IPersonalDetailsForm>()

  return (
    <Box sx={sx}>
      <Grid
        container
        columnSpacing={{ md: 4.5 }}
        rowSpacing={{ xs: 1.5, md: 2 }}
      >
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={field.value || ''}
                inputRef={field.ref}
                ref={undefined}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'personal-account-application.personal-details.first-name'
                )}
                placeholder={t(
                  'personal-account-application.personal-details.first-name'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={field.value || ''}
                inputRef={field.ref}
                ref={undefined}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'personal-account-application.personal-details.last-name'
                )}
                placeholder={t(
                  'personal-account-application.personal-details.last-name'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="birthDate"
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                value={new Date(field.value)}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                format="dd.MM.yyyy"
                label={t(
                  'personal-account-application.personal-details.date-of-birth'
                )}
                placeholder={t(
                  'personal-account-application.personal-details.date-of-birth'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="nationality"
            render={({ field, fieldState }) => (
              <CountrySelectInput
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'personal-account-application.personal-details.nationality'
                )}
                placeholder={t(
                  'personal-account-application.personal-details.nationality'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="taxIdentifier"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={field.value || ''}
                inputRef={field.ref}
                ref={undefined}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t('personal-account-application.personal-details.tin')}
                placeholder={t(
                  'personal-account-application.personal-details.tin'
                )}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="taxCountry"
            render={({ field, fieldState }) => (
              <CountrySelectInput
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'personal-account-application.personal-details.tax-country'
                )}
                placeholder={t(
                  'personal-account-application.personal-details.tax-country'
                )}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
