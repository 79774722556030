import { TExchangeLimitSetting } from '@common/types'

import { instance } from '@shared/requester'

export async function updateExchangeLimitSetting(
  params: TExchangeLimitSetting
) {
  return instance
    .put<TExchangeLimitSetting>('/settings/external-exchange-limit', params)
    .then((res) => res.data)
}
