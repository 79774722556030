import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TExchangeStatus } from '@common/types'

import {
  confirmAmlForCreateExchange,
  confirmDepositKrakenReceived,
} from '@entities/exchange'

import { queryKeys } from '@shared/constants'

interface IProps {
  exchangeId?: string
  closeConfirmationModal: () => void
}

export function useConfirmExchange({
  closeConfirmationModal,
  exchangeId,
}: IProps) {
  if (!exchangeId) {
    throw new Error('Exchange id is not defined')
  }

  const queryClient = useQueryClient()

  const handleSuccess = (showToast?: boolean) => {
    queryClient.refetchQueries({
      queryKey: [queryKeys.operationsNumberNeededToBeApprovedManually],
    })

    queryClient.refetchQueries({
      queryKey: [queryKeys.exchangeDetails, exchangeId],
    })

    showToast && toast.success(t('toasts.success'))
  }

  const { t } = useTranslation('shared')

  const confirmDepositKrakenReceivedExchangeMutation = useMutation({
    mutationFn: () => confirmDepositKrakenReceived(exchangeId),
    onError: () => toast.error(t('toasts.try-again')),
    onSuccess: () => handleSuccess(true),
    onSettled: closeConfirmationModal,
  })

  const confirmAmlForCreateExchangeMutation = useMutation({
    mutationFn: () => confirmAmlForCreateExchange(exchangeId),
    onError: () => toast.error(t('toasts.try-again')),
    onSuccess: () => handleSuccess(true),
    onSettled: closeConfirmationModal,
  })

  const isLoading =
    confirmDepositKrakenReceivedExchangeMutation.isPending ||
    confirmAmlForCreateExchangeMutation.isPending

  const handleConfirm = (status: TExchangeStatus) => {
    switch (status) {
      case 'KRAKEN_WAIT_FOR_DEPOSIT':
        confirmDepositKrakenReceivedExchangeMutation.mutate()

        break
      case 'CONFIRMED':
        confirmAmlForCreateExchangeMutation.mutate()

        break
      default:
        break
    }
  }

  return {
    handleConfirm,
    isLoading,
    handleUpdateExchangesNumberNeededToBeApprovedManually: handleSuccess,
  }
}
