import { differenceInYears, isValid, parse, parseISO } from 'date-fns'
import { isValidBIC, isValidIBAN } from 'ibantools'
import parsePhoneNumber from 'libphonenumber-js'
import * as yup from 'yup'

import { countriesAbbreviation } from '../constants'
import { IUploadFileSettings, TCurrency } from '../types'

export type TErrorKeys =
  | 'required'
  | 'min-chars'
  | 'max-chars'
  | 'email'
  | 'invalid-date'
  | 'too-young'
  | 'invalid-chars'
  | 'country'
  | 'same-nationality'
  | 'phone-number'
  | 'min-array-items_interval'
  | 'positive-number'
  | 'integer-number'
  | 'invalid-value'
  | 'total-percentage'
  | 'invalid-iban'
  | 'percentage'
  | 'not-enough-funds'
  | 'unsupported-file-format'
  | 'file-size'
  | 'min-amount'
  | 'max-amount'
  | 'greater-than'
  | 'no-false'
  | 'scales'

export const errorMessageSeparator = '__'

export const defT = (
  key: TErrorKeys,
  options?: Record<string, string | number>
) => {
  if (!options) {
    return key
  }

  return `${key}${errorMessageSeparator}${JSON.stringify(options)}`
}

// const FieldValidationRegex = /^[a-zA-Z0-9\s(\-)+',./:]*$/i

export const getValidationSchemas = (tFunc?: typeof defT) => {
  const t = tFunc || defT

  const minChars = 2
  const maxChars = 255
  const adultAge = 18
  const minArrayItems = 1

  const numberSchema = yup
    .number()
    .required(t('required'))
    .typeError(t('integer-number'))

  // Must be the same as getBackAmountSchema
  const getUiAmountSchema = (min: number, max: number, currency: TCurrency) => {
    return yup
      .string()
      .required(t('required'))
      .test(
        'min-amount',
        t('min-amount', { amount: min, currency }),
        (value) => +value >= min
      )
      .test(
        'max-amount',
        t('max-amount', { amount: max, currency }),
        (value) => +value <= max
      )
  }

  // Must be the same as getUiAmountSchema
  const getBackAmountSchema = (min: number, max: number) => {
    return yup
      .number()
      .required(t('required'))
      .min(min, t('min-amount', { amount: min }))
      .max(max, t('max-amount', { amount: max }))
  }

  const nameSchema = yup
    .string()
    .required(t('required'))
    .min(minChars, t('min-chars', { count: minChars }))
    .max(maxChars, t('max-chars', { count: maxChars }))

  const emailSchema = yup.string().required(t('required')).email(t('email'))

  const birthDateStringSchema = yup
    .string()
    .required(t('required'))
    .test('isValidDate', t('invalid-date'), (value) => {
      return isValid(parse(value, 'yyyy-MM-dd', new Date()))
    })
    .test('is-older-than-18', t('too-young'), (value) => {
      const birthDate = parseISO(value)

      if (!isValid(birthDate)) {
        return false
      }

      const age = differenceInYears(new Date(), birthDate)

      return age >= adultAge
    })

  const birthDateSchema = yup
    .date()
    .required(t('required'))
    .test('is-older-than-18', t('too-young'), (value) => {
      const age = differenceInYears(new Date(), value)

      return age >= adultAge
    })

  const dateStringSchema = yup
    .string()
    .required(t('required'))
    .test('isValidDate', t('invalid-date'), (value) =>
      isValid(parse(value, 'yyyy-MM-dd', new Date()))
    )

  const dateSchema = yup.date().required(t('required'))

  const getGeneralFieldSchema = (min = minChars, max = maxChars) =>
    yup
      .string()
      .required(t('required'))
      .min(min, t('min-chars', { count: min }))
      .max(max, t('max-chars', { count: max }))

  const countrySchema = yup
    .string()
    .required(t('required'))
    .test('is-valid-country', t('country'), (value) =>
      countriesAbbreviation.includes(value)
    )

  const dualCitizenSchema = yup
    .string()
    .test('is-valid-country', t('country'), (value, context) => {
      const isDualCitizen = context.parent.isDualCitizen

      const isValueValid = countrySchema.isValidSync(value)

      if (
        (isDualCitizen && isValueValid) ||
        (!isDualCitizen && (isValueValid || !value))
      ) {
        return true
      }

      return false
    })
    .test('same-nationality', t('same-nationality'), (value, context) => {
      const nationality = context.parent.nationality

      if (nationality === value) {
        return false
      }

      return true
    })

  const phoneNumbersSchema = yup
    .string()
    .required(t('required'))
    .test('is-phone-number', t('phone-number'), (value) => {
      const phoneNumber = parsePhoneNumber(value)

      return phoneNumber && phoneNumber.isValid()
    })

  const zipCodeSchema = yup.string().max(12, t('max-chars', { count: 12 }))

  const bicSchema = yup
    .string()
    .required(t('required'))
    .test('BIC', t('invalid-value'), isValidBIC)

  const ibanSchema = yup
    .string()
    .required(t('required'))
    .test('IBAN', t('invalid-value'), (value) => {
      if (!value) {
        return false
      }

      return isValidIBAN(value, { allowQRIBAN: true })
    })

  function getFileSchema({ allowedTypes, maxFileSize }: IUploadFileSettings) {
    return yup
      .mixed<File>()
      .test('file-type', t('unsupported-file-format'), (value) =>
        value ? allowedTypes.includes(value.type) : true
      )
      .test('file-size', t('file-size', { size: maxFileSize }), (value) =>
        value ? value.size <= maxFileSize : true
      )
  }

  return {
    numberSchema,
    nameSchema,
    emailSchema,
    birthDateStringSchema,
    birthDateSchema,
    getGeneralFieldSchema,
    countrySchema,
    dualCitizenSchema,
    dateStringSchema,
    dateSchema,
    phoneNumbersSchema,
    zipCodeSchema,

    maxChars,
    minChars,
    minArrayItems,
    adultAge,

    ibanSchema,
    bicSchema,

    getFileSchema,
    getUiAmountSchema,
    getBackAmountSchema,
  }
}
