import { ICentralWalletWithBalanceList } from '@common/types'

import { instance } from '@shared/requester'

export async function getCentralWalletList(): Promise<
  ICentralWalletWithBalanceList[]
> {
  return instance
    .get<ICentralWalletWithBalanceList[]>('/wallets/central')
    .then((res) => res.data)
}
