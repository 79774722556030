import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ICentralWalletsBalanceBody } from '@common/types'
import { getValidationSchemas } from '@common/validation'

import { useTFunc } from '@shared/hooks'

type TSchema = Omit<ICentralWalletsBalanceBody, 'asset'>

export function useCreateBalanceForm() {
  const tFunc = useTFunc()

  const { numberSchema } = getValidationSchemas(tFunc)

  const schema: yup.ObjectSchema<TSchema> = yup.object().shape({
    balance: numberSchema,
  })

  return useForm<TSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      balance: 0,
    },
  })
}
