import { useNavigate } from 'react-router-dom'

import { IExchangeListItem } from '@common/types'

import { useBoxMediaQuery } from '@shared/hooks'
import { ComposeTable, MobileList } from '@shared/ui'

import { exchangeListPageId, useExchangeListTemplate } from '../../model'

interface IProps {
  exchangesList: IExchangeListItem[]
  take?: string
  loading: boolean
}

export function ExchangeList({ exchangesList, take, loading }: IProps) {
  const boxMdAndUp = useBoxMediaQuery(exchangeListPageId).up('md')

  const navigate = useNavigate()

  const { desktopTemplate, mobileTemplate } =
    useExchangeListTemplate(exchangesList)

  return (
    <>
      {boxMdAndUp ? (
        <ComposeTable
          template={desktopTemplate}
          data={exchangesList}
          itemIdProperty="id"
          onClickItem={navigate}
          sx={{
            opacity: loading ? 0.4 : 1,
            transition: 'opacity 0.3s ease-in-out',
            ...(take && {
              mb: `${(+take - exchangesList.length) * 63}px`,
            }),
          }}
        />
      ) : (
        <MobileList mobileList={mobileTemplate} onClick={navigate} />
      )}
    </>
  )
}
