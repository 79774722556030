import { IGetUserResponse } from '@common/types'

import { instance } from '@shared/requester'

export function fetchUser(id?: string) {
  if (!id) {
    throw new Error()
  }

  return instance
    .get<IGetUserResponse>(`/users/${id}`)
    .then((response) => response.data)
}
