import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import * as yup from 'yup'

import { getValidationSchemas } from '@common/validation'

import { useAccountDetails } from '@entities/account'
import {
  IIdentityDocumentForm,
  TDocumentType,
} from '@entities/personal-account-application'

import { TParamsKeys } from '@shared/constants'

export function useIdentityDocumentForm() {
  const { t } = useTranslation('shared')

  const { accountId } = useParams<TParamsKeys>()

  const { data } = useAccountDetails({ accountId })
  const { countrySchema, getGeneralFieldSchema } = getValidationSchemas()

  const schema: yup.ObjectSchema<IIdentityDocumentForm> = yup.object({
    documentType: yup
      .mixed<TDocumentType>()
      .oneOf(['IdCard', 'Passport'], t('validation.invalid-value'))
      .required(t('validation.required')),
    documentNumber: getGeneralFieldSchema(),
    issuingCountry: countrySchema,
    documentIssue: yup.date().required(),
    documentExpire: yup.date().required(),
    citizenship: countrySchema,
  })

  const identityDocumentForm = useForm<IIdentityDocumentForm>({
    resolver: yupResolver(schema),
  })

  const { reset } = identityDocumentForm

  useEffect(() => {
    reset({
      documentType: data?.personalApplication?.documentType as TDocumentType,
      documentNumber: data?.personalApplication?.documentNumber,
      issuingCountry: data?.personalApplication?.issuingCountry,
      documentIssue: data?.personalApplication?.documentIssue
        ? new Date(data.personalApplication.documentIssue)
        : undefined,
      documentExpire: data?.personalApplication?.documentExpire
        ? new Date(data.personalApplication.documentExpire)
        : undefined,
      citizenship: data?.personalApplication?.citizenship,
    })
  }, [data])

  return { identityDocumentForm }
}
