import { Divider, Stack } from '@mui/material'

import {
  ExchangeLimitExternalSettings,
  ExchangeSettingsForm,
} from '@widgets/settings'

import {
  useExchangeLimitSetting,
  useExchangeSettings,
} from '@entities/exchange'

import { SettingLayout } from './components'

export function ExchangeSettings() {
  const { data, isLoading } = useExchangeLimitSetting()

  const { data: exchangeSettings, isLoading: isLoadingSettings } =
    useExchangeSettings()

  return (
    <SettingLayout
      data={!!data || !!exchangeSettings}
      loading={isLoading || isLoadingSettings}
    >
      <Stack gap={3}>
        {exchangeSettings && <ExchangeSettingsForm data={exchangeSettings} />}
        <Divider />
        {data && <ExchangeLimitExternalSettings data={data} />}
      </Stack>
    </SettingLayout>
  )
}
