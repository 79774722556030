import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  TUpdateUserStatusConfigPayload,
  IGetUserResponse,
  EUserConfigKeys,
} from '@common/types'

import { updateUserStatusConfigs } from '@entities/user'

import { queryKeys } from '@shared/constants'

export function useMutateStatusConfigs(
  resetForm: (data: TUpdateUserStatusConfigPayload['value']) => void,
  userId?: string
) {
  const { t } = useTranslation('shared')

  const queryClient = useQueryClient()

  const updateUserStatusConfigsQuery = (
    data: TUpdateUserStatusConfigPayload['value']
  ) => {
    queryClient.setQueryData(
      [queryKeys.user, userId],
      (prevValue?: IGetUserResponse) => {
        if (!prevValue) {
          return prevValue
        }

        return {
          ...prevValue,
          configs: prevValue.configs.map((config) => {
            if (config.key === EUserConfigKeys.STATUSES) {
              return {
                ...config,
                value: data,
              }
            }

            return config
          }),
        }
      }
    )
  }

  const mutation = useMutation({
    mutationFn: updateUserStatusConfigs,
    onError: () => toast.error(t('toasts.try-again')),
    onSuccess: (data) => {
      updateUserStatusConfigsQuery(data)

      resetForm(data)
    },
  })

  return mutation
}
