import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { IExchangeSettings } from '@common/types'
import { getExchangeSettingsSchema } from '@common/validation'

import { useTFunc } from '@shared/hooks'

export function useExchangeSettingsForm(defaultValues: IExchangeSettings) {
  const tFunc = useTFunc()

  const schema = getExchangeSettingsSchema(tFunc)

  const form = useForm<IExchangeSettings>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  return form
}
