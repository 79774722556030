import { styled } from '@mui/material'

import { grey } from '@shared/constants'

import { commonBorderStyle } from '../../model'

export const StyledScalesHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 16px 16px 32px',
  backgroundColor: grey[100],
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  ...commonBorderStyle,
}))
