import * as yup from 'yup'

import { /* assets, */ ITransactionSettings /* , TAsset  */ } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

export const getTransactionSettingsSchema = (tFunc?: typeof defT) => {
  const t = tFunc || defT

  const { numberSchema } = getValidationSchemas(t)

  const schema: yup.ObjectSchema<ITransactionSettings> = yup.object().shape({
    min: numberSchema,
    max: numberSchema.moreThan(
      yup.ref('min'),
      t('greater-than', { targetValue: '"min"' })
    ),
    minEth: numberSchema,
    minTrx: numberSchema,
    minAmountInEurForSupportingDocs: numberSchema,
    // TODO: settings for minFiatThresholdForCrypto
    // minFiatThresholdForCrypto: yup.object(
    //   assets.reduce(
    //     (acc, asset) => {
    //       acc[asset] = numberSchema.required()

    //       return acc
    //     },
    //     {} as Record<TAsset, typeof numberSchema>
    //   )
    // ),
  })

  return schema
}
