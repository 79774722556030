import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { ChangeEvent, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputProps } from '@shared/types'

export const YesNoRadioButtons = forwardRef<
  HTMLInputElement,
  IInputProps<ChangeEvent<HTMLInputElement>>
>(function RadioButtons(props, ref) {
  const { t } = useTranslation('shared')
  const { error, helperText, label, onChange, name, sx, value } = props

  return (
    <Box sx={sx}>
      <Typography variant="body1">{label}</Typography>

      <RadioGroup row value={value} name={name} onChange={onChange}>
        <FormControlLabel
          value="yes"
          control={<Radio checked={value === true} />}
          label={<Typography variant="body2">{t('yes')}</Typography>}
          sx={{ marginRight: 4 }}
          inputRef={ref}
        />

        <FormControlLabel
          value="no"
          control={<Radio checked={value === false} />}
          label={<Typography variant="body2">{t('no')}</Typography>}
          inputRef={ref}
        />
      </RadioGroup>

      {error && (
        <FormHelperText error={error}>{helperText || ''}</FormHelperText>
      )}
    </Box>
  )
})
