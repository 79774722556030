import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { TFormattedBusinessApplication } from '@common/types'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

const mainTranslationPath = 'account-application.business-application'
const mainEntitiesTranslationPath = 'entities:account.business-application'

export function CompanyDetails({
  name,
  email,
  website,
  phoneNumber,
  registrationDate,
  registrationNumber,
}: TFormattedBusinessApplication) {
  const { t } = useTranslation(['widgets', 'entities'])

  return (
    <GroupFieldsCard>
      <Typography variant="h3" mb={1}>
        {t(`${mainTranslationPath}.company-details`)}
      </Typography>

      <LabelValue label={t(`${mainTranslationPath}.name`)} value={name} />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.email`)}
        value={email}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.website`)}
        value={website}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.phone-number`)}
        value={phoneNumber}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.registration-date`)}
        value={format(new Date(registrationDate), 'dd.MM.yyyy')}
      />

      <LabelValue
        label={t(`${mainEntitiesTranslationPath}.registration-number`)}
        value={registrationNumber}
      />
    </GroupFieldsCard>
  )
}
