import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { IAccountListItem } from '@common/types'

import { routePaths } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import {
  IMobileListItem,
  ComposeTable,
  MobileList,
  EllipsisTypography,
} from '@shared/ui'

import { accountListPageId, useAccountListTableTemplate } from '../../model'

import { AccountStatusTemplate } from './account-status-template'

interface IProps {
  accounts: IAccountListItem[]
}

export function AccountListTable({ accounts }: IProps) {
  const mdAndUp = useBoxMediaQuery(accountListPageId).up('md')
  const navigate = useNavigate()

  const template = useAccountListTableTemplate()

  const handleClick = (accountId: string) => {
    navigate(`${routePaths.accounts.list}/${accountId}`)
  }

  const mobileVisibleAccounts: IMobileListItem[] | undefined = accounts?.map(
    (account) => {
      const { id, name, variant, feePlan, createdAt } = account

      return {
        id: id,
        firstRow: [name, <AccountStatusTemplate key={id} data={account} />],
        middleRows: [
          variant,
          feePlan.name,
          format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
        ],
        lastRow: [
          <EllipsisTypography variant="body1" color="text.secondary" key={1}>
            {`id: ${id}`}
          </EllipsisTypography>,
        ],
      } satisfies IMobileListItem
    }
  )

  return (
    <>
      {mdAndUp && (
        <ComposeTable
          data={accounts}
          template={template}
          onClickItem={handleClick}
          itemIdProperty="id"
        />
      )}

      {!mdAndUp && mobileVisibleAccounts && (
        <MobileList mobileList={mobileVisibleAccounts} onClick={handleClick} />
      )}
    </>
  )
}
