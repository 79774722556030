import { useNavigate, useParams } from 'react-router-dom'

import { TUpdateAccountApplicationData } from '@common/types'

import {
  IExpectedTurnoverForm,
  IHomeAddressForm,
  IIdentityDocumentForm,
  IPersonalDetailsForm,
  ITermsAndConditionsForm,
} from '@entities/personal-account-application'
import { useUploadFileSettings } from '@entities/upload-file-settings'

import { routePaths, TParamsKeys } from '@shared/constants'

import { useExpectedTurnoverForm } from './use-expected-turnover-form'
import { useHomeAddressForm } from './use-home-address-form'
import { useIdentityDocumentForm } from './use-identity-document-form'
import { usePersonalDetailsForm } from './use-personal-details-form'
import { useTermsAndConditionsForm } from './use-terms-and-conditions-form'

export function usePersonalApplicationForms() {
  const navigate = useNavigate()
  const { accountId } = useParams<TParamsKeys>()
  const { data: uploadFileSettings } = useUploadFileSettings()

  if (!uploadFileSettings || !accountId) {
    throw new Error('Upload file settings or accountId are not provided')
  }

  const { personalDetailsForm } = usePersonalDetailsForm()
  const { expectedTurnoverForm } = useExpectedTurnoverForm()
  const { homeAddressForm } = useHomeAddressForm(uploadFileSettings)
  const { termsAndConditionsForm } = useTermsAndConditionsForm()
  const { identityDocumentForm } = useIdentityDocumentForm()

  const checkIfAllFormsAreValid = async (withoutTrigger?: boolean) => {
    if (!withoutTrigger) {
      await personalDetailsForm.trigger()

      await expectedTurnoverForm.trigger()

      await identityDocumentForm.trigger()

      await homeAddressForm.trigger()

      await termsAndConditionsForm.trigger()
    }

    if (Object.keys(personalDetailsForm.formState.errors).length) {
      navigate(
        routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'personal-details'
        )
      )

      return false
    }

    if (Object.keys(expectedTurnoverForm.formState.errors).length) {
      navigate(
        routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'expected-turnover'
        )
      )

      return false
    }

    if (Object.keys(identityDocumentForm.formState.errors).length) {
      navigate(
        routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'identity-document'
        )
      )

      return false
    }

    if (Object.keys(homeAddressForm.formState.errors).length) {
      navigate(
        routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'home-address'
        )
      )

      return false
    }

    if (Object.keys(termsAndConditionsForm.formState.errors).length) {
      navigate(
        routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'terms-and-conditions'
        )
      )

      return false
    }

    return true
  }

  const getChangedFields = (): TUpdateAccountApplicationData => {
    const personalDetailsValues = personalDetailsForm.getValues()
    const expectedTurnoverValues = expectedTurnoverForm.getValues()
    const identityDocumentValues = identityDocumentForm.getValues()
    const homeAddressValues = homeAddressForm.getValues()
    const termsAndConditionsValues = termsAndConditionsForm.getValues()

    const personalDetailsDirty = Object.keys(
      personalDetailsForm.formState.dirtyFields
    )

    const expectedTurnoverDirty = Object.keys(
      expectedTurnoverForm.formState.dirtyFields
    )

    const identityDocumentDirty = Object.keys(
      identityDocumentForm.formState.dirtyFields
    )

    const homeAddressDirty = Object.keys(homeAddressForm.formState.dirtyFields)

    const termsAndConditionsDirty = Object.keys(
      termsAndConditionsForm.formState.dirtyFields
    )

    const changedFields: Record<string, unknown> = {}

    if (personalDetailsDirty.length > 0) {
      for (const field of personalDetailsDirty) {
        changedFields[field as keyof TUpdateAccountApplicationData] =
          personalDetailsValues[
            field as keyof IPersonalDetailsForm
          ] as unknown as TUpdateAccountApplicationData[keyof IPersonalDetailsForm]
      }
    }

    if (expectedTurnoverDirty.length > 0) {
      for (const field of expectedTurnoverDirty) {
        changedFields[field as keyof IExpectedTurnoverForm] =
          expectedTurnoverValues[field as keyof IExpectedTurnoverForm]
      }

      if (expectedTurnoverValues.incomePrimarySource === 'Other') {
        changedFields.incomePrimarySource =
          expectedTurnoverValues.incomePrimarySourceOther
      }

      if (expectedTurnoverValues.purposeOfAccount === 'Other') {
        changedFields.purposeOfAccount =
          expectedTurnoverValues.purposeOfAccountOther
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (changedFields as any)['incomePrimarySourceOther']

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (changedFields as any)['purposeOfAccountOther']
    }

    if (identityDocumentDirty.length > 0) {
      for (const field of identityDocumentDirty) {
        changedFields[field as keyof IIdentityDocumentForm] =
          identityDocumentValues[field as keyof IIdentityDocumentForm]
      }
    }

    if (homeAddressDirty.length > 0) {
      for (const field of homeAddressDirty) {
        changedFields[field as keyof IHomeAddressForm] =
          homeAddressValues[field as keyof IHomeAddressForm]
      }
    }

    if (termsAndConditionsDirty.length > 0) {
      for (const field of termsAndConditionsDirty) {
        changedFields[field as keyof ITermsAndConditionsForm] =
          termsAndConditionsValues[field as keyof ITermsAndConditionsForm]
      }
    }

    return changedFields as unknown as TUpdateAccountApplicationData
  }

  return {
    personalDetailsForm,
    expectedTurnoverForm,
    identityDocumentForm,
    homeAddressForm,
    termsAndConditionsForm,
    checkIfAllFormsAreValid,
    getChangedFields,
  }
}
