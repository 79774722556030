import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { TFormattedPersonalApplication } from '@common/types'

import { AccountApplicationCardTitle } from '@features/account'

import { GroupFieldsCard, LabelValue } from '@entities/account-application'

import { routePaths, TParamsKeys } from '@shared/constants'
import { booleanToYesNo, getCountryNameByAbbreviation } from '@shared/utils'

interface IProps {
  personalApplication: TFormattedPersonalApplication
}

export function PersonalDetails({ personalApplication }: IProps) {
  const { t } = useTranslation(['widgets', 'shared'])
  const navigate = useNavigate()
  const { accountId } = useParams<TParamsKeys>()

  const onClick = () => {
    accountId &&
      navigate(
        routePaths.accounts.application.personalSteps.getPath(
          accountId,
          'personal-details'
        )
      )
  }

  return (
    <GroupFieldsCard>
      <AccountApplicationCardTitle
        title={t('account-application.personalDetails')}
        onClick={onClick}
        sx={{ mb: 2 }}
      />

      <LabelValue
        label={t('account-application.firstName')}
        value={personalApplication.firstName}
      />

      <LabelValue
        label={t('account-application.lastName')}
        value={personalApplication.lastName}
      />

      <LabelValue
        label={t('account-application.birthDate')}
        value={personalApplication.birthDate}
      />

      <LabelValue
        label={t('account-application.nationality')}
        value={getCountryNameByAbbreviation(personalApplication.nationality)}
      />

      <LabelValue
        label={t('account-application.taxIdentifier')}
        value={personalApplication.taxIdentifier}
      />

      <LabelValue
        label={t('account-application.taxCountry')}
        value={getCountryNameByAbbreviation(personalApplication.taxCountry)}
      />

      <LabelValue
        label={t('account-application.isDualCitizen')}
        value={booleanToYesNo(personalApplication.isDualCitizen)}
      />

      {personalApplication.dualCitizen && (
        <LabelValue
          label={t('account-application.dualCitizen')}
          value={getCountryNameByAbbreviation(personalApplication.dualCitizen)}
        />
      )}

      <LabelValue
        label={t('account-application.isUsCitizen')}
        value={booleanToYesNo(personalApplication.isUsCitizen)}
      />

      <LabelValue
        label={t('account-application.isDualCitizen')}
        value={booleanToYesNo(personalApplication.isDualCitizen)}
      />
    </GroupFieldsCard>
  )
}
