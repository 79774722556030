import { IAccountDetails } from '@common/types'

import { AccountApplication } from '@widgets/account-application'
import { TShortInfoBarTemplate } from '@widgets/short-info-bar'

import {
  AccountFeePlanShortInfo,
  AccountWalletsShortInfoItem,
} from '@features/account'
import { FiatBalanceShortInfoItem } from '@features/balance'
import { AccountDeposit } from '@features/deposit'

interface IProps {
  account?: IAccountDetails
  accountName?: string
  onConfirmDeposit: () => void
}

export function useAccountShortInfoBarTemplate({
  account,
  accountName,
  onConfirmDeposit,
}: IProps): TShortInfoBarTemplate[] {
  return account && accountName
    ? ([
        {
          type: 'user',
          data: {
            fullName: account?.user.fullName || '',
            id: account?.user.id,
          },
        },
        {
          type: 'custom',
          data: (
            <AccountApplication account={account} accountName={accountName} />
          ),
        },
        {
          type: 'custom',
          data: (
            <AccountFeePlanShortInfo
              feePlan={account.feePlan}
              accountId={account.id}
            />
          ),
        },
        {
          type: 'custom',
          data: (
            <AccountDeposit
              reference={account.balances[0]?.reference}
              onConfirm={onConfirmDeposit}
            />
          ),
        },
        {
          type: 'custom',
          data: <AccountWalletsShortInfoItem wallets={account.wallets} />,
        },
        {
          type: 'custom',
          data: (
            <FiatBalanceShortInfoItem
              currency={account.balances[0]?.currency}
              amount={account.balances[0]?.current}
              availableAmount={account.balances[0]?.available}
            />
          ),
        },
      ] satisfies TShortInfoBarTemplate[])
    : []
}
