import { IEmailTemplate, TUpdateEmailTemplateBody } from '@common/types'

import { instance } from '@shared/requester'

interface IPayload extends TUpdateEmailTemplateBody {
  id: string
}

export function updateEmailTemplate({ id, ...body }: IPayload) {
  return instance
    .patch<IEmailTemplate>(`/settings/email-templates/${id}`, body)
    .then((response) => response.data)
}
