import {
  ITransactionDetails,
  ITransactionRequestPathParams,
} from '@common/types'

import { instance } from '@shared/requester'

export function fetchTransaction({
  transactionId,
}: Partial<ITransactionRequestPathParams>) {
  if (!transactionId) {
    throw new Error('Transaction ID is required')
  }

  return instance
    .get<ITransactionDetails>(`/transactions/${transactionId}`)
    .then((res) => res.data)
}
