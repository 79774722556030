export const purposeOfAccountList = [
  'Daily banking (savings, salary, shopping, bills, family support, etc.)',
  'Investments',
  'Betting, Gambling, Gaming',
  'Business related commercial activities (self-employment, freelance)',
  'Other',
] as const

export type purposeOfAccountListType = (typeof purposeOfAccountList)[number]

export type PurposeOfAccountTranslationKeysType =
  | 'daily-banking'
  | 'investments'
  | 'betting-gambling-gaming'
  | 'business-related-commercial-activities'
  | 'other'

export const purposeOfAccountTranslationKeys: Record<
  purposeOfAccountListType,
  PurposeOfAccountTranslationKeysType
> = {
  'Daily banking (savings, salary, shopping, bills, family support, etc.)':
    'daily-banking',
  Investments: 'investments',
  'Betting, Gambling, Gaming': 'betting-gambling-gaming',
  'Business related commercial activities (self-employment, freelance)':
    'business-related-commercial-activities',
  Other: 'other',
}
