import { boolean, object, ObjectSchema } from 'yup'

import { assets, IExchangeSettings, TAsset } from '../../types'
import { defT, getValidationSchemas } from '../validation-rules'

export const getExchangeSettingsSchema = (tFunc?: typeof defT) => {
  const t = tFunc || defT
  const { numberSchema } = getValidationSchemas(tFunc)

  const booleanSchema = boolean().required(t('required'))

  const assetsSchema = object(
    assets.reduce(
      (acc, asset) => {
        acc[asset] = boolean().required(t('required'))

        return acc
      },
      {} as Record<TAsset, typeof booleanSchema>
    )
  )

  const schema: ObjectSchema<IExchangeSettings> = object({
    TTL: numberSchema,
    availableAssets: object({
      buy: assetsSchema,
      sell: assetsSchema,
    }).required(t('required')),
  })

  return schema
}
