import { Container, Dialog, Slide, Stack } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'

import { IAccountDetails } from '@common/types'

import { useAccountApplicationModalState } from '@entities/account-application'

import {
  ModalToolbar,
  PersonalAccountApplication,
  CardLink,
  BusinessAccountApplication,
} from './components'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface IProps {
  account: IAccountDetails
  accountName: string
}

export function AccountApplication({ account, accountName }: IProps) {
  const { open, closeModal, openModal } = useAccountApplicationModalState()

  const application =
    account?.variant === 'PERSONAL'
      ? account?.personalApplication
      : account?.businessApplication

  return (
    <>
      {account && (
        <>
          <CardLink
            onOpen={() => openModal()}
            accountName={accountName}
            accountVariant={account.variant}
          />

          <Dialog
            fullScreen
            open={open}
            onClose={closeModal}
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-paper': {
                pt: 0,
                px: 0,
                bgcolor: 'background.default',
              },
            }}
          >
            <Stack gap={5}>
              <ModalToolbar
                title={`${account.variant} - ${accountName} - id ${application?.id}`}
                onClose={closeModal}
              />

              <Container maxWidth="xl">
                {account.variant === 'PERSONAL' && (
                  <PersonalAccountApplication
                    personalApplication={account.personalApplication}
                  />
                )}

                {account.variant === 'BUSINESS' && (
                  <BusinessAccountApplication account={account} />
                )}
              </Container>
            </Stack>
          </Dialog>
        </>
      )}
    </>
  )
}
