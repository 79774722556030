import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ITransactionListItem } from '@common/types'

import { IComposeTableCellTemplate, IMobileListItem } from '@shared/ui'

import { TransactionFromTo, TransactionStatus } from '../../ui'

export function useTransactionListTemplate(
  transactions: ITransactionListItem[]
) {
  const { t } = useTranslation('shared')

  const desktopTemplate: IComposeTableCellTemplate<ITransactionListItem>[] = [
    {
      key: 'id',
      title: t('compose-table.header.id'),
      component: 'hiddenMiddle',
    },
    {
      key: 'receiverWallet',
      title: t('compose-table.header.from'),
      component: (data) => <TransactionFromTo data={data.data} type="from" />,
    },
    {
      key: 'senderWallet',
      title: t('compose-table.header.to'),
      component: (data) => <TransactionFromTo data={data.data} type="to" />,
    },
    {
      key: 'status',
      title: t('compose-table.header.status'),
      component: (data) => <TransactionStatus {...data} />,
    },
    {
      key: 'amount',
      additionalKey: 'asset',
      title: t('compose-table.header.amount'),
      component: 'amount',
    },
    {
      key: 'createdAt',
      title: t('compose-table.header.created'),
      component: 'dateTime',
    },
  ]

  const mobileTemplate: IMobileListItem[] = transactions.map((transaction) => {
    const { amount, createdAt, asset, id } = transaction

    return {
      id: id,
      firstRow: [
        `${amount} ${asset}`,
        <TransactionStatus key={id} data={transaction} />,
      ],
      middleRows: [
        format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
        <TransactionFromTo key={id} data={transaction} type="to" mobile />,
        <TransactionFromTo key={id} data={transaction} type="from" mobile />,
      ],
      lastRow: [
        <Typography
          variant="body1"
          color="text.secondary"
          key={1}
          className="ellipsis"
        >
          {`id: ${id}`}
        </Typography>,
      ],
    } satisfies IMobileListItem
  })

  return { desktopTemplate, mobileTemplate }
}
