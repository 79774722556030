import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { TAccountVariant } from '@common/types'

import { routePaths, TParamsKeys } from '@shared/constants'
import { CustomBreadcrumbs } from '@shared/ui'

import { additionalInfoListOfAccount } from '../../model'

interface IProps {
  accountName?: string | null
  accountVariant?: TAccountVariant
  sx?: SxProps
}

type TAccountTab =
  (typeof additionalInfoListOfAccount)[keyof typeof additionalInfoListOfAccount]

export function AccountBreadcrumbs({
  accountName,
  accountVariant,
  sx,
}: IProps) {
  const location = useLocation()
  const { accountId } = useParams<TParamsKeys>()
  const { t } = useTranslation(['pages', 'shared'])

  const pathnames = location.pathname.split('/').filter((path) => path)

  const currentTab = pathnames[2] as TAccountTab

  const breadcrumbNameMap = {
    [routePaths.accounts.list]: t('account.account-list'),
    [`${routePaths.accounts.list}/${accountId}/${currentTab}`]: `${accountName}: ${accountVariant} / ${t(`account.tabs.${currentTab}`)}`,
  }

  return (
    <CustomBreadcrumbs
      breadcrumbNameMap={breadcrumbNameMap}
      pathnames={pathnames}
      skipParamValue={accountId}
      sx={sx}
    />
  )
}
