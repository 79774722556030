import { Stack, SxProps } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'

import { externalLinks } from '@shared/constants'

import { ITermsItem } from '../model'

import { TermsItem } from './components'

interface IProps<T extends FieldValues> {
  termsAndConditionsArbiex: FieldPath<T>
  privacyPolicyArbiex: FieldPath<T>
  usePersonalData: FieldPath<T>
  sx?: SxProps
}

export function TermsSection<T extends FieldValues>({
  sx,
  termsAndConditionsArbiex,
  privacyPolicyArbiex,
  usePersonalData,
}: IProps<T>) {
  const terms: ITermsItem<T>[] = [
    {
      fieldName: termsAndConditionsArbiex,
      link: externalLinks.termsAndConditions,
      i18nKey: 'accounts-application.terms.terms-of-arbiex',
    },
    {
      fieldName: privacyPolicyArbiex,
      link: externalLinks.privacyPolicyArbiex,
      i18nKey: 'accounts-application.terms.privacy-policy-of-arbiex',
    },
    {
      fieldName: usePersonalData,
      i18nKey: 'accounts-application.terms.agree-with-using-personal-data',
    },
  ]

  return (
    <Stack spacing={2.5} sx={sx}>
      {terms.map((item) => (
        <TermsItem
          fieldName={item.fieldName}
          i18nKey={item.i18nKey}
          link={item.link}
          key={item.fieldName}
        />
      ))}
    </Stack>
  )
}
