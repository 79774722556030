import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IUserWithStatuses } from '@common/types'

import { InfoCellTemplate, ITemplateProps } from '@shared/ui'

export function IdWithInfo({
  data: { id, active, blocked },
}: ITemplateProps<IUserWithStatuses>) {
  const { t } = useTranslation('pages')

  const infoMessage = useMemo(() => {
    let message = ''

    if (!active) {
      message += t('user-list.account-deactivated')
    }

    if (blocked) {
      message += '\n' + t('user-list.account-blocked')
    }

    return message
  }, [active, blocked])

  return (
    <InfoCellTemplate
      data={id}
      infoMessage={infoMessage}
      error={!active || blocked}
      showInfo={!active || blocked}
      short
    />
  )
}
