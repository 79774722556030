import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { uploadTransactionDocuments } from '@entities/transaction'

import { queryKeys } from '@shared/constants'

export function useAddTransactionDocumentsMutation(
  resetForm?: () => void,
  transactionId?: string
) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: uploadTransactionDocuments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.transaction, transactionId],
        exact: true,
      })

      resetForm?.()
    },
    onError: () => toast.error(t('oops')),
  })

  return mutation
}
