import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ITransactionDetails } from '@common/types'

import { useTransactionRiskOptions } from '@entities/transaction'

import { StatusChip } from '@shared/ui'

export function TransactionChips({
  isExchange,
  risk,
  spam,
}: Pick<ITransactionDetails, 'spam' | 'isExchange' | 'risk'>) {
  const { t } = useTranslation('pages')

  const { getRiskLevelColor, getRiskLevelText } =
    useTransactionRiskOptions(risk)

  return (
    <Stack direction="row" flexWrap="wrap" gap={1} alignItems="flex-start">
      {isExchange && (
        <StatusChip
          circleColor="primary.main"
          text={t('transaction.exchange')}
          variant="outlined"
        />
      )}

      {spam && (
        <StatusChip
          circleColor="error.main"
          text={t('transaction.spam')}
          variant="outlined"
        />
      )}

      {risk && (
        <StatusChip
          circleColor={getRiskLevelColor()}
          text={getRiskLevelText()}
          variant="outlined"
        />
      )}
    </Stack>
  )
}
