import { SelectProps, FormControl, Select, MenuItem } from '@mui/material'
import { ReactNode } from 'react'

interface IProps {
  menuComponent?: ReactNode[]
}

export function CustomSelect(props: SelectProps & IProps) {
  return (
    <FormControl fullWidth={props.fullWidth || true}>
      <Select
        disabled={props.disabled}
        value={props.value}
        displayEmpty={props.displayEmpty || true}
        onChange={props.onChange}
        inputProps={{
          sx: {
            opacity: props.value ? 1 : 0.5,
          },
          ...props.inputProps,
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              p: 0.5,
            },
          },
          ...props.MenuProps,
        }}
        variant={props.variant || 'outlined'}
        sx={{
          height: 34,
          '& > fieldset > legend': { display: 'none' },
          ...props.sx,
        }}
        {...props}
      >
        <MenuItem value={null} sx={{ display: 'none', opacity: 0.5 }}>
          <em> {props.placeholder}</em>
        </MenuItem>

        {props.menuComponent}
      </Select>
    </FormControl>
  )
}
