import { styled } from '@mui/material'

import { grey } from '@shared/constants'

import { TStatusChipVariant } from '../../model'

interface IProps {
  variant?: TStatusChipVariant
}

export const StyledStatusChipWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<IProps>(({ theme, variant }) => ({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  height: 'min-content',
  maxWidth: 'fit-content',
  padding: '4px 8px',

  border: '1px solid',
  borderColor: variant !== 'text' ? grey[100] : 'transparent',

  borderRadius: '8px',

  ...(variant === 'filled' && {
    backgroundColor: theme.palette.common.white,
  }),

  ...(variant === 'text' && {
    padding: 0,
    borderWidth: 0,
  }),

  [theme.breakpoints.up('md')]: {
    gap: '8px',
  },
}))
