import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IUploadFileSettings } from '@common/types'

import { updateUploadFileSettings } from '@entities/upload-file-settings'

import { queryKeys } from '@shared/constants'

export function useUploadFileMutation(
  resetForm: (updatedData: IUploadFileSettings) => void
) {
  const { t } = useTranslation('shared')
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateUploadFileSettings,
    onSuccess: (response) => {
      resetForm(response)

      queryClient.setQueryData([queryKeys.uploadFileSettings], response)
    },
    onError: () => toast.error(t('toasts.try-again')),
  })
}
