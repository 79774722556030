import { Divider, Stack, SxProps } from '@mui/material'

import { IGetUserResponse } from '@common/types'

import { UserConfigs } from './config-info'
import { MainUserInfo } from './main-info'
import { SecondaryUserInfo } from './secondary-info'
import { UserDangerActions } from './user-danger-actions'

interface IProps {
  user?: IGetUserResponse
  sx?: SxProps
}

export function UserInfo({ user, sx }: IProps) {
  return (
    <Stack gap={3} sx={sx}>
      <MainUserInfo {...user} />
      <SecondaryUserInfo {...user} />
      <Divider sx={{ borderWidth: 4 }} />
      {user && <UserConfigs configs={user.configs} />}
      <UserDangerActions configs={user?.configs} />
    </Stack>
  )
}
