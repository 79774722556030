import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ICentralWalletWithBalanceList } from '@common/types'

import { AddBalance, AddCentralWallet } from '@features/wallet'

import { deepBlue } from '@shared/constants'

interface IProps {
  data?: ICentralWalletWithBalanceList[]
}

export function CentralWalletSettings({ data }: IProps) {
  const { t } = useTranslation(['widgets', 'pages'])

  return (
    <Stack position="relative" component={'form'}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h2">
          {t('pages:settings.central-wallets')}
        </Typography>

        <AddCentralWallet />
      </Stack>

      {!data?.length && (
        <Typography>{t('wallets.central-wal.404-title')}</Typography>
      )}

      <Grid container spacing={2}>
        {data &&
          data.length > 0 &&
          data.map((wal) => (
            <Grid item xs={12} md={6} lg={4} key={wal.id}>
              <Stack
                border={`1px solid ${deepBlue[200]}`}
                p="20px 15px"
                borderRadius={5}
                alignItems="flex-start"
                justifyContent={'space-between'}
                gap={2}
                height={'100%'}
              >
                <Stack>
                  <Typography variant="h5">
                    {t('wallets.central-wal.data.wallet')} {wal.id}
                  </Typography>

                  <Typography variant="h5">
                    {t('wallets.central-wal.data.blockchain')}
                    {wal.blockchain}
                  </Typography>

                  <Typography variant="h5">{wal.address}</Typography>
                </Stack>

                <Stack>
                  {wal.balances.length > 0 ? (
                    wal.balances.map((bal) => (
                      <Typography variant="h6" key={bal.id}>
                        {`${bal.asset}: ${t('wallets.central-wal.data.current')} - ${bal.current.toString()}, ${t('wallets.central-wal.data.available')} - ${bal.available.toString()}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography>
                      {t('wallets.central-wal.data.404-balances')}
                    </Typography>
                  )}

                  <AddBalance centralWalletID={wal.id} />
                </Stack>
              </Stack>
            </Grid>
          ))}
      </Grid>
    </Stack>
  )
}
