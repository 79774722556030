import { LoadingButton } from '@mui/lab'
import { Box, Grid, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { assets, TNotificationLimits } from '@common/types'

import { deepBlue } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'

import {
  settingPageId,
  useNotificationLimitsForCentralWalletSettingsForm,
  useUpdateNotificationLimitsForCentralWalletSettingsMutation,
} from '../model'

interface IProps {
  data: TNotificationLimits
}

const mainTranslationPath = 'settings.notification-limits-for-central-wallet'

export function NotificationLimitsForCentralWalletSettingsForm({
  data,
}: IProps) {
  const [isDisabled, setIsDisabled] = useState<boolean>(true)

  const { t } = useTranslation('widgets')
  const px550AndUp = useBoxMediaQuery(settingPageId).up(550)

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useNotificationLimitsForCentralWalletSettingsForm(data)

  const { mutate, isPending } =
    useUpdateNotificationLimitsForCentralWalletSettingsMutation({
      setIsDisabled,
      resetForm: reset,
    })

  useEffect(() => {
    setIsDisabled(!isDirty)
  }, [isDirty])

  return (
    <Stack
      gap={3}
      component="form"
      onSubmit={handleSubmit((data) => mutate(data))}
    >
      <Stack gap={1.5}>
        <Typography variant="h2" mb={1}>
          {t(`${mainTranslationPath}.title`)}
        </Typography>

        <Grid container spacing={3}>
          {Object.entries(data).map((trade) => {
            const level = trade[0] as keyof TNotificationLimits

            return (
              <Grid item xs={px550AndUp ? 6 : 12} key={level}>
                <Box
                  p={2}
                  border="1px solid"
                  borderColor={deepBlue[200]}
                  borderRadius={5}
                >
                  <Typography
                    variant="h6"
                    color={level === 'red' ? 'error.main' : 'warning.main'}
                  >
                    {t(`${mainTranslationPath}.${level}`)}
                  </Typography>

                  <Grid container spacing={1}>
                    {assets.map((asset) => (
                      <Grid item xs={px550AndUp ? 6 : 12} key={asset}>
                        <Controller
                          control={control}
                          name={`${level}.${asset}`}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              ref={null}
                              inputRef={field.ref}
                              type="number"
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              label={asset}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Stack>

      <LoadingButton
        type="submit"
        loading={isPending}
        variant="contained"
        fullWidth={!px550AndUp}
        disabled={isDisabled}
        sx={{ height: 52, mx: 'auto', minWidth: 300 }}
      >
        {t(`${mainTranslationPath}.button`)}
      </LoadingButton>
    </Stack>
  )
}
