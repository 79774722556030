import { Stack, SxProps } from '@mui/material'

import { IUploadFileSettings } from '@common/types'

import { DragNDropAreaTiny, IUploadFile, UploadItemTiny } from '@shared/ui'

import { StyledUploadTinyWrap } from './styled'

interface IProps {
  removeFile: (index: number) => void
  clickHandle: () => void
  fileList?: IUploadFile[]
  inputRef: React.MutableRefObject<HTMLInputElement | null>
  isRemote?: boolean
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void
  onSelectFiles: ({ target }: React.ChangeEvent<HTMLInputElement>) => void
  uploadDocTitle: string
  error?: boolean
  helperText?: string
  sx?: SxProps
  underAreaText?: string
  maxFileSize?: string
  uploadFileSettings: IUploadFileSettings
}

export function UploadFileTiny({
  removeFile,
  clickHandle,
  fileList,
  inputRef,
  isRemote = true,
  onDrop,
  onDragOver,
  onSelectFiles,
  uploadDocTitle,
  error,
  helperText,
  sx,
  underAreaText,
  uploadFileSettings,
}: IProps) {
  return (
    <StyledUploadTinyWrap sx={sx}>
      <DragNDropAreaTiny
        underAreaText={underAreaText}
        onClickUpload={clickHandle}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onSelectFiles={onSelectFiles}
        ref={inputRef}
        error={error}
        helperText={helperText}
        uploadDocTitle={uploadDocTitle}
        uploadFileSettings={uploadFileSettings}
      />

      <Stack gap={2}>
        {fileList?.map((file, index) => (
          <UploadItemTiny
            key={file.name + file.size}
            isRemote={isRemote}
            index={index || 0}
            file={file}
            removeFile={removeFile}
          />
        ))}
      </Stack>
    </StyledUploadTinyWrap>
  )
}
