import { SxProps, Box, Stack, Skeleton, LinearProgress } from '@mui/material'

interface IProps {
  skeletonsNumber?: number | string
  sx?: SxProps
}

export function ListLoader({ skeletonsNumber = 10, sx }: IProps) {
  return (
    <Box width="100%" sx={sx}>
      <LinearProgress sx={{ mb: 2 }} />

      {!!skeletonsNumber && (
        <Stack spacing={0.5} mb={2}>
          <Skeleton variant="rectangular" height={56} />

          {new Array(Number(skeletonsNumber) - 1).fill(null).map((_, index) => (
            <Skeleton variant="rectangular" height={64} key={index} />
          ))}
        </Stack>
      )}
    </Box>
  )
}
