import { Button, styled } from '@mui/material'
import { grey } from '@mui/material/colors'

export const StyledHeaderBtn = styled(Button)(({ theme, disabled }) => ({
  border: '1px solid',
  borderColor: !disabled ? grey[100] : theme.palette.text.disabled,
  backgroundColor: !disabled ? theme.palette.common.white : grey[50],

  padding: '4px 8px',

  alignItems: 'flex-start',
  height: 28,

  '&:hover': {
    backgroundColor: !disabled ? theme.palette.common.white : grey[50],
    borderColor: !disabled ? grey[100] : theme.palette.text.disabled,
    border: '1px solid',
  },

  '&:active': {
    backgroundColor: !disabled ? theme.palette.common.white : grey[50],
    borderColor: !disabled ? grey[100] : theme.palette.text.disabled,
    border: '1px solid',
  },
}))
