import { Components, LinkProps } from '@mui/material'
import { forwardRef } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

import { green, grey, orange, red } from '@shared/constants'

import { defaultTheme } from './default-theme'
import { palette } from './palette'
import { typography } from './typography'

// eslint-disable-next-line react/display-name,react-refresh/only-export-components
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to']
  }
>((props, ref) => {
  const { href, ...other } = props

  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
      body: {
        backgroundColor: palette.common.white,
        [defaultTheme.breakpoints.up('md')]: {
          backgroundColor: palette.background.default,
        },
      },
      '.ellipsis': {
        maxWidth: '100%',
        width: 'max-content',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.first-letter-up': {
        textTransform: 'lowercase',
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableFocusRipple: true,
    },
    variants: [
      // TEXT BUTTON
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          color: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
        },
      },
      {
        props: { variant: 'text', color: 'info' },
        style: {
          color: palette.info.main,
          '&:hover': {
            backgroundColor: palette.info.light,
          },
        },
      },
      {
        props: { variant: 'text', color: 'error' },
        style: {
          color: palette.error.main,
          '&:hover': {
            backgroundColor: red[200],
          },
          '&:focus-visible': {
            backgroundColor: palette.error.light,
          },
        },
      },
      {
        props: { variant: 'text', color: 'success' },
        style: {
          color: palette.success.dark,
          '&:hover': {
            backgroundColor: green[200],
          },
          '&:focus-visible': {
            backgroundColor: palette.success.light,
          },
        },
      },
      {
        props: { variant: 'text', color: 'warning' },
        style: {
          color: palette.warning.dark,
          '&:hover': {
            backgroundColor: orange[200],
          },
          '&:focus-visible': {
            backgroundColor: palette.warning.light,
          },
        },
      },

      // CONTAINED BUTTON
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.dark,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'info' },
        style: {
          color: palette.info.contrastText,
          backgroundColor: palette.info.dark,
          '&:hover': {
            backgroundColor: palette.primary.dark,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'error' },
        style: {
          color: palette.error.main,
          backgroundColor: palette.error.light,
          '&:hover': {
            backgroundColor: red[200],
          },
        },
      },
      {
        props: { variant: 'contained', color: 'success' },
        style: {
          color: palette.success.dark,
          backgroundColor: palette.success.light,
          '&:hover': {
            backgroundColor: green[200],
          },
        },
      },
      {
        props: { variant: 'contained', color: 'warning' },
        style: {
          color: palette.warning.dark,
          backgroundColor: palette.warning.light,
          '&:hover': {
            backgroundColor: orange[200],
          },
        },
      },
      {
        props: { variant: 'contained', disabled: true },
        style: {
          '&.Mui-disabled': {
            backgroundColor: grey[50],
          },
          color: palette.text.disabled,
        },
      },

      // OUTLINED BUTTON
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: palette.primary.main,
          borderColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'info' },
        style: {
          color: palette.info.main,
          borderColor: palette.info.main,
          '&:hover': {
            backgroundColor: palette.info.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'error' },
        style: {
          color: palette.error.main,
          borderColor: palette.error.main,
          '&:hover': {
            backgroundColor: red[200],
          },
          '&:focus-visible': {
            backgroundColor: palette.error.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'success' },
        style: {
          color: palette.success.dark,
          borderColor: palette.success.dark,
          '&:hover': {
            backgroundColor: green[200],
          },
          '&:focus-visible': {
            backgroundColor: palette.success.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'warning' },
        style: {
          color: palette.warning.dark,
          borderColor: palette.warning.dark,
          '&:hover': {
            backgroundColor: orange[200],
          },
          '&:focus-visible': {
            backgroundColor: palette.warning.light,
          },
        },
      },
      {
        props: { variant: 'outlined', disabled: true },
        style: {
          '&.Mui-disabled': {
            backgroundColor: grey[50],
          },
          color: palette.text.disabled,
          borderColor: palette.text.disabled,
        },
      },
    ],
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        ...typography.button,

        borderRadius: '10px',
        '&:focus-visible': {
          outline: 'none',
          boxShadow: `0px 0px 0px 3px ${orange[500]}`,
        },
        '&.Mui-disabled': {
          color: palette.text.disabled,
        },
      },
      sizeSmall: {
        padding: '6px 14px',
        borderRadius: '8px',
      },
      sizeMedium: {
        padding: '12px 14px',
      },
      sizeLarge: {
        padding: '14px 22px',
      },

      outlined: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: { LinkComponent: LinkBehavior },
  },
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        display: 'block',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        paddingRight: '18px',
        paddingLeft: '18px',
        '&:first-of-type': {
          paddingLeft: '24px',
        },
        '&:last-of-type': {
          paddingRight: '24px',
          textAlign: 'end',
        },
      },
      head: {
        paddingTop: '16px',
        paddingBottom: '16px',
        borderBottom: 'none',
        backgroundColor: palette.background.default,
        '&:first-of-type': {
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        },
        '&:last-of-type': {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
        },
        ...typography.h5,
      },
      body: {
        paddingTop: '16px',
        paddingBottom: '16px',
        borderBottomColor: grey[100],
        ...typography.body2,
      },
    },
  },
  MuiPaper: {
    defaultProps: { elevation: 0 },
    styleOverrides: {
      root: {
        [defaultTheme.breakpoints.up('md')]: {
          borderRadius: '16px',
        },
      },
    },
  },
  MuiPopper: {
    styleOverrides: {
      root: {
        padding: '4px 8px',
        backgroundColor: palette.common.white,
        border: '1px solid',
        borderColor: palette.primary.main,
        borderRadius: 8,
        ...typography.h6,
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: {
        '& .MuiPaper-root': {
          padding: '4px 8px',
          border: '1px solid',
          borderColor: palette.primary.main,
          borderRadius: 8,
          boxShadow: 'none',
        },
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      autoComplete: 'off',
    },
    styleOverrides: {
      root: {
        borderColor: palette.common.black,
        backgroundColor: palette.common.white,
        padding: '0px 24px',
        gap: '4px',
        minHeight: 52,

        '&.MuiAutocomplete-inputRoot': {
          padding: '0px 19px',
        },
        '&:hover': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
          },

          '&.MuiInputBase-root .adornment': {
            borderColor: palette.primary.main,
          },
        },

        '& .MuiIconButton-root': {
          padding: '4px',
          color: palette.primary.dark,
        },

        '&.MuiOutlinedInput-root': {
          borderRadius: 10,
          '& fieldset': {
            borderColor: grey[100],
          },
        },
        '&.MuiInputBase-multiline': {
          minHeight: 52,
          paddingRight: 24,
          paddingLeft: 24,
          '& textarea': {
            fontSize: typography.subtitle2?.fontSize,
            lineHeight: typography.subtitle2?.lineHeight,
            '::placeholder': {
              color: palette.text.secondary,
              fontWeight: typography.subtitle2?.fontWeight,
              opacity: 1,
            },
          },
        },
        '&.Mui-disabled': {
          backgroundColor: grey[50],
          color: palette.text.secondary,
          '& > fieldset': {
            borderColor: grey[100],
          },
        },
        '& > fieldset': {
          top: 0,
          borderColor: palette.primary.light,
          '& > legend': {
            display: 'none',
          },
        },
        '&.Mui-focused': {
          backgroundColor: palette.common.white,
          '& > fieldset': {
            borderWidth: '2px',
          },
          '& .adornment': {
            backgroundColor: palette.common.white,
            borderWidth: '2px',
            borderColor: palette.primary.main,
          },
        },
        '&.Mui-error': {
          backgroundColor: palette.error.light,
          color: palette.error.main,
          '& .adornment': {
            borderColor: palette.error.main,
          },
          '&:hover': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: palette.error.dark,
            },

            '&.MuiInputBase-root .adornment': {
              borderColor: palette.error.dark,
            },
          },
          '&.Mui-focused': {
            backgroundColor: palette.error.light,
            '& .adornment': {
              backgroundColor: palette.error.light,
            },
          },
        },
        input: {
          padding: 0,
          fontSize: typography.h5?.fontSize,
          lineHeight: typography.h5?.lineHeight,
          color: palette.text.primary,
          '&:-webkit-autofill, &:-webkit-autofill:hover , &:-webkit-autofill:focus':
            {
              WebkitTextFillColor: 'currentColor',
              WebkitBoxShadow: '0 0 0px 1000px transparent inset',
              transition: 'background-color 5000s ease-in-out 0s',
            },
          '::placeholder': {
            color: palette.text.primary,
            opacity: 1,
          },
        },
        '&.MuiInputBase-adornedEnd': {
          paddingRight: 24,
        },
      },
    },
  },
  // TODO: delete if not necessary
  // MuiChip: {
  //   styleOverrides: {
  //     root: {
  //       padding: '4px 8px',
  //       backgroundColor: 'transparent',
  //       borderRadius: '10px',
  //       border: '1px solid',
  //       borderColor: grey[100],
  //     },
  //     label: {
  //       padding: 0,
  //     },
  //   },
  // },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        marginBottom: '6px',
        ...typography.subtitle2,
        color: palette.text.primary,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        paddingLeft: '8px',
      },
    },
  },
  MuiInputLabel: {
    defaultProps: { shrink: true },
    styleOverrides: {
      root: {
        ...typography.subtitle2,
        color: palette.text.primary,
        '&.Mui-disabled': {
          color: grey[100],
        },
        '&.Mui-error': {
          color: palette.error.main,
          '&:hover': {
            color: palette.error.main,
          },
          '&.Mui-focused': {
            color: palette.error.main,
          },
        },
        [defaultTheme.breakpoints.up('xs')]: {
          position: 'initial',
          transform: 'initial',
          fontFeatureSettings: "'zero' on",
          color: palette.text.primary,
        },
        '&.Mui-focused': {
          color: palette.text.primary,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: palette.text.secondary,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiFormHelperText-root': {
          marginLeft: 0,
          marginRight: 0,
        },
        '& .MuiInputBase-root': {
          paddingTop: 10,
          paddingBottom: 10,
          '& .MuiInputBase-input': {
            paddingTop: 0,
            paddingBottom: 0,
            fontWeight: 500,
            '&::placeholder': {
              fontWeight: 400,
            },
          },
        },
        input: {
          '&[readonly]': {
            WebkitTextFillColor: palette.text.primary,
          },
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        padding: '24px 48px',
        minWidth: '340px',
        borderRadius: 16,
        [defaultTheme.breakpoints.up('sm')]: {
          minWidth: '400px',
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        background: 'transparent',
        '& .MuiAccordionSummary-root': {
          padding: 0,
          minHeight: 0,
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        '& .MuiAccordionSummary-content': {
          margin: 0,
          paddingRight: 12,
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          position: 'relative',
          top: -20,
          '& .MuiBox-root': {
            color: defaultTheme.palette.common.black,
          },
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: 0,
        },
        '& .MuiAccordionDetails-root': {
          padding: '12px 36px 0 0',
          borderTop: `1px solid ${grey[100]}`,
          marginTop: 12,
        },
      },
    },
  },
}
