import { format } from 'date-fns'
import { Decimal } from 'decimal.js'
import { useTranslation } from 'react-i18next'

import { IGetExchangeById } from '@common/types'

import { IInfoSectionRow } from '@shared/ui'

import { IExchangeInfoTemplate } from '../types'

export function useExchangeInfoTemplates(exchange?: IGetExchangeById) {
  const { t } = useTranslation('pages')

  if (!exchange) {
    return {
      mainInfoTemplate: [],
      basicInfoTemplate: [],
      finalInfoTemplate: [],
    }
  }

  const {
    id,
    externalId,
    withdrawId,
    withdrawTxId,
    createdAt,
    updatedAt,
    type,
    cryptoAmount,
    cryptoAmountFinal,
    cryptoBalance,
    fee,
    feePlanInfo,
    fiatAmount,
    fiatAmountFinal,
    fiatBalance,
    processing,
    rateFinal,
  } = exchange

  const mainInfoTemplate = [
    { label: t('exchange.id'), value: id },
    { label: t('exchange.withdrawal-id'), value: withdrawId, copyable: true },
    {
      label: t('exchange.withdrawal-tx-id'),
      value: withdrawTxId,
      copyable: true,
    },
    { label: t('exchange.external-id'), value: externalId, copyable: true },
    {
      label: t('exchange.created-at'),
      value: format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
    },
  ] satisfies IInfoSectionRow[]

  const isBuyType = type === 'BUY'

  const getSellingAmount = (amountType: 'estimate' | 'final' = 'estimate') => {
    const isEstimate = amountType === 'estimate'
    const sellingAmount = new Decimal(fiatAmount).plus(fee).toFixed(2)
    const buyingAmount = isEstimate ? cryptoAmount : cryptoAmountFinal
    const amount = isBuyType ? sellingAmount : buyingAmount

    const currencyOrAsset = isBuyType
      ? fiatBalance.currency
      : cryptoBalance.asset

    return `${amount} ${currencyOrAsset}`
  }

  const getBuyingAmount = (amountType: 'estimate' | 'final' = 'estimate') => {
    const isEstimate = amountType === 'estimate'
    const sellingAmount = isEstimate ? cryptoAmount : cryptoAmountFinal
    const buyingAmount = isEstimate ? fiatAmount : fiatAmountFinal
    const amount = isBuyType ? sellingAmount : buyingAmount

    const currencyOrAsset = isBuyType
      ? cryptoBalance.asset
      : fiatBalance.currency

    return `${amount} ${currencyOrAsset}`
  }

  const basicInfoTemplate: IExchangeInfoTemplate[] = [
    { name: t('exchange.type'), value: type },
    { name: t('exchange.method'), value: t(`exchange.methods.${processing}`) },
    { name: t('exchange.selling-amount'), value: getSellingAmount() },
    {
      name: t('exchange.estimate-rate'),
      value: exchange.rate,
    },
    {
      name: t('exchange.buying-amount'),
      value: getBuyingAmount(),
    },
    {
      name: t('exchange.created-at'),
      value: format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
    },
  ]

  const finalInfoTemplate: IExchangeInfoTemplate[] = [
    { name: t('exchange.rate-final'), value: rateFinal },
    { name: t('exchange.selling-amount'), value: getSellingAmount('final') },
    ...(!isBuyType
      ? [
          {
            name: t('exchange.amount-after-exchange'),
            value: `${new Decimal(fiatAmountFinal).plus(fee).toFixed(2)} ${feePlanInfo.currency}`,
          },
        ]
      : []),
    {
      name: t('exchange.fee-arbiex'),
      value: `${(+fee).toFixed(2)} ${feePlanInfo.currency}`,
    },
    ...(isBuyType
      ? [
          {
            name: t('exchange.amount-to-exchange'),
            value: `${(+fiatAmountFinal).toFixed(2)} ${feePlanInfo.currency}`,
          },
        ]
      : []),
    {
      name: t(
        `exchange.${isBuyType ? 'buying-amount' : 'amount-to-be-credited'}`
      ),
      value: getBuyingAmount('final'),
    },
    {
      name: t(`exchange.updated-at`),
      value: format(new Date(updatedAt), 'dd.MM.yyyy HH:mm'),
    },
  ]

  return {
    mainInfoTemplate,
    basicInfoTemplate,
    finalInfoTemplate,
  }
}
