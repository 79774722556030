import { IWithdrawalResponse } from '@common/types'

import { instance } from '@shared/requester'

export function fetchWithdrawal(withdrawalId?: string) {
  if (!withdrawalId) {
    throw new Error()
  }

  return instance
    .get<IWithdrawalResponse>(`/withdrawals/${withdrawalId}`)
    .then((res) => res.data)
}
