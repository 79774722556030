import { Paper, Stack } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ShortInfoBar } from '@widgets/short-info-bar'

import { accountPageId, useAccountDetails } from '@entities/account'
import { ApplicationFormProvider } from '@entities/account-application'

import { TParamsKeys, routePaths } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import { ListLoader } from '@shared/ui'

import {
  additionalInfoListOfAccount,
  useAccountShortInfoBarTemplate,
} from '../model'

import { AccountBreadcrumbs, AccountMainInfo, AccountTabs } from './components'

export function AccountPage() {
  const { accountId } = useParams<TParamsKeys>()
  const navigate = useNavigate()
  const location = useLocation()
  const { up, between, width: paperWidth } = useBoxMediaQuery(accountPageId)
  const boxLg = up(1024)
  const boxLgToXl = between(1024, 1430)

  const mainInfoBoxWidth = useMemo(() => {
    if (boxLgToXl) {
      return `calc(${paperWidth}px - 420px - 24px)`
    }

    return '100%'
  }, [boxLgToXl, paperWidth])

  const {
    data: account,
    isLoading,
    isError,
    failureReason,
    extraData: { accountName },
  } = useAccountDetails({ accountId })

  const shortInfoBarTemplate = useAccountShortInfoBarTemplate({
    account,
    accountName,
    // TODO: here must be refetching of fiat balances and deposits
    onConfirmDeposit: () => {},
  })

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.error}`)
  }, [failureReason])

  useEffect(() => {
    if (location.pathname.split('/').filter((item) => item).length < 3) {
      navigate(
        `${routePaths.accounts.list}/${accountId}/${additionalInfoListOfAccount.cryptoBalances}`,
        {
          replace: true,
        }
      )
    }
  }, [])

  return (
    <Paper sx={{ p: '32px 24px 20px' }} id={accountPageId}>
      {isLoading || !account ? (
        <ListLoader skeletonsNumber={1} />
      ) : (
        <ApplicationFormProvider account={account}>
          <Stack
            direction={boxLg ? 'row' : 'column'}
            justifyContent={'space-between'}
            gap={3}
            width="100%"
          >
            <Stack gap={4.5} flex={1}>
              <AccountBreadcrumbs
                accountName={accountName}
                accountVariant={account?.variant}
              />

              <AccountMainInfo account={account} />

              <Stack sx={{ width: mainInfoBoxWidth }}>
                <AccountTabs />
                <Outlet />
              </Stack>
            </Stack>

            <ShortInfoBar
              template={shortInfoBarTemplate}
              viewMode={boxLg ? 'desktop' : 'mobile'}
            />
          </Stack>
        </ApplicationFormProvider>
      )}
    </Paper>
  )
}
