import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { UserListPage } from './ui'

export const UserListRouter: RouteObject = {
  path: routePaths.users.list,
  element: <UserListPage />,
}
