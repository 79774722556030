import { IGetExchangeById } from '@common/types'

import { TShortInfoBarTemplate } from '@widgets/short-info-bar'

import { TransactionShortInfoItem } from '../../ui'

export function useExchangeShortInfoBarTemplate(
  exchange?: IGetExchangeById
): TShortInfoBarTemplate[] {
  if (!exchange) {
    return []
  }

  const { cryptoBalance, feePlanInfo, transactions, fiatBalance } = exchange

  const sellingAmount =
    exchange.type === 'BUY'
      ? exchange.fiatAmount
      : +exchange.cryptoAmount * +exchange.rate

  const accountName =
    fiatBalance.account.businessApplication?.name ||
    `${fiatBalance.account.personalApplication?.firstName} ${fiatBalance.account.personalApplication?.lastName}`

  return [
    {
      type: 'user',
      data: {
        fullName: exchange.user.fullName || '',
        id: exchange.userId,
      },
    },
    {
      type: 'account',
      data: {
        balanceAmount: fiatBalance.available,
        balanceCurrency: fiatBalance.currency,
        id: fiatBalance.account.id,
        name: accountName,
        variant: fiatBalance.account.variant,
      },
    },
    {
      type: 'wallet',
      data: {
        address: cryptoBalance.wallet.address,
        blockchain: cryptoBalance.wallet.blockchain,
        fullName: exchange.user.fullName || '',
        balanceList: [
          { amount: cryptoBalance.available, asset: cryptoBalance.asset },
        ],
        isCentral: cryptoBalance.wallet.isCentral,
      },
    },
    {
      type: 'tariff',
      data: {
        minFee: +feePlanInfo.minFee,
        name: feePlanInfo.name,
        scales: feePlanInfo.scales,
        sellingAmount: +sellingAmount,
        currency: feePlanInfo.currency,
      },
    },
    ...(transactions
      ? transactions.map(
          (transaction) =>
            ({
              type: 'custom',
              data: <TransactionShortInfoItem {...transaction} />,
            }) satisfies TShortInfoBarTemplate
        )
      : []),
  ]
}
