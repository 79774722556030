import { memo, useState } from 'react'

import { IFeePlan } from '@common/types'

import {
  AccountFeePlanShortInfoItem,
  ChangeAccountFeePlanDrawer,
} from './components'

interface IProps {
  feePlan: IFeePlan
  accountId: string
}

export const AccountFeePlanShortInfo = memo(function ChangeAccountFeePlan({
  feePlan,
  accountId,
}: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <AccountFeePlanShortInfoItem feePlan={feePlan} setIsOpen={setIsOpen} />

      <ChangeAccountFeePlanDrawer
        open={isOpen}
        feePlan={feePlan}
        currentFeePlanId={feePlan.id}
        accountId={accountId}
        handleClose={() => setIsOpen(false)}
      />
    </>
  )
})
