import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { IAccountDetails, ISingleErrorResponse } from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchAccount } from '../../api'

interface IProps {
  accountId?: string
}

export function useAccountDetails({ accountId }: IProps) {
  const query = useQuery<IAccountDetails, AxiosError<ISingleErrorResponse>>({
    queryKey: [queryKeys.account, accountId],
    queryFn: () => fetchAccount(accountId),
    refetchInterval: 90 * 1000,
  })

  const { data: account } = query

  const accountName =
    (account?.variant === 'PERSONAL'
      ? `${account?.personalApplication?.firstName} ${account?.personalApplication?.lastName}`
      : account?.businessApplication?.name) || ''

  return {
    ...query,
    extraData: {
      accountName,
    },
  }
}
