import { Divider, Stack } from '@mui/material'

import {
  CentralWalletSettings,
  NotificationLimitsForCentralWalletSettingsForm,
} from '@widgets/settings'

import {
  useCentralWallets,
  useNotificationLimitsForCentralWallet,
} from '@entities/wallet'

import { SettingLayout } from './components'

export function CentralWalletsSettings() {
  const { data: centralWallets, isLoading: isLoadingCentralWallets } =
    useCentralWallets()

  const { data: notificationLimits, isLoading: isLoadingNotificationLimits } =
    useNotificationLimitsForCentralWallet()

  return (
    <SettingLayout
      data={!!centralWallets || !!notificationLimits}
      loading={isLoadingCentralWallets || isLoadingNotificationLimits}
    >
      <Stack gap={3}>
        {centralWallets && <CentralWalletSettings data={centralWallets} />}
        <Divider />

        {notificationLimits && (
          <NotificationLimitsForCentralWalletSettingsForm
            data={notificationLimits}
          />
        )}
      </Stack>
    </SettingLayout>
  )
}
