import { Theme } from '@mui/material'

import { grey, red } from '@shared/constants'

export function getBodyTableRowSx([active, blocked]: (boolean | undefined)[]) {
  return (theme: Theme) => ({
    userSelect: 'none',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: grey[50],
    },

    ...((!active || blocked) && {
      backgroundColor: theme.palette.error.light,
      '&:hover': {
        backgroundColor: red[100],
      },
    }),
  })
}
