import { Stack } from '@mui/material'

import { IFeePlan } from '@common/types'

import { getTypeOfFeePlanByIsDefaultValue } from '@entities/fee-plans'

import { ITemplateProps } from '@shared/ui'

export function FeePlanType({ data: { isDefault } }: ITemplateProps<IFeePlan>) {
  return <Stack>{getTypeOfFeePlanByIsDefaultValue(!!isDefault)}</Stack>
}
