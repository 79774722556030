import { Tab, Link, SxProps, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { grey } from '@shared/constants'
import { useRouteMatch } from '@shared/hooks'

import { ITab } from '../model'

import { StyledTabs } from './styled'

interface IProps {
  tabsRoutes: ITab[]
  searchParamName?: string
  tabPanelName?: string
  sx?: SxProps
}

export function CustomTabs({
  tabsRoutes,
  searchParamName,
  tabPanelName,
  sx,
}: IProps) {
  const [searchParams] = useSearchParams()
  const theme = useTheme()
  const routeMatch = useRouteMatch(tabsRoutes.map((route) => route.path))
  const currentTab = routeMatch?.pattern?.path

  const expandedParam = useMemo(() => {
    const expanded = searchParamName && searchParams.get(searchParamName)

    return expanded === null ? '' : `?${searchParamName}=${expanded}`
  }, [searchParams])

  const a11yProps = (index: number) => {
    return {
      id: `${tabPanelName}-tab-${index}`,
      'aria-controls': `${tabPanelName}-tabpanel-${index}`,
    }
  }

  return (
    <StyledTabs
      value={currentTab}
      scrollButtons="auto"
      variant="scrollable"
      sx={sx}
    >
      {tabsRoutes.map(({ label, path: value, icon, activeIcon }, index) => (
        <Tab
          {...a11yProps(index)}
          label={
            typeof label === 'string' ? label : label(value === currentTab)
          }
          value={value}
          href={`${value}${expandedParam}`}
          component={Link}
          icon={currentTab === value && activeIcon ? activeIcon : icon}
          iconPosition="start"
          key={index}
          sx={{
            padding: '4px 12px 4px 8px',
            minHeight: 'initial',
            color: grey[700],
            transition: theme.transitions.create(['color']),
          }}
        />
      ))}
      )
    </StyledTabs>
  )
}
