import {
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { TCurrency } from '@common/types'

interface IProps {
  selectList: TCurrency[]
  editable: boolean
  value: string
  onChange: (...event: unknown[]) => void
}

export const EditableSelect = forwardRef<HTMLDivElement, IProps>(
  function EditableSelect(
    { selectList, editable, value, onChange }: IProps,
    ref
  ) {
    const { t } = useTranslation('pages')
    const theme = useTheme()

    const isSelectList = selectList && selectList.length > 1 && editable

    return isSelectList ? (
      <Stack minHeight="100px" ref={ref}>
        <InputLabel sx={{ ...theme.typography.h6, pl: 1 }}>
          {t('fee-plan-list.currency')}
        </InputLabel>

        <Select
          defaultValue={value}
          value={value}
          sx={{ '& [role="combobox"]': { p: 0 } }}
          onChange={(e) => onChange(e.target.value)}
        >
          {selectList.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    ) : (
      <Typography variant="h6" width="fit-content">
        {t('fee-plan-list.currency')} {value}
      </Typography>
    )
  }
)
