import { create } from 'zustand'

import { TBusinessAccountApplicationTab } from '../types'

interface IProps {
  open: boolean
  setOpen: (open: boolean) => void
  priorityTab?: TBusinessAccountApplicationTab
  openModal: (tab?: TBusinessAccountApplicationTab) => void
  closeModal: () => void
}

export const useAccountApplicationModalState = create<IProps>()((set) => ({
  open: false,
  setOpen: (open) => set({ open }),
  priorityTab: undefined,
  openModal: (tab) => {
    set({ open: true, priorityTab: tab })
  },
  closeModal: () => {
    set({ open: false, priorityTab: undefined })
  },
}))
