import { SxProps, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IUserWithStatuses } from '@common/types'

interface IProps
  extends Partial<Pick<IUserWithStatuses, 'keycloakId' | 'createdAt'>> {
  sx?: SxProps
}

export function UserMetaData({ createdAt, keycloakId, sx }: IProps) {
  const { t } = useTranslation('pages')

  return (
    <Stack direction="row" spacing={1.5} sx={sx}>
      <Stack spacing={0.5}>
        <Typography variant="body2" color="text.secondary">
          {t('user.keycloak-id')}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {t('user.created-at')}
        </Typography>
      </Stack>

      <Stack spacing={0.5}>
        <Typography variant="body2">{keycloakId}</Typography>

        <Typography variant="body2">
          {format(new Date(createdAt || 0), 'dd-MM-yyyy HH:mm:ss')}
        </Typography>
      </Stack>
    </Stack>
  )
}
