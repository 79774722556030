import { Box, Stack } from '@mui/material'

import { CopyButton } from '@shared/ui'

import { ITemplateProps } from './index'

export function WithCopyButtonTemplate({ data }: ITemplateProps<string>) {
  return (
    <Stack direction="row" alignItems="center" sx={{ height: 20 }}>
      <Box component="span">{data}</Box>
      <CopyButton value={data} size="small" />
    </Stack>
  )
}
