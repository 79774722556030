import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { grey } from '@shared/constants'

import { ExpandButton, SelectButton } from './styled'

interface IProps {
  name: string
  isDefault: boolean
  loading?: boolean
  onExpand?: () => void
  onSelect?: () => void
  expanded: boolean
  selected: boolean
  current: boolean
}

export function FeePlanAccordionSummary({
  isDefault,
  loading,
  name,
  onExpand,
  onSelect,
  expanded,
  selected,
  current,
}: IProps) {
  const theme = useTheme()
  const { t } = useTranslation(['features', 'shared'])

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      py={{ md: 0.75 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={{ xs: 1, md: 1.5 }}
        overflow="hidden"
      >
        <Typography variant="h5" className="ellipsis">
          {name}
        </Typography>

        {isDefault && (
          <Box
            p="4px 8px"
            borderRadius={2}
            border="1px solid"
            borderColor={grey[100]}
            bgcolor="common.white"
            sx={{ ...theme.typography.body2 }}
          >
            {t('account.short-info-bar.fee-plan.default')}
          </Box>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" gap={{ xs: 1, md: 1.5 }}>
        <ExpandButton size="small" onClick={onExpand} variant="contained">
          {expanded
            ? t('shared:button.close')
            : t('account.short-info-bar.fee-plan.details')}
        </ExpandButton>

        <SelectButton
          size="small"
          loading={loading}
          disabled={!expanded || selected || current}
          current={current}
          selected={selected}
          onClick={onSelect}
        >
          {current && t('account.short-info-bar.fee-plan.current')}

          {!current &&
            (selected
              ? t('account.short-info-bar.fee-plan.selected')
              : t('account.short-info-bar.fee-plan.select'))}
        </SelectButton>
      </Stack>
    </Stack>
  )
}
