import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ChangeCommonUserStatusesForm } from './change-common-user-statuses-form'

export function CommonUserStatusesAccordion() {
  const { t } = useTranslation('features')

  return (
    <Accordion
      sx={{
        '&:before': { content: 'none' },
        '& .MuiAccordionSummary-expandIconWrapper': { top: 'initial' },
      }}
    >
      <AccordionSummary
        aria-controls="panel1-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1-header"
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          },
        }}
      >
        <Typography variant="body1">
          {t('user.danger-actions.statuses.common.title')}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
        <ChangeCommonUserStatusesForm />
      </AccordionDetails>
    </Accordion>
  )
}
