import { IWithdrawalResponse } from '@common/types'

import { TShortInfoBarTemplate } from '@widgets/short-info-bar'

export function useWithdrawalShortInfoBarTemplate(
  withdrawal?: Pick<IWithdrawalResponse, 'account' | 'user' | 'fiatBalance'>
): TShortInfoBarTemplate[] {
  if (!withdrawal) {
    return []
  }

  const { account, fiatBalance, user } = withdrawal

  return [
    {
      type: 'user',
      data: {
        fullName: user.fullName || '',
        id: user.id,
      },
    },
    {
      type: 'account',
      data: {
        balanceAmount: fiatBalance.available,
        balanceCurrency: fiatBalance.currency,
        id: account.id,
        name:
          (account.variant === 'BUSINESS'
            ? account.businessApplication?.name
            : `${account.personalApplication?.firstName} ${account.personalApplication?.lastName}`) ||
          '',
        variant: account.variant,
      },
    },
  ]
}
