import { Button, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const ExpandButton = styled(Button)(({ theme }) => ({
  padding: '4px 8px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '8px',
  color: grey[600],
  textTransform: 'initial',
  '&:hover': {
    backgroundColor: grey[100],
  },
}))
