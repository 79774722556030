import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TDepositStatus } from '@common/types'

import { grey } from '@shared/constants'

export function useDepositStatusOptions(status?: TDepositStatus) {
  const theme = useTheme()
  const { t } = useTranslation('entities')

  const colors: Record<TDepositStatus, string> = {
    COMPLETED: theme.palette.success.main,
    PENDING: grey[400],
    FAILED: theme.palette.error.main,
    REJECTED: theme.palette.error.main,
  }

  const getStatusText = () => {
    return status ? t(`deposit.status.${status}`) : ''
  }

  const getStatusColor = () => {
    return status ? colors[status] : theme.palette.error.main
  }

  return { getStatusText, getStatusColor }
}
