import { IGetNumberOfOperationsNeededToBeApprovedManuallyResponse } from '@common/types'

import { instance } from '@shared/requester'

export function fetchOperationsNumberNeededToBeApprovedManually() {
  return instance
    .get<IGetNumberOfOperationsNeededToBeApprovedManuallyResponse>(
      '/operations/approve-manually'
    )
    .then((res) => res.data)
}
