import {
  IGetUserResponse,
  TUserStatusesConfig,
  TUserTermsConfig,
} from '@common/types'

import { ConfigStatuses, ConfigTermsAndPrivacy } from '../../ui'

export const configTemplate: Record<
  IGetUserResponse['configs'][0]['key'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => JSX.Element
> = {
  STATUSES: (
    data: Omit<TUserStatusesConfig, 'key' | 'userId' | 'accountId'>
  ) => <ConfigStatuses {...data} />,
  TERMS_AND_PRIVACY: (
    data: Omit<TUserTermsConfig, 'key' | 'userId' | 'accountId'>
  ) => <ConfigTermsAndPrivacy {...data} />,
}
