import { IApproveWithdrawalResponse } from '@common/types'

import { instance } from '@shared/requester'

export function approveWithdrawal(withdrawalId?: string) {
  if (!withdrawalId) {
    throw new Error()
  }

  return instance
    .put<IApproveWithdrawalResponse>(
      `/withdrawals/${withdrawalId}/start-approve`
    )
    .then((res) => res.data)
}
