import { LoadingButton } from '@mui/lab'
import { SxProps, Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { StatusApproveRejectWrapper } from './styled'

interface IProps {
  sx?: SxProps
  bgcolor: string
  text: string
  onApprove?: () => void
  onReject?: () => void
  hideApproveButton?: boolean
  hideRejectButton?: boolean
  disabled?: boolean
  loading?: boolean
}

export function StatusApproveReject({
  bgcolor,
  text,
  sx,
  hideApproveButton,
  onApprove,
  onReject,
  disabled,
  hideRejectButton,
  loading,
}: IProps) {
  const { t } = useTranslation('shared')

  return (
    <StatusApproveRejectWrapper
      hiddenButtons={hideApproveButton && hideRejectButton}
      sx={sx}
    >
      {!hideApproveButton && (
        <LoadingButton
          disabled={disabled}
          loading={loading}
          variant="contained"
          color="success"
          onClick={onApprove}
          size="small"
        >
          {t('status.approve')}
        </LoadingButton>
      )}

      {!hideRejectButton && (
        <LoadingButton
          disabled={disabled}
          loading={loading}
          variant="contained"
          color="error"
          onClick={onReject}
          size="small"
        >
          {t('status.reject')}
        </LoadingButton>
      )}

      <Stack direction="row" alignItems="center" gap={1}>
        <Box width={10} height={10} borderRadius="100%" bgcolor={bgcolor} />
        <Typography variant="button">{text}</Typography>
      </Stack>
    </StatusApproveRejectWrapper>
  )
}
