import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  IAccountListItem,
  IGetAccountListQueryParams,
  IPaginationResponse,
  ISingleErrorResponse,
} from '@common/types'

import { queryKeys } from '@shared/constants'

import { fetchAccountsList } from '../../api'

export function useAccountList(params: IGetAccountListQueryParams) {
  return useQuery<
    IPaginationResponse<IAccountListItem> | null,
    AxiosError<ISingleErrorResponse>
  >({
    queryKey: [queryKeys.accountList, params],
    queryFn: () =>
      params.skip !== undefined && params.take
        ? fetchAccountsList(params)
        : null,
    refetchInterval: 5 * 60 * 1000,
  })
}
