import { IconButton, styled } from '@mui/material'

import { sidebarAdditionalHoverArea } from '@shared/constants'

interface IProps {
  open?: boolean
}

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'hover',
})<IProps>(({ theme, open }) => ({
  width: sidebarAdditionalHoverArea * 2,
  height: sidebarAdditionalHoverArea * 2,
  filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.10))',
  position: 'absolute',
  top: 10,
  right: -sidebarAdditionalHoverArea,
  zIndex: 999,
  background: '#fff',
  transition: theme.transitions.create('width'),

  '&:hover': {
    color: '#fff',
    background: theme.palette.primary.dark,
  },

  ...(!open && {
    transform: 'rotate(180deg)',
  }),
}))
