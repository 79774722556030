import { Paper, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useDepositList } from '@entities/deposit'

import { ListLoader, useCustomPagination } from '@shared/ui'
import { getPaginationSkip } from '@shared/utils'

import { depositListPageId } from '../model'

import { DepositList } from './components'

export function DepositListPage() {
  // TODO: use this hook when the filters are ready
  // const { currentStatus, filtersOptions } = useWithdrawsFilters()
  const { t } = useTranslation(['pages', 'shared'])

  const {
    page,
    take,
    paginationNode,
    setTotalPages,
    setListLength,
    // setPage,
  } = useCustomPagination()

  const { data: deposits, isLoading } = useDepositList({
    take,
    skip: getPaginationSkip(take, page),
  })

  useEffect(() => {
    if (deposits) {
      const totalPages = Math.ceil(deposits.total / deposits.take)

      setTotalPages(totalPages)

      setListLength(deposits.list.length)
    }
  }, [deposits?.take])

  // useEffect(() => {
  //   setPage(1)
  // }, [currentStatus])

  return (
    <Paper sx={{ padding: '16px 24px 28px' }} id={depositListPageId}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p="12px"
      >
        <Typography variant="h4">{t('deposit-list.title')}</Typography>
      </Stack>

      {isLoading && <ListLoader skeletonsNumber={take} />}

      {!isLoading && deposits && deposits?.list.length > 0 && (
        <DepositList deposits={deposits.list} />
      )}

      {!isLoading && !deposits?.list.length && (
        <Typography variant="h6">{t('shared:no-data')}</Typography>
      )}

      {paginationNode}
    </Paper>
  )
}
