import { LinearProgress, Paper, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { ShortInfoBar } from '@widgets/short-info-bar'

import {
  AddDocuments,
  IResetFormAddDocumentsRef,
} from '@features/add-documents'

import { useUploadFileSettings } from '@entities/upload-file-settings'
import { useWithdrawal, useWithdrawalStatusOptions } from '@entities/withdrawal'

import { TParamsKeys } from '@shared/constants'
import { useBoxMediaQuery } from '@shared/hooks'
import { ConfirmActionDialog, OtpDialog, StatusApproveReject } from '@shared/ui'

import {
  useConfirmWithdrawal,
  useRejectWithdrawal,
  withdrawalPageId,
  useWithdrawalShortInfoBarTemplate,
  useAddWithdrawalDocumentsMutation,
} from '../model'

import {
  WithdrawalDocumentList,
  WithdrawalBreadcrumbs,
  WithdrawalInfo,
} from './components'

const mainPartId = 'withdrawal-main-part'

export function WithdrawalPage() {
  const { withdrawalId } = useParams<TParamsKeys>()
  const addDocumentsRef = useRef<IResetFormAddDocumentsRef>(null)

  const {
    data: withdrawal,
    isLoading,
    isError,
    failureReason,
  } = useWithdrawal(withdrawalId)

  const { data: uploadFileSettings } = useUploadFileSettings()

  const boxMdAndUp = useBoxMediaQuery(withdrawalPageId, isLoading).up('md')
  const box1000AndUp = useBoxMediaQuery(mainPartId, isLoading).up(1000)

  const confirmMethods = useConfirmWithdrawal(withdrawalId)
  const rejectMethods = useRejectWithdrawal(withdrawalId)

  const shortInfoBarTemplate = useWithdrawalShortInfoBarTemplate(withdrawal)

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.error}`)
  }, [failureReason])

  const { getStatusColor, getStatusText } = useWithdrawalStatusOptions(
    withdrawal?.status
  )

  const { mutate: addDocuments, isPending } = useAddWithdrawalDocumentsMutation(
    withdrawalId,
    addDocumentsRef.current?.resetForm
  )

  return (
    <>
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      {!isLoading && (
        <Paper sx={{ p: '32px 24px 20px' }} id={mainPartId}>
          <Stack direction="row" spacing={3}>
            <Box flex={1} id={withdrawalPageId}>
              <WithdrawalBreadcrumbs
                userName={withdrawal?.user.fullName}
                sx={{ mb: 2 }}
              />

              <Stack
                direction={boxMdAndUp ? 'row' : 'column'}
                justifyContent="space-between"
                alignItems="flex-start"
                gap={2}
                mb={2}
              >
                {withdrawal && <WithdrawalInfo {...withdrawal} />}

                <StatusApproveReject
                  bgcolor={getStatusColor()}
                  text={getStatusText()}
                  hideApproveButton={withdrawal?.status !== 'USER_APPROVED'}
                  hideRejectButton={
                    withdrawal?.status !== 'PENDING' &&
                    withdrawal?.status !== 'USER_APPROVED'
                  }
                  onApprove={confirmMethods.handleClickConfirm}
                  onReject={rejectMethods.handleClickReject}
                  loading={confirmMethods.isLoading}
                  sx={{ order: boxMdAndUp ? 'initial' : -1 }}
                />
              </Stack>

              {!!withdrawal?.s3Links?.length && (
                <WithdrawalDocumentList
                  s3Links={withdrawal.s3Links}
                  sx={{ mb: 1.5 }}
                />
              )}

              {uploadFileSettings && (
                <AddDocuments
                  pageId={withdrawalPageId}
                  uploadFileSettings={uploadFileSettings}
                  onSubmit={(fileList) =>
                    withdrawalId &&
                    withdrawal &&
                    addDocuments({
                      fileList,
                      withdrawalId,
                      userId: withdrawal.user.id,
                    })
                  }
                  loading={isPending}
                  ref={addDocumentsRef}
                />
              )}
            </Box>

            <ShortInfoBar
              template={shortInfoBarTemplate}
              viewMode={box1000AndUp ? 'desktop' : 'mobile'}
            />
          </Stack>
        </Paper>
      )}

      <OtpDialog
        show={confirmMethods.isShowOtp}
        onClose={confirmMethods.closeOtpDialog}
        confirmationMethod={confirmMethods.confirmationMethod}
        onConfirm={confirmMethods.onConfirm}
        resendCodeMethod={confirmMethods.resendOtp}
        timer={confirmMethods.withdrawalConfirmationOptions?.end}
      />

      <ConfirmActionDialog
        open={rejectMethods.isShowRejectDialog}
        onClose={rejectMethods.closeRejectDialog}
        onClickConfirm={rejectMethods.handleRejectWithdrawal}
        loading={rejectMethods.isLoading}
      />
    </>
  )
}
